<ng-container *transloco="let tFilter; read: 'filter'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        (closeModal)="forceWithValidation()"
        (keydown.tab)="$event.stopPropagation();"
        [showLoader]="showLoader"
        [errors]="errors"
    >
        <ng-container title>
            <div class="text-primaryDark body-2">{{tFilter('active_filters')}}</div>
        </ng-container>

        <div (click)="$event.stopPropagation()" class="max-sm:mt-4 min-w-[540px] h-[672px]">
            <div class="flex justify-end mb-2 max-sm:pr-2">
                <span
                    (click)="clearAll()"
                    class="body-2 text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                    *ngIf="isClearAll()"
                >
                    {{tFilter('clear_all').toUpperCase()}}
                </span>
            </div>

            <ng-container *ngIf="(fields$|async) || { fieldTypes: [], fields: {}}; let fieldData">
                <div class="overflow-auto max-sm:pr-2 max-sm:mb-2">
                    <app-filter-group
                        class="p-0"
                        (deleteGroup)="deleteGroup(groupIndex)"
                        (clearAll)="clearAll()"
                        *ngFor="let group of formGroups; index as groupIndex"
                        [selectedFolder]="selectedFolder"
                        [fieldTypes]="fieldData.fieldTypes"
                        [fields]="fieldData.fields"
                        [groupIndex]="groupIndex"
                        [single]="formFilterService.getFilterFormArray.length === 1"
                        [isEmpty]="isFilterFormEmpty"
                    ></app-filter-group>
                    <div *ngIf="!isFilterFormEmpty">
                        <app-tb-button
                            *transloco="let tFilter; read: 'filter'"
                            [appearance]="'text'"
                            [icon]="'add_circle_outline'"
                            [disabled]="!canAddFilterGroup"
                            (click)="addGroupAction($event)"
                            class="flex items-center w-fit p-1 mt-6 sm:mb-0.5"
                        >
                            <span class="body-2">{{tFilter('add_group')}}</span>
                        </app-tb-button>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container buttons>
            <div
                *transloco="let tButtons; read: 'buttons'"
                class="flex flex-row justify-end max-sm:justify-center"
            >
                <div class="flex max-xsm:w-full">
                    <app-tb-button
                        [appearance]="'text'"
                        (click)="resetToDefault()"
                        *ngIf="showResetDefault"
                        class="mr-5"
                    >
                        <span class="body-2">{{tButtons('reset_to_default')}}</span>
                    </app-tb-button>
                    <app-tb-button
                        [appearance]="'outlined'"
                        (click)="applyFilterForSession()"
                        *ngIf="showApplyButton"
                        [disabled]="isDisabledApplyAction"
                        class="mr-5"
                    >
                        <span>{{tButtons('preview')}}</span>
                    </app-tb-button>
                </div>
                <app-tb-button
                    (click)="saveFilter()"
                    *ngIf="showApplyButton"
                    [disabled]="isDisabledApplyAction"
                >
                    <span>{{tButtons('save')}}</span>
                </app-tb-button>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
