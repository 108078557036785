import { Injectable, OnDestroy } from '@angular/core'
import { WsBaseService } from '@core/services/ws-base.service'
import * as signalR from '@microsoft/signalr'
import { HubConnection, LogLevel } from '@microsoft/signalr'
import { LogService } from '@services/log.service'
import { EMPTY, from, Observable, of, Subject } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ObjectResponseModel } from '../models'
import { AuthResponse, AuthService } from './auth-service'

@Injectable()
export class WsService extends WsBaseService implements OnDestroy {
    private connection: HubConnection | undefined
    private onMessageSubject = new Subject<ObjectResponseModel>()

    constructor(private authService: AuthService, private logService: LogService) {
        super()
    }

    private static logError(error: any): Observable<never> {
        console.log(error)
        return EMPTY
    }

    disconnect() {
        return this.authService.logout()
    }

    connect(): Observable<void> {
        console.log('connect')
        return this.authService.auth().pipe(
            switchMap((resp: AuthResponse) =>
                this.stopConnection().pipe(
                    switchMap(() => {
                        if (resp.data.ws && resp.data.token) {
                            this.connection = new signalR.HubConnectionBuilder()
                                .withUrl(resp.data.ws, {
                                    accessTokenFactory: (): string | Promise<string> =>
                                        resp.data.token!,
                                })
                                .configureLogging(LogLevel.Debug)
                                .build()

                            this.connection.onclose(() => {
                                // TODO: implement reconnect
                                // this.connect()
                                console.log('onclose')
                            })

                            this.connection.onreconnecting(() => {
                                console.log('onreconnecting')
                            })

                            this.connection.onreconnected(() => {
                                console.log('onreconnected')
                            })

                            return from(this.connection.start())
                        }
                        return EMPTY
                    }),
                ),
            ),
            catchError((e) => {
                console.error(e)
                return this.connect()
            }),
        )
    }

    onMessage(): Observable<ObjectResponseModel> {
        return this.onMessageSubject.asObservable()
    }

    sendMessage(endpoint: string, body?: any): void {
        let then = () => {}

        if (body) {
            this.connection!.send(endpoint, body).then(then)
        } else {
            this.connection!.send(endpoint).then(then)
        }
    }

    invokeMessage(endpoint: string, body?: object): Observable<ObjectResponseModel> {
        this.logService.request(endpoint, JSON.stringify(body))
        const invocationMassage = this.generateInvocationMessage(body)
        const request: Promise<ObjectResponseModel> = this.connection!.invoke(
            endpoint,
            invocationMassage,
        )
        return from(request)
    }

    stopConnection(): Observable<void> {
        if (this.connection) {
            return from(this.connection.stop()).pipe(catchError(WsService.logError))
        }

        return of(undefined)
    }

    ngOnDestroy(): void {
        this.stopConnection()
    }

    public subOnMessage(endpoint: string): void {
        if (this.connection && this.onMessageSubject) {
            this.connection.on(endpoint, (res) => {
                this.onMessageSubject.next(res)
            })
        }
    }
}
