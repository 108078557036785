import { createFeatureSelector } from '@ngrx/store'
import { configAdapter, ConfigState } from './config.reducer'
import { StoreFeatureNames } from '../store-feature-names'

const selectConfigState = createFeatureSelector<ConfigState>(StoreFeatureNames.Config)

const { selectIds, selectEntities, selectAll, selectTotal } =
    configAdapter.getSelectors(selectConfigState)

export const selectConfigIds = selectIds
export const selectConfigEntities = selectEntities
export const selectAllConfigs = selectAll
export const selectConfigTotal = selectTotal
