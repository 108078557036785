<div
    class="flex flex-col max-h-[96vh] relative"
    [class]="containerClass"
    (click)="$event.stopPropagation()"
>
    <div class="empty:hidden">
        <div class="flex">
            <div class="flex w-full title"><ng-content select="[title]"></ng-content></div>

            <div class="button-container" *ngIf="containerLayout === ModalContainer.Dialog">
                <ng-container *ngIf="!headerButtons.childElementCount">
                    <app-tb-button appearance="icon" (click)="closeModal.emit()">
                        <app-tb-icon class="icon-m" icon="close"></app-tb-icon>
                    </app-tb-button>
                </ng-container>

                <div class="flex" #headerButtons>
                    <ng-content select="[headerButtons]"></ng-content>
                </div>
            </div>
        </div>
        <div class="caption-1 text-newTextLight"><ng-content select="[subtitle]"></ng-content></div>
    </div>

    <div class="modal-content overflow-auto flex-1 relative">
        <div>
            <ng-content></ng-content>
        </div>
    </div>

    <div class="absolute inset-0 flex items-center justify-center z-50" *ngIf="showLoader">
        <div class="w-full h-full bg-newNeutral1 opacity-50"></div>
        <mat-spinner class="absolute"></mat-spinner>
    </div>

    <div *ngIf="errors" class="text-red-600 pt-4">
        <div *ngFor="let error of errors">{{ error }}</div>
    </div>

    <div class="empty:hidden modal-buttons">
        <ng-content select="[buttons]"></ng-content>
    </div>
</div>
