import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core'
import { Folder } from '@core/models'

@Component({
    selector: 'app-folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderComponent {
    @Input() folder!: Folder
    @Input() selectedFolderGuid!: string
    @Input() canEdit: boolean = true
    @Input() canDelete: boolean = true
    @Input() showSelectedIcon = false

    @Output() edit = new EventEmitter<void>()
    //TODO: delete EE
    @Output() delete = new EventEmitter<void>()
    @Output() selectFolder: EventEmitter<Folder> = new EventEmitter<Folder>()

    hovered = false

    @HostListener('mouseover', ['$event'])
    mouseOver() {
        this.hovered = true
    }

    @HostListener('mouseleave', ['$event'])
    mouseLeave() {
        this.hovered = false
    }

    get showSettings() {
        return !(this.isSelected && !this.hovered && !this.showSelectedIcon)
    }

    get isSelected() {
        return this.folder.guid === this.selectedFolderGuid
    }
}
