import { Component, Inject } from '@angular/core'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import {
    FieldTypeSelectorComponent,
    FieldTypeSelectorData,
} from '@app/shared/field-type-selector/field-type-selector.component'
import { AccordionContentContext } from '@app/shared/fields-collection/fields-collection.component'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Field, FieldTypes, Folder } from '@core/models'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-manage-fields',
    templateUrl: './manage-fields.component.html',
})
export class ManageFieldsComponent extends ModalContainerComponent<unknown, void> {
    readonly excludedFieldTypes = [FieldTypes.NAME] as string[]

    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    folders$ = this.folderFacadeService.selectAllFolders$
    selectedSchema$ = this.schemaFacadeService.selectSelectedTableSchema$
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    contextType = {} as AccordionContentContext

    constructor(
        @Inject(ModalContainerDataToken) protected data: unknown,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private manageFieldsService: ManageFieldsService,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    addField(selectedFolder: Folder) {
        this.modalManagerService.openDialog<FieldTypeSelectorComponent, FieldTypeSelectorData>({
            component: FieldTypeSelectorComponent,
            data: { selectedFolder },
        })
    }

    deleteField(field: Field) {
        this.showLoader = true
        this.manageFieldsService
            .deleteField(field.guid)
            .pipe(take(1), untilDestroyed(this))
            .subscribe({
                next: (data) => {
                    if (data && data.error) {
                        this.errors = data.error.map((error) => error.message)
                    }
                },
                error: (error) => {
                    this.errors = [error]
                },
                complete: () => {
                    this.showLoader = false
                },
            })
    }
}
