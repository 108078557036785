export interface SelectObjectOptions {
    [guid: string]: SelectOption
}

export interface SelectOption {
    label: string
    color: string
    icon?: string
    tags?: string
    guid?: string
    is_default?: number
    is_new?: boolean
}

export function prepareSelectOptionModel(selectObjectOptions: SelectObjectOptions) {
    Object.keys(selectObjectOptions).forEach((guid) => {
        if (selectObjectOptions[guid].is_new) {
            delete selectObjectOptions[guid].is_new
            return
        }
        selectObjectOptions[guid].guid = guid
    })
    return selectObjectOptions
}

export function prepareSelectOptionModelSorted(selectOptions: SelectOption[]) {
    return selectOptions.map((option) => {
        if (option.is_new) {
            return {
                label: option.label,
                color: option.color,
                icon: option.icon,
                tags: option.tags,
                is_default: option.is_default,
            }
        }
        return option
    })
}
