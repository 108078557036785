import { ResponseUserModel, ResponseUserModelEntities } from '../response'
import { User, UserEntities } from '../ui'

export function generateUser(guid: string, userModel: ResponseUserModel): User {
    return {
        ...userModel,
        guid,
        fullName: getUserFullName(userModel),
    }
}

export function generateUserEntities(users: ResponseUserModelEntities): UserEntities {
    return Object.keys(users)
        .map((key) => ({
            ...generateUser(key, users[key]),
        }))
        .reduce((prev, curr) => ({ ...prev, ...{ [curr.guid]: curr } }), {})
}

export function getUserFullName(user: ResponseUserModel) {
    return `${user.first_name} ${user.last_name}`.trim()
}
