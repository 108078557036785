import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatDialog } from '@angular/material/dialog'
import {
    DialogGroupCellEditInput,
    DialogGroupCellEditOutput,
    GroupCellEditComponent,
} from '@app/feature/actions-panel/group-cell-edit/group-cell-edit.component'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldEntities,
    FieldType,
    FieldTypes,
    findFieldByType,
    Folder,
} from '@core/models'
import { AutomationTypes } from '@core/models/response/automation.model'
import {
    Automation,
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '@core/models/ui/automation.model'
import { SchemaFacadeService } from '@core/services/store-facade'
import { AutomationFacadeService } from '@core/services/store-facade/automation-facade.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'

/* todo: [table-ref-2] Refactoring notes
 *   We should create Service like Action Panel Service
 *   It will store: selectedRecords
 *   It will make Requests to delete and edit records.
 * */
@UntilDestroy()
@Component({
    selector: 'app-actions-panel',
    templateUrl: './actions-panel.component.html',
    styleUrls: ['./actions-panel.component.sass'],
})
export class ActionsPanelComponent implements OnInit {
    // todo: remove all inputs, this component should get all needed data from store.
    @Input()
    fields!: FieldEntities

    @Input()
    selectedRecords!: BusinessRecords[]

    @Input()
    fieldTypes!: Dictionary<FieldType>

    // todo: [table-ref-2] it is unnecessary to pass count
    @Input()
    selectedCount!: number

    @Input()
    selectedFolder!: Folder

    @Input()
    cells!: { [recordGuid: string]: CellEntities }

    // todo: [table-ref-2] after removing inputs and getting all data from store we won't need to have these outputs
    @Output()
    deleteRecords: EventEmitter<any> = new EventEmitter()

    @Output()
    clearSelected: EventEmitter<any> = new EventEmitter()

    @Output()
    editRecords: EventEmitter<any> = new EventEmitter()

    fieldsList!: Field[]

    automations$ = this.automationFacadeService.selectSelectedTableSchemaAutomations$
    schemaGuid!: string

    // todo: [table-ref-2] rename
    targetGuid!: string

    constructor(
        public dialog: MatDialog,
        private automationFacadeService: AutomationFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private bottomSheet: MatBottomSheet,
    ) {}

    ngOnInit() {
        this.fieldsList = Object.values(this.fields)
            .filter((field) => !field.operationCode?.system)
            .filter((field) => !(field.system_name === 'name'))
        this.selectedCount = this.selectedRecords.length
        this.schemaGuid = this.selectedRecords[0].schemaGuid
        this.schemaFacadeService
            .selectSchemaByGuid$(this.schemaGuid)
            .pipe(untilDestroyed(this))
            .subscribe((schema) => {
                if (!schema) return

                // TODO: delete. temporary solution for automations
                this.targetGuid = findFieldByType(schema.fieldEntities, FieldTypes.TEXT)!.guid
            })
    }

    @ViewChild('actionButtons') TemplateBottomSheet!: TemplateRef<any>

    openBottomSheetS() {
        this.bottomSheet.open(this.TemplateBottomSheet, { panelClass: 'bottomSheetCustom' })
    }

    closeBottomSheetS() {
        this.bottomSheet.dismiss()
    }

    // todo: [table-ref] it deletes only selected records
    deleteAllRecords() {
        this.deleteRecords.emit()
    }

    clearAllSelected() {
        this.clearSelected.emit()
    }

    editField(field: Field) {
        const dialogRef = this.dialog.open<
            GroupCellEditComponent,
            DialogGroupCellEditInput,
            DialogGroupCellEditOutput | undefined
        >(GroupCellEditComponent, {
            minWidth: '400px',
            data: {
                records: this.selectedRecords,
                field: field,
                fieldTypes: this.fieldTypes,
                cells: this.cells,
            },
        })

        dialogRef.afterClosed().subscribe((res: DialogGroupCellEditOutput | undefined) => {
            if (res) {
                this.editRecords.emit({ value: res.value, field: res.field })
            }
        })
    }

    // no design, final solution will be implemented later, for testing purposes

    createAutomation() {
        const createModel: AutomationCreateModel = {
            name: 'test',
            solution_object_type_guid: this.schemaGuid,
            conditions: [
                {
                    source_type: 'source_type_event',
                    source_details: 'create',
                    values: [],
                },
            ],
            actions: [
                {
                    action_type_code: 'action_item',
                    action_config: {
                        target_object_field_guid: this.targetGuid,
                        value: 'automation test data',
                    },
                },
            ],
            automation_type: AutomationTypes.AUTO,
            is_active: 0,
            user_guids: [],
            is_global: 0,
        }

        this.automationFacadeService.createAutomation(createModel)
        this.clearAllSelected()
    }

    updateAutomation(automation: Automation) {
        const updateModel: AutomationUpdateModel = {
            automationGuid: automation.guid,
            automationModel: {
                name: automation.name + '1',
                solution_object_type_guid: this.schemaGuid,
                conditions: [
                    {
                        source_type: 'source_type_event',
                        source_details: 'create',
                        values: [],
                    },
                ],
                actions: [
                    {
                        action_type_code: 'action_item',
                        action_config: {
                            target_object_field_guid: this.targetGuid,
                            value: 'automation test data',
                        },
                    },
                ],
                automation_type: AutomationTypes.AUTO,
                is_active: 0,
                user_guids: [],
                is_global: 0,
            },
        }

        this.automationFacadeService.updateAutomation(updateModel)
        this.clearAllSelected()
    }

    deleteAutomation(guid: string) {
        const deleteModel: AutomationDeleteModel = {
            guid: guid,
            objectTypeGuid: this.schemaGuid,
        }

        this.automationFacadeService.deleteAutomation(deleteModel)
        this.clearAllSelected()
    }

    executeAutomation(guid: string) {
        const executeModel: AutomationExecuteModel = {
            guid,
            objectTypeGuid: this.schemaGuid,
            records: this.selectedRecords,
        }

        this.automationFacadeService.executeAutomation(executeModel)
        this.clearAllSelected()
    }
}
