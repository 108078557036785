import { Injectable } from '@angular/core'
import { BaseContainer } from './modal-containers/base-container'
import { ModalContainerComponent } from './modal-container-component/modal-container.component'
import { first, last } from 'lodash-es'

type ModalContainerItem = {
    container: BaseContainer<ModalContainerComponent>
    /**
     * If current container is replaceable, we shouldn't keep it in the Containers Stack, when the new Container is adding, we should replace it with the new Container.
     *
     * Used mainly for Menu container.
     **/
    replaceable: boolean
}

/**
 * ModalContainersService stores the Modal Containers stack and provide the API to manipulate with it.
 **/
export class ModalContainersStore {
    /**
     * Modal Containers stack that participate Modal Flow. It clears when we close Modal Flow, anf It stacks up when we make Navigation.
     **/
    private _containers: ModalContainerItem[] = []

    /**
     * Returns current container.
     **/
    get activeContainer() {
        return last(this.containers)?.container
    }

    /**
     * Returns first container.
     **/
    get firstContainer() {
        return first(this.containers)?.container
    }

    /**
     * Returns all stored containers.
     **/
    get containers() {
        return this._containers
    }

    addContainer(container: BaseContainer<ModalContainerComponent>, replaceable = false) {
        /**
         * If current container is replaceable, we shouldn't keep it in the Containers Stack, we should replace it with the new Container.
         **/
        const lastContainer = last(this.containers)
        if (lastContainer && lastContainer.replaceable) {
            lastContainer.container.close()
            lastContainer.container.destroy()

            const lastContainerIndex = this.containers.length - 1
            this.containers.splice(lastContainerIndex, 1, { container, replaceable })
            return
        }

        this.containers.push({ container, replaceable })
    }

    /**
     * Returns the last container.
     **/
    removeLastContainer() {
        return this.containers.pop()?.container
    }

    /**
     * This method calls close() on the first container to notify subscribers who wait for that.
     *
     * Let's consider the situation.
     *
     * We open Dialog 1 then we navigate to Dialog 2, and while the Dialog 2 open we click outside and confirm closing the popup.
     * In this case we have two open subscription: first when we opened Dialog 1, second when opened Dialog 2.
     * But when we close Modal Flow while Dialog 2 is opened we shouldn't call Dialog 2 closed event(),
     * we should call closed() only on the first container element.
     **/
    closeAll() {
        this.containers[0]?.container.close()
    }

    /**
     * This method calls close() with passed data and destroys the container.
     **/
    closeContainer(closeContainer: BaseContainer<ModalContainerComponent>, data?: unknown) {
        closeContainer.close(data)
        closeContainer.destroy()
    }

    /**
     * This method destroys all containers and clear the stack.
     *
     * It is called when the Modal Flow is about to be closed.
     **/
    clear() {
        this.containers.forEach((item) => {
            item.container.destroy()
        })
        this._containers = []
    }

    /**
     * Check if stack is not empty
     **/
    hasContainers() {
        return !!this.containers.length
    }

    /**
     * Check if some of the Containers has changes.
     *
     * This method called in [DialogInstanceService.reopen]{@link DialogInstanceService#reopen} and [BottomSheetInstanceService.reopen]{@link BottomSheetInstanceService#reopen} to check if we need to open Confirmation Dialog.
     **/
    hasMarkedContainer() {
        return this.containers.some((item) => {
            return item.container.componentRef.instance.hasChanged()
        })
    }

    /**
     * Get the active componentRef to render it. It used only in {@link TbMenuComponent}
     **/
    getComponentRef() {
        return last(this.containers)?.container.componentRef
    }
}
