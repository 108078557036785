import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatOptionModule } from '@angular/material/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { SharedModule } from '@app/shared/shared.module'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'
import { CardCreateSubtaskComponent } from './card-create-subtask/card-create-subtask.component'
import { CardSubtasksComponent } from './card-subtasks/card-subtasks.component'
import { CardComponent } from './card.component'
import { CardContentComponent } from './content/card-content.component'
import { CardLinkReferencesComponent } from './content/card-link-references/card-link-references.component'
import { CardDescriptionComponent } from './content/description/card-description.component'
import { CardFieldsComponent } from './content/fields/card-fields.component'
import { CardFieldComponent } from './content/fields/field/card-field.component'
import { CardMainFieldsComponent } from './content/main-fields/card-main-fields.component'
import { CardStatusRowComponent } from './content/status-row/card-status-row.component'
import { CardSubtitleComponent } from './content/subtitle/card-subtitle.component'
import { CardHeaderComponent } from './header/card-header.component'
import { CardTitleComponent } from './title/card-title.component'
import { CardUpdateComponent } from './update/card-update.component'

@NgModule({
    declarations: [
        CardComponent,
        CardFieldComponent,
        CardUpdateComponent,
        CardTitleComponent,
        CardMainFieldsComponent,
        CardSubtitleComponent,
        CardStatusRowComponent,
        CardHeaderComponent,
        CardFieldsComponent,
        CardContentComponent,
        CardLinkReferencesComponent,
        CardDescriptionComponent,
        CardSubtasksComponent,
        CardCreateSubtaskComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatInputModule,
        SharedModule,
        TranslocoModule,
        ComponentsLibraryModule,
        MatTableModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    exports: [CardComponent],
})
export class CardModule {}
