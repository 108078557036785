import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { User } from '@core/models'

@Component({
    selector: 'app-people-menu',
    templateUrl: './people-menu.component.html',
    styleUrls: ['./people-menu.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleMenuComponent {
    @Input()
    users!: User[]

    @Input()
    activeUserGuids?: string[]

    @Input()
    allowEdit!: boolean

    @Output()
    changeUser = new EventEmitter<any>()

    toggleUser(guid: string) {
        this.changeUser.emit(guid)
    }
}
