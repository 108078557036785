import { createFeature, createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Automation } from '../../models/ui/automation.model'
import {
    addAutomationsFromResponse,
    deleteAutomationFromResponse,
    setActionTypes,
    setAutomation,
    setSourceTypes,
    updateAutomationFromResponse,
} from './automation.actions'
import { StoreFeatureNames } from '../store-feature-names'
import { ActionTypeModel } from '@core/models/response/action-type.model'
import { SourceTypeModel } from '@core/models/response/source-type.model'
import { dataReset } from '../common/common.actions'

const automationFeatureKey = StoreFeatureNames.Automation

export interface AutomationState extends EntityState<Automation> {
    actionTypes: ActionTypeModel | null
    sourceTypes: SourceTypeModel | null
}

function selectAutomationId(automation: Automation): string {
    return automation.guid
}

export const automationAdapter: EntityAdapter<Automation> = createEntityAdapter<Automation>({
    selectId: selectAutomationId,
})

const initialState: AutomationState = automationAdapter.getInitialState({
    sourceTypes: null,
    actionTypes: null,
})

const automationReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(setAutomation, (state: AutomationState, { automations }) => {
        return automationAdapter.setAll(automations, state)
    }),

    on(setActionTypes, (state: AutomationState, { actionTypes }) => {
        return {
            ...state,
            actionTypes,
        }
    }),

    on(setSourceTypes, (state: AutomationState, { sourceTypes }) => {
        return {
            ...state,
            sourceTypes,
        }
    }),

    on(addAutomationsFromResponse, (state: AutomationState, { automation }) => {
        return automationAdapter.addOne(automation, state)
    }),

    on(updateAutomationFromResponse, (state: AutomationState, { automation }) => {
        return automationAdapter.updateOne(automation, state)
    }),

    on(deleteAutomationFromResponse, (state: AutomationState, { automations }) => {
        return automationAdapter.removeMany(automations, state)
    }),
)

export const automationFeature = createFeature({
    name: automationFeatureKey,
    reducer: automationReducer,
})
