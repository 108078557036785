import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SearchDialogComponent } from './search-dialog/search-dialog.component'

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
})
export class SearchComponent {
    constructor(public dialog: MatDialog) {}

    openSearch() {
        this.dialog.open<SearchDialogComponent>(SearchDialogComponent, {
            width: '100%',
        })
    }
}
