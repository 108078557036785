<div
    *ngIf="!disabled, else disabledContent"
    [matMenuTriggerFor]="statusMenu"
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer status-title_wrap"
>
    <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
    <span class="status-title"> {{getTitle(value) | async}} </span>
</div>
<ng-template #disabledContent>
    <div
        [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
        class="c-pointer status-title_wrap"
    >
        <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
        <span class="status-title"> {{getTitle(value) | async }} </span>
    </div>
</ng-template>
<mat-menu #statusMenu="matMenu" class="status-menu">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <!-- change to app-tb-button after new design will be implemented -->
            <button (click)="checkActive(key)" *ngFor="let key of  optionKeys()" mat-menu-item>
                <span
                    [ngStyle]="{'background': statusData[key].color}"
                    class="status-color_label mr-sm"
                ></span>
                <span class="status-label"> {{statusData[key].label}} </span>
                <app-tb-icon
                    class="status-icon ml-sm text-muted"
                    [icon]="statusData[key].icon || ''"
                ></app-tb-icon>
                <app-tb-icon
                    *ngIf="key === value"
                    class="active-check-label"
                    icon="check"
                ></app-tb-icon>
                <app-tb-icon *ngIf="key === default" icon="ac_unit"></app-tb-icon>
            </button>
        </div>
    </ng-template>
</mat-menu>
