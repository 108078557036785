<div
    class="mb-2 p-2 sm:p-1 rounded cursor-pointer max-sm:bg-primaryBackground hover:bg-primaryBackground workSpHolder"
    (click)="selectFolder.emit(folder)"
>
    <div class="flex items-center">
        <mat-icon class="mr-2" [style.color]="folder.color.value"> {{folder.icon.value}} </mat-icon>
        <span class="mr-2" [class.font-semibold]="isSelected"> {{folder.name.value}} </span>

        <ng-container *ngIf="showSelectedIcon && isSelected">
            <ng-container *ngTemplateOutlet="selectedIcon"></ng-container>
        </ng-container>

        <mat-icon
            *ngIf="showSettings; else selectedIcon"
            (click)="edit.emit(); $event.stopPropagation()"
            svgIcon="custom_settings"
            class="flex items-center justify-content-center w-5 h-5 text-primarySecondary ml-auto workSpSettings"
        ></mat-icon>

        <ng-template #selectedIcon>
            <mat-icon
                class="ml-auto flex items-center justify-content-center w-5 h-5 text-secondaryMain sm:bg-white text-lg leading-5"
            >
                check
            </mat-icon>
        </ng-template>
    </div>
</div>
