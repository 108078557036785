import { ChangeDetectionStrategy, Component, Optional } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatDialogRef } from '@angular/material/dialog'
import { throwAndLogError } from '@core/global-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { LogService } from '@services/log.service'
import { Observable, switchMap } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { CreateViewConfiguration, getViewIconByCode, ViewTypeCodes } from '@core/models'
import { ViewFacadeService } from '@core/services/store-facade'

interface ViewTypeWithIcon {
    name: string
    icon: string
}

@UntilDestroy()
@Component({
    selector: 'app-create-view',
    templateUrl: './create-view.component.html',
    styleUrls: ['./create-view.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateViewComponent {
    private readonly defaultViewType = ViewTypeCodes.TABLE
    viewName = new FormControl<string>('', Validators.required)
    viewType: ViewTypeWithIcon = {
        name: this.defaultViewType,
        icon: getViewIconByCode(this.defaultViewType),
    }

    viewTypes$: Observable<ViewTypeWithIcon[]> = this.viewFacadeService.availableViewTypes$.pipe(
        untilDestroyed(this),
        map((types) => (types.length ? types : ['table'])),
        map((types) => {
            return types
                .filter((type) => type !== ViewTypeCodes.LIST)
                .map((type) => ({
                    name: type,
                    icon: getViewIconByCode(type),
                }))
        }),
    )

    constructor(
        private viewFacadeService: ViewFacadeService,
        private logService: LogService,
        @Optional()
        public dialogRef: MatDialogRef<void>,
        @Optional()
        private bottomSheetRef: MatBottomSheetRef,
    ) {}

    selectViewType(type: ViewTypeWithIcon) {
        this.viewType = type
    }

    addNewView() {
        //TODO: implement solution for situation where user have no views
        this.viewFacadeService.selectedView$
            .pipe(
                take(1),
                switchMap((selectedView) => {
                    if (!selectedView) return throwAndLogError(this.logService, 'No view selected')

                    const model: CreateViewConfiguration = {
                        name: { newValue: this.viewName.value!, cell: selectedView.name },
                        type_code: { newValue: this.viewType.name, cell: selectedView.type_code },
                    }

                    return this.viewFacadeService.createViewRequest(model)
                }),
            )
            .subscribe(() => {
                this.closeModal()
            })
    }

    closeModal() {
        this.dialogRef?.close()
        this.bottomSheetRef?.dismiss()
    }
}
