<div *transloco="let tCellTypesErrors; read: 'cell_types'" class="edit-mode-form">
    <ng-container *ngIf="!isEdit">
        <ng-container *ngIf="isCard, else nonCardContent">
            <span (click)="editMode()" *ngIf="value; else placeholder" class="input-label">
                {{value}}
            </span>
            <ng-template #placeholder>
                <app-tb-icon
                    (click)="editMode()"
                    class="text-muted input-label"
                    icon="remove"
                ></app-tb-icon>
            </ng-template>
        </ng-container>
        <ng-template #nonCardContent>
            <span *ngIf="value; else placeholder" class="input-label">
                {{tCellTypesErrors('rich_text_placeholder')}}
            </span>
            <ng-template #placeholder>
                <app-tb-icon icon="remove"></app-tb-icon>
            </ng-template>
        </ng-template>
    </ng-container>
    <div *ngIf="isEdit" [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <textarea
                    (focusout)="saveValue()"
                    [appAutoFocus]="isEdit"
                    [formControl]="textFormControl"
                    autocomplete="off"
                    matInput
                    name="text"
                >
                </textarea>
                <app-tb-icon
                    (click)="saveValue()"
                    class="text-success"
                    icon="check_circle"
                ></app-tb-icon>
            </div>
        </mat-form-field>
    </div>
    <ng-container *ngIf="isEdit">
        <mat-error *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
            {{tCellTypesErrors('value_is')}}
            <strong>{{tCellTypesErrors('required')}}</strong>
        </mat-error>
    </ng-container>
</div>
