<div class="content-start" *transloco="let tUserSettings; read: 'user_settings'">
    <div
        *ngIf="(solution$ | async) as solution"
        class="flex items-center sm:px-4 py-2 max-sm:pt-0 max-sm:pb-4 sm:bg-newPrimaryDark text-newPrimaryDark capitalize sm:text-newInversed text-xl sm:body-1"
    >
        <!--        needs to be changed for dynamic names-->
        <div
            class="flex items-center justify-center w-5 h-5 rounded-full mr-2 body-1 text-newInversed bg-secondaryMain"
        >
            {{ solution.name[0] }}
        </div>
        <div>{{ solution.name }}</div>
    </div>
    <div class="dropdown-holder">
        <div (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span> {{ tUserSettings('log_out') }}</span>
        </div>
        <div (click)="resetSolution()">
            <mat-icon>refresh</mat-icon>
            <span> {{ tUserSettings('reset_solution') }}</span>
        </div>
        <div class="mb-2">
            <mat-icon></mat-icon>
            <app-version></app-version>
        </div>
        <app-tb-select
            *appEnv="'mock', visible: true"
            [value]="devDefaultSolutionValue"
            (selectionChange)="devSolutionChanged($event)"
        >
            <app-tb-option *ngFor="let type of devSolutionTypes" [value]="type">
                {{type}}
            </app-tb-option>
        </app-tb-select>
    </div>
</div>
