import { Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-rich-text',
    templateUrl: './rich-text.component.html',
})
export class RichTextComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    ngOnInit() {
        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            this.validatorOrOpts,
        )
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        if (this.value != this.textFormControl.value) {
            this.cellValueChanged.emit(this.textFormControl.value)
        }
        this.isEdit = false
    }
}
