import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core'
import { ResizeColumnService } from '@app/views/table/table/resize-column.service'

@Directive({
    selector: '[appResizeColumn]',
})
export class ResizeColumnDirective implements AfterViewInit {
    @Input() columnIndex!: string
    @Output() resizedWidth: EventEmitter<number> = new EventEmitter<number>()

    MIN_COLUMN_WIDTH = 225

    xPosition: number = 0
    width: number = 0

    constructor(private element: ElementRef, private resizeColumnService: ResizeColumnService) {}

    ngAfterViewInit() {
        const resizer = this.element.nativeElement.querySelector('[data-resizer]')
        resizer?.addEventListener('mousedown', this.mouseDown)

        this.resizeColumnService.registerColumn(this.columnIndex)
        this.resizeColumnService.getResizedWidth$(this.columnIndex)?.subscribe((width) => {
            this.element.nativeElement.style.width = `${width}px`
        })
    }

    mouseDown = (event: MouseEvent) => {
        this.xPosition = event.clientX

        const styles = window.getComputedStyle(this.element.nativeElement)
        this.width = parseInt(styles.width, 10)

        document.addEventListener('mousemove', this.mouseMove)
        document.addEventListener('mouseup', this.mouseUp)
    }

    mouseMove = (event: MouseEvent) => {
        this.resizeColumnService.setResizedWidth(this.columnIndex, this.calculateWidth(event))
    }

    mouseUp = (event: MouseEvent) => {
        document.removeEventListener('mousemove', this.mouseMove)
        document.removeEventListener('mouseup', this.mouseUp)

        this.resizedWidth.emit(this.calculateWidth(event))
    }

    private calculateWidth(event: MouseEvent) {
        const newWidth = this.width + (event.clientX - this.xPosition)
        return newWidth > this.MIN_COLUMN_WIDTH ? newWidth : this.MIN_COLUMN_WIDTH
    }
}
