import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CommonState } from './common.reducer'
import { AppState } from '../app.state'
import { ResponseFieldEntities, SolutionModel } from '../../models'
import { RoleEntities } from '../../models/response/role'
import { StoreFeatureNames } from '../store-feature-names'

export const selectCommonState = createFeatureSelector<CommonState>(StoreFeatureNames.Common)

export const selectLoaded = createSelector<AppState, [CommonState], boolean>(
    selectCommonState,
    (state: CommonState) => state.initLoaded,
)

export const selectDataInitialized = createSelector<AppState, [CommonState], boolean>(
    selectCommonState,
    (state: CommonState) => state.dataInitialized,
)

export const selectLoading = createSelector<AppState, [CommonState], boolean>(
    selectCommonState,
    (state: CommonState) => state.loading,
)

export const selectSolution = createSelector<AppState, [CommonState], SolutionModel>(
    selectCommonState,
    (state: CommonState) => state.solution,
)

export const selectAllAvailableSolutionObjectTypes = createSelector<
    AppState,
    [CommonState],
    string[]
>(selectCommonState, (state: CommonState) => Object.keys(state.solution.setting))

export const selectSystemFields = createSelector<
    AppState,
    [CommonState],
    ResponseFieldEntities | null
>(selectCommonState, (state: CommonState) => state.systemFields)
