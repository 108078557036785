import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { CommonFacadeService } from '@core/services/store-facade'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { NgIf, AsyncPipe } from '@angular/common'

@Component({
    selector: 'app-global-loading',
    templateUrl: './global-loading.component.html',
    styleUrls: ['./global-loading.component.sass'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, AsyncPipe],
})
export class GlobalLoadingComponent {
    loading$: Observable<boolean> = this.commonFacadeService.selectLoading$

    constructor(private commonFacadeService: CommonFacadeService) {}
}
