import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'

@NgModule({
    declarations: [TbMenuListComponent],
    imports: [CommonModule],
    exports: [TbMenuListComponent],
})
export class TbMenuListModule {}
