import { ResponseRecordEntities, ResponseSchema, TableModel } from './response'
import { Schema } from './ui'

/**
 * Interface for services that handle websocket "init data" and "save data" events and update store entities with fresh BE data
 */
export interface SystemResponseHandler<T> {
    initSystemRecord(
        schema: ResponseSchema,
        record: ResponseRecordEntities,
        selectedRecordGuid?: string,
    ): void

    updateRecord(table: TableModel, schema: Schema): void
}
