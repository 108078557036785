import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TbCheckboxColor, TbCheckboxSize } from './models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { MatCheckboxChange } from '@angular/material/checkbox'

@UntilDestroy()
@Component({
    selector: 'app-tb-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TbCheckboxComponent),
            multi: true,
        },
    ],
})
export class TbCheckboxComponent implements ControlValueAccessor {
    @Input() value: boolean = false

    @Input() size: keyof typeof TbCheckboxSize = TbCheckboxSize.normal
    @Input() color: keyof typeof TbCheckboxColor = TbCheckboxColor.primary

    @Input() indeterminate: boolean = false

    @Input() disabled = false

    @Output() valueChange = new EventEmitter<boolean>()

    readonly sizeEnum = TbCheckboxSize
    readonly colorEnum = TbCheckboxColor

    private _onTouched = () => {}
    private _onChange = (value: boolean) => {}

    onCheckboxValueChange(e: MatCheckboxChange) {
        this.indeterminate = false
        this.value = e.checked
        this.valueChange.emit(e.checked)
        this._onChange(e.checked)
        this._onTouched()
    }

    writeValue(obj: boolean) {
        this.value = Boolean(obj)
    }

    registerOnChange(fn: any) {
        this._onChange = fn
    }

    registerOnTouched(fn: any) {
        this._onTouched = fn
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled
    }
}
