import { Component, Input, OnInit, TemplateRef } from '@angular/core'
import { MultiselectItemsService } from '@components-library/services/multiselect-items.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-tb-board-card',
    templateUrl: './tb-board-card.component.html',
    styleUrls: ['./tb-board-card.component.sass'],
})
export class TbBoardCardComponent<Item> implements OnInit {
    @Input({ required: true })
    item!: Item

    @Input({ required: true })
    key!: keyof Item

    @Input()
    title = ''

    @Input()
    moreMenu!: TemplateRef<any>

    itemId!: string

    selectedItems!: string[]

    constructor(private multiselectService: MultiselectItemsService) {
        multiselectService
            .getSelectedItems$()
            .pipe(untilDestroyed(this))
            .subscribe((selectedItems) => {
                this.selectedItems = selectedItems
            })
    }

    ngOnInit(): void {
        const id = this.item[this.key]

        if (typeof id !== 'string') {
            throw new Error('item[key] should be a string value')
        }

        this.itemId = id
    }

    toggleSelection(selected: boolean) {
        if (selected) {
            this.multiselectService.select(this.itemId)
        } else {
            this.multiselectService.deselect(this.itemId)
        }
    }

    get isSelected() {
        return this.multiselectService.isSelected(this.itemId)
    }
}
