<div class="flex-start-center">
    <app-tb-select
        class="w-full noHintError"
        [multiple]="true"
        [value]="activeOptions"
        [placeholder]="'select'"
    >
        <app-tb-option *ngFor="let key of optionKeys()">
            <app-tb-button
                (click)="checkActive(key); $event.stopPropagation();"
                [appearance]="'text'"
                [ngStyle]="{'background':dropdownData[key].color,'color': key |getContrastColor}"
                class="text-center"
            >
                {{dropdownData[key].label}}
                <mat-icon [ngStyle]="{'color': key |getContrastColor}" class="dropdown-icon"
                    >{{dropdownData[key].icon}}</mat-icon
                >
                <mat-icon
                    *ngIf="this.activeOptions.includes(key)"
                    [ngStyle]="{'color': key |getContrastColor}"
                    class="active-check-label"
                    >check
                </mat-icon>
            </app-tb-button>
        </app-tb-option>
    </app-tb-select>
</div>
