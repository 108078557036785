import { createAction, props } from '@ngrx/store'
import { AppRecord, BusinessRecords, Deleted } from '../../models'
import { Update } from '@ngrx/entity'

export const initRecords = createAction(
    '[BusinessRecords] Init records',
    props<{ records: BusinessRecords[] }>(),
)

export const resetRecords = createAction('[Record] Reset records')

export const addRecordsFromResponse = createAction(
    '[Record] Add records',
    props<{ records: BusinessRecords[] }>(),
)

export const updateRecordsFromResponse = createAction(
    '[Record] Update records',
    props<{ records: Update<AppRecord>[] }>(),
)

export const deleteRecordsFromResponse = createAction(
    '[Record] Delete records',
    props<{ records: Deleted[] }>(),
)
