<app-tb-picker
    class="w-full inline-flex"
    [itemTemplate]="item"
    [selectedItem]="selectedIcon"
    (selectItem)="selectIcon($event)"
    [name]="name"
    [items]="pickerItems"
>
    <div
        #triggerItem
        trigger
        [class]="name ? 'name-icon-item' : 'cursor-pointer w-5 h-5 m-2 rounded-md'"
    >
        @if(name) {
        <app-tb-icon
            [style.color]="iconColor ?? defaultColor"
            [icon]="this.selectedIcon ?? this.defaultStatusIcon"
            [filled]="filled"
        ></app-tb-icon>
        } @else {
        <app-tb-icon
            color="newText"
            [icon]="this.selectedIcon ?? this.defaultStatusIcon"
            [filled]="this.selectedIcon === this.defaultStatusIcon ? false : filled"
        ></app-tb-icon>
        }
    </div>
</app-tb-picker>
<ng-template #item let-item>
    <app-tb-button
        appearance="icon"
        class="hover:border-newPrimaryLight border-2 cursor-pointer rounded-lg w-8 h-8"
        [class]="selectedIcon === item ? 'border-newText hover:border-newText' : 'border-transparent'"
    >
        <app-tb-icon color="newText" [icon]="item" [filled]="filled"></app-tb-icon>
    </app-tb-button>
</ng-template>
