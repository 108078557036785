<app-tb-picker
    class="w-full inline-flex"
    [itemTemplate]="item"
    (selectItem)="selectColor($event)"
    [name]="name"
    [items]="pickerItems"
>
    <div
        trigger
        #triggerItem
        [class]="name ? 'name-color-item' : 'cursor-pointer w-5 h-5 m-2 rounded-md'"
        [style.background-color]="selectedColor"
    ></div>
</app-tb-picker>

<ng-template #item let-item>
    <div
        class="hover:border-newPrimaryLight border-2 cursor-pointer rounded-lg w-8 h-8"
        [class]="selectedColor === item ? 'border-newText hover:border-newText' : 'border-transparent'"
        [style.background-color]="item"
    ></div>
</ng-template>
