import { createFeatureSelector, createSelector } from '@ngrx/store'
import { recordAdapter, RecordState } from './record.reducer'
import { AppState } from '../app.state'
import { BusinessRecords, CurrentUser, Folder, Schema, ViewData } from '../../models'
import { selectSelectedTableSchema, selectTableSchemas } from '../schema'
import { selectCurrentUser } from '../user'
import { selectSelectedFolder, selectSelectedFolderGuid } from '../folder'
import { getBoolean, Nullable } from '../../global-util'
import { StoreFeatureNames } from '../store-feature-names'

const selectRecordState = createFeatureSelector<RecordState>(StoreFeatureNames.Record)

const { selectIds, selectEntities, selectAll, selectTotal } =
    recordAdapter.getSelectors(selectRecordState)

export const selectRecordIds = selectIds
export const selectRecordEntities = selectEntities
export const selectAllRecords = selectAll
export const selectRecordTotal = selectTotal

export const NO_GROUPED_RECORDS_KEY = 'NO_GROUPED_RECORDS_KEY'

export const selectSelectedTableSchemaDataRecords = createSelector<
    AppState,
    [BusinessRecords[], Nullable<Schema>, Folder],
    BusinessRecords[]
>(
    selectAllRecords,
    selectSelectedTableSchema,
    selectSelectedFolder,
    (records: BusinessRecords[], selectedSchema: Nullable<Schema>, selectedFolder: Folder) => {
        if (!selectedSchema) return []

        return records.filter((record) => {
            if (record.schemaGuid !== selectedSchema.guid) return false
            if (getBoolean(selectedFolder.is_global.value)) {
                return true
            }

            return record.folder_guids?.includes(selectedFolder.guid)
        })
    },
)

export const selectTableDataBySchemasAssignedCurrenUser = createSelector<
    AppState,
    [Schema[], BusinessRecords[], Nullable<CurrentUser>, string],
    ViewData[]
>(
    selectTableSchemas,
    selectAllRecords,
    selectCurrentUser,
    selectSelectedFolderGuid,
    (
        schemas: Schema[],
        records: BusinessRecords[],
        user: Nullable<CurrentUser>,
        folderGuid: string,
    ) => {
        if (!schemas) return [] as ViewData[]

        return schemas.reduce((viewData, schema: Schema | undefined) => {
            if (!schema) return viewData

            let currentData: BusinessRecords[] = records.filter(
                (record) =>
                    record.schemaGuid === schema?.guid && record.folder_guids?.includes(folderGuid),
            )

            if (!currentData.length || !user?.guid) return viewData

            let nameColumnGuid!: string
            Object.keys(schema.fieldEntities).forEach((fieldGuid: string) => {
                if (schema.fieldEntities[fieldGuid].is_primary) {
                    nameColumnGuid = schema.fieldEntities[fieldGuid].guid
                }
            })
            const columns: string[] = Object.keys(schema.fieldEntities)
            columns.splice(columns.indexOf(nameColumnGuid), 1)
            columns.splice(0, 0, nameColumnGuid)

            let data: BusinessRecords[] = currentData.filter((record) => {
                return Object.keys(record.assignee).find((key) => {
                    return record.assignee[key].value.includes(user.guid)
                })
            })

            viewData.push({
                fields: schema!.fieldEntities,
                data,
                schema: schema,
                selectedView: undefined,
                columns: {
                    columns,
                },
            })

            return viewData
        }, [] as ViewData[])
    },
)
