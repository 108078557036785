import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { ActionPanelService } from '@app/feature/actions-panel/action-panel.service'
import {
    DialogGroupCellEditInput,
    DialogGroupCellEditOutput,
    GroupCellEditComponent,
} from '@app/feature/actions-panel/group-cell-edit/group-cell-edit.component'
import { EnvDirective } from '@app/shared/env.directive'
import { FieldNameComponent } from '@app/shared/field-name/field-name.component'
import { ViewDataService } from '@app/views/services/view-data.service'
import { BreakpointService, DisplaySize } from '@components-library/services/breakpoint.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { TbMenuComponent } from '@components-library/tb-menu'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldType,
    FieldTypes,
    findFieldByType,
    Folder,
} from '@core/models'
import { AutomationTypes } from '@core/models/response/automation.model'
import {
    Automation,
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '@core/models/ui/automation.model'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { AutomationFacadeService } from '@core/services/store-facade/automation-facade.service'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { RecordsService } from '@services/records.service'

@UntilDestroy()
@Component({
    selector: 'app-actions-panel',
    templateUrl: './actions-panel.component.html',
    styleUrls: ['./actions-panel.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        TbCheckboxComponent,
        NgTemplateOutlet,
        TbButtonComponent,
        MatMenuModule,
        NgFor,
        FieldNameComponent,
        TbMenuComponent,
        MatIconModule,
        EnvDirective,
        AsyncPipe,
        NgIf,
    ],
})
export class ActionsPanelComponent implements OnInit {
    selectedRecords: BusinessRecords[] = []
    fieldsList!: Field[]
    schemaGuid!: string
    cells!: { [recordGuid: string]: CellEntities }
    fieldTypes!: Dictionary<FieldType>
    selectedFolder!: Folder

    // todo: [table-ref-2] rename
    targetGuid!: string

    automations$ = this.automationFacadeService.selectSelectedTableSchemaAutomations$

    @ViewChild('xsmMenu') xsmMenu!: TbMenuComponent

    constructor(
        public dialog: MatDialog,
        private automationFacadeService: AutomationFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private actionPanelService: ActionPanelService,
        private viewDataService: ViewDataService,
        private recordsService: RecordsService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private breakpointService: BreakpointService,
    ) {}

    ngOnInit() {
        this.breakpointService.displaySize$.subscribe((size) => {
            if (size === DisplaySize.Small && this.xsmMenu.modalManager) {
                this.xsmMenu.modalManager.close()
            }
        })
        this.viewDataService.viewDataChanges$.pipe(untilDestroyed(this)).subscribe(() => {
            this.cells = this.viewDataService.getCells()
            this.fieldsList = Object.values(this.viewDataService.getViewData().fields)
                .filter((field) => !field.operationCode?.system)
                .filter((field) => !(field.system_name === 'name'))
        })

        this.fieldTypeFacadeService.selectFieldTypeEntities$
            .pipe(untilDestroyed(this))
            .subscribe((fieldTypes) => {
                this.fieldTypes = fieldTypes
            })

        this.folderFacadeService.selectSelectedFolder$
            .pipe(untilDestroyed(this))
            .subscribe((folder) => {
                this.selectedFolder = folder
            })

        this.schemaFacadeService.selectSelectedTableSchema$
            .pipe(untilDestroyed(this))
            .subscribe((schema) => {
                if (!schema) return

                // TODO: delete. temporary solution for automations
                this.schemaGuid = schema.guid
                this.targetGuid = findFieldByType(schema.fieldEntities, FieldTypes.TEXT)!.guid
            })

        this.actionPanelService
            .getSelectedRecords$()
            .pipe(untilDestroyed(this))
            .subscribe((selectedRecords) => {
                this.selectedRecords = selectedRecords
            })
    }

    deleteAllRecords() {
        this.recordsService.deleteRecord(this.selectedRecords).subscribe()
        this.actionPanelService.deselectAll()
    }

    clearAllSelected() {
        this.actionPanelService.deselectAll()
    }

    editFieldValues(field: Field) {
        const dialogRef = this.dialog.open<
            GroupCellEditComponent,
            DialogGroupCellEditInput,
            DialogGroupCellEditOutput | undefined
        >(GroupCellEditComponent, {
            minWidth: '400px',
            data: {
                records: this.selectedRecords,
                field: field,
                fieldTypes: this.fieldTypes,
                cells: this.cells,
            },
        })

        dialogRef.afterClosed().subscribe((res: DialogGroupCellEditOutput | undefined) => {
            if (res && res.value) {
                this.editRecords({ value: res.value, field: res.field })
            }
        })
    }

    editRecords(dataRecord: { value: string; field: Field }) {
        let data = this.selectedRecords.map((record) => {
            return {
                record: record,
                cell: this.cells[record.guid][dataRecord.field.guid],
                value: dataRecord.value,
            }
        })
        this.recordsService.updateRecords(data).subscribe()
    }

    // no design, final solution will be implemented later, for testing purposes
    createAutomation() {
        const createModel: AutomationCreateModel = {
            name: 'test',
            solution_object_type_guid: this.schemaGuid,
            conditions: [
                {
                    source_type: 'source_type_event',
                    source_details: 'create',
                    values: [],
                },
            ],
            actions: [
                {
                    action_type_code: 'action_item',
                    action_config: {
                        target_object_field_guid: this.targetGuid,
                        value: 'automation test data',
                    },
                },
            ],
            automation_type: AutomationTypes.AUTO,
            is_active: 0,
            user_guids: [],
            is_global: 0,
        }

        this.automationFacadeService.createAutomation(createModel)
        this.clearAllSelected()
    }

    updateAutomation(automation: Automation) {
        const updateModel: AutomationUpdateModel = {
            automationGuid: automation.guid,
            automationModel: {
                name: automation.name + '1',
                solution_object_type_guid: this.schemaGuid,
                conditions: [
                    {
                        source_type: 'source_type_event',
                        source_details: 'create',
                        values: [],
                    },
                ],
                actions: [
                    {
                        action_type_code: 'action_item',
                        action_config: {
                            target_object_field_guid: this.targetGuid,
                            value: 'automation test data',
                        },
                    },
                ],
                automation_type: AutomationTypes.AUTO,
                is_active: 0,
                user_guids: [],
                is_global: 0,
            },
        }

        this.automationFacadeService.updateAutomation(updateModel)
        this.clearAllSelected()
    }

    deleteAutomation(guid: string) {
        const deleteModel: AutomationDeleteModel = {
            guid: guid,
            objectTypeGuid: this.schemaGuid,
        }

        this.automationFacadeService.deleteAutomation(deleteModel)
        this.clearAllSelected()
    }

    executeAutomation(guid: string) {
        const executeModel: AutomationExecuteModel = {
            guid,
            objectTypeGuid: this.schemaGuid,
            records: this.selectedRecords,
        }

        this.automationFacadeService.executeAutomation(executeModel)
        this.clearAllSelected()
    }
}
