export interface SortObject {
    fieldGuid: string
    sortDirection: SortDirection
}

export interface SortObjectEntities {
    [fieldGuid: string]: SortObject
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}
