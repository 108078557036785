import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { AddFieldData, Field, FieldType, Folder, ObjectResponseModel } from '@core/models'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-add-column',
    templateUrl: './add-field.component.html',
    styleUrls: ['./add-field.component.sass'],
})
export class AddFieldComponent
    extends ModalContainerComponent<AddFieldData, Field | undefined>
    implements OnInit
{
    availableFieldTypes$: Observable<FieldType[]> =
        this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$
    allFieldTypesDictionary$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$
    folders$: Observable<Folder[]> = this.folderFacadeService.selectAllFolders$

    formGroup!: FormGroup<{
        columnName: FormControl<string | null>
        fieldType: FormControl<string | null>
        folder: FormControl<string | null | undefined>
        onTop: FormControl<boolean | null>
        required: FormControl<boolean | null>
        defaultValue: FormControl<any>
    }>

    field!: Field
    editMode = true
    isColumnContentValid = true
    isRequired: boolean = false

    constructor(
        @Inject(ModalContainerDataToken) public data: AddFieldData,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        private manageFieldsService: ManageFieldsService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    ngOnInit() {
        if (!this.data.guid || this.data.duplicate) {
            this.editMode = false
        }
        this.isRequired = !!this.data.is_required

        this.initForm()
        this.setDefaultState()
    }

    private initForm() {
        this.formGroup = new FormGroup({
            columnName: new FormControl(this.data.name ? this.data.name : '', [
                Validators.required,
            ]),
            fieldType: new FormControl<string>(
                {
                    value: this.data.field_type_code,
                    disabled: this.editMode,
                },
                Validators.required,
            ),
            folder: new FormControl<string | undefined>(this.data.folder_guid),
            onTop: new FormControl<boolean>(!!this.data.is_on_top),
            required: new FormControl<boolean>(!!this.data.is_required),
            defaultValue: new FormControl(this.data.default_value),
        })

        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((changes) => {
            this.isRequired = !!changes.required

            if (changes.fieldType) {
                this.field.field_type_code = changes.fieldType
            }

            this.field = {
                ...this.field,
                folder_guid: changes.folder ?? undefined,
                is_required: changes.required ? 1 : 0,
                name: changes.columnName!,
                is_on_top: changes.onTop ? 1 : 0,
                default_value: changes.defaultValue,
            }
        })
    }

    valid() {
        return this.formGroup.invalid
    }

    creteColumn() {
        //for new folder fields
        if (this.data.new_folder) {
            this.close(this.field)
            return
        }

        this.showLoader = true

        let res: Observable<ObjectResponseModel>
        if (this.editMode) {
            res = this.schemaFacadeService.updateField(this.field)
        } else {
            res = this.schemaFacadeService.createField(this.field)
        }

        res.pipe(take(1)).subscribe({
            next: (data) => {
                if (data.status === 'success') {
                    this.close()
                } else {
                    this.errors = data.error?.map((error) => error.message)
                }
            },
            error: (error) => {
                this.errors = [error]
            },
            complete: () => {
                this.showLoader = false
            },
        })
    }

    changedValue(field: Field) {
        // TODO: will be deleted during cell type rework or following issues
        // https://chat.anveo.com/git/AnveoTikibase_ui/issues/566
        if (!field.guid) {
            this.field = {
                ...this.field,
                select_object_field: field.select_object_field,
                configure_json: field.configure_json,
                link_definition: field.link_definition,
            }
            return
        }
        this.field = field
    }

    setDefaultState() {
        // TODO: will be deleted during cell type rework or following issues
        // https://chat.anveo.com/git/AnveoTikibase_ui/issues/566
        this.field = {
            ...this.data,
            field_type_code: this.formGroup.controls.fieldType.value || this.data.field_type_code,
            guid: this.data.guid ? this.data.guid : '',
            select_object_field: this.data.select_object_field,
            configure_json: this.data.configure_json,
            link_definition: this.data.link_definition,
        }
    }

    delete() {
        this.showLoader = true
        this.manageFieldsService
            .deleteField(this.field.guid)
            .pipe(take(1), untilDestroyed(this))
            .subscribe({
                next: (data) => {
                    if (data.status === 'success') {
                        this.close()
                    } else {
                        this.errors = data.error?.map((error) => error.message)
                    }
                },
                error: (error) => {
                    this.errors = [error]
                },
                complete: () => {
                    this.showLoader = false
                },
            })
    }

    defaultValueChanged(value: string) {
        this.formGroup.controls.defaultValue.setValue(value)
    }
}
