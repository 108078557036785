import { ComponentType } from '@angular/cdk/overlay'
import { Injectable, TemplateRef } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { ModalInstanceFactory } from '@components-library/tb-modal-manager/modal-instance-factory.service'
import { merge, Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ModalContainersStore } from 'src/app/@components-library/tb-modal-manager/modal-containers.store'
import { BreakpointService, DisplaySize } from '../services/breakpoint.service'
import { ModalContainerComponent } from './modal-container-component/modal-container.component'
import { ModalContainerFactoryService } from './modal-container-factory.service'
import { BaseContainer } from './modal-containers/base-container'
import { DialogContainer } from './modal-containers/dialog-container'
import { MenuContainer } from './modal-containers/menu-container'
import { BottomSheetInstanceService } from './modal-instances/bottom-sheet-instance.service'
import { DialogInstanceService } from './modal-instances/dialog-instance.service'
import { MenuInstanceService } from './modal-instances/menu-instance'

export enum ModalContainer {
    Dialog = 'Dialog',
    BottomSheet = 'BottomSheet',
    Menu = 'Menu',
}

export type MenuConfig<Component, ContainerData> = {
    menuTrigger: MatMenuTrigger
    component: ComponentType<Component>
    template?: TemplateRef<any>
    data?: ContainerData
    replaceable?: boolean
}

export type DialogConfig<Component, ContainerData> = {
    component: ComponentType<Component>
    data?: ContainerData
    replaceable?: boolean
}

/**
 * ModalManagerService is an entry point to start the Modal Flow.
 *
 * This service:
 * - opens Menu and Dialog
 * - converts to Modal Alternative when we cross some screen resolution
 * - navigate to other Containers
 **/
@Injectable()
export class ModalManagerService {
    /**
     * Current screen resolution size.
     **/
    private currentDisplaySize!: DisplaySize

    /**
     * Current Modal View
     **/
    private currentModalView: ModalContainer | null = null

    /**
     * The stream is called when the Mat Menu closes. It notifies to clear open subscriptions and ModalManagerService to clear.
     **/
    private clearAllMenus$ = new Subject<void>()

    /**
     * So to hide a Menu during the Transformation we use this stream.
     **/
    private hideMenu$ = new Subject<void>()

    /**
     * Called when we close the Flow
     **/
    readonly flowClosed = new Subject<void>()

    private containersStore = new ModalContainersStore()

    private dialogInstance!: DialogInstanceService
    private bottomSheetInstance!: BottomSheetInstanceService

    constructor(
        private breakpointService: BreakpointService,
        private modalContainerFactoryService: ModalContainerFactoryService,
        private modalInstanceFactory: ModalInstanceFactory,
    ) {
        this.dialogInstance = modalInstanceFactory.createDialogInstance(this.containersStore)
        this.bottomSheetInstance = modalInstanceFactory.createBottomSheetInstance(
            this.containersStore,
        )
        /**
         *  When some of the Modal is closed, it means that user close it by its intention.
         *  So here we clean up the ModalManagerService
         **/
        merge(
            this.dialogInstance.closed,
            this.bottomSheetInstance.closed,
            this.clearAllMenus$,
        ).subscribe(() => {
            this.closeFlow()
        })

        this.breakpointService.displaySize$.subscribe((size) => {
            this.currentDisplaySize = size
            const activeContainer = this.containersStore.activeContainer

            if (!activeContainer) return

            if (
                this.currentModalView &&
                this.currentModalView !== activeContainer?.displaySizeToRelatedContainer(size)
            ) {
                this.hideByLayout(this.currentModalView)
            }

            this.reopenByLayout(activeContainer, size)
        })
    }

    /**
     *  Method for opening a Menu
     **/
    openMenu<T extends ModalContainerComponent, ContainerData = unknown, Result = unknown>({
        menuTrigger,
        component,
        template,
        data,
        replaceable = false,
    }: MenuConfig<T, ContainerData>) {
        const menuInstanceService = new MenuInstanceService(menuTrigger)

        const menuContainer = new MenuContainer(
            this,
            menuInstanceService,
            this.modalContainerFactoryService,
            component,
            template || null,
            data,
        )

        if (this.containersStore.activeContainer) {
            this.hidePreviousContainer(this.containersStore.activeContainer, menuContainer)
        }

        this.containersStore.addContainer(menuContainer, replaceable)

        this.menuSubscriptions(menuInstanceService)

        return this.openByLayout(
            menuContainer,
            this.currentDisplaySize,
            menuInstanceService,
        ) as Observable<Result | undefined>
    }

    /**
     *  Method for opening a Dialog
     **/
    openDialog<T extends ModalContainerComponent, ContainerData = unknown, Result = unknown>({
        component,
        data,
        replaceable = false,
    }: DialogConfig<T, ContainerData>) {
        const dialogContainer = new DialogContainer(
            this,
            this.modalContainerFactoryService,
            component,
            data,
        )

        if (this.containersStore.activeContainer) {
            this.hidePreviousContainer(this.containersStore.activeContainer, dialogContainer)
        }

        this.containersStore.addContainer(dialogContainer, replaceable)

        return this.openByLayout(dialogContainer, this.currentDisplaySize) as Observable<
            Result | undefined
        >
    }

    /**
     *  Method that is called by ModalContainerComponent. It closes the current Container.
     **/
    close(data?: unknown) {
        if (!this.containersStore.activeContainer) return

        const currentContainer = this.containersStore.removeLastContainer()
        const previousContainer = this.containersStore.activeContainer

        if (currentContainer && previousContainer) {
            this.hidePreviousContainer(currentContainer, previousContainer)
        }

        if (currentContainer) {
            this.containersStore.closeContainer(currentContainer, data)
        }

        if (!this.containersStore.hasContainers()) {
            this.closeFlow()
            return
        }
        this.reopenByLayout(this.containersStore.activeContainer, this.currentDisplaySize)
    }

    /**
     *  Method closes a Modal Flow
     **/
    closeFlow() {
        this.containersStore.closeAll()
        this.containersStore.clear()

        this.currentModalView && this.hideByLayout(this.currentModalView)
        this.currentModalView = null

        this.flowClosed.next()
        this.flowClosed.complete()
    }

    /**
     * Returns "true" if there are more than one container in the stack.
     **/
    isCurrentContainerIsAChild() {
        return this.containersStore.containers.length > 1
    }

    /**
     * Returns "true" if some Containers in a stack has changes or there are more than one Container in the stack
     **/
    shouldOpenConfirmation() {
        return (
            this.containersStore.hasMarkedContainer() || this.containersStore.containers.length > 1
        )
    }

    getContainerStore() {
        return this.containersStore
    }

    /**
     * Methods for Menu subscriptions. See {@link ModalManagerService#hideMenu$} and {@link ModalManagerService#clearAllMenus$}.
     **/
    private menuSubscriptions(menuInstanceService: MenuInstanceService) {
        this.hideMenu$.pipe(takeUntil(this.clearAllMenus$)).subscribe(() => {
            menuInstanceService.hide()
        })

        menuInstanceService.closed.pipe(takeUntil(this.clearAllMenus$)).subscribe(() => {
            this.clearAllMenus$.next()
        })
    }

    /**
     * This method close Modal View if it is different from previous container.
     *
     * For example, we open Menu, than we open Dialog, so at first we should close Menu and then open Dialog.
     * In case we open Dialog, then open another Dialog, we shouldn't close the Dialog because previous and current Container Views are the same.
     **/
    private hidePreviousContainer(
        previousContainer: BaseContainer<ModalContainerComponent>,
        currentContainer: BaseContainer<ModalContainerComponent>,
    ) {
        if (!this.isPreviousModalTheSameLayout(previousContainer, currentContainer)) {
            this.hideByLayout(
                previousContainer.displaySizeToRelatedContainer(this.currentDisplaySize),
            )
        }
    }

    /**
     * This method check whether previous and current Container view are the same.
     **/
    private isPreviousModalTheSameLayout(
        previousContainer: BaseContainer<ModalContainerComponent>,
        currentContainer: BaseContainer<ModalContainerComponent>,
    ) {
        return (
            previousContainer.displaySizeToRelatedContainer(this.currentDisplaySize) ===
            currentContainer.displaySizeToRelatedContainer(this.currentDisplaySize)
        )
    }

    /**
     * Open Modal View according to the display size.
     **/
    private openByLayout(
        container: BaseContainer<ModalContainerComponent>,
        displaySize: DisplaySize,
        menuInstance?: MenuInstanceService,
    ) {
        switch (container.displaySizeToRelatedContainer(displaySize)) {
            case ModalContainer.BottomSheet: {
                this.currentModalView = ModalContainer.BottomSheet
                return this.bottomSheetInstance.open(container)
            }
            case ModalContainer.Dialog: {
                this.currentModalView = ModalContainer.Dialog
                return this.dialogInstance.open(container)
            }
            case ModalContainer.Menu: {
                this.currentModalView = ModalContainer.Menu
                return menuInstance!.open(container)
            }
            default: {
                throw new Error('Wrong layout')
            }
        }
    }

    /**
     * Reopen Modal View according to the display size.
     *
     * Differs from {@link openByLayout} by its function.
     * {@link openByLayout} returns subscription that is called when user closed Container intentionally.
     * And it is called when we open Container for the first time.
     *
     * reopenByLayout is called when we do Transformation because of the screen resolution change.
     * We shouldn't return subscription here.
     **/
    private reopenByLayout(
        container: BaseContainer<ModalContainerComponent>,
        displaySize: DisplaySize,
    ) {
        switch (container.displaySizeToRelatedContainer(displaySize)) {
            case ModalContainer.BottomSheet: {
                this.currentModalView = ModalContainer.BottomSheet
                this.bottomSheetInstance.reopen(container)
                break
            }
            case ModalContainer.Dialog: {
                this.currentModalView = ModalContainer.Dialog
                this.dialogInstance.reopen(container)
                break
            }
            case ModalContainer.Menu: {
                this.currentModalView = ModalContainer.Menu
                ;(container as MenuContainer<ModalContainerComponent>).menuInstanceService.reopen()
                break
            }
        }
    }

    /**
     * Method hides currently opened Modal View Instance.
     *
     * It happens when we navigate to a different Container Type or change screen resolution.
     **/
    private hideByLayout(layout: ModalContainer) {
        switch (layout) {
            case ModalContainer.BottomSheet: {
                this.bottomSheetInstance.hide()
                break
            }
            case ModalContainer.Dialog: {
                this.dialogInstance.hide()
                break
            }
            case ModalContainer.Menu: {
                this.hideMenu$.next()
                break
            }
        }
        this.currentModalView = null
    }
}
