<ng-container *ngIf="isNoGrouped()">
    <ng-container *transloco="let tTable; read: 'table'">
        <span *ngIf="isAssignee()" class="sticky"> {{tTable('unassigned')}} </span>
        <span *ngIf="!isAssignee()" class="sticky"> {{tTable('unknown')}} </span>
    </ng-container>
</ng-container>

<ng-container *ngIf="!isNoGrouped() && field">
    <ng-container [ngSwitch]="field.field_type_code">
        <app-status
            *ngSwitchCase="'field_type_status'"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        >
        </app-status>
        <app-assign
            *ngSwitchCase="'field_type_assignee'"
            [isGrouping]="true"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        >
        </app-assign>
        <app-assign
            *ngSwitchCase="'field_type_people'"
            [isGrouping]="true"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        >
        </app-assign>
        <app-rating
            *ngSwitchCase="'field_type_rating'"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        ></app-rating>
        <app-dropdown
            *ngSwitchCase="'field_type_dropdown'"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        >
        </app-dropdown>
        <app-boolean
            *ngSwitchCase="'field_type_bool'"
            [disabled]="true"
            [fieldType]="fieldType"
            [field]="field"
            [value]="value"
        >
        </app-boolean>
    </ng-container>
</ng-container>
