import { CdkAccordionModule } from '@angular/cdk/accordion'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { ViewErrorComponent } from '@app/pages/main/pages/error/view-error.component'
import { SharedModule } from '@app/shared/shared.module'
import { FilterCombinationToggleComponent } from '@app/views/view-controls/view-filter/filter-combination-toggle/filter-combination-toggle.component'
import { FilterGroupComponent } from '@app/views/view-controls/view-filter/filter-group/filter-group.component'
import { FilterItemComponent } from '@app/views/view-controls/view-filter/filter-item/filter-item.component'
import { FilterModalComponent } from '@app/views/view-controls/view-filter/filter-modal/filter-modal.component'
import { FilterNoResultsComponent } from '@app/views/view-controls/view-filter/filter-no-results/filter-no-results.component'
import { AssignFilterComponent } from '@app/views/view-controls/view-filter/filter-types/assign-filter/assign-filter.component'
import { BooleanFilterComponent } from '@app/views/view-controls/view-filter/filter-types/boolean-filter/boolean-filter.component'
import { DropdownFilterComponent } from '@app/views/view-controls/view-filter/filter-types/dropdown-filter/dropdown-filter.component'
import { EmailFilterComponent } from '@app/views/view-controls/view-filter/filter-types/email-filter/email-filter.component'
import { FilterTypesContainerComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types-container.component'
import { MoneyFilterComponent } from '@app/views/view-controls/view-filter/filter-types/money-filter/money-filter.component'
import { NumberFilterComponent } from '@app/views/view-controls/view-filter/filter-types/number-filter/number-filter.component'
import { RatingFilterComponent } from '@app/views/view-controls/view-filter/filter-types/rating-filter/rating-filter.component'
import { StatusFilterComponent } from '@app/views/view-controls/view-filter/filter-types/status-filter/status-filter.component'
import { TextOneLineFilterComponent } from '@app/views/view-controls/view-filter/filter-types/text-one-line-filter/text-one-line-filter.component'
import { UrlFilterComponent } from '@app/views/view-controls/view-filter/filter-types/url-filter/url-filter.component'
import { ViewsListMenuComponent } from '@app/views/view-controls/views-list/views-list-menu/views-list-menu.component'
import { ComponentsLibraryModule } from '@components-library'
import { TbIconModule } from '@components-library/tb-icon/tb-icon.module'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TbSelectModule } from '@components-library/tb-select/tb-select.module'
import { PipesModule } from '@core/pipes/pipes.module'
import { TranslocoModule } from '@ngneat/transloco'
import { ViewFilterComponent } from './view-filter'
import { GroupModalComponent } from '@app/views/view-controls/view-group/group-modal/group-modal.component'
import { ViewGroupComponent } from '@app/views/view-controls/view-group/view-group.component'
import { ShowItemComponent } from './show/show-item/show-item.component'
import { ShowComponent } from './show/show.component'
import { SortItemComponent } from '@app/views/view-controls/view-sort/sort-modal/sort-item/sort-item.component'
import { SortModalComponent } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'
import { ViewSortComponent } from '@app/views/view-controls/view-sort/view-sort.component'
import { CreateViewComponent } from './views-list/create-view/create-view.component'
import { ViewsListComponent } from './views-list/views-list.component'

const forExport = [
    ViewFilterComponent,
    ViewGroupComponent,
    ViewsListComponent,
    CreateViewComponent,
    ViewErrorComponent,
    FilterNoResultsComponent,
]

@NgModule({
    declarations: [
        ...forExport,
        FilterItemComponent,
        ViewSortComponent,
        SortModalComponent,
        ShowItemComponent,
        ShowComponent,
        FilterGroupComponent,
        FilterModalComponent,
        StatusFilterComponent,
        FilterTypesContainerComponent,
        DropdownFilterComponent,
        AssignFilterComponent,
        MoneyFilterComponent,
        RatingFilterComponent,
        NumberFilterComponent,
        TextOneLineFilterComponent,
        EmailFilterComponent,
        UrlFilterComponent,
        BooleanFilterComponent,
        CreateViewComponent,
        GroupModalComponent,
        FilterCombinationToggleComponent,
        SortItemComponent,
        ViewsListMenuComponent,
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatRadioModule,
        MatBottomSheetModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        PipesModule,
        TranslocoModule,
        SharedModule,
        MatButtonToggleModule,
        ComponentsLibraryModule,
        MatTabsModule,
        DragDropModule,
        TbSelectModule,
        TbIconModule,
        ModalManagerModule,
    ],
    exports: forExport,
})
export class ViewControlsModule {}
