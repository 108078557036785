import { AfterViewInit, Component, Inject, OnInit } from '@angular/core'
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms'
import {
    DraftPinRecord,
    PinDraftRecord,
    PinRecordService,
} from '@app/feature/pin-panel/pin-record.service'
import { AccordionContentContext } from '@app/shared/fields-collection/fields-collection.component'
import { RecordFieldsComponent } from '@app/shared/record-fields/record-fields.component'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { getBoolean } from '@core/global-util'
import {
    Field,
    FieldEntities,
    FieldTypes,
    findImportantFieldByType,
    Folder,
    isFolderGlobal,
    ObjectResponseModel,
    Schema,
} from '@core/models'
import {
    CrudRecordModelFactoryService,
    FieldValue,
} from '@core/services/crud-record-model-factory.service'
import { RecordsService } from '@core/services/records.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '@core/services/store-facade'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, of, finalize, Observable } from 'rxjs'
import { catchError, take, tap } from 'rxjs/operators'
import { TypedTemplateDirective } from '@app/shared/typed-template.directive'
import { MatMenuModule } from '@angular/material/menu'
import { TbIconToggleButtonComponent } from '@components-library/tb-icon-toggle-button/tb-icon-toggle-button.component'
import { FieldsCollectionComponent } from '@app/shared/fields-collection/fields-collection.component'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgIf, AsyncPipe } from '@angular/common'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { AddRecordSettingsSectionComponent } from './add-record-settings-section/add-record-settings-section.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule } from '@ngneat/transloco'

export type AddRecordModalData = PinDraftRecord | { name?: string; schemaGuid?: string } | null

@UntilDestroy()
@Component({
    selector: 'app-add-record-content',
    templateUrl: './add-record-content.component.html',
    styleUrls: ['./add-record-content.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        TbButtonComponent,
        AddRecordSettingsSectionComponent,
        ReactiveFormsModule,
        TbInputComponent,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        FieldsCollectionComponent,
        TbIconToggleButtonComponent,
        MatMenuModule,
        TypedTemplateDirective,
        AsyncPipe,
        RecordFieldsComponent,
    ],
})
export class AddRecordContentComponent
    extends ModalContainerComponent
    implements OnInit, AfterViewInit
{
    readonly uniqueFieldTypes = [
        FieldTypes.WATCH,
        FieldTypes.NAME,
        FieldTypes.RICH_TEXT,
    ] as string[]

    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    folders$ = this.folderFacadeService.selectAllFolders$
    schemas$ = this.schemaFacadeService.selectTableSchemas$
    records$ = this.recordFacadeService.selectRecordEntities$
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$
    selectedSchema$ = this.schemaFacadeService.selectSelectedTableSchema$

    schema!: Schema
    folder!: Folder
    availableFolders!: Folder[]
    currentUserGuid!: string
    recordFieldValues: { [fieldGuid: string]: string } = {}

    uniqueGroupControl!: FormGroup<{ name: FormControl; description: FormControl }>

    formGroupDirective!: FormGroupDirective | undefined

    nameField!: Field
    watchField!: Field
    richTextField?: Field

    isDirty = false

    contextType = {} as AccordionContentContext

    constructor(
        public pinRecordService: PinRecordService,
        private recordService: RecordsService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private crudRecordModelFactory: CrudRecordModelFactoryService,
        private userFacadeService: UserFacadeService,
        private recordFacadeService: RecordFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken)
        public draftRecord: AddRecordModalData,
    ) {
        super(draftRecord, modalManagerService, confirmationDialogService)
    }

    ngOnInit() {
        this.userFacadeService.currentUser$.pipe(take(1)).subscribe((user) => {
            if (!user) return

            this.currentUserGuid = user.guid
        })

        if (this.draftRecord && 'data' in this.draftRecord) {
            this.folder = this.draftRecord.data.folder
            this.schema = this.draftRecord.data.schema
            this.recordFieldValues = this.draftRecord.data.fieldValues
            this.setUniqueFields()
            this.setUniqueControls()

            this.folders$.pipe(take(1)).subscribe((folders) => {
                this.setAvailableFolders(folders)
            })
            return
        }

        combineLatest([this.selectedFolder$, this.selectedSchema$, this.folders$, this.schemas$])
            .pipe(take(1))
            .subscribe(([folder, schema, folders, schemas]) => {
                if (!schema) return

                this.setRecordData(this.getSchemaFromData(schemas, schema), folder, folders)

                if (this.draftRecord && 'name' in this.draftRecord) {
                    this.uniqueGroupControl.get('name')?.setValue(this.draftRecord.name)
                }
            })
    }

    ngAfterViewInit(): void {
        if (!this.formGroupDirective) return

        dirtyCheck(this.formGroupDirective.form, of(this.formGroupDirective.form.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    saveSettings({ folderGuid, schemaGuid }: { folderGuid: string; schemaGuid: string }) {
        combineLatest([this.folders$, this.schemas$])
            .pipe(take(1))
            .subscribe(([folders, schemas]) => {
                const findSchema = schemas.find((schema) => schema.guid === schemaGuid)
                if (!findSchema) return

                const findFolder = folders.find((folder) => folder.guid === folderGuid)
                if (!findFolder) return

                this.setRecordData(findSchema, findFolder, folders)
            })
    }

    addToFavorite(isActive: boolean) {
        this.recordFieldValues[this.watchField.guid] = isActive ? this.currentUserGuid : ''
    }

    createRecord() {
        this.addRecord().subscribe((data) => {
            if (data.status === 'success') this.close()
        })
    }

    createAndOpen() {
        this.addRecord().subscribe((data) => {
            if (data.status === 'success') this.close()
        })

        this.recordFacadeService
            .getLastAddedRecord()
            .pipe(take(1))
            .subscribe((records) => {
                this.pinRecordService.openPinItem(records.guid)
            })
    }

    createAndStartAnother() {
        this.addRecord().subscribe((data) => {
            if (data.status === 'success') {
                this.close()
                this.openAfterClose()
            }
        })
    }

    createAndDuplicate() {
        this.addRecord().subscribe()
    }

    pinDraftRecord() {
        const nameValue = this.recordFieldValues[this.nameField.guid]

        const draftRecordData: DraftPinRecord = {
            name: nameValue ? nameValue : null,
            folder: this.folder,
            schema: this.schema,
            fieldValues: this.recordFieldValues,
        }
        if (this.draftRecord && 'guid' in this.draftRecord) {
            this.pinRecordService.updateDraftRecord(this.draftRecord.guid, draftRecordData)
        } else {
            this.pinRecordService.pinDraftRecord(draftRecordData)
        }

        this.modalManagerService.close()
    }

    removePinRecord() {
        if (this.draftRecord && 'guid' in this.draftRecord) {
            this.pinRecordService.removePinRecord(this.draftRecord.guid)
        }
    }

    private getSchemaFromData(schemas: Schema[], schema: Schema) {
        if (this.draftRecord && 'schemaGuid' in this.draftRecord) {
            const schemaGuid = this.draftRecord.schemaGuid
            return schemas.find((schema) => schema.guid === schemaGuid) ?? schema
        }

        return schema
    }

    private addRecord() {
        this.accumulateRecordFieldValuesOnSave()
        const record = this.crudRecordModelFactory.prepareCreateModelWithFields(
            '',
            this.schema.guid,
            this.folder.guid,
            this.prepareFieldValues(),
        )

        this.removePinRecord()

        return this.wrapResponse(this.recordService.createRecord(record).pipe(take(1)))
    }

    private prepareFieldValues() {
        return Object.keys(this.recordFieldValues)
            .map((key) => {
                return {
                    fieldGuid: key,
                    value: this.recordFieldValues[key],
                } as FieldValue
            })
            .filter((item) => item.fieldGuid)
            .filter((item) => item.value.length)
    }

    private accumulateRecordFieldValuesOnSave() {
        this.parseControlsToRecordFieldValues('required')
        this.parseControlsToRecordFieldValues('shared')
        this.availableFolders.forEach((folder) => {
            this.parseControlsToRecordFieldValues(folder.guid)
        })
    }

    private parseControlsToRecordFieldValues(controlName: string) {
        const formGroup = this.formGroupDirective?.form?.value[controlName]

        if (!formGroup) return

        Object.keys(formGroup).forEach((key) => {
            this.recordFieldValues[key] = formGroup[key]
        })
    }

    private setRecordData(schema: Schema, folder: Folder, folders: Folder[]) {
        this.folder = folder

        if (this.schema !== schema) {
            this.recordFieldValues = this.setRecordFieldValues(schema.fieldEntities)
        }

        this.schema = schema
        this.setUniqueFields()
        this.setControls()

        this.setAvailableFolders(folders)
    }

    private setAvailableFolders(folders: Folder[]) {
        if (isFolderGlobal(this.folder)) {
            this.availableFolders = folders
            return
        }

        this.availableFolders = folders.filter(
            (folder) => folder.guid === this.folder.guid || isFolderGlobal(folder),
        )
    }

    private openAfterClose() {
        this.modalManagerService
            .openDialog<AddRecordContentComponent, PinDraftRecord | null>({
                component: AddRecordContentComponent,
            })
            .pipe(take(1))
    }

    private setRecordFieldValues(fieldEntities: FieldEntities) {
        return Object.keys(fieldEntities).reduce((acc, key) => {
            if (fieldEntities[key].select_object_field) {
                acc[key] = this.getDefaultValue(fieldEntities[key])
                return acc
            }

            acc[key] = ''
            return acc
        }, {} as { [guid: string]: string })
    }

    private setUniqueFields() {
        this.nameField = findImportantFieldByType(this.schema.fieldEntities, FieldTypes.NAME)
        this.watchField = findImportantFieldByType(this.schema.fieldEntities, FieldTypes.WATCH)
        this.richTextField = findImportantFieldByType(
            this.schema.fieldEntities,
            FieldTypes.RICH_TEXT,
        )
    }

    private setControls() {
        this.formGroupDirective = new FormGroupDirective([], [])
        this.formGroupDirective.form = new FormGroup({})
        this.setUniqueControls()
        this.formGroupDirective.form.setControl('unique', this.uniqueGroupControl)
    }

    private setUniqueControls() {
        this.uniqueGroupControl = new FormGroup({
            name: new FormControl(this.recordFieldValues[this.nameField.guid], Validators.required),
            description: new FormControl(''),
        })

        if (this.richTextField) {
            this.uniqueGroupControl
                .get('description')
                ?.patchValue(this.recordFieldValues[this.richTextField.guid])
        }

        this.uniqueGroupControl.valueChanges.subscribe((change) => {
            this.recordFieldValues[this.nameField.guid] = change.name ?? ''
            if (this.richTextField) {
                this.recordFieldValues[this.richTextField.guid] = change.description ?? ''
            }
        })
    }

    private getDefaultValue(field: Field) {
        const selectObjectFields = field.select_object_field
        if (!selectObjectFields) {
            return ''
        }

        return (
            Object.keys(selectObjectFields).find((guid) =>
                getBoolean(selectObjectFields[guid].is_default),
            ) ?? ''
        )
    }

    private wrapResponse(
        observable: Observable<ObjectResponseModel>,
    ): Observable<ObjectResponseModel> {
        this.showLoader = true
        return observable.pipe(
            tap((data) => {
                if (data.status === 'error') {
                    this.errors = data.error?.map((error) => error.message)
                }
            }),
            catchError((error) => {
                return of({ error: error } as ObjectResponseModel)
            }),
            finalize(() => (this.showLoader = false)),
        )
    }
}
