import { Injectable } from '@angular/core'
import { BreakpointService } from '@components-library/services/breakpoint.service'
import { ModalContainerFactoryService } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalInstanceFactory } from '@components-library/tb-modal-manager/modal-instance-factory.service'
import {
    DialogConfig,
    MenuConfig,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'

/**
 * This service is used to open Modal Flows over the existing Flows.
 *
 * If you need to redirect to another Modal from the Modal Container use {@link ModalManagerService}.
 **/
@Injectable({
    providedIn: 'root',
})
export class ModalFlowManagerService {
    /**
     * The Set of opened Modal Flows
     **/
    private readonly modalFlows = new Set<ModalManagerService>()

    constructor(
        private breakpointService: BreakpointService,
        private modalContainerFactoryService: ModalContainerFactoryService,
        private modalInstanceFactory: ModalInstanceFactory,
    ) {}

    openMenu<T extends ModalContainerComponent, ContainerData = unknown, Result = unknown>(
        config: MenuConfig<T, ContainerData>,
    ) {
        const manager = this.createModalManager()
        this.addFlow(manager)
        return manager.openMenu<T, ContainerData, Result>(config)
    }

    openDialog<
        T extends ModalContainerComponent,
        ContainerData = InstanceType<typeof ModalContainerComponent>,
        Result = unknown,
    >(config: DialogConfig<T, ContainerData>) {
        const manager = this.createModalManager()
        this.addFlow(manager)
        return manager.openDialog<T, ContainerData, Result>(config)
    }

    addFlow(modalManager: ModalManagerService) {
        this.modalFlows.add(modalManager)
        modalManager.flowClosed.subscribe(() => {
            this.modalFlows.delete(modalManager)
        })
    }

    closeAllFlows() {
        this.modalFlows.forEach((value) => {
            value.closeFlow()
        })
    }

    /**
     * Factory function for the ModalManagerService
     **/
    createModalManager() {
        return new ModalManagerService(
            this.breakpointService,
            this.modalContainerFactoryService,
            this.modalInstanceFactory,
        )
    }
}
