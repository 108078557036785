import { NgModule } from '@angular/core'
import { VisibleRangeDirective } from './directives/visible-range.directive'
import { DynamicSizeVirtualScrollDirective } from './directives/dynamic-size.directive'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { CdkFixedSizeVirtualScrollDirective } from '@components-library/virtual-scrolling/directives/fixed-size-virtual-scroll'

const forExport = [
    VisibleRangeDirective,
    DynamicSizeVirtualScrollDirective,
    CdkFixedSizeVirtualScrollDirective,
]

@NgModule({
    imports: [ScrollingModule],
    declarations: [...forExport],
    exports: [...forExport, ScrollingModule],
})
export class TbScrollingModule {}
