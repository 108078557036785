import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-tb-accordion',
    templateUrl: './tb-accordion.component.html',
    styleUrl: './tb-accordion.component.sass',
})
export class TbAccordionComponent {
    @Input() expanded = false
    @Input() title!: string
    @Input() background = false
    @Input() description = ''
    @Input() settingsShow = false

    @Output() callAdvanced = new EventEmitter()

    get headerBackgroundDisplaying() {
        return this.background ? 'bg-newNeutral2' : 'hover:bg-newNeutral3'
    }

    get headerHeightDisplaying() {
        if (this.description && this.background) return 'h-[4.25rem]'

        return this.background ? 'h-[3.25rem]' : 'h-5'
    }

    get bodyBackgroundDisplay() {
        return this.background ? 'bg-newNeutral2' : ''
    }

    get contentBackgroundDisplay() {
        return this.background ? 'bg-newNeutral1' : ''
    }
}
