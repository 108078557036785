import { Injectable } from '@angular/core'
import { AppState, setGroups } from '@core/@ngrx'
import { Group } from '@core/models/ui/group.model'
import { Store } from '@ngrx/store'

@Injectable({
    providedIn: 'root',
})
export class GroupFacadeService {
    constructor(private store: Store<AppState>) {}

    setGroups(groups: Group[]) {
        this.store.dispatch(setGroups({ groups }))
    }
}
