import {
    ApplicationRef,
    createComponent,
    Injectable,
    InjectionToken,
    Injector,
} from '@angular/core'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { ModalContainerComponent } from './modal-container-component/modal-container.component'
import { ComponentType } from '@angular/cdk/overlay'

/**
 * The InjectionToken that is used to inject data into Modal Containers.
 **/
export const ModalContainerDataToken = new InjectionToken('ModalContainerDataToken')

/**
 * ModalContainerFactoryService encapsulate logic for Modal Container creation.
 **/
@Injectable({
    providedIn: 'root',
})
export class ModalContainerFactoryService {
    constructor(private appRef: ApplicationRef, private injector: Injector) {}

    createComponentRef<T extends ModalContainerComponent, U>(
        modalManager: ModalManagerService,
        component: ComponentType<T>,
        data?: U,
    ) {
        return createComponent(component, {
            /**
             * We pass Injectors to have access to all Application Services, otherwise we wouldn't be able to inject them in the Containers.
             **/
            environmentInjector: this.appRef.injector,
            elementInjector: this.getContainerInjector(modalManager, data),
        })
    }

    private getContainerInjector<T>(modalManager: ModalManagerService, data?: T) {
        return Injector.create({
            providers: [
                {
                    provide: ModalContainerDataToken,
                    useValue: data,
                },
                {
                    provide: ModalManagerService,
                    useValue: modalManager,
                },
            ],
            parent: this.injector,
        })
    }
}
