import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import { TextOneLineConfig } from '@core/models'

@Component({
    selector: 'app-text-one-line-filter',
    templateUrl: './text-one-line-filter.component.html',
    styleUrls: ['./text-one-line-filter.component.sass'],
})
export class TextOneLineFilterComponent extends FilterTypesComponent implements OnInit {
    textFormControl!: FormControl<string | string[] | undefined | null>
    minLength!: number
    maxLength!: number

    ngOnInit(): void {
        if (this.field.configure_json) {
            const textConfig: TextOneLineConfig = JSON.parse(this.field.configure_json)
            if (textConfig) {
                this.minLength = textConfig.minLength
                this.maxLength = textConfig.maxLength
            } else {
                this.minLength = 0
                this.maxLength = 1000
            }
        }

        this.textFormControl = this.form.controls['value'] as FormControl
        this.textFormControl.addValidators([
            Validators.maxLength(this.maxLength),
            Validators.minLength(this.minLength),
        ])
    }
}
