import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { Deleted } from '../../models'
import { SubtaskRecord } from '../../models/ui/subtask-record.model'

export const setSubtasks = createAction(
    '[SubtaskRecords] Set subtasks',
    props<{ subtasks: SubtaskRecord[] }>(),
)

export const resetSubtasks = createAction('[SubtaskRecords] reset subtasks')

export const addSubtasksFromResponse = createAction(
    '[SubtaskRecords] Add subtasks',
    props<{ subtasks: SubtaskRecord[] }>(),
)

export const updateSubtasksFromResponse = createAction(
    '[SubtaskRecords] Update subtasks',
    props<{ subtasks: Update<SubtaskRecord>[] }>(),
)

export const deleteSubtasksFromResponse = createAction(
    '[SubtaskRecords] Delete subtasks',
    props<{ subtasks: Deleted[] }>(),
)
