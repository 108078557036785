import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    TemplateRef,
    ViewChildren,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatExpansionPanel } from '@angular/material/expansion'
import { BusinessRecords, Field, FieldType, Folder, isFolderGlobal, Schema } from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'

export interface AccordionContentContext {
    fieldTypes: Dictionary<FieldType>
    folder: Folder
    fields: Field[]
    //next fields are needed for record creation
    values: { [fieldGuid: string]: string }
    records: Dictionary<BusinessRecords>
    form?: FormGroup
    groupName: string
}

@UntilDestroy()
@Component({
    selector: 'app-fields-collection',
    templateUrl: './fields-collection.component.html',
})
export class FieldsCollectionComponent implements OnChanges {
    @Input() accordionContent!: TemplateRef<AccordionContentContext>
    @Input({ required: false }) form?: FormGroup
    // could be not equal to store selected folder during record creation
    @Input() selectedFolder!: Folder
    @Input() recordFieldValues: { [fieldGuid: string]: string } = {}
    // for link fields
    @Input() records!: Dictionary<BusinessRecords>
    @Input() excludedFieldTypes!: string[]
    @Input() folders!: Folder[] | null
    @Input() selectedSchema?: Schema | null
    @Input() fieldTypes!: Dictionary<FieldType>

    protected readonly isFolderGlobal = isFolderGlobal

    @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>

    availableFolders!: Folder[]
    fieldsByFolder: { [guid: string]: Field[] } = {}
    sharedFields: Field[] = []
    requiredFields: Field[] = []

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(): void {
        this.setState()
    }

    getAccordionContentContext(
        groupName: string,
        fields: Field[],
        folder: Folder,
    ): AccordionContentContext {
        return {
            fieldTypes: this.fieldTypes,
            folder,
            fields,
            values: this.recordFieldValues,
            records: this.records,
            form: this.form,
            groupName,
        }
    }

    scrollToExpansionPanel(guid: string) {
        const htmlPanel = (this.elementRef.nativeElement as HTMLElement).querySelector(`#${guid}`)

        if (htmlPanel) {
            htmlPanel.scrollIntoView({ behavior: 'smooth' })
        }
    }

    // TODO: will be moved to the accordion component later
    expansionPanelIsExpanded(guid: string) {
        if (!this.expansionPanels) return false

        const expansionPanel = this.expansionPanels.find((panel) => panel.id === guid)

        return !!expansionPanel?.expanded
    }

    private setState() {
        if (!this.selectedSchema || !this.folders) return

        this.setFields(this.folders, this.selectedSchema)

        if (isFolderGlobal(this.selectedFolder)) {
            this.availableFolders = this.folders
        } else {
            this.availableFolders = this.folders.filter(
                (folder) => folder.guid === this.selectedFolder.guid || isFolderGlobal(folder),
            )
        }
    }

    private setFields(folders: Folder[], schema: Schema) {
        const fields = Object.values(schema.fieldEntities)

        this.sharedFields = this.getSharedFields(fields)
        this.requiredFields = this.getRequiredFields(fields)
        folders.reduce((fieldsByFolder, folder) => {
            const guid = folder.guid
            fieldsByFolder[guid] = this.getFieldsByFolder(fields, guid)
            return fieldsByFolder
        }, this.fieldsByFolder)
    }

    private getRequiredFields(fields: Field[]) {
        return fields.filter(
            (field) =>
                !!field.is_required && !this.excludedFieldTypes.includes(field.field_type_code),
        )
    }

    private getFieldsByFolder(fields: Field[], folderGuid: string) {
        return fields.filter(
            (field) =>
                !field.is_required &&
                !this.excludedFieldTypes.includes(field.field_type_code) &&
                field.folder_guid === folderGuid,
        )
    }

    private getSharedFields(fields: Field[]) {
        return fields.filter(
            (field) =>
                !field.is_required &&
                !this.excludedFieldTypes.includes(field.field_type_code) &&
                !!field.shared_with_folder?.includes(this.selectedFolder.guid),
        )
    }
}
