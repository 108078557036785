import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Palettes } from '@components-library/models'

export type CommonDialogConfig = {
    translations: {
        title: string
        message: string
        confirm?: string
        cancel?: string
    }
    buttons?: {
        confirm?: boolean
        cancel?: boolean
    }
    colors?: {
        confirm?: Palettes
        cancel?: Palettes
    }
}

export enum CommonDialogResultStatus {
    CONFIRM = 'CONFIRM',
    CANCEL = 'CANCEL',
}

@Component({
    selector: 'app-tb-tb-confirmation-popup',
    templateUrl: './tb-confirmation-dialog.component.html',
})
export class TbConfirmationDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CommonDialogConfig,
        public dialog: MatDialogRef<TbConfirmationDialogComponent>,
    ) {}

    close() {
        this.dialog.close(CommonDialogResultStatus.CANCEL)
    }

    confirm() {
        this.dialog.close(CommonDialogResultStatus.CONFIRM)
    }

    get confirmButtonColor() {
        return this.data.colors?.confirm || Palettes.primary
    }

    get cancelButtonColor() {
        return this.data.colors?.cancel || Palettes.primary
    }
}
