import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    SkipSelf,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ResizeColumnService } from '@app/views/table/table/resize-column.service'
import { isNumber, sum } from 'lodash-es'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { VIRTUAL_SCROLLABLE, CdkVirtualScrollableElement } from '@angular/cdk/scrolling'

export type TableRow = {
    item: any
    guid: string
}

export type TableColumn = {
    guid: string
    isPinned: boolean
    item: any
    colGroup?: {
        colspan: number
        subheaders: string[]
    }
    colWidth?: number
}

export type RenderedColumn = TableColumn & { colWidth: number }

export type DropColumns = { columns: TableColumn[]; previousIndex: number; currentIndex: number }
export type MovedRow = {
    item: any
    previousRows: TableRow[]
    rows: TableRow[]
    previousIndex: number
    currentIndex: number
}

export type ResizeColumn = { column: TableColumn; width: number }

const resizeServiceFactory = (
    parentInjector: Injector,
    resizeColumnService?: ResizeColumnService,
) => {
    if (!resizeColumnService) {
        const injector = Injector.create({
            providers: [{ provide: ResizeColumnService }],
            parent: parentInjector,
        })
        resizeColumnService = injector.get(ResizeColumnService)
    }

    return resizeColumnService
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: ResizeColumnService,
            useFactory: resizeServiceFactory,
            deps: [Injector, [new Optional(), new SkipSelf(), ResizeColumnService]],
        },
    ],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('virtualRows')
    rowsViewport!: CdkVirtualScrollViewport

    @ViewChild('headersScroll')
    headersViewport!: CdkVirtualScrollViewport

    @ViewChild(CdkVirtualScrollableElement)
    cdkVirtualScrollableElement!: CdkVirtualScrollableElement

    @ViewChild('rowsContainer', { read: ViewContainerRef, static: false })
    rowsContainer!: ViewContainerRef

    @ViewChild('rowsWithoutVerticalScroll')
    rowsWithoutVerticalScroll!: TemplateRef<any>

    @ViewChild('rowsWithVerticalScroll')
    rowsWithVerticalScroll!: TemplateRef<any>

    @ViewChild('tableRenderContainer', { read: ViewContainerRef })
    tableRenderContainer!: ViewContainerRef

    @ViewChild('tableContent')
    tableContent!: TemplateRef<any>

    @Input()
    rows!: TableRow[]

    @Input()
    columns!: TableColumn[]

    @Input()
    lockFirstColumn = false

    @Input()
    isGrouped = false

    @Input()
    uniqueKey!: string

    @Input()
    columnCell!: TemplateRef<any>

    @Input()
    rowCell!: TemplateRef<any>

    @Input()
    rowMenu!: TemplateRef<any>

    @Input()
    selectedRowItems: TableRow[] = []

    @Output()
    changedOrderColumn: EventEmitter<DropColumns> = new EventEmitter()

    @Output()
    movedRowFromAnotherTable: EventEmitter<MovedRow> = new EventEmitter()

    @Output()
    resizeColumn: EventEmitter<ResizeColumn> = new EventEmitter()

    @Output()
    selectedRow: EventEmitter<TableRow> = new EventEmitter()

    @Input()
    verticalScrollOptimization = false

    draggableContainerWidth!: string
    columnWidths: number[] = []
    draggableColumns!: RenderedColumn[]
    fixedColumns!: RenderedColumn[]
    doesHaveColGroups = false

    DEFAULT_COLUMN_WIDTH = 300

    constructor(
        @Optional() public parentScrollableElement: CdkVirtualScrollableElement,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.doesHaveColGroups = this.columns.some((column) => !!column.colGroup)
        this.setFixedAndDraggableColumns()
        this.setGridTemplate()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            this.setFixedAndDraggableColumns()
            this.setGridTemplate()
        }

        // todo: should be only call when we change SOT
        if (this.rowsViewport && this.headersViewport) {
            this.rowsViewport.scrollToIndex(0)
            this.headersViewport.scrollToIndex(0)
        }
    }

    ngAfterViewInit() {
        const injector = Injector.create({
            providers: [
                {
                    provide: VIRTUAL_SCROLLABLE,
                    useValue: this.parentScrollableElement || this.cdkVirtualScrollableElement,
                },
            ],
        })
        this.tableRenderContainer.createEmbeddedView(this.tableContent, {}, { injector })

        this.changeDetectorRef.detectChanges()

        const rowsTemplate = this.verticalScrollOptimization
            ? this.rowsWithVerticalScroll
            : this.rowsWithoutVerticalScroll

        this.rowsContainer.createEmbeddedView(
            rowsTemplate,
            {},
            {
                injector,
            },
        )
    }

    dropRow(event: CdkDragDrop<TableRow[], any>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
            return
        }

        this.movedRowFromAnotherTable.emit({
            previousRows: event.previousContainer.data,
            item: event.item.data.item,
            rows: this.rows,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex,
        })
    }

    dropColumnFn({ previousIndex, currentIndex }: CdkDragDrop<RenderedColumn[], any>) {
        if (previousIndex === currentIndex) return

        this.changedOrderColumn.emit({
            columns: this.draggableColumns,
            previousIndex,
            currentIndex,
        })
    }

    selectRow(row: TableRow) {
        this.selectedRow.emit(row)
    }

    isRowSelected(row: TableRow) {
        return !!this.selectedRowItems.find(
            (selectedRow) => selectedRow.item[this.uniqueKey] === row.item[this.uniqueKey],
        )
    }

    resizeTableColumn(column: TableColumn, width: number) {
        if (column.colWidth === width) return
        this.resizeColumn.emit({ column: column, width })
    }

    trackByFn(index: number, item: TableColumn | TableRow): string {
        return item.guid
    }

    private setGridTemplate() {
        this.columnWidths = this.draggableColumns.map((column, index) => {
            return column.colWidth
        })

        this.draggableContainerWidth = `${sum([...this.columnWidths, 60])}px` //this is for the last button that is not present in the header row
    }

    private setFixedAndDraggableColumns() {
        const firstOrPinned = (column: TableColumn, index: number) => index === 0 || column.isPinned

        this.fixedColumns = this.columns
            .filter((column, index) => firstOrPinned(column, index))
            .map((column, index) => {
                return { ...column, colWidth: this.getColumnWidth(column) }
            })

        this.draggableColumns = this.columns
            .map((column, index) => {
                return { ...column, colWidth: this.getColumnWidth(column) }
            })
            .filter((column, index) => !firstOrPinned(column, index))
    }

    private getColumnWidth(column: TableColumn) {
        const group = column.colGroup
        const colWidth = column.colWidth
        const groupWidth = group
            ? group.colspan * this.DEFAULT_COLUMN_WIDTH
            : this.DEFAULT_COLUMN_WIDTH

        return isNumber(colWidth) ? colWidth : groupWidth
    }
}
