import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatDialog } from '@angular/material/dialog'
import { TbTab } from '@components-library/tb-tab/tb-tab.component'
import { isNonNull } from '@core/global-util'
import { CurrentUser, Folder, View } from '@core/models'
import { NavigatorService } from '@core/services/navigator.service'
import {
    FolderFacadeService,
    UserFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, Observable } from 'rxjs'
import { CreateViewComponent } from './create-view/create-view.component'

@UntilDestroy()
@Component({
    selector: 'app-views-list',
    templateUrl: './views-list.component.html',
    styleUrls: ['./views-list.component.sass'],
})
export class ViewsListComponent implements OnInit {
    views!: View[]

    selectedView!: View
    isViewMenuOpened!: boolean
    user!: CurrentUser

    toolbarIsInvisible: boolean = true

    selectedTab: TbTab | null = null
    viewTabs: TbTab[] = []

    @ViewChild('templateBottomSheet') TemplateBottomSheet!: TemplateRef<any>

    readonly selectedFolder$: Observable<Folder> = this.folderFacadeService.selectSelectedFolder$

    constructor(
        private navigatorService: NavigatorService,
        private folderFacadeService: FolderFacadeService,
        private userFacadeService: UserFacadeService,
        private viewFacadeService: ViewFacadeService,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
    ) {}

    openBottomSheetSm() {
        this.bottomSheet.open(this.TemplateBottomSheet, { panelClass: 'bottomSheetCustom' })
    }

    openBottomSheet() {
        this.bottomSheet.open(CreateViewComponent, {
            panelClass: 'bottomSheetCustom',
            data: { selectedView: this.selectedView, currentUserGuid: this.user.guid },
        })
    }

    openDialog() {
        this.dialog.open(CreateViewComponent, {
            data: { selectedView: this.selectedView, currentUserGuid: this.user.guid },
        })
    }

    ngOnInit(): void {
        this.isViewMenuOpened = false
        this.viewFacadeService.selectedTableSchemaViews$
            .pipe(untilDestroyed(this))
            .subscribe((views) => {
                this.views = views
                this.viewTabs = this.views.map((view) => ({
                    icon: view.icon,
                    name: view.name.value,
                    id: view.guid,
                }))

                if (this.selectedView) {
                    this.selectedTab = this.getTabByView(this.selectedView) || null
                }
            })

        this.viewFacadeService.selectedView$
            .pipe(untilDestroyed(this), filter(isNonNull))
            .subscribe((view) => {
                this.selectedView = view
                this.selectedTab = this.getTabByView(view) || null
            })

        this.userFacadeService.currentUser$
            .pipe(untilDestroyed(this), filter(isNonNull))
            .subscribe((user) => {
                this.user = user
            })
    }

    selectView(tab: TbTab) {
        this.selectedTab = tab
        const view = this.getViewByTab(tab)
        if (!view) return

        this.navigate(view)
    }

    showFilterBar() {
        this.toolbarIsInvisible = !this.toolbarIsInvisible
    }

    IsSelected(view: View): boolean {
        return view.guid === this.selectedView.guid
    }

    navigate(view: View) {
        this.navigatorService.goToView(view)
    }

    private getViewByTab(tab: TbTab) {
        return this.views.find((view) => view.guid === tab.id)
    }

    private getTabByView(view: View) {
        return this.viewTabs.find((tab) => tab.id === view.guid)
    }
}
