import { forEach, omitBy, pickBy, reduce, set } from 'lodash-es'
import {
    ResponseCellValue,
    ResponseField,
    ResponseFieldEntities,
    ResponseRecord,
} from '../response'
import { BusinessRecord } from './business-record.model'

export interface CellEntities {
    [field_guid: string]: Cell
}

export interface TypeRelatedCellEntities {
    [fieldName: string]: Cell | string
}

export interface Cell {
    fieldName?: string
    fieldType: string
    systemName?: string
    fieldGuid: string
    value: string
    value_json?: ValueJson
    revision: number
}

export interface ValueJson {
    [guid: string]: string
}

export enum LinkOptions {
    delete = 'DELETE',
    archive = 'ARCHIVE',
}

export function generateCell(
    field_guid: string,
    field: ResponseField,
    cell?: ResponseCellValue,
): Cell | undefined {
    if (field.acl === -1) {
        return
    }

    if (!cell) {
        cell = {
            value: '',
            revision: 0,
        }
    }

    if (cell.value_json) {
        return {
            fieldName: field.name,
            fieldGuid: field_guid,
            fieldType: field.field_type_code,
            value_json: cell.value_json,
            revision: cell.revision,
        } as Cell
    }

    return {
        fieldName: field.name,
        fieldGuid: field_guid,
        fieldType: field.field_type_code,
        value: cell.value ?? '',
        revision: cell.revision,
    }
}

export function generateTypeRelatedCellEntities(
    responseFieldEntities: ResponseFieldEntities,
    responseRecord: ResponseRecord,
    fieldNames: string[],
): TypeRelatedCellEntities {
    return Object.keys(responseFieldEntities).reduce((res, fieldGuid: string) => {
        const name = responseFieldEntities[fieldGuid].system_name
        if (name && fieldNames.includes(name)) {
            const cell = generateCell(
                fieldGuid,
                responseFieldEntities[fieldGuid],
                responseRecord.cells[fieldGuid],
            )
            if (cell) {
                res[name] = cell
            }
        } else {
            // TODO: handle
            console.error('System record generation. Invalid field system name', [
                name,
                fieldNames,
                responseFieldEntities,
                responseRecord,
            ])
        }
        return res
    }, {} as { [fieldGuid: string]: Cell })
}

export function generateGlobalTypeRelatedCellEntities(
    responseFieldEntities: ResponseFieldEntities,
    responseRecord: ResponseRecord,
    fieldNames: string[],
): Partial<BusinessRecord> {
    const foldersCells = ['status', 'assignee', 'description']

    const globalFields = generateGlobalFields(
        responseFieldEntities,
        responseRecord,
        fieldNames,
        foldersCells,
    )
    const folderFields = generateFieldsWithFolder(
        responseFieldEntities,
        responseRecord,
        fieldNames,
        foldersCells,
    )

    const invalidField = pickBy(
        responseFieldEntities,
        (field) => field.system_name && !fieldNames.includes(field.system_name),
    )
    forEach(invalidField, (field) =>
        console.error(`System name ${field.system_name} is not supported.`, field),
    )

    const fieldWithoutName = omitBy(responseFieldEntities, (field) => !!field.system_name)
    const noNameCells = generateNoNameCells(
        {
            ...invalidField,
            ...fieldWithoutName,
        },
        responseRecord,
    )

    return {
        noNameCells,
        ...globalFields,
        ...folderFields,
    }
}

function generateGlobalFields(
    responseFieldEntities: ResponseFieldEntities,
    responseRecord: ResponseRecord,
    fieldNames: string[],
    foldersCells: string[],
) {
    const recordFields = pickBy(responseFieldEntities, (field) => {
        return (
            field.system_name &&
            fieldNames.includes(field.system_name) &&
            !foldersCells.includes(field.system_name)
        )
    })

    type SystemNames = 'watch' | 'name' | 'richTextDescription'
    return reduce(
        recordFields,
        (dictionary, field, fieldGuid) => {
            const name = field.system_name as SystemNames
            if (!name) return dictionary

            const cell = generateCell(fieldGuid, field, responseRecord.cells[fieldGuid])

            if (cell) {
                dictionary[name] = cell
            }

            return dictionary
        },
        {} as { [key in SystemNames]: Cell },
    )
}

function generateNoNameCells(
    responseFieldEntities: ResponseFieldEntities,
    responseRecord: ResponseRecord,
) {
    return reduce(
        responseFieldEntities,
        (dictionary, field, fieldGuid: string) => {
            const cell = generateCell(fieldGuid, field, responseRecord.cells[fieldGuid])

            if (cell) {
                dictionary[fieldGuid] = cell
            }

            return dictionary
        },
        {} as { [fieldGuid: string]: Cell },
    )
}

function generateFieldsWithFolder(
    responseFieldEntities: ResponseFieldEntities,
    responseRecord: ResponseRecord,
    fieldNames: string[],
    foldersCells: string[],
): { [folderGuid: string]: Cell } {
    type SystemNames = 'status' | 'assignee' | 'description'
    return reduce(
        responseFieldEntities,
        (dictionary, field, fieldGuid) => {
            const name = field.system_name as SystemNames
            if (!name || !foldersCells.includes(name)) return dictionary

            const cell = generateCell(fieldGuid, field, responseRecord.cells[fieldGuid])

            if (cell) {
                set(dictionary, `${name}.${field.folder_guid}`, cell)
            }

            return dictionary
        },
        {} as { [key in SystemNames]: Cell },
    )
}
