<ng-container *transloco="let tGroup; read: 'group'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (keydown.tab)="$event.stopPropagation()"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <span class="text-primaryDark body-2">{{tGroup('group_by')}}</span>
        </ng-container>

        <div
            (click)="$event.stopPropagation()"
            (keydown.tab)="$event.stopPropagation();"
            class="max-sm:mt-4 filter-area body-1 leading-4"
        >
            <div class="flex items-center mb-4 dense-1">
                <app-tb-select
                    class="w-full noHintError"
                    placeholder="Select item"
                    [formControl]="selectControl"
                >
                    <app-tb-option [value]="''">{{tGroup('none')}}</app-tb-option>
                    <app-tb-option *ngFor="let field of fields" [value]="field.guid"
                        ><app-field-name
                            [field]="field"
                            [selectedFolder]="selectedFolder"
                            [showIcon]="false"
                        ></app-field-name
                    ></app-tb-option>
                </app-tb-select>
            </div>
        </div>

        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex items-center">
                <app-tb-button
                    [appearance]="'text'"
                    [color]="'secondary'"
                    [icon]="'close'"
                    [class.invisible]="!this.applyForSession"
                    (click)="resetToDefault()"
                    class="mr-10 whitespace-nowrap"
                >
                    <span class="body-2">{{tGroup('reset')}}</span>
                </app-tb-button>
                <div class="flex items-center justify-end">
                    <app-tb-button
                        [appearance]="'outlined'"
                        (click)="onApplyForSession()"
                        [disabled]="!isSelectControlChanged()"
                        class="mr-5"
                    >
                        {{tButtons('preview')}}
                    </app-tb-button>
                    <app-tb-button (click)="onSaveGroup()" [disabled]="!isSaveButtonActive()">
                        {{tGroup('save')}}
                    </app-tb-button>
                </div>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
