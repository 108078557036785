<div
    *transloco="let tFilter; read: 'filter'"
    class="flex flex-col items-center w-full h-full mt-12"
>
    <mat-icon class="w-[328px] h-[362px]" svgIcon="filters_img"></mat-icon>
    <div class="max-w-[260px] body-1 text-primarySecondary">
        {{tFilter('no_results')}}

        <app-tb-button [appearance]="'link'" (click)="changeFilters.emit()" class="inline">
            {{tFilter('change_filters')}}
        </app-tb-button>
    </div>
</div>
