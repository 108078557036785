import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { LogService } from '@services/log.service'
import { EnvironmentMode } from '@test/dev-utils'
import { environment } from 'src/environments/environment'
import { AutomationStoreModule } from './automation'
import { CommonStoreModule, dataReset } from './common'
import { ConfigStoreModule } from './config'
import { FieldTypeStoreModule } from './field-type/field-type-store.module'
import { FolderStoreModule } from './folder'
import { GroupStoreModule } from './group'
import { RecordStoreModule } from './record'
import { RoleStoreModule } from './role'
import { SchemaStoreModule } from './schema'
import { SubtaskStoreModule } from './subtask/subtask-store.module'
import { UserStoreModule } from './user/user-store.module'
import { ViewStoreModule } from './view'

function metaReducerDataReset(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === dataReset.type) {
            console.log('[META] Data reset initiated. Clearing store now...')

            return reducer({ router: state.router }, action)
        }

        return reducer(state, action)
    }
}

const metaReducers: MetaReducer[] = [LogService.logStoreActions, metaReducerDataReset]

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot(),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        CommonStoreModule,
        ConfigStoreModule,
        UserStoreModule,
        FieldTypeStoreModule,
        SchemaStoreModule,
        FolderStoreModule,
        ViewStoreModule,
        RecordStoreModule,
        AutomationStoreModule,
        SubtaskStoreModule,
        GroupStoreModule,
        RoleStoreModule,

        // Can't call functions in imports. MB you have any solution? checkEnvironmentMode should call here
        (environment.mode as EnvironmentMode) !== 'prod'
            ? StoreDevtoolsModule.instrument({ connectInZone: true })
            : [],
    ],
})
export class RootStoreModule {}
