<div mat-dialog-content>
    <app-cell-container
        (cellValueChanged)="updateRecord(record, cells[record.guid][field.guid], $event)"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [guid]="field.guid"
        [record]="record"
        [value]="newValue"
        class="flex-start-center text-center"
    ></app-cell-container>
</div>
<div mat-dialog-actions>
    <div
        class="flex-start-center w-100 mt-xxl justify-content-end"
        *transloco="let tButtons; read: 'buttons'"
    >
        <app-tb-button (click)="closeEditField()" class="mr-5" [appearance]="'outlined'">
            {{tButtons('cancel')}}
        </app-tb-button>
        <app-tb-button (click)="applyEditField()"> {{tButtons('apply')}} </app-tb-button>
    </div>
</div>
