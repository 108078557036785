import { Injectable } from '@angular/core'
import { ViewFilterService } from '@app/views/view-controls/view-filter/view-filter.service'
import { ViewGroupService } from '@app/views/view-controls/view-group/view-group.service'
import { ViewSortService } from '@app/views/view-controls/view-sort/view-sort.service'
import { SelectObjectOptions } from '@models/response/select-object-options'
import { SortObject, SortObjectEntities } from '@models/ui/sort.model'
import { Actions, ofType } from '@ngrx/effects'
import { isNonNull } from '@core/global-util'
import { Dictionary, Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { SortStorageService } from '@services/local-storage/sort-storage.service'
import { LogService } from '@services/log.service'
import { cloneDeepWith } from 'lodash-es'
import { combineLatest, filter, map, shareReplay } from 'rxjs'
import { take } from 'rxjs/operators'
import {
    addRecordsFromResponse,
    AppState,
    deleteRecordsFromResponse,
    initRecords,
    NO_GROUPED_RECORDS_KEY,
    resetRecords,
    selectAllRecords,
    selectRecordEntities,
    selectSchemaEntities,
    selectSelectedFolder,
    selectSelectedTableSchema,
    selectSelectedTableSchemaDataRecords,
    selectSelectedView,
    selectTableDataBySchemasAssignedCurrenUser,
    updateRecordsFromResponse,
} from '../../@ngrx'
import {
    AppRecord,
    BusinessRecords,
    CellEntities,
    ColGroup,
    Deleted,
    Field,
    FieldEntities,
    FieldTypes,
    FilterGroup,
    Folder,
    getFieldNamesBySOT,
    getRecordCells,
    isFolderGlobal,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    Schema,
    View,
    ViewTypeCodes,
} from '../../models'

@Injectable({
    providedIn: 'root',
})
export class RecordFacadeService {
    constructor(
        private store: Store<AppState>,
        private viewFilterService: ViewFilterService,
        private filterStorageService: FilterStorageService,
        private viewGroupService: ViewGroupService,
        private groupStorageService: GroupStorageService,
        private sortStorageService: SortStorageService,
        private viewSortService: ViewSortService,
        private logService: LogService,
        private actions$: Actions,
    ) {}

    selectRecordEntities$ = this.store.select(selectRecordEntities)

    selectAllRecords$ = this.store.select(selectAllRecords)

    //TODO: remove effect from facade
    getLastAddedRecord() {
        return this.actions$.pipe(
            ofType(addRecordsFromResponse),
            map(({ records }) => records[0]),
        )
    }

    prepareViewData$ = combineLatest([
        this.store.select(selectSelectedTableSchema).pipe(filter(isNonNull)),
        this.store.select(selectSelectedTableSchemaDataRecords),
        this.store.select(selectSelectedView).pipe(filter(isNonNull)),
        this.store.select(selectSchemaEntities),
        this.store.select(selectSelectedFolder),
        this.filterStorageService.getStore$(),
        this.groupStorageService.getStore$(),
        this.sortStorageService.getStore$(),
    ]).pipe(
        filter(([schema, , view]) => {
            return view?.parent_sot_guid === schema?.guid
        }),
        map(([schema, records, view, allSchemas, folder]) => {
            // TODO: [table-ref] I would keep only one Data Structure
            let data: BusinessRecords[] | Map<string, RecordGroup> = this.filterRecords(
                view,
                records,
                this.viewFilterService.getFilterGroupByView(view),
            )

            let { columnsWidth, hiddenColumns, pinnedColumns, columnsOrder } =
                this.parseViewFields(view)
            hiddenColumns = this.prepareHiddenFields(view, hiddenColumns, schema, folder)

            let first_column!: string
            Object.keys(schema.fieldEntities).forEach((fieldGuid: string) => {
                if (schema.fieldEntities[fieldGuid].is_primary) {
                    first_column = schema.fieldEntities[fieldGuid].guid
                }

                if (!columnsOrder.includes(fieldGuid)) {
                    columnsOrder.push(fieldGuid)
                }
            })

            const pinnedNotHide: string[] = pinnedColumns.filter(
                (item) => !hiddenColumns.includes(item),
            )
            const displayColumns: string[] = columnsOrder.filter(
                (item) => !hiddenColumns.includes(item) && !pinnedNotHide.includes(item),
            )
            columnsOrder = pinnedColumns.concat(
                columnsOrder.filter((item) => !pinnedColumns.includes(item)),
            )
            const columns: string[] = pinnedNotHide.concat(displayColumns)

            let sortedColumns: string[] = []
            let sortedFields: FieldEntities = {}

            columns.concat(hiddenColumns).forEach((guid) => {
                if (schema.fieldEntities[guid] && folder) {
                    if (schema.fieldEntities[guid].folder_guid && !isFolderGlobal(folder)) {
                        if (
                            schema.fieldEntities[guid].folder_guid === folder.guid ||
                            schema.fieldEntities[guid].shared_with_folder?.includes(folder.guid) ||
                            schema.fieldEntities[guid].folder_name?.is_global
                        ) {
                            if (!hiddenColumns.includes(guid)) sortedColumns.push(guid)
                            sortedFields[guid] = schema.fieldEntities[guid]
                        }
                    } else {
                        if (!hiddenColumns.includes(guid)) sortedColumns.push(guid)
                        sortedFields[guid] = schema.fieldEntities[guid]
                    }
                }
            })

            if (!first_column) {
                throw new Error(
                    'It is not enough data to render the view. The first column is required',
                )
            }

            sortedColumns.splice(sortedColumns.indexOf(first_column), 1)
            sortedColumns.splice(0, 0, first_column)

            const groupValue = this.viewGroupService.getGroupByView(view)
            this.groupStorageService.updateIsSetValue(view.guid)
            data = this.generateGroup(
                data as BusinessRecords[],
                groupValue,
                schema.fieldEntities[groupValue],
            )

            this.sortStorageService.updateIsSetValue(view.guid)
            data = this.sortData(data, view, this.viewSortService.getSortByView(view))

            return {
                fields: sortedFields,
                data,
                selectedView: view,
                columns: {
                    columns: sortedColumns,
                    columnsOrder,
                    hiddenColumns,
                    pinnedColumns,
                    columnsWidth,
                    colGroups: this.generateColGroup(
                        sortedColumns,
                        schema.fieldEntities,
                        allSchemas,
                    ),
                },
            }
        }),
        shareReplay(1),
    )

    selectViewData$ = () => this.prepareViewData$

    parseViewFields(view: View) {
        //TODO: parse before save view
        const columnsOrderString = view.columns_order.value
        const pinedColumnsString = view.columns_pinned.value
        const hiddenColumnsString = view.columns_hide.value
        const columnsWidthString = view.columns_width.value

        const columnsWidth: { [p: string]: number } | undefined = columnsWidthString
            ? JSON.parse(columnsWidthString)
            : undefined

        const hiddenColumns: string[] = hiddenColumnsString ? hiddenColumnsString.split(',') : []
        const pinnedColumns: string[] = pinedColumnsString ? pinedColumnsString.split(',') : []
        let columnsOrder: string[] = columnsOrderString ? columnsOrderString.split(',') : []
        return {
            columnsWidth,
            hiddenColumns,
            pinnedColumns,
            columnsOrder,
        }
    }

    private prepareHiddenFields(
        view: View,
        hiddenColumns: string[],
        schema: Schema,
        selectedFolder: Folder,
    ) {
        if (view.columns_hide.revision > 0 || view.type_code.value !== ViewTypeCodes.BOARD)
            return hiddenColumns

        const schemaFields = getFieldNamesBySOT(schema.object_type_code!)

        return Object.keys(schema.fieldEntities).filter((key) => {
            const systemName = schema.fieldEntities[key].system_name
            if (!systemName) return true

            return (
                !schemaFields.includes(systemName) ||
                schema.fieldEntities[key].folder_guid !== selectedFolder.guid
            )
        })
    }

    selectTableDataBySchemasAssignedCurrenUser$ = this.store.select(
        selectTableDataBySchemasAssignedCurrenUser,
    )

    selectRecordsBySchemaId(schemaGuid: string) {
        return this.selectAllRecords$.pipe(
            take(1),
            map((records) => records.filter((record) => record.schemaGuid === schemaGuid)),
        )
    }

    selectDataTableRecordsBySchemaGuid$ = (schemaGuid: string) => {
        return this.selectAllRecords$.pipe(
            map((records: BusinessRecords[]) => {
                if (!schemaGuid) {
                    return []
                }
                return records.filter((record) => record.schemaGuid === schemaGuid)
            }),
        )
    }

    selectRecordById$ = (recordGuid: string) => {
        return this.selectRecordEntities$.pipe(
            map((records: Dictionary<BusinessRecords>) => records[recordGuid]),
        )
    }

    initRecords(records: BusinessRecords[]) {
        this.store.dispatch(initRecords({ records }))
    }

    resetRecords() {
        this.store.dispatch(resetRecords())
    }

    addRecordsFromResponse(records: BusinessRecords[]) {
        this.store.dispatch(addRecordsFromResponse({ records }))
    }

    updateRecordsFromResponse(records: Update<AppRecord>[]) {
        this.store.dispatch(updateRecordsFromResponse({ records }))
    }

    deleteRecordsFromResponse(records: Deleted[]) {
        this.store.dispatch(deleteRecordsFromResponse({ records }))
    }

    private filterRecords(view: View, records: BusinessRecords[], filterGroups?: FilterGroup[]) {
        if (!filterGroups) return records

        this.filterStorageService.updateIsSetValue(view.guid)
        let filteredRecords: BusinessRecords[] | Map<string, RecordGroup> = records
        if (filterGroups?.length) {
            filteredRecords = this.viewFilterService.applyFilter(filterGroups, records)
        }
        return cloneDeepWith(filteredRecords)
    }

    generateGroup(data: BusinessRecords[], groupFieldGuid: string, field: Field) {
        if (!groupFieldGuid || !field) return data

        const emptyStatusData = this.generateEmptyStatusGroups(
            field,
            new Map<string, RecordGroup>(),
        )

        const reducedData = data.reduce(
            (map: Map<string, RecordGroup>, record: BusinessRecords) => {
                const cells = getRecordCells(record)
                if (!cells[groupFieldGuid]) return map

                let groupFieldValue: string
                if (field.select_object_field) {
                    groupFieldValue = Object.keys(
                        field.select_object_field as SelectObjectOptions,
                    ).includes(cells[groupFieldGuid].value)
                        ? cells[groupFieldGuid].value
                        : NO_GROUPED_RECORDS_KEY
                } else {
                    groupFieldValue =
                        cells[groupFieldGuid].value !== ''
                            ? cells[groupFieldGuid].value
                            : NO_GROUPED_RECORDS_KEY
                }

                if (groupFieldValue.includes(',')) {
                    groupFieldValue = groupFieldValue.split(',').sort().join()
                }
                const group = map.get(groupFieldValue)
                if (!group) {
                    map.set(groupFieldValue, {
                        data: [record],
                        field: field,
                        value: groupFieldValue,
                    })
                } else {
                    group.data.push(record)
                }

                return map
            },
            emptyStatusData,
        )

        const unsetData = reducedData.get(NO_GROUPED_RECORDS_KEY)?.data.length

        if (!unsetData) reducedData.delete(NO_GROUPED_RECORDS_KEY)

        return reducedData
    }

    private generateColGroup(
        columns: string[],
        fields: FieldEntities,
        allSchemas: Dictionary<Schema>,
    ) {
        return columns.reduce((acc, fieldGuid) => {
            const colGropedFields: string[] = [FieldTypes.LINK]

            const field: Field | undefined = fields[fieldGuid]
            if (!field) {
                this.logService.error(new Error('incorrect field guid in colGroup generation'))
                return acc
            }

            if (colGropedFields.includes(String(field.field_type_code))) {
                const schemaGuid = field.link_definition?.target_solution_object_type_guid
                const fieldGuids = field.link_definition?.target_object_field_guids

                if (!fieldGuids?.length || !schemaGuid || !allSchemas[schemaGuid]) return acc

                return {
                    ...acc,
                    [fieldGuid]: {
                        colspan: fieldGuids?.length,
                        subheaders: fieldGuids.map(
                            (guid) =>
                                allSchemas[schemaGuid]!.fieldEntities[guid]?.name || 'unknown',
                        ),
                    },
                }
            }

            return acc
        }, {} as { [colGuid: string]: ColGroup })
    }

    private generateEmptyStatusGroups(field: Field, data: Map<string, RecordGroup>) {
        const options = field.select_object_field
        if (!options) return data

        data.set(NO_GROUPED_RECORDS_KEY, {
            data: [],
            field: field,
            value: NO_GROUPED_RECORDS_KEY,
        })

        return Object.keys(options).reduce((map, key) => {
            const group = map.get(key)
            if (group) return map
            map.set(key, {
                data: [],
                field: field,
                value: key,
            })
            return map
        }, new Map(data))
    }

    private sortData(
        data: Map<string, RecordGroup> | BusinessRecords[],
        selectedView: View,
        sortValue?: SortObjectEntities,
    ) {
        if (!sortValue) return data

        this.sortStorageService.updateIsSetValue(selectedView.guid)
        const sortArray = Object.values(sortValue)

        if (sortArray.length) {
            if (data instanceof Array) {
                const cells = prepareCellsForRecords(data)
                return this.viewSortService.applySortToArray(data, cells, sortArray)
            }

            if (data instanceof Map) {
                const cells = prepareFromGroups([...data.values()])
                return this.prepareMapFromRecordGroups(data, cells, sortArray)
            }
        }

        return data
    }

    private prepareMapFromRecordGroups(
        data: Map<string, RecordGroup>,
        cells: { [recordGuid: string]: CellEntities },
        sortArray: SortObject[],
    ) {
        data.forEach((recordGroup, key) => {
            recordGroup.data = this.viewSortService.applySortToArray(
                recordGroup.data,
                cells,
                sortArray,
            )
            data.set(key, recordGroup)
        })

        return data
    }
}
