<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="selectedFolder$ | async as selectedFolder">
        <app-tb-board class="px-6" [columnTemplate]="columnTemplate"></app-tb-board>

        <ng-template #columnTemplate>
            <app-tb-board-column
                *ngFor="let group of groups"
                [items]="group.data || []"
                [card]="card"
                [columnItem]="group"
                [headerMenu]="cardHeaderMenu"
                [disableDragIntoList]="group.value === NO_GROUPED_RECORDS_KEY"
                [selectedItems]="selectedRecords"
                (columnMoved)="drop(group, $event)"
                (selectColumn)="toggleGroup($event, group)"
            >
                <div header class="h-full w-full">
                    <div
                        class="flex items-center h-full w-full relative"
                        *ngIf="group.field && fieldTypes; else unchecked"
                    >
                        <app-grouping-values
                            class="w-100"
                            [fieldType]="fieldTypes[group.field.field_type_code]"
                            [field]="group.field"
                            [value]="group.value ?? ''"
                        ></app-grouping-values>
                    </div>
                    <ng-template #unchecked>
                        <span *transloco="let tBoard; read: 'board'"> {{tBoard('template')}} </span>
                    </ng-template>
                </div>
            </app-tb-board-column>
        </ng-template>

        <ng-template #businessCardContent let-fieldTypes="fieldTypes" let-record="record">
            <ng-container *ngIf="fields$ | async as fields">
                <app-board-card-fields
                    [fieldTypes]="fieldTypes"
                    [fields]="visibleFields"
                    [fieldEntities]="fields"
                    [selectedFolder]="selectedFolder"
                    [cells]="cells[record.guid]"
                ></app-board-card-fields>
            </ng-container>
        </ng-template>

        <ng-template #card let-record="item">
            <app-tb-board-card
                [item]="record"
                [title]="record.name.value"
                [key]="boardItemsKey"
                [moreMenu]="cardMoreMenu"
                [selected]="isRecordSelected(record)"
                [selectedItems]="selectedRecords"
                (selectCard)="toggleSelection(record)"
                (click)="openCardDialog(record)"
            >
                <ng-container
                    *ngTemplateOutlet="businessCardContent; context: { fieldTypes: fieldTypes, record: record }"
                ></ng-container>

                <ng-template #cardMoreMenu>
                    <app-tb-menu-list *transloco="let tBoardCard; read: 'board.board_card'">
                        <app-tb-menu-list-item icon="custom_copy" (click)="openCardDialog(record)"
                            >{{ tBoardCard('open_record') }}</app-tb-menu-list-item
                        >
                        <app-tb-menu-list-item
                            icon="control_point_duplicate"
                            (click)="duplicate(record)"
                            >{{ tBoardCard('duplicate') }}</app-tb-menu-list-item
                        >
                        <app-tb-menu-list-item icon="edit_note" (click)="manageFields()"
                            >{{ tBoardCard('manage_fields') }}</app-tb-menu-list-item
                        >

                        <app-tb-divider></app-tb-divider>

                        <app-tb-menu-list-item (click)="deleteRecord(record)">
                            <app-tb-icon
                                color="red-600"
                                icon="custom_trash"
                                item-icon
                            ></app-tb-icon>
                            <span class="text-red-600" item-title
                                >{{ tBoardCard('delete_record') }}</span
                            >
                        </app-tb-menu-list-item>
                    </app-tb-menu-list>
                </ng-template>
            </app-tb-board-card>
        </ng-template>

        <ng-template #cardHeaderMenu let-data="data">
            <app-tb-menu-list *transloco="let tBoard; read: 'board.board_header'">
                @if (data.columnItem.data.length) {
                <app-tb-menu-list-item
                    icon="custom_select"
                    (click)="toggleGroup(!isAllGroupSelected(data.columnItem), data.columnItem)"
                    >{{ isAllGroupSelected(data.columnItem) ? tBoard('unselect_all') :
                    tBoard('select_all') }}</app-tb-menu-list-item
                >
                }

                <app-tb-menu-list-item
                    icon="keyboard_double_arrow_left"
                    (click)="data.toggleCollapse()"
                    >{{ data.collapsed ? tBoard('expand') : tBoard('collapse')
                    }}</app-tb-menu-list-item
                >

                <app-tb-divider></app-tb-divider>

                <app-tb-menu-list-item icon="custom_edit" (click)="editField(data.columnItem.field)"
                    >{{ tBoard('edit_field') }}</app-tb-menu-list-item
                >
                <app-tb-menu-list-item icon="edit_note" (click)="manageFields()"
                    >{{ tBoard('manage_fields') }}</app-tb-menu-list-item
                >

                <app-tb-divider></app-tb-divider>

                <app-tb-menu-list-item>
                    <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
                    <span class="text-red-600" item-title>{{ tBoard('delete_field') }}</span>
                </app-tb-menu-list-item>
            </app-tb-menu-list>
        </ng-template>
    </ng-container>
</ng-container>
