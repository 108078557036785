import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'

@UntilDestroy()
@Component({
    selector: 'app-select-views-settings',
    templateUrl: './select-views-settings.component.html',
})
export class SelectViewsSettingsComponent extends ModalContainerComponent {}
