import { inject } from '@angular/core'
import { SystemRecordsFacadeService } from '../services/system-records-facade.service'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export const RedirectToErrorPageIfEmptyDataGuard: CanActivateFn = (): Observable<
    boolean | UrlTree
> => {
    const systemRecordsFacadeService = inject(SystemRecordsFacadeService)
    const router = inject(Router)

    console.log('empty folders|schemas|views guarded')
    return combineLatest([
        systemRecordsFacadeService.getAllSystemRecords(),
        systemRecordsFacadeService.getCurrentUserAndUsersList(),
    ]).pipe(
        map(([[folders, schemas], [currentUser, users]]) => {
            if (!folders.length || !schemas.length) {
                return router.createUrlTree(['error-page'])
            }

            const isCurrentUserInList =
                !!currentUser && !!users.find((user) => user.guid === currentUser.guid)

            if (!isCurrentUserInList) {
                return router.createUrlTree(['error-page'])
            }

            return true
        }),
    )
}
