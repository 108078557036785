import { Injectable } from '@angular/core'
import { FieldTypes } from '@core/models'
import { FieldType, findFieldByType } from '@models/ui'
import { Store } from '@ngrx/store'
import { isProd } from '@test/dev-utils'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import {
    AppState,
    selectAllFieldTypes,
    selectFieldTypeEntities,
    selectFieldTypesFilterable,
    setFieldTypes,
} from '../../@ngrx'
import { getBoolean } from '../../global-util'
import { FolderFacadeService } from './folder-facade.service'
import { SchemaFacadeService } from './schema-facade.service'

@Injectable({
    providedIn: 'root',
})
export class FieldTypeFacadeService {
    constructor(
        private store: Store<AppState>,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    selectFieldTypeEntities$ = this.store.select(selectFieldTypeEntities)

    selectAllFieldTypes$ = this.store.select(selectAllFieldTypes)
    selectFieldTypesFilterable$ = this.store.select(selectFieldTypesFilterable)

    selectAvailableFieldTypeByFolder$ = combineLatest([
        this.selectAllFieldTypes$,
        this.folderFacadeService.selectSelectedFolder$,
        this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$,
    ]).pipe(
        map(([types, selectedFolder, fields]) => {
            //TODO: folder could not have own fields.
            if (!fields) return []

            const globalFolder = getBoolean(selectedFolder.is_global.value)
            return types.filter((type) => {
                if ((isProd() && !type.status) || !type.operationCode.create) {
                    return
                }

                if (!getBoolean(selectedFolder.is_global.value)) {
                    return !type.operationCode.global
                }

                const folderField =
                    type.operationCode.multiple ||
                    !findFieldByType(fields, type.guid as FieldTypes, selectedFolder)

                return globalFolder ? folderField : folderField && !type.operationCode.global
            })
        }),
    )

    setFieldTypes(fieldTypes: FieldType[]) {
        this.store.dispatch(setFieldTypes({ fieldTypes }))
    }
}
