<ng-container *transloco="let tCardContentMainFields; read: 'card_content.main_fields'">
    <div
        *ngIf="isContact(record) && doesRecordFieldExist(record.email)"
        class="flex-start-center field-row"
    >
        <div class="card-field-title">{{tCardContentMainFields('email')}}</div>
        <app-email
            (cellValueChanged)="updateRecord(record, record.email, $event)"
            [fieldType]="fieldTypes[fields[record.email.fieldGuid].field_type_code]"
            [field]="fields[record.email.fieldGuid]"
            [record]="record"
            [value]="record.email.value"
            class="flex-start-center text-center"
        ></app-email>
    </div>
    <div
        *ngIf="isContact(record) && doesRecordFieldExist(record.phone)"
        class="flex-start-center field-row"
    >
        <div class="card-field-title">{{tCardContentMainFields('phone')}}</div>
        <app-number
            (cellValueChanged)="updateRecord(record, record.phone, $event)"
            [fieldType]="fieldTypes[fields[record.phone.fieldGuid].field_type_code]"
            [field]="fields[record.phone.fieldGuid]"
            [record]="record"
            [value]="record.phone.value"
            class="flex-start-center text-center"
        ></app-number>
    </div>
    <div
        *ngIf="isVendor(record) && doesRecordFieldExist(record.address)"
        class="flex-start-center field-row"
    >
        <div class="card-field-title">{{tCardContentMainFields('address')}}</div>
        <app-text-one-line
            (cellValueChanged)="updateRecord(record, record.address, $event)"
            [fieldType]="fieldTypes[fields[record.address.fieldGuid].field_type_code]"
            [field]="fields[record.address.fieldGuid]"
            [record]="record"
            [value]="record.address.value"
            class="flex-start-center text-center"
        ></app-text-one-line>
    </div>
    <ng-container *ngIf="isDeal(record) && doesRecordFieldExist(record.name)">
        <div class="flex-start-center field-row w-100">
            <div class="card-field-title">{{tCardContentMainFields('deal_name')}}</div>
            <app-text-one-line
                (cellValueChanged)="updateRecord(record, record.name, $event)"
                [fieldType]="fieldTypes[fields[record.name.fieldGuid].field_type_code]"
                [field]="fields[record.name.fieldGuid]"
                [record]="record"
                [value]="record.name.value"
                class="flex-start-center text-center"
            ></app-text-one-line>
        </div>
        <div *ngIf="doesRecordFieldExist(record.amount)" class="flex-start-center field-row w-100">
            <div class="card-field-title">{{tCardContentMainFields('amount')}}</div>
            <app-money
                (cellValueChanged)="updateRecord(record, record.amount, $event)"
                [fieldType]="fieldTypes[fields[record.amount.fieldGuid].field_type_code]"
                [field]="fields[record.amount.fieldGuid]"
                [record]="record"
                [value]="record.amount.value"
                class="flex-start-center text-center"
            ></app-money>
        </div>
    </ng-container>
</ng-container>
