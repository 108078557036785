import { Injectable } from '@angular/core'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@core/services/local-storage/view-settings-storage/view-settings-storage.service'
import { FilterGroup } from '@models/ui'

@Injectable({
    providedIn: 'root',
})
export class FilterStorageService extends ViewSettingsStorageService<FilterGroup[]> {
    constructor(localStorageService: DataStorageService) {
        super(localStorageService, 'filter')
    }

    get(key: string): FilterGroup[] | null {
        const filterValue = super.get(key)

        if (!filterValue) return null

        if (typeof filterValue !== 'string') return filterValue

        return filterValue.length ? JSON.parse(filterValue) : null
    }
}
