import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { TbMenuComponent } from '@components-library/tb-menu'
import { NgIf } from '@angular/common'
import { TbButtonComponent } from '../../tb-button/tb-button.component'

@Component({
    selector: 'app-tb-board-column-header',
    templateUrl: './tb-board-column-header.component.html',
    styleUrls: ['./tb-board-column-header.component.sass'],
    standalone: true,
    imports: [TbButtonComponent, NgIf, TbMenuComponent, TbCheckboxComponent],
})
export class TbBoardColumnHeaderComponent<Item, Column> implements OnInit, OnChanges {
    @Input({ required: true })
    items!: Item[]

    @Input()
    moreMenu!: TemplateRef<any>

    @Input({ required: true })
    columnItem!: Column

    @Input()
    selectedItems: Item[] = []

    @Input()
    dragOn = false

    @Output()
    collapseChanged = new EventEmitter<boolean>()

    @Output()
    selectColumn = new EventEmitter<boolean>()

    collapsed = false

    toggleCollapseBound = this.toggleCollapse.bind(this)

    get stateClass() {
        return `${this.collapsed ? 'collapsed w-[64px]' : 'h-[64px]'} ${
            this.dragOn ? 'drag-on' : 'bg-newNeutral1'
        }`
    }

    ngOnChanges(): void {
        this.checkCollapsedState()
    }

    ngOnInit(): void {
        this.checkCollapsedState()
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed
        this.collapseChanged.emit(this.collapsed)
    }

    checkCollapsedState() {
        const collapsed = this.items.length === 0
        if (collapsed === this.collapsed) return

        this.collapsed = collapsed
        this.collapseChanged.emit(this.collapsed)
    }

    get isAllItemsSelected() {
        return this.items.every((item) =>
            this.selectedItems.find((selectedItem) => selectedItem === item),
        )
    }

    get isSomeItemsSelected() {
        return this.items.some((item) =>
            this.selectedItems.find((selectedItem) => selectedItem === item),
        )
    }

    get isIndeterminate() {
        return !this.isAllItemsSelected && this.isSomeItemsSelected
    }
}
