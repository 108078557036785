import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { FieldOptionsMenuComponent } from '@app/feature/manage-fields/field-options-menu/field-options-menu.component'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { SharedModule } from '@app/shared/shared.module'
import { ComponentsLibraryModule } from '@components-library'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TbTagsModule } from '@components-library/tb-tags'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [ManageFieldsComponent, FieldOptionsMenuComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslocoModule,
        ComponentsLibraryModule,
        SharedModule,
        ModalManagerModule,
        TbTagsModule,
        MatMenuModule,
    ],
})
export class ManageFieldsModule {}
