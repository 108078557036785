<button [matMenuTriggerFor]="menuRow" mat-icon-button>
    <mat-icon class="text-muted">more_horiz</mat-icon>
</button>
<mat-menu #menuRow="matMenu">
    <!--    todo: [table-ref] i would refactor this and make ngFor -->
    <ng-template matMenuContent>
        <button (click)="deleteRecord.emit(record)" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
        <button (click)="shareRecord.emit(record)" mat-menu-item>
            <mat-icon>folder</mat-icon>
            <span> Share/Move record</span>
        </button>
    </ng-template>
</mat-menu>
