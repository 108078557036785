import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TbCheckboxComponent } from './checkbox.component'

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule],
    declarations: [TbCheckboxComponent],
    exports: [TbCheckboxComponent],
})
export class TbCheckboxModule {}
