import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Field } from '@core/models'
import { FolderNameEntities } from '@core/models/response/folder-names'
import { FolderFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

type RadioInput = {
    value: any
    label?: string
    checked?: boolean
    disabled?: boolean
}

@UntilDestroy()
@Component({
    selector: 'app-change-field-folder',
    templateUrl: './change-field-folder.component.html',
    styleUrls: ['./change-field-folder.component.sass'],
})
export class ChangeFieldFolderComponent implements OnInit {
    field!: Field
    folders!: FolderNameEntities
    folderGuids!: string[]
    radioButtonOptions: RadioInput[] = []
    folderGuid!: string | undefined

    constructor(
        public dialogRef: MatDialogRef<ChangeFieldFolderComponent, Field | undefined>,
        private folderFacadeService: FolderFacadeService,
        @Inject(MAT_DIALOG_DATA) public data: Field,
    ) {
        this.field = data
        this.folderGuid = data.folder_guid
    }

    ngOnInit(): void {
        this.folderFacadeService.selectFolderNames$
            .pipe(untilDestroyed(this))
            .subscribe((data) => (this.folders = data))
        this.folderGuids = Object.keys(this.folders)
        this.radioButtonOptions = this.folderGuids.map((guid) => {
            return {
                label: this.folders[guid].name,
                value: guid,
                checked: this.field.folder_guid === guid,
            }
        })
    }

    folderChange(folder: string): void {
        this.field = {
            ...this.field,
            folder_guid: folder,
        }
    }

    applyShare() {
        if (this.folderGuid === this.field.folder_guid) {
            this.cancelShare()
        } else {
            this.dialogRef.close(this.field)
        }
    }

    cancelShare() {
        this.dialogRef.close()
    }
}
