<ng-container *ngIf="(state$ | async) as state">
    <mat-sidenav-container autosize class="z-50 h-screen" id="sidenav-container">
        <mat-sidenav
            [mode]="state.mode"
            [opened]="state.opened"
            (openedStart)="onMatSidenavOpenedChange(true)"
            (closedStart)="onMatSidenavOpenedChange(false)"
            class="[&>div]:flex [&>div]:flex-col sm:pb-2 overflow-visible fixed bottom-0 left-0 p-2.5 pr-[5px] pb-5 bg-primaryMain"
        >
            <!--            the two [&>div]:flex properties are needed on the autogenerated div, couldn't fix without complex tailwind/sass-->
            <mat-toolbar class="flex p-0 pr-[5px] h-10 mb-6 justify-center bg-primaryMain">
                <ng-container [ngSwitch]="state.expand">
                    <ng-container *ngSwitchCase="expandMods.EXPANDED">
                        <img class="grow" alt="logo" src="../../../assets/img/Logotype.svg" />
                    </ng-container>
                    <ng-container *ngSwitchCase="expandMods.COLLAPSED">
                        <img class="grow h-9" alt="logo" src="../../../assets/img/LOGO.svg" />
                    </ng-container>
                    <ng-container *ngSwitchCase="expandMods.DISABLED">
                        <!--Todo: // [desing-ref] icon-->
                        <mat-icon
                            (click)="collapse()"
                            class="flex shrink-0 items-center justify-center w-8 h-8 text-white text-3xl cursor-pointer mr-4"
                            >menu_open</mat-icon
                        >
                        <div class="w-inherit">
                            <img class="grow" alt="logo" src="../../../assets/img/Logotype.svg" />
                        </div>
                    </ng-container>
                </ng-container>
            </mat-toolbar>
            <div class="grow flex flex-col overflow-auto pr-[5px]">
                <ng-container *ngIf="state.expand !== expandMods.DISABLED">
                    <!--Todo: // [desing-ref] icon-->
                    <mat-icon
                        (click)="state.expand === expandMods.EXPANDED ? collapse() : expand()"
                        class="absolute top-8 right-[-8px] z-10 w-4 h-4 subheading-1 leading-4 rounded-full cursor-pointer text-white bg-accentContrastLight hover:bg-accentContrast"
                    >
                        {{state.expand === expandMods.EXPANDED ? 'chevron_left' : 'chevron_right'}}
                    </mat-icon>
                </ng-container>
                <ng-container *ngFor="let item of menuItems, let i=index">
                    <div [class]="{'mt-auto': i === 7}">
                        <app-tb-navigation-menu-item
                            *transloco="let tNavMenu; read 'nav_menu'"
                            (click)="item.action()"
                            [hideName]="hideMenuItemNames"
                            [name]="tNavMenu(item.name)"
                            [isSelected]="item.selected"
                            class="block mb-6 w-fit rounded-lg cursor-pointer overflow-hidden"
                        >
                            <!--Todo: // [desing-ref] icon-->
                            <mat-icon class="w-8 h-8" svgIcon="{{item.icon}}"></mat-icon>
                        </app-tb-navigation-menu-item>
                    </div>
                </ng-container>
            </div>
            <ng-content select="[user-settings]"></ng-content>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="flex content-between bg-primaryBackground">
                <!--Todo: // [desing-ref] icon-->
                <mat-icon
                    class="absolute xsm:relative top-4 left-4 h-8 w-8 mr-4 text-3xl leading-8 cursor-pointer"
                    (click)="expand()"
                    *ngIf="state.canClose && !state.opened"
                >
                    menu
                </mat-icon>
                <ng-content select="[header]"></ng-content>
            </div>
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
