import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeComponent } from './home.component'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatIconModule } from '@angular/material/icon'
import { TableContainerModule } from '../../../../views/table/table-container.module'
import { MatTabsModule } from '@angular/material/tabs'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        TableContainerModule,
        CdkAccordionModule,
        MatIconModule,
        MatTabsModule,
        TranslocoModule,
    ],
    exports: [HomeComponent],
})
export class HomeModule {}
