<app-tb-menu [template]="dropdownMenu" xPosition="after">
    <!-- TODO: fix backdrop issue #1350 -->
    <div
        *ngIf="activeOptions.length > 0; else defaultStatus"
        trigger
        class="flex-start-center status-dropdown"
    >
        <span
            *ngFor="let option of activeOptions"
            [ngStyle]="{'background':getBgColor(option)}"
            class="option-item"
        ></span>
    </div>
    <ng-template #defaultStatus>
        <div class="status-dropdown" *transloco="let tFilter; read: 'filter'">
            {{tFilter('status_default_filter')}}
        </div>
    </ng-template>
</app-tb-menu>

<ng-template #dropdownMenu>
    <div [formGroup]="formGroup">
        <cdk-accordion class="example-accordion" formArrayName="statusListChbx">
            <cdk-accordion-item
                #accordionItem="cdkAccordionItem"
                *ngFor="let item of statusList; let index = index;"
                [attr.aria-controls]="'accordion-body-' + index"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.id]="'accordion-header-' + index"
                [formGroupName]="index"
                class="example-accordion-item"
                role="button"
                tabindex="0"
            >
                <div
                    (click)="$event.stopPropagation()"
                    class="example-accordion-item-header flex-start-center justify-content-between ml-sm"
                >
                    <app-tb-checkbox
                        (valueChange)="this.setAll($event, item)"
                        [formControlName]="'label'"
                        [indeterminate]="someComplete(item)"
                        class="example-margin text-primary"
                    >
                        {{ item }}
                    </app-tb-checkbox>
                    <app-tb-icon
                        (click)="accordionItem.toggle(); $event.stopPropagation()"
                        class="example-accordion-item-description"
                        [icon]="accordionItem.expanded ? 'expand_less' : 'expand_more'"
                    ></app-tb-icon>
                </div>
                <div
                    [attr.aria-labelledby]="'accordion-header-' + index"
                    [attr.id]="'accordion-body-' + index"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    class="example-accordion-item-body"
                    formArrayName="statusListInnerChbx"
                    role="region"
                >
                    <div *ngFor="let option of optionValuesItem(item); let index = index;">
                        <div class="accordion-row flex-start-center ml-lg">
                            <app-tb-checkbox
                                [formControlName]="index"
                                (click)="$event.stopPropagation()"
                                (valueChange)="updateAllComplete($event, item, option)"
                            >
                                <span
                                    [style]="{backgroundColor: option.color}"
                                    class="color-label mr-xs"
                                ></span>
                                {{option.label}}
                            </app-tb-checkbox>
                        </div>
                    </div>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>
</ng-template>
