import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core'
import { TbSelectValue } from '../../models'

@Component({
    selector: 'app-tb-option',
    templateUrl: './tb-option.component.html',
    styleUrl: './tb-option.component.sass',
})
export class TbOptionComponent<T = TbSelectValue> {
    @Input() value!: T

    @Input() icon = ''

    @Input() disabled: boolean = false

    @ViewChild('optionTemplate', { static: true }) optionTemplate!: TemplateRef<any>
    @ViewChild('optionContent') optionContent!: ElementRef<any>

    get label() {
        return this.optionContent?.nativeElement.innerText
    }

    constructor() {}
}
