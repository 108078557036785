import { Injectable } from '@angular/core'
import { SubtaskRecord, SubtaskUpdate } from '@models/ui/subtask-record.model'
import { Update } from '@ngrx/entity'
import { select, Store } from '@ngrx/store'
import { CommonFacadeService } from '@services/store-facade/common-facade.service'
import { FolderFacadeService } from '@services/store-facade/folder-facade.service'
import { switchMap, take } from 'rxjs/operators'
import { AppState } from '../../@ngrx'
import {
    addSubtasksFromResponse,
    deleteSubtasksFromResponse,
    resetSubtasks,
    selectSubtasksBySchemaGuid,
    setSubtasks,
    updateSubtasksFromResponse,
} from '../../@ngrx/subtask'
import { AppRecord, Cell, CreateSubtaskModel, Deleted, Folder } from '../../models'
import { UpdateService } from '../update.service'

@Injectable({
    providedIn: 'root',
})
export class SubtaskFacadeService {
    constructor(
        private store: Store<AppState>,
        private updateService: UpdateService,
        private folderFacadeService: FolderFacadeService,
        private commonFacadeService: CommonFacadeService,
    ) {}

    initSubtasks(subtasks: SubtaskRecord[]) {
        this.store.dispatch(setSubtasks({ subtasks }))
    }

    resetSubtasks() {
        this.store.dispatch(resetSubtasks())
    }

    selectSubtasksBySchemaId(schemaGuid: string) {
        return this.store.pipe(select(selectSubtasksBySchemaGuid(schemaGuid)))
    }

    updateSubtask(subtask: SubtaskRecord, cell: Cell, value: string) {
        this.commonFacadeService.setLoading(true)
        return this.folderFacadeService.selectSelectedFolder$.pipe(
            take(1),
            switchMap((folder: Folder) => {
                const dataRecord: SubtaskUpdate = {
                    subtask,
                    cell,
                    value,
                }

                return this.updateService.updateSubtask(dataRecord, folder)
            }),
        )
    }

    createSubtask(subtask: CreateSubtaskModel) {
        this.commonFacadeService.setLoading(true)
        return this.updateService.createSubtask(subtask)
    }

    deleteSubtask(subtask: AppRecord) {
        this.commonFacadeService.setLoading(true)
        return this.updateService.deleteSubtask(subtask)
    }

    addSubtasksFromResponse(subtasks: SubtaskRecord[]) {
        this.store.dispatch(addSubtasksFromResponse({ subtasks }))
    }

    updateSubtasksFromResponse(subtasks: Update<SubtaskRecord>[]) {
        this.store.dispatch(updateSubtasksFromResponse({ subtasks }))
    }

    deleteSubtasksFromResponse(subtasks: Deleted[]) {
        this.store.dispatch(deleteSubtasksFromResponse({ subtasks }))
    }
}
