import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Field, FieldEntities, FieldType, GlobalFieldNames } from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'

/* todo: [table-ref-2] Refactoring notes
 *   Remove outputs and make Requests from this component
 * */
@UntilDestroy()
@Component({
    selector: 'app-column-menu',
    templateUrl: './column-menu.component.html',
    styleUrls: ['./column-menu.component.sass'],
})
export class ColumnMenuComponent {
    @Input() field!: Field
    @Input() guid!: string
    @Input() visibleFieldGuids!: string[]

    // todo: [table-ref-2] remove
    @Input() hiddenFieldGuids?: string[]

    @Input() isPinned!: boolean
    @Input() isPinAllowed!: boolean
    @Input() fields!: FieldEntities
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() columnsOrder?: string[]
    @Input() isCustomWidth!: boolean

    // todo: [table-ref-2] remove outputs and implement all methods in the common table service
    @Output() moveFieldToFolder: EventEmitter<any> = new EventEmitter<any>()
    @Output() sortColumn: EventEmitter<any> = new EventEmitter()
    @Output() hideColumn: EventEmitter<any> = new EventEmitter()
    @Output() editField: EventEmitter<any> = new EventEmitter()
    @Output() copyColumn: EventEmitter<any> = new EventEmitter()
    @Output() deleteColumn: EventEmitter<any> = new EventEmitter()
    @Output() pinColumn: EventEmitter<any> = new EventEmitter()
    @Output() unPinColumn: EventEmitter<any> = new EventEmitter()
    @Output() shareWith: EventEmitter<any> = new EventEmitter<any>()
    @Output() resetWidth: EventEmitter<any> = new EventEmitter<any>()

    get isPinAvailable() {
        return !this.isNameColumn && !this.isPinAllowed && !this.isPinned
    }

    get isNameColumn() {
        return this.field.system_name === GlobalFieldNames.NAME
    }

    get isShareAvailable() {
        return !this.field.folder_name?.is_global && !this.isNameColumn
    }
}
