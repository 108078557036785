import { Injectable } from '@angular/core'
import { SystemResponseHandler } from '@core/models'
import { Config } from '@models/ui/config.model'
import {
    generateRecords,
    generateUpdatedSystemRecords,
    ResponseRecordEntities,
    ResponseSchema,
    Schema,
    TableModel,
} from '../models'
import { ConfigFacadeService } from './store-facade/config-facade.service'

@Injectable({
    providedIn: 'root',
})
export class ConfigResponseHandlerService implements SystemResponseHandler<Config> {
    constructor(private configFacadeService: ConfigFacadeService) {}

    initSystemRecord(schema: ResponseSchema, record: ResponseRecordEntities): void {
        const configs: Config[] = generateRecords(schema, record) as Config[]
        this.configFacadeService.setConfig(configs)
    }

    updateRecord(table: TableModel, schema: Schema): void {
        if (table.deleted) {
            this.configFacadeService.deleteConfigFromResponse(table.deleted!)
        }

        if (table.updated) {
            const configs = generateUpdatedSystemRecords<Config>(table, schema)
            this.configFacadeService.updateConfigFromResponse(configs)
        }

        if (table.added) {
            this.configFacadeService.addConfigFromResponse(
                generateRecords(schema, table.added)[0] as Config,
            )
        }
    }
}
