import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-tb-tag',
    templateUrl: './tb-tag.component.html',
    styleUrl: './tb-tag.component.sass',
})
export class TbTagComponent {
    @Input() removable = false

    @Output() remove = new EventEmitter<void>()
}
