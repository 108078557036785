import { Component, Input, OnInit } from '@angular/core'
import { BusinessRecord, Cell, CellEntities, FieldType, getRecordCells, Schema } from '@core/models'
import { SchemaFacadeService } from '@core/services/store-facade'
import { filter } from 'rxjs'
import { SubtaskFacadeService } from '@core/services/store-facade/subtask-facade.service'
import { SubtaskRecord } from '@core/models/ui/subtask-record.model'
import { take } from 'rxjs/operators'
import { Dictionary } from '@ngrx/entity'

@Component({
    selector: 'app-card-subtasks',
    templateUrl: './card-subtasks.component.html',
    styleUrl: './card-subtasks.component.sass',
})
export class CardSubtasksComponent implements OnInit {
    @Input() schema!: Schema
    @Input() record!: BusinessRecord

    @Input()
    fieldTypes!: Dictionary<FieldType>

    subtaskSchema: Schema | null = null
    subtaskRecords: SubtaskRecord[] = []

    cells: { [recordGuid: string]: CellEntities } = {}

    columns: string[] = []
    tableColumns: string[] = []

    constructor(
        private readonly schemaFacadeService: SchemaFacadeService,
        private readonly subtaskFacadeService: SubtaskFacadeService,
    ) {}

    ngOnInit() {
        this.getSelectedSubtask()
    }

    getSelectedSubtask() {
        const { guid } = this.schema
        this.schemaFacadeService
            .selectSchemaByParentSotGuid$(guid)
            .pipe(take(1), filter(Boolean))
            .subscribe((schema) => {
                this.subtaskSchema = schema
                this.columns = this.getColumnNames()
                this.getSelectedSubtaskRecords()
            })
    }

    getSelectedSubtaskRecords() {
        if (!this.subtaskSchema) {
            return
        }

        this.subtaskFacadeService
            .selectSubtasksBySchemaId(this.subtaskSchema.guid)
            .subscribe((subtasks) => {
                this.subtaskRecords = subtasks
                this.setTableColumnsAndCells()
            })
    }

    getFieldByGuid(guid: string) {
        return this.subtaskSchema?.fieldEntities[guid]
    }

    updateSubtask(subtask: SubtaskRecord, cell: Cell, value: string) {
        this.subtaskFacadeService.updateSubtask(subtask, cell, value)
    }

    deleteSubtask(subtask: SubtaskRecord) {
        this.subtaskFacadeService.deleteSubtask(subtask)
    }

    getCells(subtask: SubtaskRecord) {
        return this.cells[subtask.guid]
    }

    private setTableColumnsAndCells() {
        this.subtaskRecords.reduce((cells, record) => {
            cells[record.guid] = getRecordCells(record)
            return cells
        }, this.cells)

        this.tableColumns = this.getTableColumns()
    }

    private getColumnNames() {
        return Object.values(this.subtaskSchema!.fieldEntities).map((field) => field.guid)
    }

    private getTableColumns() {
        return [...this.columns, 'delete']
    }
}
