export interface FilterValue {
    fieldGuid: string
    value?: string | string[]
    option?: string
}

export interface FilterValuesObject {
    [fieldGuid: string]: FilterValue
}

export interface FilterGroup {
    filterGroupsCombinationType: string
    filterCombinationType: string
    values?: FilterValuesObject
}

export const FilterGroupsCombinationTypes: { [key: string]: string } = {
    AND: 'and',
    OR: 'or',
}

export const FilterCombinationTypes: { [key: string]: string } = {
    ALL: 'all',
    ANY: 'any',
}

export const FilterOptionTypes = {
    IS_NOT: 'IS_NOT',
    IS_ALL: 'IS_ALL',
    IS_ANY: 'IS_ANY',
    CONTAINS_ME: 'CONTAINS_ME',

    IS_NOT_SET: 'IS_NOT_SET',
    IS_SET: 'IS_SET',
    CONTAINS: 'CONTAINS',
    DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
    EQUALS: 'EQUALS',
    NOT_EQUAL_TO: 'NOT_EQUAL_TO',
    BEGINS_WITH: 'BEGINS_WITH',
    ENDS_WITH: 'ENDS_WITH',
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
    LESS_THAN_OR_EQUAL_TO: 'LESS_THAN_OR_EQUAL_TO',
}

export type FilterOptionTypesAction = {
    [option: string]: (filter: FilterValue, value: string) => boolean
}
