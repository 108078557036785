import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-card-subtitle',
    templateUrl: './card-subtitle.component.html',
    styleUrls: ['./card-subtitle.component.sass'],
})
export class CardSubtitleComponent {
    @Input() amount!: number
    @Input() subtitle!: string
}
