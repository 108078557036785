import { Injectable } from '@angular/core'
import { CreateRecordModel, RecordCellModel } from '../models'

export type FieldValue = { fieldGuid: string; value: string }

@Injectable({
    providedIn: 'root',
})
export class CrudRecordModelFactoryService {
    prepareCreateModel(
        parent_sot_guid: string,
        solution_object_type_guid: string,
        folders_guid: string,
        cells: RecordCellModel[],
    ) {
        return {
            parent_sot_guid,
            solution_object_type_guid,
            folders_guid,
            cells,
        }
    }

    prepareCreateModelWithFields(
        parent_sot_guid: string,
        solution_object_type_guid: string,
        folders_guid: string,
        fields: FieldValue[],
    ): CreateRecordModel {
        const cells = fields.map(({ fieldGuid, value }) =>
            this.generateResponseCell(fieldGuid, value),
        )
        return this.prepareCreateModel(
            parent_sot_guid,
            solution_object_type_guid,
            folders_guid,
            cells,
        )
    }

    generateResponseCell(fieldGuid: string, value: string) {
        return {
            value: value,
            revision: '0',
            field_guid: fieldGuid,
        }
    }
}
