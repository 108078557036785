<div
    class="flex items-center cursor-pointer px-2 py-1 rounded hover:bg-newNeutral3"
    [class]="color ? 'text-' + color : ''"
>
    <div class="flex mr-2 item-icon empty:hidden" #iconRef>
        <ng-content select="[item-icon]"></ng-content>
    </div>
    <app-tb-icon
        *ngIf="icon && !iconRef.children.length"
        color="primarySecondary"
        [icon]="icon"
        class="item-icon mr-2"
    ></app-tb-icon>
    <div class="inline body-1 empty:hidden" #titleRef>
        <ng-content select="[item-title]"></ng-content>
    </div>
    <span *ngIf="!titleRef.children.length" class="body-1">
        <ng-content></ng-content>
    </span>
</div>
