import { Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { Nullable } from '../global-util'

type HasGuid = {
    guid?: string
}

export function distinctUntilGuidChanged<T extends Nullable<HasGuid>>(): (
    source$: Observable<T>,
) => Observable<T> {
    return (source$) =>
        source$.pipe(
            distinctUntilChanged((prev, curr) => {
                return prev?.guid === curr?.guid
            }),
        )
}
