import { Component, Input, ViewEncapsulation } from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'

@Component({
    selector: 'app-tb-tooltip-component',
    templateUrl: './tb-tooltip.component.html',
    styleUrl: './tb-tooltip.component.sass',
    encapsulation: ViewEncapsulation.None,
})
export class TbTooltipComponent {
    @Input() tooltip = ''
    @Input() tooltipPosition: TooltipPosition = 'above'
    @Input() tooltipDisabled = false
    @Input() tooltipShowDelay = 0
    @Input() tooltipHideDelay = 0
}
