import { Component, OnInit } from '@angular/core'
import { SortModalComponent } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SortStorageService } from '@core/services/local-storage/sort-storage.service'
import { ViewFacadeService } from '@core/services/store-facade'
import { SortObjectEntities } from '@core/models/ui/sort.model'
import { ViewSortService } from 'src/app/views/view-controls/view-sort/view-sort.service'
import { combineLatest, filter, Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-view-sort',
    templateUrl: './view-sort.component.html',
})
export class ViewSortComponent implements OnInit {
    filterCountText$!: Observable<string>
    isSortEntitiesEmpty$!: Observable<boolean>
    sortEntities?: SortObjectEntities

    constructor(
        private sortStorageService: SortStorageService,
        private viewFacadeService: ViewFacadeService,
        private sortService: ViewSortService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    ngOnInit() {
        this.openMenuFromColumn()
        combineLatest([this.viewFacadeService.selectedView$, this.sortStorageService.getStore$()])
            .pipe(untilDestroyed(this))
            .subscribe(([view]) => {
                if (!view) return

                this.sortEntities = this.sortService.getSortByView(view)
                this.sortStorageService.updateIsSetValue(view.guid)

                this.isSortEntitiesEmpty$ = of(
                    !this.sortEntities || !Object.keys(this.sortEntities).length,
                )
                this.filterCountText$ = of(
                    this.sortEntities ? Object.keys(this.sortEntities).length.toString() : '',
                )
            })
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<SortModalComponent, null>({
                component: SortModalComponent,
            })
            .pipe(take(1))
    }

    private openMenuFromColumn() {
        this.sortService.getSortGuidFromColumn$
            .pipe(untilDestroyed(this), filter(Boolean))
            .subscribe(() => {
                this.openDialog()
            })
    }
}
