import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { isNonNull } from '@core/global-util'
import {
    Field,
    FieldEntities,
    FieldTypes,
    findImportantFieldByType,
    Folder,
    LinkDefinition,
    ObjectType,
    Schema,
} from '@core/models'
import { FolderFacadeService, SchemaFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { CellComponent } from '../../cell.component'
import { FieldNameComponent } from '../../../field-name/field-name.component'
import { MatOptionModule } from '@angular/material/core'
import { NgFor, NgIf } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-link-settings',
    styleUrls: ['link-settings.component.sass'],
    templateUrl: './link-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        FieldNameComponent,
        NgIf,
    ],
})
export class LinkSettingsComponent extends CellComponent implements OnInit {
    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()

    allSchema!: Schema[]
    schemasFields!: Record<string, Field[]>
    folder!: Folder

    linkDefinition = this.fb.group({
        schema: '',
        fields: { value: [''], disabled: true },
    })

    constructor(
        private fb: FormBuilder,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {
        super()
    }

    ngOnInit() {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(take(1), untilDestroyed(this), filter(isNonNull))
            .subscribe((folder) => {
                this.folder = folder
            })

        this.schemaFacadeService.selectAllSchemas$
            .pipe(
                take(1),
                untilDestroyed(this),
                map((schemas) => this.filterSchemasAndFields(schemas)),
            )
            .subscribe(([schemas, fields]) => {
                this.allSchema = schemas
                this.schemasFields = fields

                if (this.field.link_definition) {
                    this.setDefaultLinDefinition(this.field.link_definition)
                }
            })

        this.linkDefinition.controls.schema.valueChanges.subscribe((value) => {
            if (!!value) {
                this.linkDefinition.controls.fields.enable()
            } else {
                this.linkDefinition.controls.fields.disable()
            }
        })
    }

    get selectedSchemaFields(): Field[] {
        const guid = this.linkDefinition.controls.schema.value

        return guid ? this.schemasFields[guid] : []
    }

    get fieldsValue(): string[] {
        return this.linkDefinition.controls.fields.value || []
    }

    getField(fieldGuid: string): Field {
        return this.selectedSchemaFields.find((schemaField) => schemaField.guid === fieldGuid)!
    }

    updateValue(isOpened: boolean) {
        if (isOpened) return

        const { schema: schemaGuid, fields: formFields } = this.linkDefinition.getRawValue()
        if (!schemaGuid || !formFields) return

        const fields = this.allSchema.find((schema) => schema.guid === schemaGuid)?.fieldEntities
        if (!fields) return

        const nameField = findImportantFieldByType(fields, FieldTypes.NAME)
        if (!nameField) return

        this.fieldChanged.emit({
            ...this.field,
            link_definition: {
                target_object_field_guids: [nameField.guid, ...formFields],
                target_solution_object_type_guid: schemaGuid,
            },
        })
    }

    private setDefaultLinDefinition(linkDefinition: LinkDefinition) {
        const schemaGuid = linkDefinition.target_solution_object_type_guid
        const fields = this.allSchema.find((schema) => schema.guid === schemaGuid)?.fieldEntities
        if (!fields) return

        const nameField = findImportantFieldByType(fields, FieldTypes.NAME)

        if (!nameField) return
        const linkFieldsWithoutNameField = linkDefinition.target_object_field_guids.filter(
            (guid) => guid !== nameField.guid,
        )

        this.linkDefinition.setValue({
            schema: schemaGuid,
            fields: linkFieldsWithoutNameField,
        })
        this.linkDefinition.controls.schema.disable()
        this.linkDefinition.controls.fields.enable()
    }

    private filterSchemasAndFields(schemas: Schema[]) {
        const filteredSchemas = schemas.filter((schema) => {
            return schema.system_object_type_code === ObjectType.TABLE
        })

        const filters = filteredSchemas.reduce((acc, schema) => {
            return { ...acc, [schema.guid]: this.filterFields(schema.fieldEntities) }
        }, {} as Record<string, Field[]>)

        return [filteredSchemas, filters] as [Schema[], Record<string, Field[]>]
    }

    private filterFields(fields: FieldEntities) {
        const filteredFieldTypes: string[] = [FieldTypes.LINK, FieldTypes.NAME]

        return Object.values(fields).filter(
            (filter) => !filteredFieldTypes.includes(filter.field_type_code),
        )
    }
}
