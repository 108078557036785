import { Component } from '@angular/core'
import { filter, Observable } from 'rxjs'
import { isNonNull } from '@core/global-util'
import { View, ViewTypeCodes } from '@core/models'
import { ViewFacadeService } from '@core/services/store-facade'

@Component({
    selector: 'app-view-content',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.sass'],
})
export class ViewComponent {
    readonly ViewTypeCodes = ViewTypeCodes

    selectedView$: Observable<View> = this.viewFacadeService.selectedView$.pipe(filter(isNonNull))

    constructor(private viewFacadeService: ViewFacadeService) {}
}
