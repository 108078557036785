import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GlobalLoadingModule } from '@app/feature/global-loading/global-loading.module'
import { NotificationBannerModule } from '@app/feature/notification-banner/notification-banner.module'
import { PinPanelModule } from '@app/feature/pin-panel/pin-panel.module'
import { HeaderModule } from '@app/pages/main/header/header.module'
import { UserSettingsModule } from '@app/pages/main/user-settings/user-settings.module'
import { SharedModule } from '@app/shared/shared.module'
import { ViewControlsModule } from '@app/views/view-controls/view-controls.module'
import { ViewsModule } from '@app/views/views.module'
import { ComponentsLibraryModule } from '@components-library/components-library.module'
import { RootStoreModule } from '@core/@ngrx/root-store.module'
import { WsService } from '@services/ws.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TranslocoRootModule } from './transloco-root.module'

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ViewsModule,
        MatProgressSpinnerModule,
        RootStoreModule,
        AppRoutingModule,
        NotificationBannerModule,
        PinPanelModule,
        ViewControlsModule,
        TranslocoRootModule,
        HeaderModule,
        UserSettingsModule,
        ComponentsLibraryModule,
        SharedModule,
        GlobalLoadingModule,
    ],
    providers: [WsService],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
