import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import { TbTab } from '../tb-tab/tb-tab.component'

@Component({
    selector: 'app-tb-tabs',
    templateUrl: './tb-tabs.component.html',
    styleUrls: ['./tb-tabs.component.sass'],
})
export class TbTabsComponent {
    @Input()
    tabs!: TbTab[]

    @Input()
    icon?: TemplateRef<any>

    @Input()
    activeTab: TbTab | null = null

    @Input()
    moreMenu: TemplateRef<any> | null = null

    @Output()
    tabOpened = new EventEmitter<TbTab>()
}
