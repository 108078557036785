import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatOption, MatOptionModule } from '@angular/material/core'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { getBoolean } from '@core/global-util'
import { AppRecord, Folder } from '@core/models'
import { FolderFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { MatButtonModule } from '@angular/material/button'
import { NgFor } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'

interface ListFolderItem {
    item_id: string
    item_text?: string
}

@UntilDestroy()
@Component({
    selector: 'app-share-record',
    templateUrl: './share-record.component.html',
    styleUrls: ['./share-record.component.sass'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatOptionModule,
        NgFor,
        MatButtonModule,
        ModalLayoutComponent,
        TbButtonComponent,
    ],
})
export class ShareRecordComponent extends ModalContainerComponent implements OnInit {
    record!: AppRecord
    folders!: Dictionary<Folder>
    dropdownList: Array<ListFolderItem> = []
    selectedItems: Array<ListFolderItem> = []
    sharedFolders!: FormControl
    folderGuids!: string
    @ViewChild('allSelected') private allSelected: MatOption | undefined

    constructor(
        private folderFacadeService: FolderFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) public data: AppRecord,
    ) {
        super(data, modalManagerService, confirmationDialogService)
        this.record = data
    }

    ngOnInit(): void {
        this.folderFacadeService.selectFolderEntities$
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.sharedFolders = new FormControl<any>('')
                this.folderGuids = this.record.folder_guids || ''
                this.folders = data

                this.setLists()
            })
    }

    onItemClick() {
        if (this.allSelected?.selected) {
            this.allSelected.deselect()
        }

        if (this.sharedFolders.value.length === this.dropdownList.length) {
            this.allSelected?.select()
        }

        this.updateFolderGuids()
    }

    toggleAllSelection() {
        if (!this.allSelected?.selected) {
            this.sharedFolders.patchValue([])
            this.folderGuids = this.record.folder_guids ?? ''
            return
        }

        this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
        this.folderGuids = this.removeValueOfAllOption().toString()
    }

    applyShare() {
        this.record = {
            ...this.record,
            folder_guids: this.folderGuids,
        }

        this.close(this.record)
    }

    cancelShare() {
        this.close()
    }

    private setLists() {
        Object.keys(this.folders).forEach((folderGuid) => {
            if (!getBoolean(this.folders[folderGuid]?.is_global.value)) {
                const folderName = this.folders[folderGuid]?.name.value
                if (!folderName) return

                this.setDropdownList(folderGuid, folderName)

                this.setSelectedList(folderGuid, folderName)
            }
        })

        this.setFormControlSelected()
    }

    private setDropdownList(folderGuid: string, folderName: string) {
        if (!this.folderGuids?.includes(folderName)) {
            this.dropdownList.push({
                item_id: folderGuid,
                item_text: folderName,
            })
        }
    }

    private setSelectedList(folderGuid: string, folderName: string) {
        if (this.folderGuids.length && this.folderGuids.includes(folderGuid)) {
            this.selectedItems.push({
                item_id: folderGuid,
                item_text: folderName,
            })
        }
    }

    private setFormControlSelected() {
        if (this.selectedItems.length === 0) return

        if (this.selectedItems.length !== this.dropdownList.length) {
            this.sharedFolders.patchValue(this.selectedItems.map((item) => item.item_id))
            return
        }

        this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
    }

    private updateFolderGuids() {
        if (!this.folderGuids) {
            this.folderGuids = this.sharedFolders.value[0]
            return
        }

        this.folderGuids = this.sharedFolders.value.includes(0)
            ? this.removeValueOfAllOption().toString()
            : this.sharedFolders.value.toString()
    }

    private removeValueOfAllOption() {
        return this.sharedFolders.value.filter((item: string | number) => item !== 0)
    }
}
