import { createAction, props } from '@ngrx/store'
import { ObjectResponseModel, ResponseFieldEntities, SolutionModel } from '../../models'
import { RoleEntities } from '../../models/response/role'

export const setError = createAction('[Common] Set error', props<{ error: string | null }>())

export const initApp = createAction('[Common] Init App')

export const reInitApp = createAction('[Common] Re-init App')

export const initAppSuccess = createAction('[Common] Init App Success')

export const dataInitialized = createAction('[Common] Data initialized')

export const dataReset = createAction('[Common] Data resetting')

export const setLoading = createAction('[Common] SetLoading', props<{ loading: boolean }>())

export const onMessage = createAction(
    '[Common] WS processing on message',
    props<{ response: ObjectResponseModel }>(),
)

export const setSolution = createAction(
    '[Common] Available view types',
    props<{ solution: SolutionModel }>(),
)

export const initSystemFields = createAction(
    '[Common] InitSystemFields',
    props<{ systemFields: ResponseFieldEntities }>(),
)

export const resetSolutionToTemplate = createAction('[Common] Reset solution to template')
