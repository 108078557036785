<div class="flex items-center cursor-pointer">
    <div class="flex mr-2 item-icon empty:hidden" #iconRef>
        <ng-content select="[item-icon]"></ng-content>
    </div>
    <app-tb-icon
        *ngIf="icon && !iconRef.children.length"
        color="primarySecondary"
        [icon]="icon"
        class="item-icon mr-2"
    ></app-tb-icon>
    <div class="inline body-1 empty:hidden" #titleRef>
        <ng-content select="[item-title]"></ng-content>
    </div>
    <span *ngIf="!titleRef.children.length" class="body-1">
        <ng-content></ng-content>
    </span>
</div>
