<div class="flex-start-center justify-content-between w-100">
    <div class="info-bl" *transloco="let tCardUpdate; read: 'card_update'">
        <div *ngIf="record.update_at">
            <span class="text-muted text-size-xxs text-upper">
                {{tCardUpdate('update_label')}}
            </span>
            {{updateDate}}
        </div>
        <div *ngIf="record.update_by && user">
            {{tCardUpdate('updated', {name: this.user.fullName})}}
        </div>
    </div>
    <div *ngIf="record.watch && fields[record.watch.fieldGuid]" id="card-watch">
        <app-cell-container
            (cellValueChanged)="updateRecord(record, record.watch, $event)"
            [fieldTypes]="fieldTypes"
            [field]="fields[record.watch.fieldGuid]"
            [guid]="record.watch.fieldGuid"
            [record]="record"
            [value]="record.watch.value"
            class="flex-start-center text-center"
        ></app-cell-container>
    </div>
</div>
