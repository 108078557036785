import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatBadgeModule } from '@angular/material/badge'
import { TbBoardCardComponent } from '@components-library/tb-board/tb-board-card/tb-board-card.component'
import { TbBoardColumnHeaderComponent } from '@components-library/tb-board/tb-board-column-header/tb-board-column-header.component'
import { TbBoardColumnComponent } from '@components-library/tb-board/tb-board-column/tb-board-column.component'
import { TbBoardComponent } from '@components-library/tb-board/tb-board.component'
import { ComponentsLibraryModule } from '@components-library'
import { SharedModule } from '@app/shared/shared.module'
import { TranslocoModule } from '@ngneat/transloco'

const exportComponents = [
    TbBoardComponent,
    TbBoardCardComponent,
    TbBoardColumnComponent,
    TbBoardColumnHeaderComponent,
]

@NgModule({
    declarations: [...exportComponents],
    imports: [
        CommonModule,
        DragDropModule,
        MatBadgeModule,
        ComponentsLibraryModule,
        SharedModule,
        TranslocoModule,
    ],
    exports: [...exportComponents],
})
export class TbBoardModule {}
