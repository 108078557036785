<div class="flex items-center w-full min-h-[38px]">
    <ng-container *ngIf="isFieldNonText; else textFieldInput">
        <ng-container *ngIf="field.field_type_code !== FieldTypes.LINK; else linkSelector">
            <!-- TODO: https://chat.anveo.com/git/AnveoTikibase_ui/pulls/1270#issuecomment-26953 -->
            <app-cell-container
                [class.static]="field.field_type_code === FieldTypes.STATUS || field.field_type_code === FieldTypes.DROPDOWN"
                [fieldTypes]="fieldTypes"
                [field]="field"
                [value]="fieldValueFormControl.value ?? ''"
                (cellValueChanged)="cellValueChanged($event)"
            ></app-cell-container>
        </ng-container>
        <!-- TODO: will be reworked in task #1263 -->
        <ng-template #linkSelector>
            <mat-form-field appearance="outline" class="flex items-center w-full max-h-[38px] mb-2">
                <mat-select
                    multiple
                    [disabled]="fieldValueFormControl.disabled"
                    [value]="linkValue"
                    (selectionChange)="setLinkValue($event)"
                    placeholder="Select"
                >
                    <mat-option *ngFor="let record of linkRecords" [value]="record.guid">
                        {{record.name.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>
    </ng-container>
    <ng-template #textFieldInput>
        <app-tb-input
            [formControl]="fieldValueFormControl"
            (keydown.enter)="updateOnEnter()"
            class="w-full"
        ></app-tb-input>
    </ng-template>
</div>
