<div class="mb-6" *transloco="let tRecordSettings; read: 'record_settings'">
    <div class="mb-2 text-primaryDark">{{tRecordSettings('access')}}</div>
    <div
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-primaryLight cursor-pointer shadow-light"
    >
        <mat-icon
            class="mr-2 h-8 w-8 min-w-[32px] text-3xl leading-8 text-primarySecondary group-hover:text-secondaryMain group-[.selectedAccess]:text-secondaryMain"
            >groups_outline</mat-icon
        >
        <div>
            <div
                class="mb-1 text-primaryDark group-hover:subheading-2 group-[.selectedAccess]:subheading-2"
            >
                {{tRecordSettings('everyone')}}
            </div>
            <div class="caption-1 text-primarySecondary">
                {{tRecordSettings('everyone_description')}}
            </div>
        </div>
    </div>
    <div
        [class.selectedAccess]="isActive"
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-primaryLight cursor-pointer shadow-light"
    >
        <mat-icon
            class="mr-2 h-8 w-8 min-w-[32px] text-3xl leading-8 text-primarySecondary group-hover:text-secondaryMain group-[.selectedAccess]:text-secondaryMain"
            >group</mat-icon
        >
        <div>
            <div
                class="mb-1 text-primaryDark group-hover:subheading-2 group-[.selectedAccess]:subheading-2"
            >
                {{tRecordSettings('roles')}}
            </div>
            <div class="mb-4 caption-1 text-primarySecondary">
                {{tRecordSettings('roles_description')}}
            </div>
            <div class="hidden group-[.selectedAccess]:flex items-center">
                <mat-form-field class="w-full noHintError" appearance="outline">
                    <mat-select #rolesSelect multiple>
                        <!--                        options should be dynamically inserted-->
                        <mat-option value="yes">yes</mat-option>
                    </mat-select>
                    <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix
                        >{{ rolesSelect.panelOpen ? 'expand_less' : 'expand_more' }}</mat-icon
                    >
                </mat-form-field>
                <mat-icon
                    svgIcon="custom_settings"
                    class="ml-2 text-secondaryMainLight hover:text-secondaryMain"
                ></mat-icon>
            </div>
        </div>
    </div>
    <div
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-primaryLight cursor-pointer shadowBox"
    >
        <mat-icon
            class="mr-2 h-8 w-8 min-w-[32px] text-3xl leading-8 text-primarySecondary group-hover:text-secondaryMain group-[.selectedAccess]:text-secondaryMain"
        >
            manage_accounts_outline
        </mat-icon>
        <div>
            <div
                class="mb-1 text-primaryDark group-hover:subheading-2 group-[.selectedAccess]:subheading-2"
            >
                {{tRecordSettings('custom')}}
            </div>
            <div class="caption-1 text-primarySecondary">
                {{tRecordSettings('custom_description')}}
            </div>
        </div>
    </div>
</div>
