import { Injectable } from '@angular/core'
import {
    FolderCreateModel,
    FolderDeleteModel,
    FolderTemplate,
    FolderUpdateModel,
} from '@core/models'
import { FolderNameEntities } from '@models/response/folder-names'
import { Dictionary, Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { UpdateService } from '@services/update.service'
import { map } from 'rxjs/operators'
import {
    addFoldersFromResponse,
    AppState,
    deleteFoldersFromResponse,
    foldersAreLoaded,
    initFolderNames,
    initFolders,
    initFolderTemplate,
    selectAllFolders,
    selectFolderEntities,
    selectFolderNames,
    selectFoldersAreLoaded,
    selectFoldersIsError,
    selectFolderTemplate,
    selectGlobalFolder,
    selectSelectedFolder,
    selectSelectedFolderGuid,
    selectSelectedFolderStatusField,
    setSelectedFolderGuid,
    updateFoldersFromResponse,
} from '../../@ngrx'
import { Deleted, Folder } from '../../models'

@Injectable({
    providedIn: 'root',
})
export class FolderFacadeService {
    constructor(private store: Store<AppState>, private updateService: UpdateService) {}

    selectFolderEntities$ = this.store.select(selectFolderEntities)

    selectAllFolders$ = this.store.select(selectAllFolders)

    selectFoldersAreLoaded$ = this.store.select(selectFoldersAreLoaded)

    selectFoldersIsError$ = this.store.select(selectFoldersIsError)

    selectSelectedFolderGuid$ = this.store.select(selectSelectedFolderGuid)

    selectSelectedFolder$ = this.store.select(selectSelectedFolder)

    // TODO: investigate and replace global folder search in all places with this selection if needed
    // https://chat.anveo.com/git/AnveoTikibase_ui/issues/1575
    selectGlobalFolder$ = this.store.select(selectGlobalFolder)

    selectSelectedFolderStatusField$ = this.store.select(selectSelectedFolderStatusField)

    selectFolderNames$ = this.store.select(selectFolderNames)

    selectFolderTemplate$ = this.store.select(selectFolderTemplate)

    selectFolderByGuid$ = (guid: string) => {
        return this.selectFolderEntities$.pipe(
            map((folderDictionary: Dictionary<Folder>) => folderDictionary[guid] || null),
        )
    }

    initFolders(folders: Folder[], selectedFolderGuid?: string) {
        this.store.dispatch(initFolders({ folders, selectedFolderGuid }))
    }

    initFolderNames(folderNames: FolderNameEntities) {
        this.store.dispatch(initFolderNames({ folderNames }))
    }

    foldersAreLoaded() {
        this.store.dispatch(foldersAreLoaded())
    }

    setSelectedFolderGuid(selectedFolderGuid: string | null) {
        this.store.dispatch(setSelectedFolderGuid({ selectedFolderGuid }))
    }

    createFolderRequest(model: FolderCreateModel) {
        return this.updateService.createFolder(model)
    }

    updateFolderRequest(model: FolderUpdateModel) {
        return this.updateService.updateFolder(model)
    }

    deleteFolderRequest(model: FolderDeleteModel) {
        return this.updateService.deleteFolder(model)
    }

    addFoldersFromResponse(folders: Folder[]) {
        this.store.dispatch(addFoldersFromResponse({ folders }))
    }

    updateFoldersFromResponse(folders: Update<Folder>[]) {
        this.store.dispatch(updateFoldersFromResponse({ folders }))
    }

    deleteFoldersFromResponse(folders: Deleted[]) {
        this.store.dispatch(deleteFoldersFromResponse({ folders }))
    }

    initFolderTemplate(folderTemplate: FolderTemplate) {
        this.store.dispatch(initFolderTemplate({ folderTemplate }))
    }
}
