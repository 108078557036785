<div
    class="inline-flex h-9 mb-[5px] pl-2 pr-3 cursor-pointer items-center bg-white"
    [ngClass]="{ 'body-1': !isSelectedOrMenuOpened, 'body-2': isSelectedOrMenuOpened, 'text-secondaryMain': isMenuOpened}"
>
    <div class="flex items-center relative" (click)="select()">
        <ng-container
            *ngIf="icon; else stringIcon"
            [ngTemplateOutlet]="icon"
            [ngTemplateOutletContext]="{ tab }"
        >
        </ng-container>

        <div
            title="{{ tab.name }}"
            class="tab-name relative max-w-[17ch] whitespace-nowrap text-ellipsis"
        >
            {{tab.name}}
        </div>
        <div
            [class]="isSelected ? 'visible' : 'invisible'"
            class="w-100 h-[4px] min-h-[4px] rounded-sm bg-secondaryMain absolute bottom-[-8px]"
        ></div>
    </div>

    <div *ngIf="moreMenu" class="more-menu ml-2.5">
        <app-tb-menu
            [template]="moreMenu"
            (stateChanged)="isMenuOpened = $event === TbMenuStateEnum.Open"
        >
            <app-tb-button
                [appearance]="'icon'"
                [icon]="'more_vert'"
                [class.visible]="isMenuOpened"
                trigger
            >
            </app-tb-button>
        </app-tb-menu>
    </div>
</div>

<ng-template #stringIcon>
    <ng-container *ngIf="tab.icon">
        <app-tb-icon class="icon-sm mr-2" [icon]="tab.icon"></app-tb-icon>
    </ng-container>
</ng-template>
