<div class="inline-block h-8 w-8 rounded-full shrink-0 overflow-hidden">
    <img
        class="flex-shrink-0 h-8 w-8"
        *ngIf="userUrl, else defaultAvatar"
        [src]="userUrl"
        alt=""
        onerror="this.src='../../../../assets/img/avatar.png'"
    />
    <ng-template #defaultAvatar>
        <img alt="userName" class="flex-shrink-0 h-8 w-8" src="../../../assets/img/avatar.png" />
    </ng-template>
</div>
