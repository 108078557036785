import { Component, Input, OnInit } from '@angular/core'
import { Cell, CurrentUser, Schema, View } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NavigatorService } from '@core/services/navigator.service'
import { combineLatest, EMPTY, filter, mergeMap, Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { Dictionary } from '@ngrx/entity'
import {
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '@core/services/store-facade'

export type ViewType = Pick<View, 'schemaGuid' | 'parent_sot_guid' | 'folder_guids'> & {
    name: CellType
    type_code: CellType
}

export type CellType = Pick<Cell, 'fieldType' | 'fieldGuid' | 'fieldName'>

@UntilDestroy()
@Component({
    selector: 'app-view-error',
    templateUrl: './view-error.component.html',
    styleUrls: ['./view-error.component.sass'],
})
export class ViewErrorComponent implements OnInit {
    @Input()
    errorTypeNames?: string[]

    views!: View[]
    schema!: Schema
    view!: ViewType

    currentUser$: Observable<CurrentUser | null> = this.userFacadeService.currentUser$

    constructor(
        private navigator: NavigatorService,
        private schemaFacadeService: SchemaFacadeService,
        private userFacadeService: UserFacadeService,
        private folderFacadeService: FolderFacadeService,
    ) {}

    ngOnInit(): void {
        combineLatest([
            this.schemaFacadeService.selectSchemaBySystemObjectTypeCode$('view'),
            this.schemaFacadeService.selectSelectedTableSchemaGuid$,
            this.folderFacadeService.selectSelectedFolderGuid$,
        ])
            .pipe(
                untilDestroyed(this),
                mergeMap(([schema, schemaGuid, folderGuid]) => {
                    if (!schema) return EMPTY

                    if (!schemaGuid) {
                        this.navigator.goToViewPage()
                        return EMPTY
                    }

                    return of(this.getViewSchema(schema, folderGuid))
                }),
                filter(Boolean),
            )
            .subscribe((view) => (this.view = view))
    }

    navigateToSOT() {
        combineLatest([
            this.schemaFacadeService.selectSelectedTableSchemaGuid$,
            this.folderFacadeService.selectSelectedFolderGuid$,
        ])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(([schemaGuid, folderGuid]) => {
                if (!schemaGuid) return

                this.navigator.goToSystemObject(schemaGuid, folderGuid)
            })
    }

    private getViewSchema(schema: Schema, folderGuid: string) {
        const templateCells: Dictionary<CellType> = Object.keys(schema.fieldEntities).reduce(
            (cells, guid) => ({
                ...cells,
                [schema.fieldEntities[guid].name as string]: {
                    fieldGuid: guid,
                    fieldName: schema.fieldEntities[guid].name,
                },
            }),
            {},
        )

        return {
            schemaGuid: schema.guid,
            parent_sot_guid: schema.guid,
            folder_guids: folderGuid || '',
            name: templateCells['name'] as CellType,
            type_code: templateCells['type_code'] as CellType,
            pinned: templateCells['pinned'] as CellType,
            private: templateCells['private'] as CellType,
        }
    }
}
