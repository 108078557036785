import { Component, OnInit } from '@angular/core'
import { AddFieldComponent } from '@app/feature/add-field/add-field.component'
import {
    AddRecordContentComponent,
    AddRecordModalData,
} from '@app/feature/add-record/add-record-content.component'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { MultiselectItemsService } from '@components-library/services/multiselect-items.service'
import { MovedItem } from '@components-library/tb-board/tb-board-column/tb-board-column.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import {
    AddFieldData,
    AppRecord,
    BusinessRecord,
    BusinessRecords,
    CellEntities,
    Field,
    Folder,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    ViewData,
} from '@core/models'
import { Dictionary } from '@ngrx/entity'
import { isNonNull } from '@core/global-util'
import { RecordsService } from '@core/services/records.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NotificationService } from '@services/notification.service'
import { filter, Observable, of } from 'rxjs'
import { concatMap, map, switchMap, take } from 'rxjs/operators'

import { map as lMap } from 'lodash-es'
@UntilDestroy()
@Component({
    selector: 'app-board-view',
    templateUrl: './board-view.component.html',
    styleUrls: ['./board-view.component.sass'],
    providers: [MultiselectItemsService],
})
export class BoardViewComponent implements OnInit {
    // todo: [board-ref] move these to the Service
    ViewData!: ViewData
    visibleFields: string[] = []
    fields: string[] = []
    selectedFolder$: Observable<Folder> = this.folderFacadeService.selectSelectedFolder$.pipe(
        filter(isNonNull),
    )
    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    cells!: { [recordGuid: string]: CellEntities }

    // todo: [board-ref] remove this array from this component
    selectedRecords: BusinessRecords[] = []

    NO_GROUPED_RECORDS_KEY = 'NO_GROUPED_RECORDS_KEY'

    boardItemsKey: keyof BusinessRecord = 'guid'
    allRecords: Dictionary<BusinessRecords> = {}

    fields$ = this.selectedFolder$.pipe(
        concatMap((selectedFolder) =>
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                selectedFolder,
            ),
        ),
        filter(isNonNull),
    )

    constructor(
        private recordService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private notificationService: NotificationService,
        private multiselectService: MultiselectItemsService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {
        multiselectService
            .getSelectedItems$()
            .pipe(untilDestroyed(this))
            .subscribe((records) => {
                this.selectedRecords = records.map((key) => {
                    return this.allRecords[key]!
                })
            })

        recordFacadeService.selectRecordEntities$
            .pipe(untilDestroyed(this))
            .subscribe((records) => {
                this.allRecords = records
            })
    }

    get groups(): RecordGroup[] {
        if (this.ViewData.data instanceof Map) {
            return [...this.ViewData.data.values()]
        }

        return []
    }

    get records(): AppRecord[] {
        if (this.ViewData?.data instanceof Array) {
            return [...this.ViewData?.data]
        }
        return []
    }

    drop(group: RecordGroup, movedItem: MovedItem<BusinessRecords>) {
        if (group.field && group.value) {
            this.moveCardToAnotherColumn(group.field, movedItem, group.value)
        }
    }

    // todo: [board-ref] move to Service
    ngOnInit(): void {
        this.recordFacadeService
            .selectViewData$()
            .pipe(
                untilDestroyed(this),
                switchMap((viewData) => {
                    if (!viewData || viewData.data instanceof Map) return of(viewData)
                    return this.changeArrayDataToGroup(viewData)
                }),
            )
            .subscribe((ViewData: ViewData | undefined) => {
                if (ViewData) {
                    this.ViewData = ViewData
                    this.visibleFields = [...ViewData.columns.columns]
                    this.prepareCells()
                }
            })
    }

    // todo: [board-ref] remove
    clearSelected() {
        this.multiselectService.deselectAll()
    }

    openCardDialog(record: BusinessRecords) {
        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: { recordGuid: record.guid, isFolder: true },
        })
    }

    duplicate(record: BusinessRecords) {
        this.modalFlowManagerService
            .openDialog<AddRecordContentComponent, AddRecordModalData>({
                component: AddRecordContentComponent,
                data: { name: record.name.value },
            })
            .pipe(take(1))
    }

    editField(field: Field) {
        this.modalFlowManagerService
            .openDialog<AddFieldComponent, AddFieldData, Field | undefined>({
                component: AddFieldComponent,
                data: {
                    ...field,
                },
            })
            .subscribe((res: Field | undefined) => {
                if (res) {
                    this.schemaFacadeService.updateField(field)
                }
            })
    }

    manageFields() {
        this.modalFlowManagerService.openDialog({ component: ManageFieldsComponent })
    }

    selectGroup(group: RecordGroup) {
        this.multiselectService.toggleItems(lMap<BusinessRecords>(group.data, this.boardItemsKey))
    }

    isAllGroupSelected(group: RecordGroup) {
        return this.multiselectService.areSelected(
            lMap<BusinessRecords>(group.data, this.boardItemsKey),
        )
    }

    // todo: [board-ref] remove
    deleteSelected() {
        this.recordService.deleteRecord(this.selectedRecords)
        this.clearSelected()
    }

    deleteRecord(record: BusinessRecords) {
        this.recordService.deleteRecord(record)
    }

    // todo: [board-ref] remove
    editSelected(records: { value: string; field: Field }) {
        const data = this.selectedRecords.map((record) => {
            return {
                record: record,
                cell: this.cells[record.guid][records.field.guid],
                value: records.value,
            }
        })
        this.recordService.updateRecords(data)
        this.clearSelected()
    }

    private prepareCells() {
        if (this.records?.length) {
            this.cells = prepareCellsForRecords(this.records)
        } else if (this.groups?.length) {
            this.cells = prepareFromGroups(this.groups)
        }
    }

    private moveCardToAnotherColumn(field: Field, item: MovedItem<BusinessRecords>, value: string) {
        const moveCard = (item.previousItems as BusinessRecords[]).find(
            (record) => record.guid === item.item.guid,
        )

        if (!moveCard) return

        if (this.isNoGroupedStatus(field.field_type_code, value)) {
            this.notificationService.openErrorNotification([
                {
                    message: 'NO_STATUS_GROUP',
                    object_field_guid: '',
                    object_record_guid: '',
                },
            ])
            return
        }

        this.recordService.updateRecord({
            record: moveCard!,
            cell: this.cells[moveCard.guid][field.guid],
            value: value !== this.NO_GROUPED_RECORDS_KEY ? value : '',
        })
    }

    private changeArrayDataToGroup(viewData: ViewData) {
        return this.folderFacadeService.selectSelectedFolderStatusField$.pipe(
            map((folderStatus) => {
                if (!folderStatus || !viewData) return viewData

                return this.changeViewData(folderStatus, viewData)
            }),
        )
    }

    private changeViewData(folderStatus: Field, viewData: ViewData) {
        const data = this.recordFacadeService.generateGroup(
            viewData.data as BusinessRecords[],
            folderStatus.guid,
            folderStatus,
        )

        return {
            ...viewData,
            data: data,
        }
    }

    private isNoGroupedStatus(fieldType: string, value: string) {
        return fieldType === 'field_type_status' && value === this.NO_GROUPED_RECORDS_KEY
    }
}
