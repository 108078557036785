import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'

import { BusinessRecord, Field, FieldType, FieldTypes, ValueJson } from '@core/models'
import { NumberComponent } from './number/number.component'
import { BooleanComponent } from './boolean/boolean.component'
import { StatusComponent } from './select/status'
import { DropdownComponent } from './select/dropdown'
import { RatingComponent } from './rating/rating.component'
import { AssignComponent } from './people/assign/assign.component'
import { NameComponent } from './name/name.component'
import { DateTimeComponent } from './date-time/date-time.component'
import { DateComponent } from './date/date.component'
import { MoneyComponent } from './money/money.component'
import { EmailComponent } from './email/email.component'
import { TextOneLineComponent } from './text-one-line/text-one-line.component'
import { UrlComponent } from './url/url.component'
import { RangeComponent } from './range/range.component'
import { WatchComponent } from './people/watch/watch.component'
import { TextMultiLineComponent } from './text-multiline/text-multi-line.component'
import { Dictionary } from '@ngrx/entity'
import { LinkComponent } from './link/link.component'
import { RichTextComponent } from './rich-text/rich-text.component'
import { LinkReferenceComponent } from './link-reference/link-reference.component'

export const fieldTypeComponents: { [key: string]: Type<any> } = {
    [FieldTypes.NAME]: NameComponent,
    [FieldTypes.TEXT]: TextOneLineComponent,
    [FieldTypes.MONEY]: MoneyComponent,
    [FieldTypes.EMAIL]: EmailComponent,
    [FieldTypes.WEBSITE]: UrlComponent,
    [FieldTypes.NUMBER]: NumberComponent,
    [FieldTypes.STATUS]: StatusComponent,
    [FieldTypes.DROPDOWN]: DropdownComponent,
    [FieldTypes.ASSIGNEE]: AssignComponent,
    [FieldTypes.PEOPLE]: AssignComponent,
    [FieldTypes.BOOL]: BooleanComponent,
    [FieldTypes.RATING]: RatingComponent,
    [FieldTypes.DATE_TIME]: DateTimeComponent,
    [FieldTypes.DATE_RANGE]: RangeComponent,
    [FieldTypes.DATE]: DateComponent,
    [FieldTypes.WATCH]: WatchComponent,
    [FieldTypes.MULTILINE_TEXT]: TextMultiLineComponent,
    [FieldTypes.LINK]: LinkComponent,
    [FieldTypes.RICH_TEXT]: RichTextComponent,
    [FieldTypes.LINK_REFERENCE]: LinkReferenceComponent,
}

@Component({
    selector: 'app-cell-container',
    templateUrl: './cell-container.component.html',
    styleUrls: ['./cell-container.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellContainerComponent implements OnDestroy, AfterViewInit, OnChanges {
    @Input() field!: Field
    @Input() value!: string
    @Input() valueJson?: ValueJson
    @Input() guid!: string
    @Input() record!: BusinessRecord
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() disabled: boolean = false
    @Input() isCard?: boolean

    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter()

    @ViewChild('cellArea', { read: ViewContainerRef }) cellArea!: ViewContainerRef

    cmpRef!: ComponentRef<any>
    private isViewInitialized: boolean = false

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges() {
        this.updateComponent()
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return
        }
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }

        const componentName = fieldTypeComponents[this.field.field_type_code]

        if (!componentName) {
            console.log(new Error(`unknown field type ${this.field.field_type_code}`))
        }

        this.cmpRef = this.cellArea.createComponent(componentName)

        this.setCmpRefInstance()

        this.cdr.detectChanges()
    }

    ngAfterViewInit() {
        this.isViewInitialized = true
        this.updateComponent()
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
    }

    private setCmpRefInstance() {
        if (this.value) {
            this.cmpRef.instance.value = this.value
        }

        if (this.valueJson) {
            this.cmpRef.instance.valueJson = this.valueJson
        }

        this.cmpRef.instance.fieldType = this.fieldTypes[this.field.field_type_code]
        this.cmpRef.instance.field = this.field
        this.cmpRef.instance.cellValueChanged = this.cellValueChanged
        this.cmpRef.instance.record = this.record
        this.cmpRef.instance.disabled = this.disabled || this.field.operationCode?.system

        if (this.field.field_type_code === FieldTypes.RICH_TEXT) {
            this.cmpRef.instance.isCard = this.isCard
        }
    }
}
