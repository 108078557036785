import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { CardModule } from '@app/feature/record-card/card.module'
import { SharedModule } from '@app/shared/shared.module'
import { ViewContainerComponent } from '@app/pages/main/pages/view-container/view-container.component'
import { HomeModule } from '@app/pages/main/pages/home/home.module'
import { TbBoardModule } from '@components-library/tb-board/tb-board.module'
import { ViewControlsModule } from '@app/views/view-controls/view-controls.module'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'
import { ViewComponent } from '../pages/main/pages/view/view.component'
import { BoardViewModule } from 'src/app/views/board/board-view.module'
import { TableContainerModule } from './table/table-container.module'
import { ViewRoutingModule } from './view-routing.module'

//TODO: [page extraction] review view modules. create base view module and move related imports and declarations to new module
@NgModule({
    declarations: [ViewComponent, ViewContainerComponent],
    imports: [
        CommonModule,
        ViewControlsModule,
        BoardViewModule,
        ViewRoutingModule,
        TableContainerModule,
        CardModule,
        HomeModule,
        TranslocoModule,
        MatIconModule,
        FormsModule,
        MatDialogModule,
        SharedModule,
        MatButtonModule,
        TbBoardModule,
        ComponentsLibraryModule,
    ],
    exports: [ViewRoutingModule],
})
export class ViewsModule {}
