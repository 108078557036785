import { ComponentRef, EventEmitter, inject, Injectable } from '@angular/core'
import { ModalContainersStore } from '@components-library/tb-modal-manager/modal-containers.store'
import { ReplaySubject } from 'rxjs'
import { ModalRenderComponent } from '../modal-render-container/modal-render.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { BaseContainer } from '../modal-containers/base-container'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { take } from 'rxjs/operators'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'

/**
 * DialogInstanceService manages opening MatDialog. It assures that at some point there is opened only one dialog instance,
 * and when we call DialogInstanceService.open() again, it just replaces the Modal Content, instead of opening new MatDialog.
 **/
export class DialogInstanceService {
    /**
     * This Symbol indicates when we want close popup programmatically.
     * There are two options how Modal Flow can be closed:
     * - programmatically with ForcedData
     * - by User intention, when click outside the Dialog or Bottom sheet, or click some *close* buttons etc.
     *
     * Programmatically we close Modal Container when we convert it to its Alternative. In this case we shouldn’t call “this.closed.emit()”, so we should pass some flag to notify that this "close" action is programmatically made and do not call the “closed”. That is why ForcedData flag is here.
     *
     * Automatically we close Modal Container when User click outside the Dialog or BottomSheet, or click some *close* buttons etc. In this case we should emit “closed” to call callbacks that were set for this event.
     **/
    private readonly ForcedData = Symbol('ForcedData')

    /**
     * MatDialog instance
     **/
    private dialogInstance: MatDialogRef<unknown> | null = null

    /**
     * Stream that is passed into the ModalRenderComponent to render Modal Container during Navigation. We can't call MatDialog.open() on an instantiated MatDialogRef, so we pass stream that receive ModalContainers that should be rendered and replace the previous Modal Container.
     **/
    private renderComponent$ = new ReplaySubject<ComponentRef<unknown>>(1)

    /**
     * Event that signals that MatDialog is closed by User intention.
     **/
    public closed = new EventEmitter()

    constructor(private containersStore: ModalContainersStore, private dialog: MatDialog) {}

    /**
     * This method opens MatDialog. It is called by ModalManagerService.
     * @param {BaseContainer<ModalContainerComponent>} container The Modal Container instance
     **/
    open(container: BaseContainer<ModalContainerComponent>) {
        this.reopen(container)

        return container.closed.asObservable().pipe(take(1))
    }

    /**
     * This method opens MatDialog. It is called by ModalManagerService when the Modal Transformation performs.
     * @param {BaseContainer<ModalContainerComponent>} container The Modal Container instance
     **/
    reopen(container: BaseContainer<ModalContainerComponent>) {
        /**
         * This condition assures that the MatDialog is singleton, and it doesn't overlap.
         **/
        if (!this.dialogInstance) {
            this.dialogInstance = this.dialog.open(ModalRenderComponent, {
                data: { renderComponent$: this.renderComponent$ },
                disableClose: true,
                panelClass: 'modal-flow-dialog',
            })

            this.dialogInstance.afterClosed().subscribe((data) => {
                /**
                 * data === this.ForcedData means that it is programmatically closed, and not by User intention. It indicates Modal Transformation.
                 **/
                if (data === this.ForcedData) return

                this.closed.emit()
                this.clear()
            })

            /**
             * When user click on backdrop we should open Confirmation Dialog.
             * There are two condition:
             * - this.modalContainersService.containers.length > 1 - means that currently we are in the Child Flow component, it happens when we make Navigation
             * - this.modalContainersService.hasMarkedContainer() - when some of the containers in the Stack has marked state
             **/
            this.dialogInstance.backdropClick().subscribe(() => {
                if (
                    this.containersStore.containers.length > 1 ||
                    this.containersStore.hasMarkedContainer()
                ) {
                    this.containersStore.firstContainer?.componentRef.instance.openConfirmationDialog()
                } else {
                    this.closed.emit()
                    this.clear()
                }
            })
        }

        /**
         * If the MatDialogRef is created, we should pass the Container we want to open, and set Dialog in the ModalContainerComponent.containerLayout property.
         **/
        container.componentRef.setInput('containerLayout', ModalContainer.Dialog)
        this.renderComponent$.next(container.componentRef)
    }

    /**
     * Calls when we do Modal Transformation. This method is called from ModalManagerService.
     **/
    hide() {
        this.dialogInstance?.close(this.ForcedData)
        this.dialogInstance = null
    }

    /**
     * Closes and clears the MatDialogRef.
     **/
    private clear() {
        this.dialogInstance?.close()
        this.dialogInstance = null
    }
}
