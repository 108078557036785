import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    AppState,
    selectCurrentUser,
    selectCurrentUserEntity,
    selectUserEntities,
    selectUsers,
    setCurrentUser,
    setUsers,
} from '../../@ngrx'
import { CurrentUser, User } from '../../models'

@Injectable({
    providedIn: 'root',
})
export class UserFacadeService {
    constructor(private store: Store<AppState>) {}

    userEntities$ = this.store.select(selectUserEntities)

    users$ = this.store.select(selectUsers)

    currentUser$ = this.store.select(selectCurrentUser)

    currentUserEntity$ = this.store.select(selectCurrentUserEntity)

    setUsers(users: User[]) {
        this.store.dispatch(setUsers({ users }))
    }

    setCurrentUser(currentUser: CurrentUser | null) {
        this.store.dispatch(setCurrentUser({ currentUser }))
    }
}
