import { Injectable } from '@angular/core'
import { SystemResponseHandler } from '@core/models'
import { take } from 'rxjs/operators'
import {
    Folder,
    generateRecords,
    generateUpdatedSystemRecords,
    ResponseRecordEntities,
    ResponseSchema,
    Schema,
    TableModel,
} from '../models'
import { NavigatorService } from './navigator.service'
import { FolderFacadeService } from './store-facade'

@Injectable({
    providedIn: 'root',
})
export class FolderResponseHandlerService implements SystemResponseHandler<Folder> {
    constructor(
        private folderFacadeService: FolderFacadeService,
        private navigatorService: NavigatorService,
    ) {}

    initSystemRecord(
        schema: ResponseSchema,
        record: ResponseRecordEntities,
        selectedSystemRecordGuid?: string,
    ): void {
        const folders = generateRecords(schema, record) as Folder[]
        this.folderFacadeService.initFolders(folders, selectedSystemRecordGuid)
        this.folderFacadeService.foldersAreLoaded()
    }

    updateRecord(table: TableModel, schema: Schema): void {
        if (table.deleted) {
            this.folderFacadeService.selectSelectedFolderGuid$.pipe(take(1)).subscribe((guid) => {
                if (table.deleted!.find((deleted) => deleted.guid === guid)) {
                    this.navigatorService.goToViewPage()
                }
                this.folderFacadeService.deleteFoldersFromResponse(table.deleted!)
            })
        }

        if (table.updated) {
            const folders = generateUpdatedSystemRecords<Folder>(table, schema)
            this.folderFacadeService.updateFoldersFromResponse(folders)
        }

        if (table.added) {
            this.folderFacadeService.addFoldersFromResponse(
                generateRecords(schema, table.added) as Folder[],
            )
        }
    }
}
