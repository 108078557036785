import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration-type',
    templateUrl: './field-configuration-type.component.html',
})
export class FieldConfigurationTypeComponent {
    @Input() icon!: string
    @Input() customIcon!: string
    @Input() isField!: boolean
    @Input() name!: string
    @Input() description!: string
}
