<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4">
    <app-tb-button
        appearance="text"
        [color]="this.groupFieldGuid ? 'secondary' : 'primary'"
        icon="custom_group"
        [disabled]="isGroupDisabled"
        (click)="openDialog()"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </app-tb-button>

    <ng-template #buttonInside>
        <div *transloco="let tGroup; read: 'group'" class="rounded-lg subheading-2">
            <div class="flex items-center">
                <span class="flex items-center"
                    >{{tGroup('group_by_btn')}}
                    <span *ngIf="this.groupFieldGuid">:&nbsp;</span>
                    <app-field-name
                        *ngIf="groupField"
                        [field]="groupField"
                        [selectedFolder]="selectedFolder"
                        [showIcon]="false"
                        class="group-name"
                    ></app-field-name>
                </span>
            </div>
        </div>
    </ng-template>
</div>
