<h1 *transloco="let t">{{t('my_folder_title')}}</h1>

<mat-tab-group>
    <mat-tab *ngFor="let data of tableDataArr" [label]="tabLabel(data)">
        <ng-template matTabContent>
            <app-table-container
                [tableDataIn]="data"
                [currentUser]="currentUser$ | async"
            ></app-table-container>
        </ng-template>
    </mat-tab>
</mat-tab-group>
