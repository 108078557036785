<div class="edit-mode-form" *transloco="let tEmail; read: 'cell_types.email'">
    <ng-container *ngIf="!isEdit">
        <ng-container *ngIf="!disabled, else disabledContent">
            <div
                (click)="editMode();"
                *ngIf="value; else placeholder"
                class="input-label flex-start-center"
            >
                <span class="text-muted text-size-xs mr-xs"> {{tEmail('mail_to')}} </span>
                <a (click)="$event.stopPropagation()" href="mailto:{{value}}"> {{value}} </a>
            </div>
            <ng-template #placeholder>
                <app-tb-icon
                    (click)="editMode()"
                    class="text-muted input-label icon"
                    icon="remove"
                ></app-tb-icon>
            </ng-template>
        </ng-container>
        <ng-template #disabledContent>
            <div *ngIf="value; else placeholder" class="input-label flex-start-center">
                <span class="text-muted text-size-xs mr-xs"> {{tEmail('mail_to')}} </span>
                <a (click)="$event.stopPropagation()" [href]="'mailto:' + value"> {{value}} </a>
            </div>
            <ng-template #placeholder>
                <app-tb-icon class="text-muted input-label" icon="remove"></app-tb-icon>
            </ng-template>
        </ng-template>
    </ng-container>
    <div [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <input
                    (focusout)="saveValue()"
                    (keyup)="saveOnEnter($event)"
                    [appAutoFocus]="isEdit"
                    [formControl]="textFormControl"
                    autocomplete="off"
                    matInput
                    name="name"
                    type="text"
                />
                <app-tb-icon
                    (click)="saveValue()"
                    *ngIf="isEdit"
                    class="text-success"
                    icon="check_circle"
                ></app-tb-icon>
            </div>
        </mat-form-field>
    </div>
    <mat-error *ngIf="textFormControl.errors && isEdit">
        <ng-container *transloco="let tCellError; read: 'cell_types.errors'">
            {{tCellError('value_is')}}
            <strong *ngIf="textFormControl.hasError('required')">
                {{tCellError('required')}}
            </strong>
            <strong *ngIf="textFormControl.hasError('pattern')">
                {{tCellError('not_email')}}
            </strong>
        </ng-container>
    </mat-error>
</div>
