import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { environmentMode, EnvironmentMode } from '@test/dev-utils'
import { environment } from 'src/environments/environment'

@Directive({ selector: '[appEnv]' })
export class EnvDirective implements OnInit {
    @Input('appEnv') env: EnvironmentMode = 'prod'
    @Input('appEnvVisible') visible: boolean = false

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        const correctEnv = this.CorrectEnv()
        if (correctEnv) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }

    private CorrectEnv() {
        const mode = environmentMode(environment.mode)
        return (this.visible && this.env === mode) || (!this.visible && this.env !== mode)
    }
}
