<ng-container *transloco="let tAddRecordContent; read: 'add_record_content'">
    <div class="flex flex-wrap mb-3">
        <ng-container *ngFor="let folder of this.availableFolders">
            <ng-container
                [ngTemplateOutlet]="tagTemplate"
                [ngTemplateOutletContext]="{
                    guid: folder.guid,
                    color: folder.color.value,
                    content: folder.name.value
                }"
            >
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!isFolderGlobal(selectedFolder) && sharedFields.length">
            <!--TODO: change when full coloring will be added-->
            <ng-container
                [ngTemplateOutlet]="tagTemplate"
                [ngTemplateOutletContext]="{
                    guid: 'shared',
                    color: '#F9A825',
                    content: tAddRecordContent('shared')
                }"
            >
            </ng-container>
        </ng-container>

        <ng-template #tagTemplate let-guid="guid" let-color="color" let-content="content">
            <app-tb-tag
                class="mr-2"
                [style.background]="color"
                (click)="scrollToExpansionPanel(guid)"
            >
                {{ content }}
            </app-tb-tag>
        </ng-template>
    </div>

    <ng-container
        [ngTemplateOutlet]="accordionContent"
        [ngTemplateOutletContext]="getAccordionContentContext('required', requiredFields, selectedFolder)"
    ></ng-container>

    <ng-container *ngFor="let folder of this.availableFolders">
        <ng-container *ngIf="fieldsByFolder[folder.guid].length">
            <ng-container
                [ngTemplateOutlet]="accordion"
                [ngTemplateOutletContext]="{
                    id: folder.guid,
                    name: folder.name.value,
                    accordionContentContext: getAccordionContentContext(folder.guid, fieldsByFolder[folder.guid], folder)
                }"
            >
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!isFolderGlobal(selectedFolder) && sharedFields.length">
        <ng-container
            [ngTemplateOutlet]="accordion"
            [ngTemplateOutletContext]="{
                id: 'shared',
                name: tAddRecordContent('shared'),
                accordionContentContext: getAccordionContentContext('shared', sharedFields, selectedFolder)
            }"
        >
        </ng-container>
    </ng-container>

    <ng-template
        #accordion
        let-id="id"
        let-name="name"
        let-accordionContentContext="accordionContentContext"
    >
        <mat-expansion-panel class="xsm:mt-6 mt-2" [id]="id" hideToggle="true" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-primaryDark body-1">
                    <app-tb-icon
                        [icon]="expansionPanelIsExpanded(id) ? 'expand_less' : 'expand_more'"
                    ></app-tb-icon>
                    {{ name }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container
                [ngTemplateOutlet]="accordionContent"
                [ngTemplateOutletContext]="accordionContentContext"
            >
            </ng-container>
        </mat-expansion-panel>
    </ng-template>
</ng-container>
