import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../../environments/environment'

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
})
export class VersionComponent implements OnInit {
    version!: { branch?: string; commit?: string; date?: string }
    prod = environment.mode

    constructor() {}

    ngOnInit(): void {
        this.version = {
            branch: environment.version.branch,
            commit: environment.version.commit,
            date: environment.version.date,
        }
    }
}
