import { NgModule } from '@angular/core'
import { TbSelectComponent } from './components/tb-select/tb-select.component'
import { TbOptionComponent } from './components/tb-option/tb-option.component'
import { CommonModule } from '@angular/common'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { TbIconModule } from '../tb-icon/tb-icon.module'
import { ReactiveFormsModule } from '@angular/forms'
import { TbCheckboxModule } from '../tb-checkbox'
import { TbTagsModule } from '../tb-tags'

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TbIconModule,
        TbCheckboxModule,
        TbTagsModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
    declarations: [TbSelectComponent, TbOptionComponent],
    exports: [TbSelectComponent, TbOptionComponent],
})
export class TbSelectModule {}
