import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { EMPTY, shareReplay } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

interface ConfigResponse {
    env: string
    server_base_url: string
}

/**
 * NOTE: !!! do not remove /assets/config.json as it can be rewritten by developers without need to rebuild angular app
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private readonly config$ = this.httpClient.get<ConfigResponse>('/assets/config.json').pipe(
        untilDestroyed(this),
        catchError((error: any) => {
            console.log(error)
            return EMPTY
        }),
        shareReplay(),
    )

    constructor(private httpClient: HttpClient) {}

    getServerUrl() {
        return this.config$.pipe(map((config) => config.server_base_url))
    }
}
