<div class="container" *transloco="let tTable; read: 'table'">
    <h2>{{tTable('change_field_title')}}</h2>
    <mat-radio-group (change)="folderChange($event.value)">
        <div *ngFor="let option of radioButtonOptions">
            <app-tb-radio-button
                [value]="option.value"
                [checked]="option.checked ?? false"
                [label]="option.label"
            ></app-tb-radio-button>
        </div>
    </mat-radio-group>
    <div class="bottom" *transloco="let tButtons; read: 'buttons'">
        <button (click)="cancelShare()" mat-button>{{tButtons('cancel')}}</button>
        <button (click)="applyShare()" color="primary" mat-button>{{tButtons('apply')}}</button>
    </div>
</div>
