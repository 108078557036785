import { Observable } from 'rxjs'
import { ObjectResponseModel } from '../models'

type WindowLog = Window &
    typeof globalThis & {
        logRequest: () => void
    }

export interface InvocationMessage {
    invocationId: number
    // TODO: create some request model type to use here
    payload: any
}

export abstract class WsBaseService {
    protected constructor() {
        this.initWsLog()
    }

    abstract connect(): Observable<void>

    abstract disconnect(): Observable<any>

    abstract onMessage(endpoint: string): Observable<ObjectResponseModel>

    abstract sendMessage(endpoint: string, body?: any): void

    abstract invokeMessage(endpoint: string, body?: object): Observable<ObjectResponseModel>

    abstract stopConnection(): Observable<void>

    generateInvocationMessage(body?: any): InvocationMessage | undefined {
        if (!body) {
            return
        }

        return {
            invocationId: Date.now(),
            payload: body,
        }
    }

    //TODO: move to some dev service?
    private initWsLog() {
        const windowLog = <WindowLog>window
        windowLog.logRequest = this.requestWsLog.bind(this)
    }

    private requestWsLog() {
        this.sendMessage('loggingView')
    }
}
