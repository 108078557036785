import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { NotificationBannerComponent } from '@app/feature/notification-banner/notification-banner.component'
import { ResponseError } from '@models/response/response-error'
import { DataObject } from './save-data.service'

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private matSnackBar: MatSnackBar) {}

    open(dataObject: DataObject) {
        this.matSnackBar.openFromComponent(NotificationBannerComponent, {
            data: dataObject,
            duration: 1000,
        })
    }

    openErrorNotification(errors: ResponseError[]) {
        this.matSnackBar.openFromComponent(NotificationBannerComponent, {
            data: {
                errors: errors,
            },
            duration: 2000,
        })
    }
}
