import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core'
import { CellComponent } from '../../cell.component'
import { User, UserStatus } from '@core/models'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { UserFacadeService } from '@core/services/store-facade'
import { generateUser } from '@core/models/factories'
import { PeopleMenuComponent } from '../people-menu.component'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { UserAvatarComponent } from '@shared/user-avatar/user-avatar.component'
import { MatMenuModule } from '@angular/material/menu'
import { NgIf, NgFor } from '@angular/common'

@UntilDestroy()
@Component({
    selector: 'app-assign',
    templateUrl: './assign.component.html',
    styleUrls: ['./assign.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatMenuModule,
        NgFor,
        UserAvatarComponent,
        TbIconComponent,
        TranslocoModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        PeopleMenuComponent,
    ],
})
export class AssignComponent extends CellComponent implements OnInit, OnChanges {
    @Input() isGrouping!: boolean

    assignedUsers: User[] = []
    users!: User[]
    userGuids: string[] = []
    isShowAssignees = false
    isInvalidUser = false
    search!: FormControl<string | null>

    constructor(
        private userFacadeService: UserFacadeService,
        private translation: TranslocoService,
    ) {
        super()
    }

    get assignUsersLength(): number {
        return this.assignedUsers ? this.assignedUsers.length : 0
    }

    ngOnInit(): void {
        this.userFacadeService.users$.pipe(untilDestroyed(this)).subscribe((users) => {
            this.users = users
        })
        if (this.value) {
            if (this.value.includes(',')) {
                this.userGuids = this.value.split(',')
            } else {
                this.userGuids = [this.value]
            }
            this.assignedUsers = this.getAssigneeUsers(this.userGuids)
        }
        this.search = new FormControl<string>('')
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value?.firstChange) return

        const currentValue = changes?.value?.currentValue || this.value
        if (currentValue) {
            this.userGuids = currentValue.includes(',') ? currentValue.split(',') : [currentValue]
        } else {
            this.userGuids = []
        }
        this.assignedUsers = this.getAssigneeUsers(this.userGuids)
    }

    toggleUser(guid: string) {
        this.isInvalidUser = false
        if (this.userGuids.includes(guid)) {
            if (this.checkForActiveUser()) {
                this.isInvalidUser = true
                return
            }

            this.userGuids.splice(this.userGuids.indexOf(guid), 1)
            this.userUpdate()
            return
        }

        this.userGuids.push(guid)
        this.userUpdate()
    }

    getAssigneeUsers(guids: string[]) {
        const assignTranslation = this.translation.translateObject('cell_types.assign')
        return guids.map((userGuid: string) => {
            const user = this.findExistingUser(userGuid)
            if (user === undefined) {
                return generateUser(userGuid, {
                    first_name: assignTranslation['unknown'],
                    last_name: assignTranslation['user'],
                    avatar_url: '',
                    email: '',
                    role_guid: '',
                    status: UserStatus.Offline,
                })
            } else {
                return user
            }
        })
    }

    stopPropagation(event: any) {
        event.stopPropagation()
    }

    private userUpdate() {
        this.assignedUsers = this.getAssigneeUsers(this.userGuids)
        this.cellValueChanged.emit([...this.userGuids].join(','))
    }

    private checkForActiveUser() {
        const activeUsers = this.userGuids.filter((guid) => this.findExistingUser(guid))
        return activeUsers.length === 1 && this.userGuids.length > activeUsers.length
    }

    private findExistingUser(userGuid: string) {
        return this.users.find((user) => user.guid === userGuid)
    }
}
