import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbButtonModule } from '@components-library/tb-button/tb-button.module'
import { TbMenuModule } from '@components-library/tb-menu/tb-menu.module'
import { TbPickerComponent } from '@components-library/tb-picker/tb-picker.component'

@NgModule({
    declarations: [TbPickerComponent],
    imports: [CommonModule, TbMenuModule, TbButtonModule],
    exports: [TbPickerComponent],
})
export class TbPickerModule {}
