import { Component } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { PinDraftRecord } from '@app/feature/pin-panel/pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { isNonNull } from '@core/global-util'
import { Schema } from '@core/models'
import { SchemaFacadeService } from '@core/services/store-facade'
import { filter, Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbMenuComponent } from '@components-library/tb-menu/tb-menu.component'
import { NgIf, AsyncPipe } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-header-name',
    templateUrl: './header-name.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgIf,
        TbMenuComponent,
        TbButtonComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        AsyncPipe,
    ],
})
export class HeaderNameComponent {
    readonly selectedSchema$: Observable<Schema> =
        this.schemaFacadeService.selectSelectedTableSchema$.pipe(filter(isNonNull))

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    newRecord() {
        this.modalFlowManagerService
            .openDialog<AddRecordContentComponent, PinDraftRecord | null>({
                component: AddRecordContentComponent,
            })
            .pipe(take(1))
    }

    manageFields(modalManager: ModalManagerService) {
        modalManager.openDialog({ component: ManageFieldsComponent })
    }
}
