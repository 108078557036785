import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling'
import { sum } from 'lodash-es'
import { isDevMode } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { VisibleRange } from './directives/visible-range.directive'

/** Virtual scrolling strategy for lists with items of known sizes. */
export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {
    private readonly _scrolledIndexChange = new Subject<number>()

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged())

    /** @param renderedRange keeps maximum rendered range and helps render only visible items.
     *
     * [appendOnly] always renders range with start = 0, but when we use virtual-scroll in a grid it is not a performant solution.
     * For case when we rendered a grid, then we scrolled up to the right to the end and then started scrolling to the bottom,
     * default [appendOnly] would render range { start: 0, end: 40 } for each row, but in reality we need only 10 columns for example.
     * renderedRange keeps maximum rendered range and helps render only visible items.
     * */
    renderedRange: { start: number | null; end: number | null } = { start: null, end: null }

    /** If the virtual scroll is a part of grid, we can assign gridRowIndex to render columns in a rows that are in a buffered area.*/
    gridRowIndex!: number

    /** If the virtual scroll is a part of grid, gridVisibleRowRange keeps range of visible rows.*/
    gridVisibleRowRange: VisibleRange | null = null

    /** The attached viewport. */
    private _viewport: CdkVirtualScrollViewport | null = null

    /**
     * @param _sizes The size of the items in the virtually scrolling list.
     * @param _minBufferPx The minimum amount of buffer (in pixels) before needing to render more
     * @param _maxBufferPx The amount of buffer (in pixels) to render when rendering more.
     */
    constructor(
        private _sizes: number[],
        private _minBufferPx: number,
        private _maxBufferPx: number,
    ) {}

    /**
     * Attaches this scroll strategy to a viewport.
     * @param viewport The viewport to attach this strategy to.
     */
    attach(viewport: CdkVirtualScrollViewport) {
        this._viewport = viewport
        this._updateTotalContentSize()
    }

    /** Detaches this scroll strategy from the currently attached viewport. */
    detach() {
        this._scrolledIndexChange.complete()
        this._viewport = null
    }

    /**
     * Update the item size and buffer size.
     * @param _sizes The size of the items in the virtually scrolling list.
     * @param minBufferPx The minimum amount of buffer (in pixels) before needing to render more
     * @param maxBufferPx The amount of buffer (in pixels) to render when rendering more.
     */
    updateItemAndBufferSize(_sizes: number[], minBufferPx: number, maxBufferPx: number) {
        this.renderedRange = { start: null, end: null }

        if (maxBufferPx < minBufferPx && isDevMode()) {
            throw Error(
                'CDK virtual scroll: maxBufferPx must be greater than or equal to minBufferPx',
            )
        }
        this._sizes = _sizes
        this._minBufferPx = minBufferPx
        this._maxBufferPx = maxBufferPx
        this._updateTotalContentSize()
        this.updateRangeIfItIsInVisibleArea()
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onContentScrolled() {
        this.updateRangeIfItIsInVisibleArea()
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onDataLengthChanged() {
        this.renderedRange = { start: null, end: null }
        this._updateTotalContentSize()
        this._updateRenderedRange()
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onContentRendered() {
        /* no-op */
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onRenderedOffsetChanged() {
        /* no-op */
    }

    /**
     * Scroll to the offset for the given index.
     * @param index The index of the element to scroll to.
     * @param behavior The ScrollBehavior to use when scrolling.
     */
    scrollToIndex(index: number, behavior: ScrollBehavior): void {
        if (this._viewport) {
            this._viewport.scrollToOffset(this._getItemIdxByOffset(index), behavior)
        }
    }

    setVisibleRowRange(range: VisibleRange) {
        this.gridVisibleRowRange = range
    }

    private updateRangeIfItIsInVisibleArea() {
        if (
            this.gridRowIndex === undefined ||
            !this.gridVisibleRowRange ||
            (this.gridRowIndex >= this.gridVisibleRowRange.start &&
                this.gridRowIndex <= this.gridVisibleRowRange.end)
        ) {
            this._updateRenderedRange()
        }
    }

    /** Update the viewport's total content size. */
    private _updateTotalContentSize() {
        if (!this._viewport) {
            return
        }

        this._viewport.setTotalContentSize(this.getTotalViewportWidthSize())
    }

    /** Update the viewport's rendered range. */
    private _updateRenderedRange() {
        if (!this._viewport) {
            return
        }

        const renderedRange = this._viewport.getRenderedRange()
        const newRange = { start: renderedRange.start, end: renderedRange.end }
        const viewportSize = this._viewport.getViewportSize()
        const dataLength = this._viewport.getDataLength()
        let scrollOffset = this._viewport.measureScrollOffset()
        let firstVisibleIndex = this._getItemIdxByOffset(scrollOffset)

        // If user scrolls to the bottom of the list and data changes to a smaller list
        if (newRange.end > dataLength) {
            // We have to recalculate the first visible index based on new data length and viewport size.
            const newVisibleIndex = this._getItemIdxByOffset(
                viewportSize,
                firstVisibleIndex - 1,
                'down',
            )

            // If first visible index changed we must update scroll offset to handle start/end buffers
            // Current range must also be adjusted to cover the new position (bottom of new list).
            if (firstVisibleIndex != newVisibleIndex) {
                firstVisibleIndex = newVisibleIndex
                scrollOffset = this._getOffsetByItemIdx(newVisibleIndex)
                newRange.start = firstVisibleIndex
            }

            const endRange = this._getItemIdxByOffset(viewportSize, firstVisibleIndex)
            newRange.end = Math.max(0, Math.min(dataLength, endRange))
        }

        const startBuffer = scrollOffset - this._getOffsetByItemIdx(newRange.start)
        if (startBuffer < this._minBufferPx && newRange.start != 0) {
            const expandStart = this._getItemIdxByOffset(
                this._maxBufferPx - startBuffer,
                newRange.start - 1,
                'down',
            )
            newRange.start = Math.max(0, expandStart)
            newRange.end = Math.min(
                dataLength,
                this._getItemIdxByOffset(viewportSize + this._minBufferPx, firstVisibleIndex),
            )
        } else {
            const endBuffer = this._getOffsetByItemIdx(newRange.end) - (scrollOffset + viewportSize)
            if (endBuffer < this._minBufferPx && newRange.end != dataLength) {
                const expandEnd = this._getItemIdxByOffset(
                    this._maxBufferPx - endBuffer,
                    newRange.end + 1,
                )
                if (expandEnd > 0) {
                    newRange.end = Math.min(dataLength, newRange.end + expandEnd)
                    newRange.start = Math.max(
                        0,
                        this._getItemIdxByOffset(this._minBufferPx, firstVisibleIndex - 1, 'down'),
                    )
                }
            }
        }

        // todo: move this common code to somewhere else
        if (this.renderedRange.start === null || this.renderedRange.start > newRange.start) {
            this.renderedRange.start = newRange.start
        } else {
            newRange.start = this.renderedRange.start
        }

        if (this.renderedRange.end === null || this.renderedRange.end < newRange.end) {
            this.renderedRange.end = newRange.end
        } else {
            newRange.end = this.renderedRange.end
        }

        this._viewport.setRenderedRange(newRange)
        this._viewport.setRenderedContentOffset(this._getOffsetByItemIdx(newRange.start))

        this._scrolledIndexChange.next(firstVisibleIndex)
    }

    private getTotalViewportWidthSize() {
        return this.getAllSizes(this._sizes)
    }

    private getAllSizes(sizes: number[]): number {
        return sum(sizes)
    }

    private _getOffsetByItemIdx(idx: number): number {
        return this.getAllSizes(this._sizes.slice(0, idx))
    }

    private _getItemIdxByOffset(
        offset: number,
        offsetIdx: number = 0,
        dir: 'down' | 'up' = 'up',
    ): number {
        let accumOffset = 0

        for (let i = offsetIdx; i < this._sizes.length && i >= 0; dir === 'down' ? i-- : i++) {
            const msgHeight = this._sizes[i]
            accumOffset += msgHeight

            if (accumOffset >= offset) {
                return i
            }
        }

        if (accumOffset < offset && dir === 'up') {
            return this._sizes.length
        }

        return 0
    }
}
