<div
    *ngIf="!disabled, else disabledContent"
    [matMenuTriggerFor]="dropdownMenu"
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer dropdown-title_wrap"
>
    <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
    <span class="dropdown-title"> {{getTitle(value)}} </span>
</div>
<ng-template #disabledContent>
    <div
        [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
        class="c-pointer dropdown-title_wrap"
    >
        <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
        <span class="dropdown-title"> {{getTitle(value)}} </span>
    </div>
</ng-template>
<mat-menu #dropdownMenu="matMenu">
    <ng-template matMenuContent>
        <!-- change to app-tb-button after new design will be implemented -->
        <button
            *ngIf="!field.is_required"
            (click)="checkActive('')"
            class="text-center"
            mat-menu-item
        >
            <app-tb-icon class="text-muted" icon="remove"></app-tb-icon>
            <app-tb-icon *ngIf="!value" class="active-check-label" icon="check"></app-tb-icon>
        </button>
        <!-- change to app-tb-button after new design will be implemented -->
        <button
            (click)="checkActive(key)"
            *ngFor="let key of optionKeys()"
            [ngStyle]="{'background':dropdownData[key].color,'color': key |getContrastColor}"
            class="text-center"
            mat-menu-item
        >
            {{dropdownData[key].label}}
            <mat-icon [ngStyle]="{'color': key |getContrastColor}" class="dropdown-icon"
                >{{dropdownData[key].icon}}</mat-icon
            >
            <mat-icon
                *ngIf="key === value"
                [ngStyle]="{'color': key |getContrastColor}"
                class="active-check-label"
                >check
            </mat-icon>
            <mat-icon
                *ngIf="key === default"
                [ngStyle]="{'color': key |getContrastColor}"
                class="active-check-label"
                >ac_unit
            </mat-icon>
        </button>
    </ng-template>
</mat-menu>
