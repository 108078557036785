// These colors are just Tailwind classes without prefixes. Use them when you need to concat it with some specific prefix like text-* or bg-*, as in the <app-tb-button>.

export enum Palettes {
    primary = 'primary',
    secondary = 'secondary',
    text = 'text',
}

export enum Colors {
    neutral3 = 'neutral3',
    neutral4 = 'neutral4',
    darkThemePrimaryDark = 'newDarkThemePrimaryDark',
    darkThemeDisabled = 'newDarkThemeDisabled',
}
