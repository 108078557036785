import { Injectable } from '@angular/core'
import { throwAndLogError } from '@core/global-util'
import { ViewCreateModel, ViewDeleteModel, ViewUpdateModel } from '@core/models'
import { FolderFacadeService } from '@core/services/store-facade/folder-facade.service'
import { RecordFacadeService } from '@core/services/store-facade/record-facade.service'
import { SchemaFacadeService } from '@core/services/store-facade/schema-facade.service'
import { Dictionary, Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { LogService } from '@services/log.service'
import { UpdateService } from '@services/update.service'
import { size, pickBy } from 'lodash-es'
import { combineLatest, Observable, switchMap } from 'rxjs'
import { map, take } from 'rxjs/operators'
import {
    addViewsEntities,
    AppState,
    deleteViewsEntities,
    initViews,
    openViewFilterMenu,
    selectAllViews,
    selectAvailableViewTypes,
    selectSelectedTableSchemaViews,
    selectSelectedView,
    selectSelectedViewGuid,
    selectViewEntities,
    selectViewsAreLoaded,
    setSelectedViewGuid,
    updateViewsEntities,
    viewsAreLoaded,
} from '../../@ngrx'
import {
    AccessModel,
    CreateViewConfiguration,
    Deleted,
    getSystemSotRequestConfiguration,
    View,
} from '../../models'

@Injectable({
    providedIn: 'root',
})
export class ViewFacadeService {
    viewEntities$ = this.store.select(selectViewEntities)

    allViews$ = this.store.select(selectAllViews)

    viewsAreLoaded$ = this.store.select(selectViewsAreLoaded)

    selectedView$ = this.store.select(selectSelectedView)

    selectedViewGuid$ = this.store.select(selectSelectedViewGuid)

    availableViewTypes$ = this.store.select(selectAvailableViewTypes)

    selectedTableSchemaViews$ = this.store.select(selectSelectedTableSchemaViews)

    constructor(
        private store: Store<AppState>,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private updateService: UpdateService,
        private logService: LogService,
    ) {}

    viewsAreLoaded() {
        this.store.dispatch(viewsAreLoaded())
    }

    openViewFilterMenu() {
        this.store.dispatch(openViewFilterMenu())
    }

    setSelectedViewGuid(selectedViewGuid: string | null) {
        this.store.dispatch(setSelectedViewGuid({ selectedViewGuid }))
    }

    initViews(views: View[], selectedViewGuid?: string) {
        this.store.dispatch(initViews({ views, selectedViewGuid }))
    }

    addViewsEntities(views: View[]) {
        this.store.dispatch(addViewsEntities({ views }))
    }

    updateViewsEntities(views: Update<View>[]) {
        this.store.dispatch(updateViewsEntities({ views }))
    }

    deleteViewsEntities(views: Deleted[]) {
        this.store.dispatch(deleteViewsEntities({ views }))
    }

    createViewRequest(configuration: CreateViewConfiguration, access?: AccessModel) {
        return combineLatest([
            this.folderFacadeService.selectSelectedFolderGuid$,
            this.schemaFacadeService.selectSelectedTableSchemaGuid$,
        ]).pipe(
            take(1),
            switchMap(([selectedFolderGuid, selectedSchemaGuid]) => {
                if (!selectedSchemaGuid)
                    return throwAndLogError(this.logService, 'No table or folder selected!')

                const model: ViewCreateModel = {
                    parent_sot_guid: selectedSchemaGuid,
                    folder_guid: selectedFolderGuid,
                    access,
                    configuration: getSystemSotRequestConfiguration(configuration),
                }

                return this.updateService.createView(model)
            }),
        )
    }

    updateViewRequest(view: View, changes: Partial<CreateViewConfiguration>, access?: AccessModel) {
        return this.cleanUpOutdatedViewData(view, changes).pipe(
            take(1),
            switchMap((updateChanges) => {
                if (!view.folder_guids)
                    return throwAndLogError(this.logService, 'No folder guids in view!')

                const model: ViewUpdateModel = {
                    record_guid: view.guid,
                    record_revision: view.revision,
                    configuration: getSystemSotRequestConfiguration(updateChanges),
                    access,
                    folder_guid: view.folder_guids,
                }

                return this.updateService.updateView(model)
            }),
        )
    }

    deleteViewRequest(view: View) {
        //TODO: move reset of selected items from current logic flow to navigation. they shouldn't be related
        return this.selectedView$.pipe(
            take(1),
            switchMap((selectedView) => {
                const model: ViewDeleteModel = {
                    record_guid: view.guid,
                    record_revision: view.revision,
                }

                if (selectedView?.guid === view.guid) {
                    this.setSelectedViewGuid(null)
                }

                return this.updateService.deleteView(model)
            }),
        )
    }

    private cleanUpOutdatedViewData(
        view: View,
        changes: Partial<CreateViewConfiguration>,
    ): Observable<Partial<CreateViewConfiguration>> {
        return this.schemaFacadeService.selectSelectedTableSchema$.pipe(
            take(1),
            map((schema) => {
                if (!schema) return changes
                const schemaFieldGuids = Object.keys(schema.fieldEntities)
                const { columnsWidth, hiddenColumns, pinnedColumns, columnsOrder } =
                    this.recordFacadeService.parseViewFields(view)

                const cleanedChanges: Partial<CreateViewConfiguration> = {}

                cleanedChanges.columns_width = this.updateOutdatedColumnWidth(
                    columnsWidth,
                    schemaFieldGuids,
                    changes,
                    view,
                )

                cleanedChanges.columns_hide = this.updateOutdatedViewDataArrays(
                    hiddenColumns,
                    'columns_hide',
                    schemaFieldGuids,
                    changes,
                    view,
                )

                cleanedChanges.columns_pinned = this.updateOutdatedViewDataArrays(
                    pinnedColumns,
                    'columns_pinned',
                    schemaFieldGuids,
                    changes,
                    view,
                )

                cleanedChanges.columns_order = this.updateOutdatedViewDataArrays(
                    columnsOrder,
                    'columns_order',
                    schemaFieldGuids,
                    changes,
                    view,
                )

                return { ...changes, ...cleanedChanges }
            }),
        )
    }

    private updateOutdatedViewDataArrays(
        viewFields: string[],
        key: keyof Partial<CreateViewConfiguration>,
        schemaFieldGuids: string[],
        changes: Partial<CreateViewConfiguration>,
        view: View,
    ) {
        const currentColumns = changes[key]?.newValue?.split(',') || viewFields
        const columnsWithoutOutdated = currentColumns.filter((fieldGuid) =>
            schemaFieldGuids.includes(fieldGuid),
        )
        // if changes[key] === true, then it function works on update and should return update object anyway
        // otherwise it was called only to cleanup
        if (size(columnsWithoutOutdated) === size(currentColumns) && !changes[key]) return

        return {
            cell: view[key],
            newValue: columnsWithoutOutdated.join(','),
        }
    }

    private updateOutdatedColumnWidth(
        columnsWidth: { [p: string]: number } | undefined,
        schemaFiledGuids: string[],
        changes: Partial<CreateViewConfiguration>,
        view: View,
    ) {
        const currentColumnsWidth = changes.columns_width?.newValue
            ? JSON.parse(changes.columns_width.newValue)
            : columnsWidth || {}

        const columnsWithoutOutdated = pickBy(currentColumnsWidth, (_value, fieldGuid) => {
            return schemaFiledGuids.includes(fieldGuid)
        })
        // if changes.columns_width === true, then it function works on update and should return update object anyway
        // otherwise it was called only to cleanup
        if (size(currentColumnsWidth) === size(columnsWithoutOutdated) && !changes.columns_width)
            return

        return {
            cell: view.columns_width,
            newValue: this.stringifyColumnsWidths(columnsWithoutOutdated),
        }
    }

    private stringifyColumnsWidths(columnsWidths: Dictionary<number>) {
        const hasKeys = !!Object.keys(columnsWidths).length

        return hasKeys ? JSON.stringify(columnsWidths) : ''
    }
}
