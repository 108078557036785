import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { createFeature, createReducer, on } from '@ngrx/store'
import * as subtaskActions from './subtask.actions'
import { SubtaskRecord } from '../../models/ui/subtask-record.model'
import { dataReset } from '../common/common.actions'

export const subtaskFeatureKey = 'SUBTASK'

export interface SubtaskState extends EntityState<SubtaskRecord> {}

export function selectSubtaskId(subtask: SubtaskRecord) {
    return subtask.guid
}

export const subtaskAdapter: EntityAdapter<SubtaskRecord> = createEntityAdapter<SubtaskRecord>({
    selectId: selectSubtaskId,
})

export const initialState = subtaskAdapter.getInitialState()

export const subtaskReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(subtaskActions.setSubtasks, (state, { subtasks }) =>
        subtaskAdapter.addMany(subtasks, { ...state }),
    ),

    on(subtaskActions.resetSubtasks, (state) => subtaskAdapter.removeAll({ ...state })),

    on(subtaskActions.addSubtasksFromResponse, (state, { subtasks }) =>
        subtaskAdapter.addMany(subtasks, { ...state }),
    ),

    on(subtaskActions.updateSubtasksFromResponse, (state, { subtasks }) =>
        subtaskAdapter.updateMany(subtasks, { ...state }),
    ),

    on(subtaskActions.deleteSubtasksFromResponse, (state, { subtasks }) =>
        subtaskAdapter.removeMany(
            subtasks.map((subtask) => subtask.guid),
            { ...state },
        ),
    ),
)

export const subtaskFeature = createFeature({
    name: subtaskFeatureKey,
    reducer: subtaskReducer,
})
