import { Component } from '@angular/core'
import {
    FieldTypeSelectorComponent,
    FieldTypeSelectorData,
} from '@app/shared/field-type-selector/field-type-selector.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { Field } from '@models/ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration',
    templateUrl: './field-configuration.component.html',
})
export class FieldConfigurationComponent extends ModalContainerComponent {
    addField() {
        this.modalManagerService
            .openDialog<FieldTypeSelectorComponent, FieldTypeSelectorData, Field | undefined>({
                component: FieldTypeSelectorComponent,
                data: { newFolder: true },
            })
            .subscribe((field) => {
                //TODO: use field
                console.log(field)
            })
    }
}
