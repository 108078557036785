import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { Deleted, View } from '../../models'
import { ViewUpdateModel } from '@core/models'

export const initViews = createAction(
    '[View] Init views',
    props<{ views: View[]; selectedViewGuid?: string }>(),
)

export const viewsAreLoaded = createAction('[View] Views are loaded')

export const openViewFilterMenu = createAction('[View] Open filter menu')

export const setSelectedViewGuid = createAction(
    '[View] Set selected view',
    props<{ selectedViewGuid: string | null }>(),
)

export const addViewsEntities = createAction('[View] Add views', props<{ views: View[] }>())

export const updateViewsEntities = createAction(
    '[View] Update views',
    props<{ views: Update<View>[] }>(),
)

export const deleteViewsEntities = createAction(
    '[View] Delete views',
    props<{ views: Deleted[] }>(),
)

export const updateViewRequest = createAction(
    '[Update View Request] UPDATE_VIEW',
    props<{ model: ViewUpdateModel }>(),
)
