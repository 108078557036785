import { setGroups } from './group.actions'
import { StoreFeatureNames } from '../store-feature-names'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Group } from '@core/models/ui/group.model'
import { createFeature, createReducer, on } from '@ngrx/store'
import { dataReset } from '../common/common.actions'

const groupFeatureKey = StoreFeatureNames.Group

export interface GroupState extends EntityState<Group> {}

function selectGroupId(group: Group) {
    return group.guid
}

export const groupAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({
    selectId: selectGroupId,
})

const initialState: GroupState = groupAdapter.getInitialState({})

const groupReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(setGroups, (state: GroupState, { groups }) => {
        return groupAdapter.setMany(groups, { ...state })
    }),
)

export const groupFeature = createFeature({
    name: groupFeatureKey,
    reducer: groupReducer,
})
