import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SystemRecordsErrorService } from '@core/services/system-records-error.service'
import { WsProcessingService } from '@services/ws-processing.service'
import { ErrorDialogComponent } from './error-dialog/error-dialog.component'

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.sass'],
})
export class ErrorPageComponent implements OnInit {
    systemRecordsErrorMessages$ = this.systemRecordsErrorService.getApplicationErrorMessages()

    constructor(
        private systemRecordsErrorService: SystemRecordsErrorService,
        private dialog: MatDialog,
        private wsProcessingService: WsProcessingService,
    ) {}

    ngOnInit() {
        this.systemRecordsErrorMessages$.subscribe((entities) => {
            const userErrorEntity = entities.find((entity) => entity.type === 'user')
            if (userErrorEntity) {
                this.openUserErrorDialog(userErrorEntity.message)
            }
        })
    }

    openUserErrorDialog(message: string) {
        this.dialog
            .open<ErrorDialogComponent, { message: string }>(ErrorDialogComponent, {
                width: '300px',
                height: '100px',
                data: { message },
            })
            .afterClosed()
            .subscribe(() => {
                this.wsProcessingService.logout()
            })
    }
}
