<div class="flex-start-center">
    <ng-container *ngIf="!disabled, else disabledContent">
        <mat-icon
            (click)="setRating(reit)"
            (mouseenter)="changeHoverStyles($event, reit)"
            (mouseleave)="backHoverStyles()"
            *ngFor="let reit of countStarsInArray"
            [class.hoverStar]="(selectValue != undefined)&&(reit <= selectValue)||(hoverValue != undefined)&&(reit <= hoverValue)"
            class="star-item"
        >
            star
        </mat-icon>
    </ng-container>
    <ng-template #disabledContent>
        <mat-icon
            *ngFor="let reit of countStarsInArray"
            [class.hoverStar]="(selectValue != undefined)&&(reit <= selectValue)||(hoverValue != undefined)&&(reit <= hoverValue)"
            class="star-item"
        >
            star
        </mat-icon>
    </ng-template>
</div>
