import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { BaseContainer } from './base-container'
import { ComponentType } from '@angular/cdk/overlay'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { DisplaySize } from '../../services/breakpoint.service'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'

/**
 * DialogContainer is a Modal Container instance for Dialogs.
 **/
export class DialogContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> extends BaseContainer<T, ContainerData, Result> {
    constructor(
        modalManagerService: ModalManagerService,
        modalContainerFactoryService: ModalContainerFactoryService,
        component: ComponentType<T>,
        data?: ContainerData,
    ) {
        super(modalManagerService, modalContainerFactoryService, component, data)
    }

    /**
     * Check [BaseContainer.displaySizeToRelatedContainer]{@link BaseContainer#displaySizeToRelatedContainer}
     **/
    displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer {
        return ModalContainer.Dialog
    }
}
