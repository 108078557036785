import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ExpandMods, MenuItem, SideNavigationService } from './side-navigation.service'
import { MatSidenavContainer } from '@angular/material/sidenav'

@UntilDestroy()
@Component({
    selector: 'app-tb-side-navigation-layout',
    templateUrl: './tb-side-navigation-layout.component.html',
    styleUrls: ['tb-side-navigation-scroller.sass'],
})
export class TbSideNavigationLayoutComponent implements OnInit {
    @Input() menuItems!: MenuItem[]
    @ViewChild(MatSidenavContainer) matSidenavContainer!: MatSidenavContainer

    state$ = this.sideNavigationService.state$
    expandMods = ExpandMods
    hideMenuItemNames = false

    constructor(private sideNavigationService: SideNavigationService) {}

    ngOnInit(): void {
        this.sideNavigationService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
            this.hideMenuItemNames = value.expand === ExpandMods.COLLAPSED
        })
    }

    collapse() {
        this.sideNavigationService.setOpen(false)
    }

    expand() {
        this.sideNavigationService.setOpen(true)
    }

    onMatSidenavOpenedChange(newMatState: boolean) {
        if (newMatState === this.sideNavigationService.isOpened) {
            return
        }

        this.sideNavigationService.setOpen(newMatState)

        this.sideNavigationService.isOpened
            ? this.matSidenavContainer.open()
            : this.matSidenavContainer.close()
    }
}
