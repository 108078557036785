import { createFeatureSelector } from '@ngrx/store'
import { StoreFeatureNames } from '../store-feature-names'
import { groupAdapter, GroupState } from './group.reducer'

const selectGroupState = createFeatureSelector<GroupState>(StoreFeatureNames.Group)

const { selectIds, selectEntities, selectAll, selectTotal } =
    groupAdapter.getSelectors(selectGroupState)

export const selectGroupIds = selectIds
export const selectGroupEntities = selectEntities
export const selectAllGroups = selectAll
export const selectGroupTotal = selectTotal
