import { environment } from 'src/environments/environment'

export enum DevKeys {
    Mode = 'mode',
    SolutionType = 'solutionType',
}

export type EnvironmentMode = 'prod' | 'dev' | 'mock'
export type SolutionType = 'TM' | 'CRM'

export function environmentMode(env: string): EnvironmentMode | undefined {
    const mode = env as EnvironmentMode

    if (mode === 'mock' || mode === 'prod' || mode === 'dev') {
        return mode
    }

    return
}

export function isProd() {
    return checkEnvironmentMode('prod')
}

export function isDev() {
    return checkEnvironmentMode('dev')
}

export function isMock() {
    return checkEnvironmentMode('mock')
}

function checkEnvironmentMode(mode: EnvironmentMode): boolean {
    return environmentMode(environment.mode) === mode
}
