import { createFeatureSelector, createSelector } from '@ngrx/store'
import { userAdapter, UserState } from './user.state'
import { AppState } from '../app.state'
import { CurrentUser, User } from '../../models'
import { Dictionary } from '@ngrx/entity'
import { StoreFeatureNames } from '../store-feature-names'

export const selectUsersState = createFeatureSelector<UserState>(StoreFeatureNames.Users)

export const { selectIds, selectEntities, selectAll } = userAdapter.getSelectors()

export const selectUserEntities = createSelector<AppState, [UserState], Dictionary<User>>(
    selectUsersState,
    (state: UserState) => selectEntities(state),
)

export const selectUsers = createSelector<AppState, [UserState], User[]>(
    selectUsersState,
    (state) => selectAll(state),
)

export const selectCurrentUser = createSelector<AppState, [UserState], CurrentUser | null>(
    selectUsersState,
    (state: UserState) => {
        return state.currentUser
    },
)

export const selectCurrentUserEntity = createSelector<AppState, [UserState], User | null>(
    selectUsersState,
    (state: UserState) => {
        const currentUserGuid = state.currentUser?.guid as string

        return state.entities[currentUserGuid] || null
    },
)
