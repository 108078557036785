import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { StoreModule } from '@ngrx/store'
import { fieldTypeFeature } from './field-type.reducer'

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature(fieldTypeFeature)],
})
export class FieldTypeStoreModule {}
