import { Component, Input } from '@angular/core'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    standalone: true,
    imports: [NgIf],
})
export class UserAvatarComponent {
    @Input()
    userUrl!: string | undefined

    @Input()
    userName!: string
}
