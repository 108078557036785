export interface UpdateLinks {
    solution_object_type_guid: string
    records: UpdateLinkModel[]
}

export interface UpdateLinkModel {
    record_guid: string
    record_revision: number
    object_field_guid: string
    revision: number
    add_guids?: string[]
    delete_guids?: string[]
}
