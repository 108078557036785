import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl, ValidatorFn, Validators } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-text-multi-line',
    templateUrl: './text-multi-line.component.html',
    styleUrls: ['./text-multi-line.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextMultiLineComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    ngOnInit() {
        if (this.field.is_required) {
            this.validatorOrOpts = [Validators.required]
        }

        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            {
                validators: this.validatorOrOpts as ValidatorFn[],
                updateOn: 'blur',
            },
        )
        this.textFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.saveValue())
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.value === this.textFormControl.value || this.textFormControl.invalid) return

        this.cellValueChanged.emit(this.textFormControl.value)
    }
}
