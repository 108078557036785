import { Component, Input } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    FieldEntities,
    FieldType,
    isContact,
    isDeal,
    isVendor,
    ObjectTypes,
    RecordUpdate,
    Schema,
} from '@core/models'
import { Dictionary } from '@ngrx/entity'
import { RecordsService } from '@core/services/records.service'

@Component({
    selector: 'app-card-main-fields',
    templateUrl: './card-main-fields.component.html',
    styleUrls: ['./card-main-fields.component.sass'],
})
export class CardMainFieldsComponent {
    @Input()
    record!: BusinessRecords

    @Input()
    fields!: FieldEntities

    @Input()
    schema!: Schema

    @Input()
    fieldTypes!: Dictionary<FieldType>

    ObjectTypes = ObjectTypes

    isContact = isContact
    isVendor = isVendor
    isDeal = isDeal

    constructor(private recordsService: RecordsService) {}

    updateRecord(data: AppRecord, cell: Cell, newValue: any) {
        const dataRecord: RecordUpdate = {
            record: data,
            cell: cell,
            value: newValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }

    doesRecordFieldExist(cell: Cell) {
        return cell && this.fields[cell.fieldGuid]
    }
}
