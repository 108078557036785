<div
    (cdkDropListDropped)="dropRow($event)"
    [cdkDropListData]="rows"
    cdkDropList
    cdkDropListOrientation="vertical"
    *ngIf="parentScrollableElement; else tableAsScrollable"
>
    <ng-container #tableRenderContainer></ng-container>
</div>

<ng-template #tableAsScrollable>
    <div
        (cdkDropListDropped)="dropRow($event)"
        [cdkDropListData]="rows"
        cdkDropList
        cdkDropListOrientation="vertical"
        cdkVirtualScrollingElement
    >
        <ng-container #tableRenderContainer></ng-container>
    </div>
</ng-template>

<ng-template #tableContent>
    <div class="rows">
        <div
            (cdkDropListDropped)="dropColumnFn($event)"
            [cdkDropListData]="draggableColumns"
            cdkDropList
            cdkDropListOrientation="horizontal"
            class="row"
        >
            <div
                *ngFor="let header of fixedColumns; let colIndex = index"
                [ngStyle]="{
                    'width.px': header.colWidth,
                    'left.px': colIndex === 0 ? 0 : fixedColumns[colIndex - 1].colWidth
                }"
                appResizeColumn
                [columnIndex]="'fixed' + colIndex"
                (resizedWidth)="resizeTableColumn(header, $event)"
                class="column ml-[5px]"
            >
                <div class="flex items-center justify-between w-full">
                    <mat-icon class="flex" *ngIf="header.isPinned">push_pin</mat-icon>
                    <div
                        [ngClass]="{
                            'w-[calc(100%-70px)]': !header.isPinned,
                            'w-50': header.isPinned
                        }"
                        class="flex items-center"
                    >
                        <ng-container
                            *ngTemplateOutlet="columnCell; context: { $implicit: header }"
                        ></ng-container>
                    </div>
                    <div class="resizer" data-resizer></div>
                </div>
            </div>
            <cdk-virtual-scroll-viewport
                #headersScroll
                class="column-container"
                [style.width]="draggableContainerWidth"
                orientation="horizontal"
                appDynamicSize
                minBufferPx="400"
                maxBufferPx="600"
                [sizes]="columnWidths"
            >
                <div
                    *cdkVirtualFor="let header of draggableColumns; templateCacheSize: 0; let thIndex = index; trackBy: trackByFn"
                    appResizeColumn
                    [columnIndex]="'draggable' + thIndex"
                    (resizedWidth)="resizeTableColumn(header, $event)"
                    class="flex-col column"
                    [ngStyle]="{
                        'width.px': header.colWidth,
                    }"
                >
                    <div class="flex items-center justify-between w-full">
                        <div
                            cdkDrag
                            cdkDragLockAxis="x"
                            cdkDragBoundary=".column-container"
                            class="grow flex"
                        >
                            <div class="flex items-center" [class.mt-6]="header.colGroup">
                                <mat-icon>drag_indicator</mat-icon>
                            </div>
                            <div class="text-center flex items-center w-[calc(100%-70px)]">
                                <div *cdkDragPlaceholder></div>
                                <div class="flex items-center w-full">
                                    <ng-container
                                        *ngTemplateOutlet="columnCell; context: { $implicit: header }"
                                    ></ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="resizer" data-resizer [class.mt-6]="header.colGroup"></div>
                    </div>
                    <ng-container *ngIf="header.colGroup">
                        <div class="w-full flex flex-row justify-around bottom-[8px]">
                            <div class="flex" *ngFor="let header of header.colGroup.subheaders">
                                <div class="flex">{{ header }}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <ng-container #rowsContainer></ng-container>
    </div>
</ng-template>

<ng-template #rowsWithoutVerticalScroll>
    <div class="flex flex-col">
        <div
            class="row"
            [attr.data-id]="rowIndex"
            *ngFor="let row of rows; let rowIndex = index; trackBy: trackByFn"
            [cdkDragData]="row"
            cdkDrag
        >
            <div
                *ngFor="let header of fixedColumns; let cellIndex = index"
                appResizeColumn
                [columnIndex]="'fixed' + cellIndex"
                [ngStyle]="{
                        'width.px': header.colWidth,
                        'left.px': cellIndex === 0 ? 0 : fixedColumns[cellIndex - 1].colWidth
                    }"
                class="column"
            >
                <ng-container>
                    <mat-checkbox
                        *ngIf="!header.isPinned"
                        (change)="selectRow(row)"
                        [checked]="isRowSelected(row)"
                    ></mat-checkbox>
                    <mat-icon *ngIf="isGrouped && !header.isPinned" class="text-muted">
                        drag_indicator
                    </mat-icon>
                    <ng-container
                        *ngTemplateOutlet="rowCell; context: { header: header, row }"
                    ></ng-container>
                </ng-container>
            </div>
            <cdk-virtual-scroll-viewport
                class="column-container"
                orientation="horizontal"
                appDynamicSize
                minBufferPx="400"
                maxBufferPx="600"
                [sizes]="columnWidths"
                [style.width]="draggableContainerWidth"
            >
                <div
                    class="column"
                    *cdkVirtualFor="let header of draggableColumns; templateCacheSize: 0; trackBy: trackByFn; let cellIndex = index"
                    appResizeColumn
                    [columnIndex]="'draggable' + cellIndex"
                    [ngStyle]="{
                        'width.px': header.colWidth,
                    }"
                >
                    <ng-container>
                        <ng-container
                            *ngTemplateOutlet="rowCell; context: { header, row }"
                        ></ng-container>
                    </ng-container>
                </div>
                <div class="column">
                    <ng-container
                        *ngTemplateOutlet="rowMenu; context: { $implicit: row }"
                    ></ng-container>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-template>

<ng-template #rowsWithVerticalScroll>
    <cdk-virtual-scroll-viewport
        #virtualRows
        class="virtual-rows"
        appItemSize="48"
        appVisibleRange
        maxBufferPx="192"
        #visibleRange="visibleRange"
        *ngIf="rows.length"
    >
        <div
            class="row"
            [attr.data-id]="rowIndex"
            *cdkVirtualFor="let row of rows;  templateCacheSize: 0; let rowIndex = index; trackBy: trackByFn"
            [cdkDragData]="row"
            cdkDrag
        >
            <div
                *ngFor="let header of fixedColumns; let cellIndex = index"
                [ngStyle]="{
                        'width.px': header.colWidth,
                        'left.px': cellIndex === 0 ? 0 : fixedColumns[cellIndex - 1].colWidth
                    }"
                class="column"
            >
                <ng-container>
                    <mat-checkbox
                        *ngIf="!header.isPinned"
                        (change)="selectRow(row)"
                        [checked]="isRowSelected(row)"
                    ></mat-checkbox>
                    <mat-icon *ngIf="isGrouped && !header.isPinned" class="text-muted">
                        drag_indicator
                    </mat-icon>
                    <ng-container
                        *ngTemplateOutlet="rowCell; context: { header: header, row }"
                    ></ng-container>
                </ng-container>
            </div>
            <cdk-virtual-scroll-viewport
                class="column-container"
                orientation="horizontal"
                appDynamicSize
                minBufferPx="400"
                maxBufferPx="600"
                [visibleRange]="visibleRange.range"
                [rowindex]="rowIndex"
                [sizes]="columnWidths"
                [style.width]="draggableContainerWidth"
            >
                <div
                    class="column"
                    *cdkVirtualFor="let header of draggableColumns; templateCacheSize: 0; trackBy: trackByFn"
                    [ngStyle]="{
                        'width.px': header.colWidth,
                    }"
                >
                    <ng-container>
                        <ng-container
                            *ngTemplateOutlet="rowCell; context: { header, row }"
                        ></ng-container>
                    </ng-container>
                </div>
                <div class="column">
                    <ng-container
                        *ngTemplateOutlet="rowMenu; context: { $implicit: row }"
                    ></ng-container>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-template>
