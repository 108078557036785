<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark ml-4"
>
    <div
        [matMenuTriggerFor]="showMenu"
        [class]="{'bg-white text-primaryDark': trigger.menuOpen}"
        #trigger="matMenuTrigger"
        class="flex-start-center h-100 c-pointer sm:p-1 p-2 pr-2 rounded-lg subheading-2"
        *transloco="let tShow; read: 'show'"
    >
        <div class="flex items-center text-accentContrastLight">
            <mat-icon class="w-5 h-5 text-xl leading-5 mr-2" svgIcon="custom_settings"></mat-icon>
            <span class="">{{ tShow('show_title') }}</span>
        </div>
    </div>

    <mat-menu #showMenu="matMenu" class="show-by">
        <div class="position-relative h-100">
            <ng-template matMenuContent>
                <div class="text-muted mb-0 p-l-r-sm mt-md subheading-1">VISIBLE FIELDS</div>
                <ng-container *ngFor="let fieldGuid of visibleFields">
                    <app-tb-button
                        *ngIf="fields[fieldGuid]!.field_type_code !== 'field_type_name'"
                        [appearance]="'text'"
                        [icon]="'done'"
                        (click)="changeFieldState(fieldGuid, false)"
                    >
                        <app-field-name
                            *ngIf="fields[fieldGuid] && selectedFolder"
                            [field]="fields[fieldGuid]"
                            [selectedFolder]="selectedFolder"
                            [showIcon]="true"
                        ></app-field-name>
                    </app-tb-button>
                </ng-container>
                <div *ngIf="hiddenFields" class="hr-row"></div>
                <div *ngIf="hiddenFields" class="text-muted mb-0 p-l-r-sm mt-md subheading-1">
                    FIELDS IN THE SPACE
                </div>
                <app-tb-button
                    [appearance]="'text'"
                    (click)="changeFieldState(fieldGuid, true)"
                    *ngFor="let fieldGuid of hiddenFields"
                >
                    <app-field-name
                        [field]="fields[fieldGuid]!"
                        [selectedFolder]="selectedFolder!"
                        [showIcon]="true"
                    ></app-field-name>
                </app-tb-button>
            </ng-template>
        </div>
    </mat-menu>
</div>
