<div class="flex" [class]="[colorCss]">
    <ng-container [ngSwitch]="style">
        <ng-container *ngSwitchCase="'rounded'">
            <mat-icon [class]="filled ? 'filled' : 'non-filled'"> {{ icon }} </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'svg'">
            <mat-icon [svgIcon]="icon"></mat-icon>
        </ng-container>
    </ng-container>
</div>
