import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl } from '@angular/forms'
import { MoneyConfig } from '@core/models'

@Component({
    selector: 'app-money',
    templateUrl: './money.component.html',
    styleUrls: ['./money.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyComponent extends CellComponent implements OnInit {
    numberFormControl!: FormControl<string | null>
    currency!: string
    currencySymbol!: string
    isEdit: boolean = false

    ngOnInit(): void {
        if (this.field.configure_json) {
            const MoneyConfig: MoneyConfig = JSON.parse(this.field.configure_json)
            if (MoneyConfig) {
                this.currency = MoneyConfig.currency
                this.currencySymbol = MoneyConfig.currencySymbol
            }
        }

        this.numberFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            this.validatorOrOpts,
        )
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false
        if (this.value != this.numberFormControl.value) {
            this.cellValueChanged.emit(this.numberFormControl.value)
        }
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }
}
