import { BaseContainer } from './base-container'
import { ComponentType } from '@angular/cdk/overlay'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { DisplaySize } from '../../services/breakpoint.service'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'
import { MenuInstanceService } from '../modal-instances/menu-instance'
import { TemplateRef } from '@angular/core'

/**
 * MenuContainer is a Modal Container instance for Menus.
 **/
export class MenuContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> extends BaseContainer<T, ContainerData, Result> {
    constructor(
        modalManagerService: ModalManagerService,
        public menuInstanceService: MenuInstanceService,
        modalContainerFactoryService: ModalContainerFactoryService,
        component: ComponentType<T>,
        /**
         * Menu differs from Dialog by ability to pass template.
         **/
        template: TemplateRef<any> | null,
        data?: ContainerData,
    ) {
        super(modalManagerService, modalContainerFactoryService, component, data)

        if (!template) return

        /**
         *  When we import TemplateContainerComponent in the file, it causes circular dependency, temp solution is using "import()"
         **/
        import('../template-container/template-container.component').then(
            ({ TemplateContainerComponent }) => {
                if (this.componentRef.instance instanceof TemplateContainerComponent) {
                    this.componentRef.instance.template = template
                }
            },
        )
    }

    /**
     * Check [BaseContainer.displaySizeToRelatedContainer]{@link BaseContainer#displaySizeToRelatedContainer}
     **/
    displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer {
        if (displaySize === DisplaySize.XSmall || displaySize === DisplaySize.Small) {
            return ModalContainer.BottomSheet
        }
        return ModalContainer.Menu
    }

    destroy() {
        this.menuInstanceService.destroy()
        /**
         * We delay component destruction to prevent showing empty container because MatMenu has opacity transition when closing
         **/
        setTimeout(() => {
            super.destroy()
        }, 300)
    }
}
