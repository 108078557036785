<div
    *ngIf="!isField"
    [class]="typeCheck.value ? 'border-secondaryMainLight shadow-none' : 'border-transparent'"
    class="relative flex justify-between items-center xsm:p-4 p-2 rounded-2xl border-2 border-solid shadow-light"
>
    <div class="flex items-center">
        <mat-icon
            *ngIf="customIcon && !icon"
            svgIcon="{{customIcon}}"
            class="h-10 w-10 mr-2 text-primarySecondary"
        ></mat-icon>
        <mat-icon
            *ngIf="!customIcon && icon"
            class="h-10 w-10 text-4xl leading-10 mr-2 text-primarySecondary"
            >{{icon}}</mat-icon
        >
        <div>
            <div class="caption-1 text-primarySecondary">{{description}}</div>
            <div>{{name}}</div>
        </div>
    </div>
    <div class="absolute top-[8px] right-[8px] flex items-center gap-2">
        <!-- In this case we need either ability to change hover or remove it to use hover from passed mat-icon -->
        <app-tb-button class="h-4" [appearance]="'icon'">
            <mat-icon
                svgIcon="custom_settings"
                class="h-4 w-4 text-secondaryMainLight hover:text-secondaryMain"
            ></mat-icon>
        </app-tb-button>
        <app-tb-checkbox class="flex" [size]="'small'" #typeCheck></app-tb-checkbox>
    </div>
</div>

<div
    *ngIf="isField"
    class="relative flex justify-between items-center xsm:p-4 p-2 rounded-2xl border-2 border-solid border-secondaryMainLight"
>
    <div class="flex items-center">
        <mat-icon
            *ngIf="customIcon && !icon"
            svgIcon="{{customIcon}}"
            class="h-10 w-10 mr-2 text-primarySecondary"
        ></mat-icon>
        <mat-icon
            *ngIf="!customIcon && icon"
            class="h-10 w-10 text-4xl leading-10 mr-2 text-primarySecondary"
            >{{icon}}</mat-icon
        >
        <div>
            <div class="caption-1 text-primarySecondary">{{description}}</div>
            <div>{{name}}</div>
        </div>
    </div>
    <div class="absolute top-[8px] right-[8px] flex items-center gap-2">
        <!-- In this case we need either ability to change hover or remove it to use hover from passed mat-icon -->
        <app-tb-button class="h-4" [appearance]="'icon'">
            <mat-icon
                svgIcon="custom_settings"
                class="h-4 w-4 text-secondaryMainLight hover:text-secondaryMain"
            ></mat-icon>
        </app-tb-button>
        <!-- In this case we need either ability to change hover or remove it to use hover from passed mat-icon -->
        <app-tb-button class="h-4" [appearance]="'icon'">
            <mat-icon
                class="h-4 w-4 subheading-1 leading-4 text-secondaryMainLight hover:text-secondaryMain"
            >
                close
            </mat-icon>
        </app-tb-button>
    </div>
</div>
