import { ChangeDetectorRef, Directive, Self } from '@angular/core'

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { isNumber } from 'lodash-es'
import { CdkFixedSizeVirtualScrollDirective } from '@components-library/virtual-scrolling/directives/fixed-size-virtual-scroll'

export type VisibleRange = { start: number; end: number }

@UntilDestroy()
@Directive({
    selector: '[appVisibleRange]',
    exportAs: 'visibleRange',
})
export class VisibleRangeDirective {
    _range: VisibleRange = { start: 0, end: 0 }

    private lastVisibleIndex!: number

    constructor(
        @Self() private viewport: CdkVirtualScrollViewport,
        @Self() private cdkFixedSizeVirtualScroll: CdkFixedSizeVirtualScrollDirective,
        private cdr: ChangeDetectorRef,
    ) {
        this.viewport.scrolledIndexChange.pipe(untilDestroyed(this)).subscribe((index: number) => {
            this.lastVisibleIndex = index
            this.onScroll(index)
        })

        // _range is not updated when we change table data, so we subscribe on callback to update the visible range
        const original = this.cdkFixedSizeVirtualScroll._scrollStrategy.onDataLengthChanged
        this.cdkFixedSizeVirtualScroll._scrollStrategy.onDataLengthChanged = () => {
            original.call(this.cdkFixedSizeVirtualScroll._scrollStrategy)
            if (isNumber(this.lastVisibleIndex)) {
                this.onScroll(this.lastVisibleIndex)
            }
        }
    }

    get range() {
        return this._range
    }

    onScroll(firstVisibleIndex: number) {
        const viewportSize = this.viewport.getViewportSize()
        const itemSize = this.cdkFixedSizeVirtualScroll.itemSize
        const bufferMax = this.cdkFixedSizeVirtualScroll.maxBufferPx
        const dataLength = this.viewport.getDataLength()

        const bufferedItems = Math.ceil(bufferMax / itemSize)
        const visibleItems = Math.ceil(viewportSize / itemSize)

        this._range = {
            start: Math.max(0, firstVisibleIndex - bufferedItems),
            end: Math.min(dataLength, firstVisibleIndex + visibleItems + bufferedItems),
        }

        this.cdr.markForCheck()
    }
}
