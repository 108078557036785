<ng-container *transloco="let tVersion; read: 'version'">
    <app-tb-button [appearance]="'text'" [matMenuTriggerFor]="versionContent">
        version
    </app-tb-button>
    <mat-menu #versionContent="matMenu">
        <ng-template matMenuContent>
            {{tVersion('branch')}}{{version.branch}}
            <br />
            {{tVersion('commit')}}{{version.commit}}
            <br />
            {{tVersion('date')}}{{version.date}}
            <br />
            {{tVersion('mode')}}{{prod}}
        </ng-template>
    </mat-menu>
</ng-container>
