import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core'
import { Field, Folder, getIconByFieldType } from '@core/models'
import { getBoolean } from '@core/global-util'

@Component({
    selector: 'app-field-name',
    templateUrl: './field-name.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNameComponent implements OnInit, OnChanges {
    @Input()
    field!: Field

    // todo: [table-ref-2] rename to folder
    @Input()
    selectedFolder!: Folder

    @Input()
    showIcon!: boolean

    @Input()
    iconColor?: string

    @Input()
    trim = false

    showFolderName!: boolean
    bgIconColor!: string

    ngOnInit() {
        this.bgIconColor = this.iconColor ? `bg-[${this.iconColor}]` : 'bg-newNeutral1'
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedFolder) {
            if (this.selectedFolder && this.field) {
                if (getBoolean(this.selectedFolder.is_global.value)) {
                    this.showFolderName = !!this.field.folder_name?.is_global
                } else {
                    this.showFolderName = this.field.folder_guid === this.selectedFolder.guid
                }
            } else {
                this.showFolderName = false
            }
        }
    }

    getFieldIcon() {
        return getIconByFieldType(this.field.field_type_code)
    }
}
