import { Component, Input } from '@angular/core'
import { BusinessRecords, CellEntities, FieldEntities, FieldType, Folder } from '@core/models'
import { Dictionary } from '@ngrx/entity'

@Component({
    selector: 'app-card-fields',
    templateUrl: './card-fields.component.html',
    styleUrls: ['./card-fields.component.sass'],
})
export class CardFieldsComponent {
    @Input()
    subFields!: string[]

    @Input()
    record!: BusinessRecords

    @Input()
    fields!: FieldEntities

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    selectedFolder!: Folder

    @Input()
    cells!: CellEntities
}
