export enum RecordSystemFieldNames {
    CREATE_AT = 'create_at',
    CREATE_BY = 'create_by',
    UPDATE_AT = 'update_at',
    UPDATE_BY = 'update_by',
}

export enum FolderFieldNames {
    FOLDER_NAME = 'name',
    FOLDER_IS_GLOBAL = 'is_global',
    FOLDER_ICON = 'icon',
    FOLDER_COLOR = 'color',
}

export enum ViewFieldNames {
    VIEW_NAME = 'name',
    VIEW_TYPE = 'type_code',
    VIEW_COLUMNS_ORDER = 'columns_order',
    VIEW_COLUMNS_PINNED = 'columns_pinned',
    VIEW_HIDDEN_COLUMNS = 'columns_hide',
    VIEW_COLUMNS_WIDTH = 'columns_width',
    VIEW_SORT = 'sort',
    VIEW_FILTER = 'filter',
    VIEW_GROUP = 'group',
    VIEW_PRIVATE = 'private',
}

export enum ConfigFieldNames {
    CONFIG_SCOPE = 'scope',
    CONFIG_SCOPE_GUID = 'scope_guid',
    CONFIG_FOR_USERS = 'for_users',
    CONFIG_SOT_CODE = 'system_object_type_code',
    CONFIG_SOT_RECORD_GUID = 'sot_record_guid',
    CONFIG_KEY = 'key',
    CONFIG_VALUE = 'value',
}
