export const icons = [
    'abc_rounded',
    'ac_unit_rounded',
    'access_alarm_rounded',
    'access_alarms_rounded',
    'access_time_filled_rounded',
    'access_time_rounded',
    'accessibility_new_rounded',
    'accessibility_rounded',
    'accessible_forward_rounded',
    'accessible_rounded',
    'account_balance_rounded',
    'account_balance_wallet_rounded',
    'account_box_rounded',
    'account_circle_rounded',
    'account_tree_rounded',
    'ad_units_rounded',
    'adb_rounded',
    'add_a_photo_rounded',
    'add_alarm_rounded',
    'add_alert_rounded',
    'add_box_rounded',
    'add_business_rounded',
    'add_card_rounded',
    'add_circle_outline_rounded',
    'add_circle_rounded',
    'add_comment_rounded',
    'add_home_rounded',
    'add_home_work_rounded',
    'add_ic_call_rounded',
    'add_link_rounded',
    'add_location_alt_rounded',
    'add_location_rounded',
    'add_moderator_rounded',
    'add_photo_alternate_rounded',
    'add_reaction_rounded',
    'add_road_rounded',
    'add_rounded',
    'add_shopping_cart_rounded',
    'add_task_rounded',
    'add_to_drive_rounded',
    'add_to_home_screen_rounded',
    'add_to_photos_rounded',
    'add_to_queue_rounded',
    'addchart_rounded',
    'adf_scanner_rounded',
    'adjust_rounded',
    'admin_panel_settings_rounded',
    'ads_click_rounded',
    'agriculture_rounded',
    'air_rounded',
    'airline_seat_flat_angled_rounded',
    'airline_seat_flat_rounded',
    'airline_seat_individual_suite_rounded',
    'airline_seat_legroom_extra_rounded',
    'airline_seat_legroom_normal_rounded',
    'airline_seat_legroom_reduced_rounded',
    'airline_seat_recline_extra_rounded',
    'airline_seat_recline_normal_rounded',
    'airline_stops_rounded',
    'airlines_rounded',
    'airplane_ticket_rounded',
    'airplanemode_active_rounded',
    'airplanemode_inactive_rounded',
    'airplay_rounded',
    'airport_shuttle_rounded',
    'alarm_add_rounded',
    'alarm_off_rounded',
    'alarm_on_rounded',
    'alarm_rounded',
    'album_rounded',
    'align_horizontal_center_rounded',
    'align_horizontal_left_rounded',
    'align_horizontal_right_rounded',
    'align_vertical_bottom_rounded',
    'align_vertical_center_rounded',
    'align_vertical_top_rounded',
    'all_inbox_rounded',
    'all_inclusive_rounded',
    'all_out_rounded',
    'alt_route_rounded',
    'alternate_email_rounded',
    'analytics_rounded',
    'anchor_rounded',
    'android_rounded',
    'animation_rounded',
    'announcement_rounded',
    'aod_rounded',
    'apartment_rounded',
    'api_rounded',
    'app_blocking_rounded',
    'app_registration_rounded',
    'app_settings_alt_rounded',
    'app_shortcut_rounded',
    'approval_rounded',
    'apps_outage_rounded',
    'apps_rounded',
    'architecture_rounded',
    'archive_rounded',
    'arrow_back_ios_new_rounded',
    'arrow_back_ios_rounded',
    'arrow_back_rounded',
    'arrow_circle_down_rounded',
    'arrow_circle_left_rounded',
    'arrow_circle_right_rounded',
    'arrow_circle_up_rounded',
    'arrow_downward_rounded',
    'arrow_drop_down_circle_rounded',
    'arrow_drop_down_rounded',
    'arrow_drop_up_rounded',
    'arrow_forward_ios_rounded',
    'arrow_forward_rounded',
    'arrow_left_rounded',
    'arrow_outward_rounded',
    'arrow_right_alt_rounded',
    'arrow_right_rounded',
    'arrow_upward_rounded',
    'art_track_rounded',
    'article_rounded',
    'aspect_ratio_rounded',
    'assessment_rounded',
    'assignment_ind_rounded',
    'assignment_late_rounded',
    'assignment_return_rounded',
    'assignment_returned_rounded',
    'assignment_rounded',
    'assignment_turned_in_rounded',
    'assist_walker_rounded',
    'assistant_direction_rounded',
    'assistant_photo_rounded',
    'assistant_rounded',
    'assured_workload_rounded',
    'atm_rounded',
    'attach_email_rounded',
    'attach_file_rounded',
    'attach_money_rounded',
    'attachment_rounded',
    'attractions_rounded',
    'attribution_rounded',
    'audio_file_rounded',
    'audiotrack_rounded',
    'auto_awesome_mosaic_rounded',
    'auto_awesome_motion_rounded',
    'auto_awesome_rounded',
    'auto_delete_rounded',
    'auto_fix_high_rounded',
    'auto_fix_normal_rounded',
    'auto_fix_off_rounded',
    'auto_graph_rounded',
    'auto_mode_rounded',
    'auto_stories_rounded',
    'autofps_select_rounded',
    'autorenew_rounded',
    'av_timer_rounded',
    'baby_changing_station_rounded',
    'back_hand_rounded',
    'backpack_rounded',
    'backspace_rounded',
    'backup_rounded',
    'backup_table_rounded',
    'badge_rounded',
    'bakery_dining_rounded',
    'balance_rounded',
    'balcony_rounded',
    'ballot_rounded',
    'bar_chart_rounded',
    'batch_prediction_rounded',
    'bathroom_rounded',
    'bathtub_rounded',
    'battery_0_bar_rounded',
    'battery_1_bar_rounded',
    'battery_20_rounded',
    'battery_2_bar_rounded',
    'battery_30_rounded',
    'battery_3_bar_rounded',
    'battery_4_bar_rounded',
    'battery_50_rounded',
    'battery_5_bar_rounded',
    'battery_60_rounded',
    'battery_6_bar_rounded',
    'battery_80_rounded',
    'battery_90_rounded',
    'battery_alert_rounded',
    'battery_charging_20_rounded',
    'battery_charging_30_rounded',
    'battery_charging_50_rounded',
    'battery_charging_60_rounded',
    'battery_charging_80_rounded',
    'battery_charging_90_rounded',
    'battery_charging_full_rounded',
    'battery_full_rounded',
    'battery_saver_rounded',
    'battery_std_rounded',
    'battery_unknown_rounded',
    'beach_access_rounded',
    'bed_rounded',
    'bedroom_baby_rounded',
    'bedroom_child_rounded',
    'bedroom_parent_rounded',
    'bedtime_off_rounded',
    'bedtime_rounded',
    'beenhere_rounded',
    'bento_rounded',
    'bike_scooter_rounded',
    'biotech_rounded',
    'blender_rounded',
    'blind_rounded',
    'blinds_closed_rounded',
    'blinds_rounded',
    'block_rounded',
    'bloodtype_rounded',
    'bluetooth_audio_rounded',
    'bluetooth_connected_rounded',
    'bluetooth_disabled_rounded',
    'bluetooth_drive_rounded',
    'bluetooth_rounded',
    'bluetooth_searching_rounded',
    'blur_circular_rounded',
    'blur_linear_rounded',
    'blur_off_rounded',
    'blur_on_rounded',
    'bolt_rounded',
    'book_online_rounded',
    'book_rounded',
    'bookmark_add_rounded',
    'bookmark_added_rounded',
    'bookmark_border_rounded',
    'bookmark_remove_rounded',
    'bookmark_rounded',
    'bookmarks_rounded',
    'border_all_rounded',
    'border_bottom_rounded',
    'border_clear_rounded',
    'border_color_rounded',
    'border_horizontal_rounded',
    'border_inner_rounded',
    'border_left_rounded',
    'border_outer_rounded',
    'border_right_rounded',
    'border_style_rounded',
    'border_top_rounded',
    'border_vertical_rounded',
    'boy_rounded',
    'branding_watermark_rounded',
    'breakfast_dining_rounded',
    'brightness_1_rounded',
    'brightness_2_rounded',
    'brightness_3_rounded',
    'brightness_4_rounded',
    'brightness_5_rounded',
    'brightness_6_rounded',
    'brightness_7_rounded',
    'brightness_auto_rounded',
    'brightness_high_rounded',
    'brightness_low_rounded',
    'brightness_medium_rounded',
    'broadcast_on_home_rounded',
    'broadcast_on_personal_rounded',
    'broken_image_rounded',
    'browse_gallery_rounded',
    'browser_not_supported_rounded',
    'browser_updated_rounded',
    'brunch_dining_rounded',
    'brush_rounded',
    'bubble_chart_rounded',
    'bug_report_rounded',
    'build_circle_rounded',
    'build_rounded',
    'bungalow_rounded',
    'burst_mode_rounded',
    'bus_alert_rounded',
    'business_center_rounded',
    'business_rounded',
    'cabin_rounded',
    'cable_rounded',
    'cached_rounded',
    'cake_rounded',
    'calculate_rounded',
    'calendar_month_rounded',
    'calendar_today_rounded',
    'calendar_view_day_rounded',
    'calendar_view_month_rounded',
    'calendar_view_week_rounded',
    'call_end_rounded',
    'call_made_rounded',
    'call_merge_rounded',
    'call_missed_outgoing_rounded',
    'call_missed_rounded',
    'call_received_rounded',
    'call_rounded',
    'call_split_rounded',
    'call_to_action_rounded',
    'camera_alt_rounded',
    'camera_enhance_rounded',
    'camera_front_rounded',
    'camera_indoor_rounded',
    'camera_outdoor_rounded',
    'camera_rear_rounded',
    'camera_roll_rounded',
    'camera_rounded',
    'cameraswitch_rounded',
    'campaign_rounded',
    'cancel_presentation_rounded',
    'cancel_rounded',
    'cancel_schedule_send_rounded',
    'candlestick_chart_rounded',
    'car_crash_rounded',
    'car_rental_rounded',
    'car_repair_rounded',
    'card_giftcard_rounded',
    'card_membership_rounded',
    'card_travel_rounded',
    'carpenter_rounded',
    'cases_rounded',
    'casino_rounded',
    'cast_connected_rounded',
    'cast_for_education_rounded',
    'cast_rounded',
    'castle_rounded',
    'catching_pokemon_rounded',
    'category_rounded',
    'celebration_rounded',
    'cell_tower_rounded',
    'cell_wifi_rounded',
    'center_focus_strong_rounded',
    'center_focus_weak_rounded',
    'chair_alt_rounded',
    'chair_rounded',
    'chalet_rounded',
    'change_circle_rounded',
    'change_history_rounded',
    'charging_station_rounded',
    'chat_bubble_outline_rounded',
    'chat_bubble_rounded',
    'chat_rounded',
    'check_box_outline_blank_rounded',
    'check_box_rounded',
    'check_circle_outline_rounded',
    'check_circle_rounded',
    'check_rounded',
    'checklist_rounded',
    'checklist_rtl_rounded',
    'checkroom_rounded',
    'chevron_left_rounded',
    'chevron_right_rounded',
    'child_care_rounded',
    'child_friendly_rounded',
    'chrome_reader_mode_rounded',
    'church_rounded',
    'circle_notifications_rounded',
    'circle_rounded',
    'class_rounded',
    'clean_hands_rounded',
    'cleaning_services_rounded',
    'clear_all_rounded',
    'clear_rounded',
    'close_fullscreen_rounded',
    'close_rounded',
    'closed_caption_disabled_rounded',
    'closed_caption_off_rounded',
    'closed_caption_rounded',
    'cloud_circle_rounded',
    'cloud_done_rounded',
    'cloud_download_rounded',
    'cloud_off_rounded',
    'cloud_queue_rounded',
    'cloud_rounded',
    'cloud_sync_rounded',
    'cloud_upload_rounded',
    'co_2_rounded',
    'co_present_rounded',
    'code_off_rounded',
    'code_rounded',
    'coffee_maker_rounded',
    'coffee_rounded',
    'collections_bookmark_rounded',
    'collections_rounded',
    'color_lens_rounded',
    'colorize_rounded',
    'comment_bank_rounded',
    'comment_rounded',
    'comments_disabled_rounded',
    'commit_rounded',
    'commute_rounded',
    'compare_arrows_rounded',
    'compare_rounded',
    'compass_calibration_rounded',
    'compress_rounded',
    'computer_rounded',
    'confirmation_number_rounded',
    'connect_without_contact_rounded',
    'connected_tv_rounded',
    'connecting_airports_rounded',
    'construction_rounded',
    'contact_emergency_rounded',
    'contact_mail_rounded',
    'contact_page_rounded',
    'contact_phone_rounded',
    'contact_support_rounded',
    'contactless_rounded',
    'contacts_rounded',
    'content_copy_rounded',
    'content_cut_rounded',
    'content_paste_go_rounded',
    'content_paste_off_rounded',
    'content_paste_rounded',
    'content_paste_search_rounded',
    'contrast_rounded',
    'control_camera_rounded',
    'control_point_duplicate_rounded',
    'control_point_rounded',
    'cookie_rounded',
    'copy_all_rounded',
    'copyright_rounded',
    'coronavirus_rounded',
    'corporate_fare_rounded',
    'cottage_rounded',
    'countertops_rounded',
    'create_new_folder_rounded',
    'create_rounded',
    'credit_card_off_rounded',
    'credit_card_rounded',
    'credit_score_rounded',
    'crib_rounded',
    'crisis_alert_rounded',
    'crop_169_rounded',
    'crop_32_rounded',
    'crop_54_rounded',
    'crop_75_rounded',
    'crop_din_rounded',
    'crop_free_rounded',
    'crop_landscape_rounded',
    'crop_original_rounded',
    'crop_portrait_rounded',
    'crop_rotate_rounded',
    'crop_rounded',
    'crop_square_rounded',
    'css_rounded',
    'currency_bitcoin_rounded',
    'currency_exchange_rounded',
    'currency_franc_rounded',
    'currency_lira_rounded',
    'currency_pound_rounded',
    'currency_ruble_rounded',
    'currency_rupee_rounded',
    'currency_yen_rounded',
    'currency_yuan_rounded',
    'curtains_closed_rounded',
    'curtains_rounded',
    'cyclone_rounded',
    'dangerous_rounded',
    'dark_mode_rounded',
    'dashboard_customize_rounded',
    'dashboard_rounded',
    'data_array_rounded',
    'data_object_rounded',
    'data_saver_off_rounded',
    'data_saver_on_rounded',
    'data_thresholding_rounded',
    'data_usage_rounded',
    'dataset_linked_rounded',
    'dataset_rounded',
    'date_range_rounded',
    'deblur_rounded',
    'deck_rounded',
    'dehaze_rounded',
    'delete_forever_rounded',
    'delete_outline_rounded',
    'delete_rounded',
    'delete_sweep_rounded',
    'delivery_dining_rounded',
    'density_large_rounded',
    'density_medium_rounded',
    'density_small_rounded',
    'departure_board_rounded',
    'description_rounded',
    'deselect_rounded',
    'design_services_rounded',
    'desk_rounded',
    'desktop_access_disabled_rounded',
    'desktop_mac_rounded',
    'desktop_windows_rounded',
    'details_rounded',
    'developer_board_off_rounded',
    'developer_board_rounded',
    'developer_mode_rounded',
    'device_hub_rounded',
    'device_thermostat_rounded',
    'device_unknown_rounded',
    'devices_fold_rounded',
    'devices_other_rounded',
    'devices_rounded',
    'dialer_sip_rounded',
    'dialpad_rounded',
    'diamond_rounded',
    'difference_rounded',
    'dining_rounded',
    'dinner_dining_rounded',
    'directions_bike_rounded',
    'directions_boat_filled_rounded',
    'directions_boat_rounded',
    'directions_bus_filled_rounded',
    'directions_bus_rounded',
    'directions_car_filled_rounded',
    'directions_car_rounded',
    'directions_off_rounded',
    'directions_railway_filled_rounded',
    'directions_railway_rounded',
    'directions_rounded',
    'directions_run_rounded',
    'directions_subway_filled_rounded',
    'directions_subway_rounded',
    'directions_transit_filled_rounded',
    'directions_transit_rounded',
    'directions_walk_rounded',
    'dirty_lens_rounded',
    'disabled_by_default_rounded',
    'disc_full_rounded',
    'discount_rounded',
    'display_settings_rounded',
    'diversity_1_rounded',
    'diversity_2_rounded',
    'diversity_3_rounded',
    'dns_rounded',
    'do_disturb_alt_rounded',
    'do_disturb_off_rounded',
    'do_disturb_on_rounded',
    'do_disturb_rounded',
    'do_not_disturb_alt_rounded',
    'do_not_disturb_off_rounded',
    'do_not_disturb_on_rounded',
    'do_not_disturb_on_total_silence_rounded',
    'do_not_disturb_rounded',
    'do_not_step_rounded',
    'do_not_touch_rounded',
    'dock_rounded',
    'document_scanner_rounded',
    'domain_add_rounded',
    'domain_disabled_rounded',
    'domain_rounded',
    'domain_verification_rounded',
    'done_all_rounded',
    'done_outline_rounded',
    'done_rounded',
    'donut_large_rounded',
    'donut_small_rounded',
    'door_back_rounded',
    'door_front_rounded',
    'door_sliding_rounded',
    'doorbell_rounded',
    'double_arrow_rounded',
    'downhill_skiing_rounded',
    'download_done_rounded',
    'download_for_offline_rounded',
    'download_rounded',
    'downloading_rounded',
    'drafts_rounded',
    'drag_handle_rounded',
    'drag_indicator_rounded',
    'draw_rounded',
    'drive_eta_rounded',
    'drive_file_move_rounded',
    'drive_file_rename_outline_rounded',
    'drive_folder_upload_rounded',
    'dry_cleaning_rounded',
    'dry_rounded',
    'duo_rounded',
    'dvr_rounded',
    'dynamic_feed_rounded',
    'dynamic_form_rounded',
    'e_mobiledata_rounded',
    'earbuds_battery_rounded',
    'earbuds_rounded',
    'east_rounded',
    'edgesensor_high_rounded',
    'edgesensor_low_rounded',
    'edit_attributes_rounded',
    'edit_calendar_rounded',
    'edit_location_alt_rounded',
    'edit_location_rounded',
    'edit_note_rounded',
    'edit_notifications_rounded',
    'edit_off_rounded',
    'edit_road_rounded',
    'edit_rounded',
    'egg_alt_rounded',
    'egg_rounded',
    'eight_k_plus_rounded',
    'eight_k_rounded',
    'eight_mp_rounded',
    'eighteen_mp_rounded',
    'eighteen_up_rating_rounded',
    'eightteen_mp_rounded',
    'eject_rounded',
    'elderly_rounded',
    'elderly_woman_rounded',
    'electric_bike_rounded',
    'electric_bolt_rounded',
    'electric_car_rounded',
    'electric_meter_rounded',
    'electric_moped_rounded',
    'electric_rickshaw_rounded',
    'electric_scooter_rounded',
    'electrical_services_rounded',
    'elevator_rounded',
    'eleven_mp_rounded',
    'email_rounded',
    'emergency_recording_rounded',
    'emergency_share_rounded',
    'emoji_emotions_rounded',
    'emoji_events_rounded',
    'emoji_flags_rounded',
    'emoji_food_beverage_rounded',
    'emoji_nature_rounded',
    'emoji_objects_rounded',
    'emoji_people_rounded',
    'emoji_symbols_rounded',
    'emoji_transportation_rounded',
    'energy_savings_leaf_rounded',
    'engineering_rounded',
    'enhanced_encryption_rounded',
    'equalizer_rounded',
    'error_outline_rounded',
    'error_rounded',
    'escalator_rounded',
    'escalator_warning_rounded',
    'euro_rounded',
    'euro_symbol_rounded',
    'ev_station_rounded',
    'event_available_rounded',
    'event_busy_rounded',
    'event_note_rounded',
    'event_repeat_rounded',
    'event_rounded',
    'event_seat_rounded',
    'exit_to_app_rounded',
    'expand_circle_down_rounded',
    'expand_less_rounded',
    'expand_more_rounded',
    'expand_rounded',
    'explicit_rounded',
    'explore_off_rounded',
    'explore_rounded',
    'exposure_rounded',
    'extension_off_rounded',
    'extension_rounded',
    'face_2_rounded',
    'face_3_rounded',
    'face_4_rounded',
    'face_5_rounded',
    'face_6_rounded',
    'face_retouching_natural_rounded',
    'face_retouching_off_rounded',
    'face_rounded',
    'facebook_rounded',
    'fact_check_rounded',
    'factory_rounded',
    'family_restroom_rounded',
    'fast_forward_rounded',
    'fast_rewind_rounded',
    'fastfood_rounded',
    'favorite_border_rounded',
    'favorite_rounded',
    'fax_rounded',
    'featured_play_list_rounded',
    'featured_video_rounded',
    'feed_rounded',
    'feedback_rounded',
    'female_rounded',
    'fence_rounded',
    'festival_rounded',
    'fiber_dvr_rounded',
    'fiber_manual_record_rounded',
    'fiber_new_rounded',
    'fiber_pin_rounded',
    'fiber_smart_record_rounded',
    'fifteen_mp_rounded',
    'file_copy_rounded',
    'file_download_done_rounded',
    'file_download_off_rounded',
    'file_download_rounded',
    'file_open_rounded',
    'file_present_rounded',
    'file_upload_rounded',
    'filter_1_rounded',
    'filter_2_rounded',
    'filter_3_rounded',
    'filter_4_rounded',
    'filter_5_rounded',
    'filter_6_rounded',
    'filter_7_rounded',
    'filter_8_rounded',
    'filter_9_plus_rounded',
    'filter_9_rounded',
    'filter_alt_off_rounded',
    'filter_alt_rounded',
    'filter_b_and_w_rounded',
    'filter_center_focus_rounded',
    'filter_drama_rounded',
    'filter_frames_rounded',
    'filter_hdr_rounded',
    'filter_list_off_rounded',
    'filter_list_rounded',
    'filter_none_rounded',
    'filter_rounded',
    'filter_tilt_shift_rounded',
    'filter_vintage_rounded',
    'find_in_page_rounded',
    'find_replace_rounded',
    'fingerprint_rounded',
    'fire_extinguisher_rounded',
    'fire_hydrant_alt_rounded',
    'fire_truck_rounded',
    'fireplace_rounded',
    'first_page_rounded',
    'fit_screen_rounded',
    'fitbit_rounded',
    'fitness_center_rounded',
    'five_g_rounded',
    'five_k_plus_rounded',
    'five_k_rounded',
    'five_mp_rounded',
    'fivteen_mp_rounded',
    'flag_circle_rounded',
    'flag_rounded',
    'flaky_rounded',
    'flare_rounded',
    'flash_auto_rounded',
    'flash_off_rounded',
    'flash_on_rounded',
    'flashlight_off_rounded',
    'flashlight_on_rounded',
    'flatware_rounded',
    'flight_class_rounded',
    'flight_land_rounded',
    'flight_rounded',
    'flight_takeoff_rounded',
    'flip_camera_android_rounded',
    'flip_camera_ios_rounded',
    'flip_rounded',
    'flip_to_back_rounded',
    'flip_to_front_rounded',
    'flood_rounded',
    'fluorescent_rounded',
    'flutter_dash_rounded',
    'fmd_bad_rounded',
    'fmd_good_rounded',
    'folder_copy_rounded',
    'folder_delete_rounded',
    'folder_off_rounded',
    'folder_open_rounded',
    'folder_rounded',
    'folder_shared_rounded',
    'folder_special_rounded',
    'folder_zip_rounded',
    'follow_the_signs_rounded',
    'font_download_off_rounded',
    'font_download_rounded',
    'food_bank_rounded',
    'forest_rounded',
    'fork_left_rounded',
    'fork_right_rounded',
    'format_align_center_rounded',
    'format_align_justify_rounded',
    'format_align_left_rounded',
    'format_align_right_rounded',
    'format_bold_rounded',
    'format_clear_rounded',
    'format_color_fill_rounded',
    'format_color_reset_rounded',
    'format_color_text_rounded',
    'format_indent_decrease_rounded',
    'format_indent_increase_rounded',
    'format_italic_rounded',
    'format_line_spacing_rounded',
    'format_list_bulleted_rounded',
    'format_list_numbered_rounded',
    'format_list_numbered_rtl_rounded',
    'format_overline_rounded',
    'format_paint_rounded',
    'format_quote_rounded',
    'format_shapes_rounded',
    'format_size_rounded',
    'format_strikethrough_rounded',
    'format_textdirection_l_to_r_rounded',
    'format_textdirection_r_to_l_rounded',
    'format_underlined_rounded',
    'fort_rounded',
    'forum_rounded',
    'forward_10_rounded',
    'forward_30_rounded',
    'forward_5_rounded',
    'forward_rounded',
    'forward_to_inbox_rounded',
    'foundation_rounded',
    'four_g_mobiledata_rounded',
    'four_g_plus_mobiledata_rounded',
    'four_k_plus_rounded',
    'four_k_rounded',
    'four_mp_rounded',
    'fourteen_mp_rounded',
    'free_breakfast_rounded',
    'fullscreen_exit_rounded',
    'fullscreen_rounded',
    'functions_rounded',
    'g_mobiledata_rounded',
    'g_translate_rounded',
    'gamepad_rounded',
    'games_rounded',
    'garage_rounded',
    'gas_meter_rounded',
    'gavel_rounded',
    'gesture_rounded',
    'get_app_rounded',
    'gif_box_rounded',
    'gif_rounded',
    'girl_rounded',
    'gite_rounded',
    'golf_course_rounded',
    'gpp_bad_rounded',
    'gpp_good_rounded',
    'gpp_maybe_rounded',
    'gps_fixed_rounded',
    'gps_not_fixed_rounded',
    'gps_off_rounded',
    'grade_rounded',
    'gradient_rounded',
    'grading_rounded',
    'grain_rounded',
    'graphic_eq_rounded',
    'grass_rounded',
    'grid_3_x_3_rounded',
    'grid_4_x_4_rounded',
    'grid_goldenratio_rounded',
    'grid_off_rounded',
    'grid_on_rounded',
    'grid_view_rounded',
    'group_add_rounded',
    'group_remove_rounded',
    'group_rounded',
    'group_work_rounded',
    'groups_2_rounded',
    'groups_3_rounded',
    'groups_rounded',
    'h_mobiledata_rounded',
    'h_plus_mobiledata_rounded',
    'hail_rounded',
    'handshake_rounded',
    'handyman_rounded',
    'hardware_rounded',
    'hd_rounded',
    'hdr_auto_rounded',
    'hdr_auto_select_rounded',
    'hdr_enhanced_select_rounded',
    'hdr_off_rounded',
    'hdr_off_select_rounded',
    'hdr_on_rounded',
    'hdr_on_select_rounded',
    'hdr_plus_rounded',
    'hdr_strong_rounded',
    'hdr_weak_rounded',
    'headphones_battery_rounded',
    'headphones_rounded',
    'headset_mic_rounded',
    'headset_off_rounded',
    'headset_rounded',
    'healing_rounded',
    'health_and_safety_rounded',
    'hearing_disabled_rounded',
    'hearing_rounded',
    'heart_broken_rounded',
    'heat_pump_rounded',
    'height_rounded',
    'help_center_rounded',
    'help_outline_rounded',
    'help_rounded',
    'hevc_rounded',
    'hexagon_rounded',
    'hide_image_rounded',
    'hide_source_rounded',
    'high_quality_rounded',
    'highlight_alt_rounded',
    'highlight_off_rounded',
    'highlight_rounded',
    'hiking_rounded',
    'history_edu_rounded',
    'history_rounded',
    'history_toggle_off_rounded',
    'hive_rounded',
    'hls_off_rounded',
    'hls_rounded',
    'holiday_village_rounded',
    'home_max_rounded',
    'home_mini_rounded',
    'home_repair_service_rounded',
    'home_rounded',
    'home_work_rounded',
    'horizontal_rule_rounded',
    'horizontal_split_rounded',
    'hot_tub_rounded',
    'hotel_rounded',
    'hourglass_bottom_rounded',
    'hourglass_disabled_rounded',
    'hourglass_empty_rounded',
    'hourglass_full_rounded',
    'hourglass_top_rounded',
    'house_rounded',
    'house_siding_rounded',
    'houseboat_rounded',
    'how_to_reg_rounded',
    'how_to_vote_rounded',
    'html_rounded',
    'http_rounded',
    'https_rounded',
    'hub_rounded',
    'hvac_rounded',
    'ice_skating_rounded',
    'icecream_rounded',
    'image_aspect_ratio_rounded',
    'image_not_supported_rounded',
    'image_rounded',
    'image_search_rounded',
    'imagesearch_roller_rounded',
    'import_contacts_rounded',
    'import_export_rounded',
    'important_devices_rounded',
    'inbox_rounded',
    'indeterminate_check_box_rounded',
    'info_rounded',
    'input_rounded',
    'insert_chart_outlined_rounded',
    'insert_chart_rounded',
    'insert_comment_rounded',
    'insert_drive_file_rounded',
    'insert_emoticon_rounded',
    'insert_invitation_rounded',
    'insert_link_rounded',
    'insert_page_break_rounded',
    'insert_photo_rounded',
    'insights_rounded',
    'install_desktop_rounded',
    'install_mobile_rounded',
    'integration_instructions_rounded',
    'interests_rounded',
    'interpreter_mode_rounded',
    'inventory_2_rounded',
    'inventory_rounded',
    'invert_colors_off_rounded',
    'invert_colors_rounded',
    'ios_share_rounded',
    'iron_rounded',
    'iso_rounded',
    'javascript_rounded',
    'join_full_rounded',
    'join_inner_rounded',
    'join_left_rounded',
    'join_right_rounded',
    'kayaking_rounded',
    'kebab_dining_rounded',
    'key_off_rounded',
    'key_rounded',
    'keyboard_alt_rounded',
    'keyboard_arrow_down_rounded',
    'keyboard_arrow_left_rounded',
    'keyboard_arrow_right_rounded',
    'keyboard_arrow_up_rounded',
    'keyboard_backspace_rounded',
    'keyboard_capslock_rounded',
    'keyboard_command_key_rounded',
    'keyboard_control_key_rounded',
    'keyboard_double_arrow_down_rounded',
    'keyboard_double_arrow_left_rounded',
    'keyboard_double_arrow_right_rounded',
    'keyboard_double_arrow_up_rounded',
    'keyboard_hide_rounded',
    'keyboard_option_key_rounded',
    'keyboard_return_rounded',
    'keyboard_rounded',
    'keyboard_tab_rounded',
    'keyboard_voice_rounded',
    'king_bed_rounded',
    'kitchen_rounded',
    'kitesurfing_rounded',
    'label_important_rounded',
    'label_off_rounded',
    'label_rounded',
    'lan_rounded',
    'landscape_rounded',
    'landslide_rounded',
    'language_rounded',
    'laptop_chromebook_rounded',
    'laptop_mac_rounded',
    'laptop_rounded',
    'laptop_windows_rounded',
    'last_page_rounded',
    'launch_rounded',
    'layers_clear_rounded',
    'layers_rounded',
    'leaderboard_rounded',
    'leak_add_rounded',
    'leak_remove_rounded',
    'legend_toggle_rounded',
    'lens_blur_rounded',
    'lens_rounded',
    'library_add_check_rounded',
    'library_add_rounded',
    'library_books_rounded',
    'library_music_rounded',
    'light_mode_rounded',
    'light_rounded',
    'lightbulb_circle_rounded',
    'lightbulb_rounded',
    'line_axis_rounded',
    'line_style_rounded',
    'line_weight_rounded',
    'linear_scale_rounded',
    'link_off_rounded',
    'link_rounded',
    'linked_camera_rounded',
    'liquor_rounded',
    'list_alt_rounded',
    'list_rounded',
    'live_help_rounded',
    'live_tv_rounded',
    'living_rounded',
    'local_activity_rounded',
    'local_airport_rounded',
    'local_atm_rounded',
    'local_bar_rounded',
    'local_cafe_rounded',
    'local_car_wash_rounded',
    'local_convenience_store_rounded',
    'local_dining_rounded',
    'local_drink_rounded',
    'local_fire_department_rounded',
    'local_florist_rounded',
    'local_gas_station_rounded',
    'local_grocery_store_rounded',
    'local_hospital_rounded',
    'local_hotel_rounded',
    'local_laundry_service_rounded',
    'local_library_rounded',
    'local_mall_rounded',
    'local_movies_rounded',
    'local_offer_rounded',
    'local_parking_rounded',
    'local_pharmacy_rounded',
    'local_phone_rounded',
    'local_pizza_rounded',
    'local_play_rounded',
    'local_police_rounded',
    'local_post_office_rounded',
    'local_printshop_rounded',
    'local_see_rounded',
    'local_shipping_rounded',
    'local_taxi_rounded',
    'location_city_rounded',
    'location_disabled_rounded',
    'location_off_rounded',
    'location_on_rounded',
    'location_searching_rounded',
    'lock_clock_rounded',
    'lock_open_rounded',
    'lock_person_rounded',
    'lock_reset_rounded',
    'lock_rounded',
    'login_rounded',
    'logo_dev_rounded',
    'logout_rounded',
    'looks_3_rounded',
    'looks_4_rounded',
    'looks_5_rounded',
    'looks_6_rounded',
    'looks_one_rounded',
    'looks_rounded',
    'looks_two_rounded',
    'loop_rounded',
    'loupe_rounded',
    'low_priority_rounded',
    'loyalty_rounded',
    'lte_mobiledata_rounded',
    'lte_plus_mobiledata_rounded',
    'luggage_rounded',
    'lunch_dining_rounded',
    'lyrics_rounded',
    'macro_off_rounded',
    'mail_lock_rounded',
    'mail_outline_rounded',
    'mail_rounded',
    'male_rounded',
    'man_2_rounded',
    'man_3_rounded',
    'man_4_rounded',
    'man_rounded',
    'manage_accounts_rounded',
    'manage_history_rounded',
    'manage_search_rounded',
    'map_rounded',
    'maps_home_work_rounded',
    'maps_ugc_rounded',
    'margin_rounded',
    'mark_as_unread_rounded',
    'mark_chat_read_rounded',
    'mark_chat_unread_rounded',
    'mark_email_read_rounded',
    'mark_email_unread_rounded',
    'mark_unread_chat_alt_rounded',
    'markunread_mailbox_rounded',
    'markunread_rounded',
    'masks_rounded',
    'maximize_rounded',
    'media_bluetooth_off_rounded',
    'media_bluetooth_on_rounded',
    'mediation_rounded',
    'medical_information_rounded',
    'medical_services_rounded',
    'medication_liquid_rounded',
    'medication_rounded',
    'meeting_room_rounded',
    'memory_rounded',
    'menu_book_rounded',
    'menu_open_rounded',
    'menu_rounded',
    'merge_rounded',
    'merge_type_rounded',
    'message_rounded',
    'mic_external_off_rounded',
    'mic_external_on_rounded',
    'mic_none_rounded',
    'mic_off_rounded',
    'mic_rounded',
    'microwave_rounded',
    'military_tech_rounded',
    'minimize_rounded',
    'minor_crash_rounded',
    'miscellaneous_services_rounded',
    'missed_video_call_rounded',
    'mms_rounded',
    'mobile_friendly_rounded',
    'mobile_off_rounded',
    'mobile_screen_share_rounded',
    'mobiledata_off_rounded',
    'mode_comment_rounded',
    'mode_edit_outline_rounded',
    'mode_edit_rounded',
    'mode_fan_off_rounded',
    'mode_night_rounded',
    'mode_of_travel_rounded',
    'mode_rounded',
    'mode_standby_rounded',
    'model_training_rounded',
    'monetization_on_rounded',
    'money_off_csred_rounded',
    'money_off_rounded',
    'money_rounded',
    'monitor_heart_rounded',
    'monitor_rounded',
    'monitor_weight_rounded',
    'monochrome_photos_rounded',
    'mood_bad_rounded',
    'mood_rounded',
    'moped_rounded',
    'more_horiz_rounded',
    'more_rounded',
    'more_time_rounded',
    'more_vert_rounded',
    'mosque_rounded',
    'motion_photos_auto_rounded',
    'motion_photos_off_rounded',
    'mouse_rounded',
    'move_down_rounded',
    'move_to_inbox_rounded',
    'move_up_rounded',
    'movie_creation_rounded',
    'movie_filter_rounded',
    'movie_rounded',
    'moving_rounded',
    'mp_rounded',
    'multiline_chart_rounded',
    'multiple_stop_rounded',
    'museum_rounded',
    'music_note_rounded',
    'music_off_rounded',
    'music_video_rounded',
    'my_location_rounded',
    'nat_rounded',
    'nature_people_rounded',
    'nature_rounded',
    'navigate_before_rounded',
    'navigate_next_rounded',
    'navigation_rounded',
    'near_me_disabled_rounded',
    'near_me_rounded',
    'nearby_error_rounded',
    'nearby_off_rounded',
    'nest_cam_wired_stand_rounded',
    'network_cell_rounded',
    'network_check_rounded',
    'network_locked_rounded',
    'network_ping_rounded',
    'network_wifi_1_bar_rounded',
    'network_wifi_2_bar_rounded',
    'network_wifi_3_bar_rounded',
    'network_wifi_rounded',
    'new_releases_rounded',
    'newspaper_rounded',
    'next_plan_rounded',
    'next_week_rounded',
    'nfc_rounded',
    'night_shelter_rounded',
    'nightlife_rounded',
    'nightlight_round_rounded',
    'nightlight_rounded',
    'nights_stay_rounded',
    'nine_k_plus_rounded',
    'nine_k_rounded',
    'nine_mp_rounded',
    'nineteen_mp_rounded',
    'no_accounts_rounded',
    'no_adult_content_rounded',
    'no_backpack_rounded',
    'no_cell_rounded',
    'no_crash_rounded',
    'no_drinks_rounded',
    'no_encryption_gmailerrorred_rounded',
    'no_encryption_rounded',
    'no_flash_rounded',
    'no_food_rounded',
    'no_luggage_rounded',
    'no_meals_rounded',
    'no_meeting_room_rounded',
    'no_photography_rounded',
    'no_sim_rounded',
    'no_stroller_rounded',
    'no_transfer_rounded',
    'noise_aware_rounded',
    'noise_control_off_rounded',
    'nordic_walking_rounded',
    'north_east_rounded',
    'north_rounded',
    'north_west_rounded',
    'not_accessible_rounded',
    'not_interested_rounded',
    'not_listed_location_rounded',
    'not_started_rounded',
    'note_add_rounded',
    'note_alt_rounded',
    'note_rounded',
    'notes_rounded',
    'notification_add_rounded',
    'notification_important_rounded',
    'notifications_active_rounded',
    'notifications_none_rounded',
    'notifications_off_rounded',
    'notifications_paused_rounded',
    'notifications_rounded',
    'numbers_rounded',
    'offline_bolt_rounded',
    'offline_pin_rounded',
    'offline_share_rounded',
    'oil_barrel_rounded',
    'on_device_training_rounded',
    'ondemand_video_rounded',
    'one_k_plus_rounded',
    'one_k_rounded',
    'one_kk_rounded',
    'online_prediction_rounded',
    'opacity_rounded',
    'open_in_browser_rounded',
    'open_in_full_rounded',
    'open_in_new_off_rounded',
    'open_in_new_rounded',
    'open_with_rounded',
    'other_houses_rounded',
    'outbound_rounded',
    'outbox_rounded',
    'outdoor_grill_rounded',
    'outlet_rounded',
    'outlined_flag_rounded',
    'output_rounded',
    'padding_rounded',
    'pages_rounded',
    'pageview_rounded',
    'paid_rounded',
    'palette_rounded',
    'pan_tool_alt_rounded',
    'pan_tool_rounded',
    'panorama_fish_eye_rounded',
    'panorama_horizontal_rounded',
    'panorama_horizontal_select_rounded',
    'panorama_photosphere_rounded',
    'panorama_photosphere_select_rounded',
    'panorama_rounded',
    'panorama_vertical_rounded',
    'panorama_vertical_select_rounded',
    'panorama_wide_angle_rounded',
    'panorama_wide_angle_select_rounded',
    'paragliding_rounded',
    'park_rounded',
    'party_mode_rounded',
    'password_rounded',
    'pattern_rounded',
    'pause_circle_filled_rounded',
    'pause_circle_outline_rounded',
    'pause_circle_rounded',
    'pause_presentation_rounded',
    'pause_rounded',
    'payment_rounded',
    'payments_rounded',
    'pedal_bike_rounded',
    'pending_actions_rounded',
    'pending_rounded',
    'pentagon_rounded',
    'people_alt_rounded',
    'people_outline_rounded',
    'people_rounded',
    'percent_rounded',
    'perm_camera_mic_rounded',
    'perm_contact_calendar_rounded',
    'perm_data_setting_rounded',
    'perm_device_information_rounded',
    'perm_identity_rounded',
    'perm_media_rounded',
    'perm_phone_msg_rounded',
    'perm_scan_wifi_rounded',
    'person_2_rounded',
    'person_3_rounded',
    'person_4_rounded',
    'person_add_alt_1_rounded',
    'person_add_alt_rounded',
    'person_add_disabled_rounded',
    'person_add_rounded',
    'person_off_rounded',
    'person_outline_rounded',
    'person_pin_circle_rounded',
    'person_pin_rounded',
    'person_remove_alt_1_rounded',
    'person_remove_rounded',
    'person_rounded',
    'person_search_rounded',
    'personal_video_rounded',
    'pest_control_rodent_rounded',
    'pest_control_rounded',
    'pets_rounded',
    'phishing_rounded',
    'phone_android_rounded',
    'phone_bluetooth_speaker_rounded',
    'phone_callback_rounded',
    'phone_disabled_rounded',
    'phone_enabled_rounded',
    'phone_forwarded_rounded',
    'phone_in_talk_rounded',
    'phone_iphone_rounded',
    'phone_locked_rounded',
    'phone_missed_rounded',
    'phone_paused_rounded',
    'phone_rounded',
    'phonelink_erase_rounded',
    'phonelink_lock_rounded',
    'phonelink_off_rounded',
    'phonelink_ring_rounded',
    'phonelink_rounded',
    'phonelink_setup_rounded',
    'photo_album_rounded',
    'photo_camera_back_rounded',
    'photo_camera_front_rounded',
    'photo_camera_rounded',
    'photo_filter_rounded',
    'photo_library_rounded',
    'photo_rounded',
    'photo_size_select_actual_rounded',
    'photo_size_select_large_rounded',
    'photo_size_select_small_rounded',
    'php_rounded',
    'piano_off_rounded',
    'piano_rounded',
    'picture_as_pdf_rounded',
    'picture_in_picture_alt_rounded',
    'picture_in_picture_rounded',
    'pie_chart_outline_rounded',
    'pie_chart_rounded',
    'pin_drop_rounded',
    'pin_rounded',
    'pinch_rounded',
    'pivot_table_chart_rounded',
    'pix_rounded',
    'place_rounded',
    'plagiarism_rounded',
    'play_arrow_rounded',
    'play_circle_filled_rounded',
    'play_circle_filled_white_rounded',
    'play_circle_outline_rounded',
    'play_circle_rounded',
    'play_disabled_rounded',
    'play_for_work_rounded',
    'play_lesson_rounded',
    'playlist_add_check_circle_rounded',
    'playlist_add_check_rounded',
    'playlist_add_circle_rounded',
    'playlist_add_rounded',
    'playlist_play_rounded',
    'playlist_remove_rounded',
    'plumbing_rounded',
    'plus_one_rounded',
    'podcasts_rounded',
    'point_of_sale_rounded',
    'policy_rounded',
    'poll_rounded',
    'polyline_rounded',
    'pool_rounded',
    'portable_wifi_off_rounded',
    'portrait_rounded',
    'post_add_rounded',
    'power_input_rounded',
    'power_off_rounded',
    'power_rounded',
    'power_settings_new_rounded',
    'precision_manufacturing_rounded',
    'pregnant_woman_rounded',
    'present_to_all_rounded',
    'preview_rounded',
    'price_change_rounded',
    'price_check_rounded',
    'print_disabled_rounded',
    'print_rounded',
    'priority_high_rounded',
    'privacy_tip_rounded',
    'production_quantity_limits_rounded',
    'propane_rounded',
    'propane_tank_rounded',
    'psychology_alt_rounded',
    'psychology_rounded',
    'public_off_rounded',
    'public_rounded',
    'publish_rounded',
    'published_with_changes_rounded',
    'punch_clock_rounded',
    'push_pin_rounded',
    'qr_code_2_rounded',
    'qr_code_rounded',
    'qr_code_scanner_rounded',
    'query_builder_rounded',
    'query_stats_rounded',
    'question_answer_rounded',
    'question_mark_rounded',
    'queue_music_rounded',
    'queue_play_next_rounded',
    'queue_rounded',
    'quickreply_rounded',
    'quiz_rounded',
    'r_mobiledata_rounded',
    'radar_rounded',
    'radio_button_checked_rounded',
    'radio_button_unchecked_rounded',
    'radio_rounded',
    'railway_alert_rounded',
    'ramen_dining_rounded',
    'ramp_left_rounded',
    'ramp_right_rounded',
    'rate_review_rounded',
    'raw_off_rounded',
    'raw_on_rounded',
    'read_more_rounded',
    'receipt_long_rounded',
    'receipt_rounded',
    'recent_actors_rounded',
    'recommend_rounded',
    'record_voice_over_rounded',
    'rectangle_rounded',
    'recycling_rounded',
    'redeem_rounded',
    'redo_rounded',
    'reduce_capacity_rounded',
    'refresh_rounded',
    'remember_me_rounded',
    'remove_circle_outline_rounded',
    'remove_circle_rounded',
    'remove_done_rounded',
    'remove_from_queue_rounded',
    'remove_moderator_rounded',
    'remove_red_eye_rounded',
    'remove_road_rounded',
    'remove_rounded',
    'remove_shopping_cart_rounded',
    'reorder_rounded',
    'repartition_rounded',
    'repeat_on_rounded',
    'repeat_one_on_rounded',
    'repeat_one_rounded',
    'repeat_rounded',
    'replay_10_rounded',
    'replay_30_rounded',
    'replay_5_rounded',
    'replay_circle_filled_rounded',
    'replay_rounded',
    'reply_all_rounded',
    'reply_rounded',
    'report_gmailerrorred_rounded',
    'report_off_rounded',
    'report_problem_rounded',
    'report_rounded',
    'request_page_rounded',
    'request_quote_rounded',
    'reset_tv_rounded',
    'restart_alt_rounded',
    'restaurant_menu_rounded',
    'restaurant_rounded',
    'restore_from_trash_rounded',
    'restore_page_rounded',
    'restore_rounded',
    'reviews_rounded',
    'rice_bowl_rounded',
    'ring_volume_rounded',
    'rocket_launch_rounded',
    'rocket_rounded',
    'roller_shades_closed_rounded',
    'roller_shades_rounded',
    'roller_skating_rounded',
    'roofing_rounded',
    'room_preferences_rounded',
    'room_rounded',
    'room_service_rounded',
    'rotate_90_degrees_ccw_rounded',
    'rotate_90_degrees_cw_rounded',
    'rotate_left_rounded',
    'rotate_right_rounded',
    'roundabout_left_rounded',
    'roundabout_right_rounded',
    'rounded_corner_rounded',
    'route_rounded',
    'router_rounded',
    'rowing_rounded',
    'rss_feed_rounded',
    'rsvp_rounded',
    'rtt_rounded',
    'rule_folder_rounded',
    'rule_rounded',
    'run_circle_rounded',
    'running_with_errors_rounded',
    'rv_hookup_rounded',
    'safety_check_rounded',
    'safety_divider_rounded',
    'sailing_rounded',
    'sanitizer_rounded',
    'satellite_alt_rounded',
    'satellite_rounded',
    'save_alt_rounded',
    'save_as_rounded',
    'save_rounded',
    'saved_search_rounded',
    'savings_rounded',
    'scale_rounded',
    'scanner_rounded',
    'scatter_plot_rounded',
    'schedule_rounded',
    'schedule_send_rounded',
    'schema_rounded',
    'school_rounded',
    'science_rounded',
    'score_rounded',
    'scoreboard_rounded',
    'screen_lock_landscape_rounded',
    'screen_lock_portrait_rounded',
    'screen_lock_rotation_rounded',
    'screen_rotation_alt_rounded',
    'screen_rotation_rounded',
    'screen_search_desktop_rounded',
    'screen_share_rounded',
    'screenshot_monitor_rounded',
    'screenshot_rounded',
    'scuba_diving_rounded',
    'sd_card_alert_rounded',
    'sd_card_rounded',
    'sd_rounded',
    'sd_storage_rounded',
    'search_off_rounded',
    'search_rounded',
    'security_rounded',
    'security_update_good_rounded',
    'security_update_rounded',
    'security_update_warning_rounded',
    'segment_rounded',
    'select_all_rounded',
    'self_improvement_rounded',
    'sell_rounded',
    'send_and_archive_rounded',
    'send_rounded',
    'send_time_extension_rounded',
    'send_to_mobile_rounded',
    'sensor_door_rounded',
    'sensor_occupied_rounded',
    'sensor_window_rounded',
    'sensors_off_rounded',
    'sensors_rounded',
    'sentiment_dissatisfied_rounded',
    'sentiment_neutral_rounded',
    'sentiment_satisfied_alt_rounded',
    'sentiment_satisfied_rounded',
    'sentiment_very_dissatisfied_rounded',
    'sentiment_very_satisfied_rounded',
    'set_meal_rounded',
    'settings_accessibility_rounded',
    'settings_applications_rounded',
    'settings_backup_restore_rounded',
    'settings_bluetooth_rounded',
    'settings_brightness_rounded',
    'settings_cell_rounded',
    'settings_ethernet_rounded',
    'settings_input_antenna_rounded',
    'settings_input_component_rounded',
    'settings_input_composite_rounded',
    'settings_input_hdmi_rounded',
    'settings_input_svideo_rounded',
    'settings_overscan_rounded',
    'settings_phone_rounded',
    'settings_power_rounded',
    'settings_remote_rounded',
    'settings_rounded',
    'settings_suggest_rounded',
    'settings_system_daydream_rounded',
    'settings_voice_rounded',
    'seven_k_plus_rounded',
    'seven_k_rounded',
    'seven_mp_rounded',
    'seventeen_mp_rounded',
    'severe_cold_rounded',
    'shape_line_rounded',
    'share_location_rounded',
    'share_rounded',
    'shield_moon_rounded',
    'shield_rounded',
    'shop_2_rounded',
    'shop_rounded',
    'shop_two_rounded',
    'shopping_bag_rounded',
    'shopping_basket_rounded',
    'shopping_cart_checkout_rounded',
    'shopping_cart_rounded',
    'short_text_rounded',
    'shortcut_rounded',
    'show_chart_rounded',
    'shower_rounded',
    'shuffle_on_rounded',
    'shuffle_rounded',
    'shutter_speed_rounded',
    'sick_rounded',
    'sign_language_rounded',
    'signal_cellular_0_bar_rounded',
    'signal_cellular_1_bar_rounded',
    'signal_cellular_2_bar_rounded',
    'signal_cellular_3_bar_rounded',
    'signal_cellular_4_bar_rounded',
    'signal_cellular_alt_1_bar_rounded',
    'signal_cellular_alt_2_bar_rounded',
    'signal_cellular_alt_rounded',
    'signal_cellular_connected_no_internet_0_bar_rounded',
    'signal_cellular_connected_no_internet_1_bar_rounded',
    'signal_cellular_connected_no_internet_2_bar_rounded',
    'signal_cellular_connected_no_internet_3_bar_rounded',
    'signal_cellular_connected_no_internet_4_bar_rounded',
    'signal_cellular_no_sim_rounded',
    'signal_cellular_nodata_rounded',
    'signal_cellular_null_rounded',
    'signal_cellular_off_rounded',
    'signal_wifi_0_bar_rounded',
    'signal_wifi_1_bar_lock_rounded',
    'signal_wifi_1_bar_rounded',
    'signal_wifi_2_bar_lock_rounded',
    'signal_wifi_2_bar_rounded',
    'signal_wifi_3_bar_lock_rounded',
    'signal_wifi_3_bar_rounded',
    'signal_wifi_4_bar_lock_rounded',
    'signal_wifi_4_bar_rounded',
    'signal_wifi_bad_rounded',
    'signal_wifi_connected_no_internet_4_rounded',
    'signal_wifi_off_rounded',
    'signal_wifi_statusbar_4_bar_rounded',
    'signal_wifi_statusbar_connected_no_internet_4_rounded',
    'signal_wifi_statusbar_null_rounded',
    'signpost_rounded',
    'sim_card_alert_rounded',
    'sim_card_download_rounded',
    'sim_card_rounded',
    'single_bed_rounded',
    'sip_rounded',
    'six_k_plus_rounded',
    'six_k_rounded',
    'six_mp_rounded',
    'sixteen_mp_rounded',
    'sixty_fps_rounded',
    'sixty_fps_select_rounded',
    'skateboarding_rounded',
    'skip_next_rounded',
    'skip_previous_rounded',
    'sledding_rounded',
    'slideshow_rounded',
    'slow_motion_video_rounded',
    'smart_button_rounded',
    'smart_display_rounded',
    'smart_screen_rounded',
    'smart_toy_rounded',
    'smartphone_rounded',
    'smoke_free_rounded',
    'smoking_rooms_rounded',
    'sms_failed_rounded',
    'sms_rounded',
    'snippet_folder_rounded',
    'snooze_rounded',
    'snowboarding_rounded',
    'snowmobile_rounded',
    'snowshoeing_rounded',
    'soap_rounded',
    'social_distance_rounded',
    'solar_power_rounded',
    'sort_by_alpha_rounded',
    'sort_rounded',
    'sos_rounded',
    'soup_kitchen_rounded',
    'source_rounded',
    'south_america_rounded',
    'south_east_rounded',
    'south_rounded',
    'south_west_rounded',
    'spa_rounded',
    'space_bar_rounded',
    'space_dashboard_rounded',
    'spatial_audio_off_rounded',
    'spatial_audio_rounded',
    'spatial_tracking_rounded',
    'speaker_group_rounded',
    'speaker_notes_off_rounded',
    'speaker_notes_rounded',
    'speaker_phone_rounded',
    'speaker_rounded',
    'speed_rounded',
    'spellcheck_rounded',
    'splitscreen_rounded',
    'spoke_rounded',
    'sports_bar_rounded',
    'sports_baseball_rounded',
    'sports_basketball_rounded',
    'sports_cricket_rounded',
    'sports_esports_rounded',
    'sports_football_rounded',
    'sports_golf_rounded',
    'sports_gymnastics_rounded',
    'sports_handball_rounded',
    'sports_hockey_rounded',
    'sports_kabaddi_rounded',
    'sports_martial_arts_rounded',
    'sports_mma_rounded',
    'sports_motorsports_rounded',
    'sports_rounded',
    'sports_rugby_rounded',
    'sports_score_rounded',
    'sports_soccer_rounded',
    'sports_tennis_rounded',
    'sports_volleyball_rounded',
    'square_foot_rounded',
    'square_rounded',
    'ssid_chart_rounded',
    'stacked_bar_chart_rounded',
    'stacked_line_chart_rounded',
    'stadium_rounded',
    'stairs_rounded',
    'star_border_purple_500_rounded',
    'star_border_rounded',
    'star_half_rounded',
    'star_outline_rounded',
    'star_purple_500_rounded',
    'star_rate_rounded',
    'star_rounded',
    'stars_rounded',
    'start_rounded',
    'stay_current_landscape_rounded',
    'stay_current_portrait_rounded',
    'stay_primary_landscape_rounded',
    'stay_primary_portrait_rounded',
    'sticky_note_2_rounded',
    'stop_circle_rounded',
    'stop_rounded',
    'stop_screen_share_rounded',
    'storage_rounded',
    'store_mall_directory_rounded',
    'store_rounded',
    'storefront_rounded',
    'storm_rounded',
    'straight_rounded',
    'straighten_rounded',
    'stream_rounded',
    'streetview_rounded',
    'strikethrough_s_rounded',
    'stroller_rounded',
    'style_rounded',
    'subdirectory_arrow_left_rounded',
    'subdirectory_arrow_right_rounded',
    'subject_rounded',
    'subscript_rounded',
    'subscriptions_rounded',
    'subtitles_off_rounded',
    'subtitles_rounded',
    'subway_rounded',
    'summarize_rounded',
    'superscript_rounded',
    'supervised_user_circle_rounded',
    'supervisor_account_rounded',
    'support_agent_rounded',
    'support_rounded',
    'surfing_rounded',
    'surround_sound_rounded',
    'swap_calls_rounded',
    'swap_horiz_rounded',
    'swap_horizontal_circle_rounded',
    'swap_vert_rounded',
    'swap_vertical_circle_rounded',
    'swipe_down_alt_rounded',
    'swipe_down_rounded',
    'swipe_left_alt_rounded',
    'swipe_left_rounded',
    'swipe_right_alt_rounded',
    'swipe_right_rounded',
    'swipe_rounded',
    'swipe_up_alt_rounded',
    'swipe_up_rounded',
    'swipe_vertical_rounded',
    'switch_access_shortcut_add_rounded',
    'switch_access_shortcut_rounded',
    'switch_account_rounded',
    'switch_camera_rounded',
    'switch_left_rounded',
    'switch_right_rounded',
    'switch_video_rounded',
    'synagogue_rounded',
    'sync_alt_rounded',
    'sync_disabled_rounded',
    'sync_lock_rounded',
    'sync_problem_rounded',
    'sync_rounded',
    'system_security_update_good_rounded',
    'system_security_update_rounded',
    'system_security_update_warning_rounded',
    'system_update_alt_rounded',
    'system_update_rounded',
    'tab_rounded',
    'tab_unselected_rounded',
    'table_bar_rounded',
    'table_chart_rounded',
    'table_restaurant_rounded',
    'table_rows_rounded',
    'table_view_rounded',
    'tablet_android_rounded',
    'tablet_mac_rounded',
    'tablet_rounded',
    'tag_faces_rounded',
    'tag_rounded',
    'takeout_dining_rounded',
    'tap_and_play_rounded',
    'tapas_rounded',
    'task_alt_rounded',
    'task_rounded',
    'taxi_alert_rounded',
    'temple_buddhist_rounded',
    'temple_hindu_rounded',
    'ten_mp_rounded',
    'terminal_rounded',
    'terrain_rounded',
    'text_decrease_rounded',
    'text_fields_rounded',
    'text_format_rounded',
    'text_increase_rounded',
    'text_rotate_up_rounded',
    'text_rotate_vertical_rounded',
    'text_rotation_angledown_rounded',
    'text_rotation_angleup_rounded',
    'text_rotation_down_rounded',
    'text_rotation_none_rounded',
    'text_snippet_rounded',
    'textsms_rounded',
    'texture_rounded',
    'theater_comedy_rounded',
    'theaters_rounded',
    'thermostat_auto_rounded',
    'thermostat_rounded',
    'thirteen_mp_rounded',
    'thirty_fps_rounded',
    'thirty_fps_select_rounded',
    'three_d_rotation_rounded',
    'three_g_mobiledata_rounded',
    'three_k_plus_rounded',
    'three_k_rounded',
    'three_mp_rounded',
    'three_p_rounded',
    'three_sixty_rounded',
    'thumb_down_alt_rounded',
    'thumb_down_off_alt_rounded',
    'thumb_down_rounded',
    'thumb_up_alt_rounded',
    'thumb_up_off_alt_rounded',
    'thumb_up_rounded',
    'thumbs_up_down_rounded',
    'thunderstorm_rounded',
    'time_to_leave_rounded',
    'timelapse_rounded',
    'timeline_rounded',
    'timer_10_rounded',
    'timer_10_select_rounded',
    'timer_3_rounded',
    'timer_3_select_rounded',
    'timer_off_rounded',
    'timer_rounded',
    'times_one_mobiledata_rounded',
    'tips_and_updates_rounded',
    'tire_repair_rounded',
    'title_rounded',
    'toc_rounded',
    'today_rounded',
    'toggle_off_rounded',
    'toggle_on_rounded',
    'token_rounded',
    'toll_rounded',
    'tonality_rounded',
    'topic_rounded',
    'tornado_rounded',
    'touch_app_rounded',
    'tour_rounded',
    'toys_rounded',
    'track_changes_rounded',
    'traffic_rounded',
    'train_rounded',
    'tram_rounded',
    'transcribe_rounded',
    'transfer_within_a_station_rounded',
    'transform_rounded',
    'transgender_rounded',
    'transit_enterexit_rounded',
    'translate_rounded',
    'travel_explore_rounded',
    'trending_down_rounded',
    'trending_flat_rounded',
    'trending_up_rounded',
    'trip_origin_rounded',
    'troubleshoot_rounded',
    'try_rounded',
    'tsunami_rounded',
    'tty_rounded',
    'tune_rounded',
    'tungsten_rounded',
    'turn_left_rounded',
    'turn_right_rounded',
    'turn_sharp_left_rounded',
    'turn_sharp_right_rounded',
    'turn_slight_left_rounded',
    'turn_slight_right_rounded',
    'turned_in_not_rounded',
    'turned_in_rounded',
    'tv_off_rounded',
    'tv_rounded',
    'twelve_mp_rounded',
    'twenty_four_mp_rounded',
    'twenty_one_mp_rounded',
    'twenty_three_mp_rounded',
    'twenty_two_mp_rounded',
    'twenty_zero_mp_rounded',
    'two_k_plus_rounded',
    'two_k_rounded',
    'two_mp_rounded',
    'two_wheeler_rounded',
    'type_specimen_rounded',
    'u_turn_left_rounded',
    'u_turn_right_rounded',
    'umbrella_rounded',
    'unarchive_rounded',
    'undo_rounded',
    'unfold_less_double_rounded',
    'unfold_less_rounded',
    'unfold_more_double_rounded',
    'unfold_more_rounded',
    'unpublished_rounded',
    'unsubscribe_rounded',
    'upcoming_rounded',
    'update_disabled_rounded',
    'update_rounded',
    'upgrade_rounded',
    'upload_file_rounded',
    'upload_rounded',
    'usb_off_rounded',
    'usb_rounded',
    'vaccines_rounded',
    'vape_free_rounded',
    'vaping_rooms_rounded',
    'verified_rounded',
    'verified_user_rounded',
    'vertical_align_bottom_rounded',
    'vertical_align_center_rounded',
    'vertical_align_top_rounded',
    'vertical_shades_closed_rounded',
    'vertical_shades_rounded',
    'vertical_split_rounded',
    'vibration_rounded',
    'video_call_rounded',
    'video_camera_back_rounded',
    'video_camera_front_rounded',
    'video_chat_rounded',
    'video_file_rounded',
    'video_label_rounded',
    'video_library_rounded',
    'video_settings_rounded',
    'video_stable_rounded',
    'videocam_off_rounded',
    'videocam_rounded',
    'videogame_asset_off_rounded',
    'videogame_asset_rounded',
    'view_agenda_rounded',
    'view_array_rounded',
    'view_carousel_rounded',
    'view_column_rounded',
    'view_comfy_alt_rounded',
    'view_comfy_rounded',
    'view_compact_alt_rounded',
    'view_compact_rounded',
    'view_cozy_rounded',
    'view_day_rounded',
    'view_headline_rounded',
    'view_in_ar_rounded',
    'view_kanban_rounded',
    'view_list_rounded',
    'view_module_rounded',
    'view_quilt_rounded',
    'view_sidebar_rounded',
    'view_stream_rounded',
    'view_timeline_rounded',
    'view_week_rounded',
    'vignette_rounded',
    'villa_rounded',
    'visibility_off_rounded',
    'visibility_rounded',
    'voice_chat_rounded',
    'voice_over_off_rounded',
    'voicemail_rounded',
    'volcano_rounded',
    'volume_down_rounded',
    'volume_mute_rounded',
    'volume_off_rounded',
    'volume_up_rounded',
    'volunteer_activism_rounded',
    'vpn_key_off_rounded',
    'vpn_key_rounded',
    'vpn_lock_rounded',
    'vrpano_rounded',
    'wallet_rounded',
    'wallpaper_rounded',
    'warehouse_rounded',
    'warning_amber_rounded',
    'warning_rounded',
    'wash_rounded',
    'watch_later_rounded',
    'watch_off_rounded',
    'watch_rounded',
    'water_damage_rounded',
    'water_drop_rounded',
    'water_rounded',
    'waterfall_chart_rounded',
    'waves_rounded',
    'waving_hand_rounded',
    'wb_auto_rounded',
    'wb_cloudy_rounded',
    'wb_incandescent_rounded',
    'wb_iridescent_rounded',
    'wb_shade_rounded',
    'wb_sunny_rounded',
    'wb_twilight_rounded',
    'wc_rounded',
    'web_asset_off_rounded',
    'web_asset_rounded',
    'web_rounded',
    'web_stories_rounded',
    'webhook_rounded',
    'weekend_rounded',
    'west_rounded',
    'whatshot_rounded',
    'wheelchair_pickup_rounded',
    'where_to_vote_rounded',
    'widgets_rounded',
    'width_full_rounded',
    'width_normal_rounded',
    'width_wide_rounded',
    'wifi_1_bar_rounded',
    'wifi_2_bar_rounded',
    'wifi_calling_3_rounded',
    'wifi_calling_rounded',
    'wifi_channel_rounded',
    'wifi_find_rounded',
    'wifi_lock_rounded',
    'wifi_off_rounded',
    'wifi_password_rounded',
    'wifi_protected_setup_rounded',
    'wifi_rounded',
    'wifi_tethering_error_rounded',
    'wifi_tethering_error_rounded_rounded',
    'wifi_tethering_off_rounded',
    'wifi_tethering_rounded',
    'wind_power_rounded',
    'window_rounded',
    'wine_bar_rounded',
    'woman_2_rounded',
    'woman_rounded',
    'work_history_rounded',
    'work_off_rounded',
    'work_outline_rounded',
    'work_rounded',
    'workspace_premium_rounded',
    'workspaces_rounded',
    'wrap_text_rounded',
    'wrong_location_rounded',
    'wysiwyg_rounded',
    'yard_rounded',
    'youtube_searched_for_rounded',
    'zoom_in_map_rounded',
    'zoom_in_rounded',
    'zoom_out_map_rounded',
    'zoom_out_rounded',
]
