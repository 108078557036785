import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { ModalContainersStore } from '@components-library/tb-modal-manager/modal-containers.store'
import { BottomSheetInstanceService } from '@components-library/tb-modal-manager/modal-instances/bottom-sheet-instance.service'
import { DialogInstanceService } from '@components-library/tb-modal-manager/modal-instances/dialog-instance.service'

/**
 * This service is used to create Modal Instances like BottomSheet and Dialog.
 **/
@Injectable({
    providedIn: 'root',
})
export class ModalInstanceFactory {
    constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet) {}

    createBottomSheetInstance(containersStore: ModalContainersStore) {
        return new BottomSheetInstanceService(containersStore, this.bottomSheet)
    }

    createDialogInstance(containersStore: ModalContainersStore) {
        return new DialogInstanceService(containersStore, this.dialog)
    }
}
