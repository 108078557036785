import { Component } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { PinDraftRecord } from '@app/feature/pin-panel/pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { isNonNull } from '@core/global-util'
import { Schema } from '@core/models'
import { SchemaFacadeService } from '@core/services/store-facade'
import { filter, Observable } from 'rxjs'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-header-name',
    templateUrl: './header-name.component.html',
})
export class HeaderNameComponent {
    readonly selectedSchema$: Observable<Schema> =
        this.schemaFacadeService.selectSelectedTableSchema$.pipe(filter(isNonNull))

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    newRecord() {
        this.modalFlowManagerService
            .openDialog<AddRecordContentComponent, PinDraftRecord | null>({
                component: AddRecordContentComponent,
            })
            .pipe(take(1))
    }

    manageFields(modalManager: ModalManagerService) {
        modalManager.openDialog({ component: ManageFieldsComponent })
    }
}
