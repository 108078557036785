import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconModule } from '@components-library/tb-icon/tb-icon.module'

@NgModule({
    declarations: [TbButtonComponent],
    imports: [CommonModule, TbIconModule],
    exports: [TbButtonComponent],
})
export class TbButtonModule {}
