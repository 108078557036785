<div
    cdkDrag
    cdkDragBoundary=".sort-container"
    [cdkDragData]="sortObject.fieldGuid"
    class="flex items-center mb-4 dense-1"
>
    <app-tb-icon class="mr-2" icon="drag_indicator" color="newTextLight"></app-tb-icon>
    <app-tb-select
        class="w-full noHintError"
        placeholder="Select"
        [formControl]="sortSelectionControl"
    >
        <app-tb-option *ngFor="let field of fields" [value]="field.guid">
            <app-field-name
                [field]="field"
                [selectedFolder]="selectedFolder"
                [showIcon]="false"
            ></app-field-name>
        </app-tb-option>
    </app-tb-select>

    <div class="px-2 flex flex-column self-stretch justify-center">
        <mat-icon
            [class.text-secondaryMain]="sortDirectionControl.value === 'asc'"
            svgIcon="custom_sort_up"
            class="cursor-pointer h-4 w-4 subheading-1 leading-4 text-primarySecondary"
            (click)="changeDirection('asc')"
        ></mat-icon>
        <mat-icon
            [class.text-secondaryMain]="sortDirectionControl.value === 'desc'"
            svgIcon="custom_sort_down"
            class="cursor-pointer h-4 w-4 subheading-1 leading-4 text-primarySecondary"
            (click)="changeDirection('desc')"
        ></mat-icon>
    </div>
    <app-tb-icon
        (click)="removeItem()"
        class="cursor-pointer w-6 h-6 text-2xl leading-6 mr-1"
        icon="close"
    >
    </app-tb-icon>
</div>
