<div class="flex-start-center w-100">
    <ng-container *ngIf="isDeal(record); else titleName">
        <h1 *ngIf="fields[record.vendor_name.fieldGuid]">
            <app-text-one-line
                (cellValueChanged)="updateRecord(record, record.vendor_name, $event)"
                [fieldType]="fieldTypes[record.vendor_name.fieldType]"
                [field]="fields[record.vendor_name.fieldGuid]"
                [record]="record"
                [value]="record.vendor_name.value"
                class="flex-start-center text-center"
            ></app-text-one-line>
        </h1>
    </ng-container>
    <ng-template #titleName>
        <h1 *ngIf="fields[record.name.fieldGuid]">
            <app-name
                (cellValueChanged)="updateRecord(record, record.name, $event)"
                [fieldType]="fieldTypes[record.name.fieldType]"
                [field]="fields[record.name.fieldGuid]"
                [record]="record"
                [value]="record.name.value"
                class="flex-start-center text-center"
            ></app-name>
        </h1>
    </ng-template>

    <div *ngIf="selectedRecordAssignee">
        <app-assign
            (cellValueChanged)="updateRecord(record, record.assignee[folder.guid], $event)"
            [fieldType]="fieldTypes[record.assignee[folder.guid].fieldType]"
            [field]="fields[record.assignee[folder.guid].fieldGuid]"
            [record]="record"
            [value]="record.assignee[folder.guid].value"
            class="flex-start-center text-center"
        ></app-assign>
    </div>
</div>
