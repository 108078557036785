<ng-container *transloco="let tButtons; read: 'buttons'">
    <app-tb-menu-list *ngIf="field.operationCode as oc">
        <app-tb-menu-list-item
            *appOperation="oc; target: 'update'"
            (click)="editField()"
            icon="custom_edit"
        >
            {{ tButtons('edit') }}
        </app-tb-menu-list-item>
        <app-tb-menu-list-item
            *appOperation="oc; target: 'copy'"
            (click)="duplicateField()"
            icon="custom_copy"
        >
            {{ tButtons('duplicate') }}
        </app-tb-menu-list-item>
        <app-tb-menu-list-item
            *appOperation="oc; target: 'freeze'; condition: !isGlobalFolder"
            icon="custom_share"
        >
            {{ tButtons('share') }} (not_implemented)
        </app-tb-menu-list-item>

        <ng-container *appOperation="oc; target: 'delete'">
            <app-tb-divider></app-tb-divider>
            <app-tb-menu-list-item (click)="deleteField.emit()">
                <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
                <span class="text-red-600 body-1" item-title> {{ tButtons('delete') }} </span>
            </app-tb-menu-list-item>
        </ng-container>
    </app-tb-menu-list>
</ng-container>
