import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { User } from '@core/models'
import { CellComponent } from '../../cell.component'
import { FormControl } from '@angular/forms'
import { getBoolean } from '@core/global-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UserFacadeService } from '@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchComponent extends CellComponent implements OnInit, OnChanges {
    watchedUsers: User[] = []
    users!: User[]
    userGuids: string[] = []
    search!: FormControl<string | null>
    currentUser: string | null = null
    allowEdit: boolean = false

    constructor(private userFacadeService: UserFacadeService) {
        super()
    }

    get watchCount(): number {
        return this.watchedUsers ? this.watchedUsers.length : 0
    }

    get isUserWatcher() {
        return this.currentUser ? this.userGuids.includes(this.currentUser) : null
    }

    ngOnInit(): void {
        this.userFacadeService.users$.pipe(untilDestroyed(this)).subscribe((users) => {
            this.users = users
            if (this.value) {
                if (this.value.includes(',')) {
                    this.userGuids = this.value.split(',')
                } else {
                    this.userGuids = [this.value]
                }
                this.setWatchedUsers()
            }
        })
        this.userFacadeService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.currentUser = user.guid
                this.allowEdit = getBoolean(user.is_admin)
            }
        })
        this.search = new FormControl<string>('')
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value?.firstChange) return

        const currentValue = changes?.value?.currentValue || this.value
        if (currentValue) {
            this.userGuids = currentValue.includes(',') ? currentValue.split(',') : [currentValue]
            this.setWatchedUsers()
        } else {
            this.watchedUsers = []
            this.userGuids = []
        }
    }

    stopPropagation(event: any) {
        event.stopPropagation()
    }

    setWatchedUsers() {
        this.watchedUsers = this.users.filter((item) => this.userGuids.includes(item.guid))
    }

    changeWatch() {
        if (this.isUserWatcher) {
            const userIndex = this.userGuids.indexOf(this.currentUser!)
            this.userGuids.splice(userIndex, 1)
            this.cellValueChanged.emit(this.userGuids.join(','))
            this.setWatchedUsers()
            return
        }
        this.userGuids.push(this.currentUser!)
        this.cellValueChanged.emit(this.userGuids.join(','))
        this.setWatchedUsers()
    }
}
