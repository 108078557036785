export interface SolutionSettings {
    [object_type: string]: {
        view: string[]
    }
}

export interface SolutionModel {
    guid: string
    name: string
    setting: SolutionSettings
    is_template?: number
    version: string
}
