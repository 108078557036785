import { Component } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { map, take } from 'rxjs/operators'
import {
    PinDraftRecord,
    PinObject,
    PinObjectType,
    PinRecord,
    PinRecordService,
} from './pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'

@Component({
    selector: 'app-pin-panel',
    templateUrl: './pin-panel.component.html',
})
export class PinPanelComponent {
    showRecordCount: number = 5

    pinObjects$ = this.pinRecordService.pinObjects$.pipe(
        map((pinObjects) => {
            const pinRecords = pinObjects.filter((object) => object.type === PinObjectType.CREATED)
            const pinDraftRecords = pinObjects.filter(
                (object) => object.type === PinObjectType.DRAFT,
            )
            return [...pinDraftRecords, ...pinRecords]
        }),
    )

    pinRecords$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraft$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    pinRecordsMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraftMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    constructor(
        private pinRecordService: PinRecordService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    openEmptyDialog() {
        this.openDialog()
    }

    showMoreBtn(pinRecordsMore: PinRecord[], pinDraftRecordsMore: PinDraftRecord[]) {
        return pinRecordsMore.length || pinDraftRecordsMore.length
    }

    removePinItem(guid: string) {
        this.pinRecordService.removePinRecord(guid)
    }

    openPinItem(guid: string) {
        this.pinRecordService.openPinItem(guid)
    }

    openPinDraftItem(draftRecord: PinDraftRecord) {
        this.openDialog(draftRecord)
    }

    private filterRecordByType<T extends PinRecord | PinDraftRecord>(type: PinObjectType) {
        return map<PinObject[], T[]>((objects) => {
            return objects
                .filter((object) => object.type === type)
                .map((object) => object.record) as T[]
        })
    }

    private openDialog(draftRecord?: PinDraftRecord) {
        this.modalFlowManagerService
            .openDialog<AddRecordContentComponent, PinDraftRecord | null>({
                component: AddRecordContentComponent,
                data: draftRecord ?? null,
            })
            .pipe(take(1))
    }
}
