import { createFeature, createReducer, on } from '@ngrx/store'
import * as FieldTypeActions from './field-type.actions'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { FieldType } from '../../models'
import { StoreFeatureNames } from '../store-feature-names'
import { dataReset } from '../common/common.actions'

const fieldTypeFeatureKey: string = StoreFeatureNames.FieldType

export interface FieldTypeState extends EntityState<FieldType> {}

function selectFieldTypeId(fieldType: FieldType): string {
    return fieldType.guid
}

export const fieldTypeAdapter: EntityAdapter<FieldType> = createEntityAdapter<FieldType>({
    selectId: selectFieldTypeId,
})

const initialState: FieldTypeState = fieldTypeAdapter.getInitialState({})

const reducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(
        FieldTypeActions.setFieldTypes,
        (state: FieldTypeState, { fieldTypes }: { fieldTypes: FieldType[] }) => {
            return fieldTypeAdapter.setAll(fieldTypes, state)
        },
    ),
)

export const fieldTypeFeature = createFeature({
    name: fieldTypeFeatureKey,
    reducer: reducer,
})
