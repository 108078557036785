import { Injectable } from '@angular/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

@Injectable({
    providedIn: 'root',
})
export class DateService {
    createDate(date?: string) {
        return date ? dayjs(date) : dayjs()
    }

    createNextDayDate(date?: string) {
        return date ? dayjs(date) : dayjs().set('day', dayjs().day() + 1)
    }

    rangeDataFormat(formatString: string, dateLabel: string, startDate?: string, endDate?: string) {
        if (!startDate || !endDate) return dateLabel

        return `${this.createDate(startDate).format(formatString)} - ${this.createDate(
            endDate,
        ).format(formatString)}`
    }

    localizeAndFormat(date: string, formatString: string) {
        const valueDate = this.createDate(date).local()
        return valueDate.format(formatString)
    }

    formatWithUTC(date: string, formatString: string) {
        return dayjs.utc(date).format(formatString)
    }
}
