import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core'
import { Field, Folder, getIconByFieldType } from '@core/models'
import { getBoolean } from '@core/global-util'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-field-name',
    templateUrl: './field-name.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TbIconComponent],
})
export class FieldNameComponent implements OnInit, OnChanges {
    @Input()
    field!: Field

    @Input()
    folder!: Folder

    @Input()
    showIcon!: boolean

    @Input()
    iconColor?: string

    @Input()
    trim = false

    showFolderName!: boolean
    bgIconColor!: string

    ngOnInit() {
        this.bgIconColor = this.iconColor ? `bg-[${this.iconColor}]` : 'bg-newNeutral1'
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.folder) {
            if (this.folder && this.field) {
                if (getBoolean(this.folder.is_global.value)) {
                    this.showFolderName = !!this.field.folder_name?.is_global
                } else {
                    this.showFolderName = this.field.folder_guid === this.folder.guid
                }
            } else {
                this.showFolderName = false
            }
        }
    }

    getFieldIcon() {
        return getIconByFieldType(this.field.field_type_code)
    }
}
