import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-tb-navigation-menu-item',
    templateUrl: 'tb-navigation-menu-item.component.html',
})
export class TbNavigationMenuItemComponent {
    @Input() name!: string
    @Input() hideName!: boolean
    @Input() isSelected!: boolean
}
