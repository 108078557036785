<div class="w-100 flex-start-center card-status">
    <app-card-status-row
        [fieldTypes]="fieldTypes"
        [record]="record"
        [statusField]="status"
        [folder]="selectedFolder"
    ></app-card-status-row>
</div>
<div class="mb-lg">
    <app-card-main-fields
        [schema]="schema"
        [fieldTypes]="fieldTypes"
        [record]="record"
        [fields]="fields"
    ></app-card-main-fields>
</div>
<div class="w-100 flex-start-center">
    <app-card-description
        [descriptionField]="description"
        [richTextDescriptionField]="richTextDescription"
        [fieldTypes]="fieldTypes"
        [record]="record"
        [folder]="selectedFolder"
    ></app-card-description>
</div>
<div class="w-100 flex-start-center">
    <app-card-fields
        [fieldTypes]="fieldTypes"
        [fields]="fields"
        [record]="record"
        [selectedFolder]="selectedFolder"
        [subFields]="subFields"
        [cells]="cells"
    ></app-card-fields>
</div>
<div class="w-100 flex-start-center" *ngFor="let field of virtualFields">
    <app-card-link-references [field]="field" [record]="record"></app-card-link-references>
</div>
