import { UserState } from './user'
import { FieldTypeState } from './field-type'
import { CommonState } from './common'
import { FolderState } from './folder'
import { RecordState } from './record'
import { SchemaState } from './schema'
import { ViewState } from './view'
import { ConfigState } from './config'
import { AutomationState } from './automation'
import { SubtaskState } from './subtask'
import { GroupState } from './group'
import { RoleState } from '@core/@ngrx/role'

export interface AppState {
    AUTOMATIONS: AutomationState
    USERS: UserState
    FIELD_TYPE: FieldTypeState
    COMMON: CommonState
    CONFIG: ConfigState
    FOLDER: FolderState
    GROUP: GroupState
    ROLE: RoleState
    RECORD: RecordState
    SCHEMA: SchemaState
    VIEW: ViewState
    SUBTASK: SubtaskState
}
