import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatMenuTrigger } from '@angular/material/menu'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TbMenuState } from '../tb-menu'

export type TbTab = {
    icon?: string
    name?: string
    id: string
}

@UntilDestroy()
@Component({
    selector: 'app-tb-tab',
    templateUrl: './tb-tab.component.html',
    styleUrls: ['./tb-tab.component.sass'],
})
export class TbTabComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger

    @Input()
    tab!: TbTab

    @Input()
    icon?: TemplateRef<any>

    @Input()
    isSelected: boolean = false

    @Input()
    moreMenu: TemplateRef<any> | null = null

    @Output()
    selectTab = new EventEmitter<void>()

    textFormControl!: FormControl<string | null>

    isMenuOpened = false

    protected readonly TbMenuStateEnum = TbMenuState

    ngOnInit() {
        this.textFormControl = new FormControl(this.tab.name || '')
    }

    select() {
        if (!this.isSelected) {
            this.selectTab.emit()
        }
    }

    get isSelectedOrMenuOpened() {
        return this.isSelected || this.isMenuOpened
    }
}
