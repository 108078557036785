import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { Schema } from '@models/ui'

export const initSchemas = createAction('[Schema] Init schemas', props<{ schemas: Schema[] }>())
export const setSelectedTableSchemaGuid = createAction(
    '[Schema] Set selected schema',
    props<{ selectedSchemaGuid: string | null }>(),
)
export const updateSchema = createAction(
    '[Update Schema WS] UPDATE_SCHEMA',
    props<{ schema: Update<Schema> }>(),
)

export const updateSchemas = createAction(
    '[Update Schemas WS] UPDATE_SCHEMA',
    props<{ schemas: Update<Schema>[] }>(),
)
