import { setRoles } from './role.actions'
import { StoreFeatureNames } from '../store-feature-names'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { createFeature, createReducer, on } from '@ngrx/store'
import { Role } from '@core/models/ui/role.model'
import { dataReset } from '../common/common.actions'

const roleFeatureKey = StoreFeatureNames.Role

export interface RoleState extends EntityState<Role> {}

function selectRoleId(role: Role) {
    return role.guid
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
    selectId: selectRoleId,
})

const initialState: RoleState = roleAdapter.getInitialState({})

const roleReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(setRoles, (state: RoleState, { roles }) => {
        return roleAdapter.setMany(roles, { ...state })
    }),
)

export const roleFeature = createFeature({
    name: roleFeatureKey,
    reducer: roleReducer,
})
