<ng-container [ngSwitch]="appearance">
    <button
        *ngSwitchCase="'primary'"
        class="flex w-100 py-3 px-5 rounded-lg justify-center items-center sm:py-2 text-white transition-colors"
        [class]="[bgColor, 'hover:' + getDark(bgColor) ]"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" color="white" class="mr-2"></app-tb-icon>
        <span class="body-2">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </span>
    </button>

    <button
        *ngSwitchCase="'iconPrimary'"
        class="flex py-2.5 px-2 rounded-lg justify-center items-center text-white transition-colors"
        [class]="[bgColor, 'hover:' + getDark(bgColor) ]"
    >
        <app-tb-icon *ngIf="icon; else content" [icon]="icon"></app-tb-icon>
    </button>

    <button
        *ngSwitchCase="'outlined'"
        class="flex w-100 py-3 px-5 rounded-lg justify-center items-center sm:py-2 transition-colors border border-solid"
        [class]="getOutlinedClasses()"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" class="mr-2"></app-tb-icon>
        <span class="body-2">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </span>
    </button>

    <button
        *ngSwitchCase="'text'"
        class="flex w-100 py-1 px-2 rounded-lg justify-center items-center transition-colors"
        [class]="getTextClasses()"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" class="mr-2"></app-tb-icon>
        <span class="body-2">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </span>
    </button>

    <button
        *ngSwitchCase="'link'"
        [class]="[disabled ? textColor : getLight(textColor), 'hover:' + textColor]"
        class="underline inline-flex"
    >
        <span class="body-2">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </span>
    </button>

    <button
        *ngSwitchCase="'icon'"
        class="flex justify-center items-center p-0.5 rounded-lg cursor-pointer w-full h-full"
        [class]="getIconClasses()"
    >
        <app-tb-icon *ngIf="icon; else content" [color]="iconColor" [icon]="icon"></app-tb-icon>
    </button>

    <div *ngSwitchCase="'fab'" class="relative">
        <button
            class="flex p-2.5 sm:p-2 rounded-full cursor-pointer transition-colors"
            [class]="[bgColor, 'hover:' + getDark(bgColor)]"
        >
            <app-tb-icon color="white" class="icon-xlg sm:icon-lg" [icon]="icon"></app-tb-icon>
        </button>
    </div>
</ng-container>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
