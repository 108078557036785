<ng-container *ngIf="selectedView">
    <ng-container *ngIf="selectedFolder$ | async as folder">
        <div
            class="hidden sm:flex items-center h-100 px-2 mx-6 p-0 rounded-lg h-9 bg-white"
            *transloco="let tViewList; read: 'view_list'"
        >
            <app-tb-tabs
                [tabs]="viewTabs"
                [icon]="icon"
                [moreMenu]="moreMenuTab"
                [activeTab]="selectedTab"
                (tabOpened)="selectView($event)"
            ></app-tb-tabs>

            <ng-template #icon let-tab="tab">
                <app-tb-icon class="mr-2" [icon]="tab.icon"></app-tb-icon>
            </ng-template>

            <ng-template #moreMenuTab let-tab="tab">
                <app-views-list-menu [view]="tab.refObject"></app-views-list-menu>
            </ng-template>

            <app-tb-button
                #trigger="matMenuTrigger"
                [icon]="trigger.menuOpen ? 'expand_less' : 'expand_more'"
                [appearance]="'text'"
                [mat-menu-trigger-for]="allViews"
            >
                <span class="mr-2">{{tViewList('all')}}</span>
            </app-tb-button>
            <mat-menu #allViews="matMenu">
                <div class="flex flex-col py-2">
                    <ng-template #templateBottomSheet matMenuContent>
                        <div class="max-sm:flex hidden mb-6 text-primaryDark title">
                            {{tViewList('views')}}
                        </div>
                        <div (click)="navigate(itemView)" *ngFor="let itemView of views">
                            <div
                                class="flex items-center text-primaryDark mb-2 max-sm:mb-4 allViewHolder"
                            >
                                <app-tb-icon
                                    [icon]="'custom_drag'"
                                    [color]="'primarySecondary'"
                                    class="max-sm:w-5 w-4 max-sm:visible cursor-move"
                                ></app-tb-icon>
                                <div
                                    class="flex items-center w-full max-sm:h-12 py-1.5 sm:mr-4 max-sm:p-0 rounded cursor-pointer max-sm:bg-primaryBackground hover:bg-primaryBackground"
                                >
                                    <div
                                        [ngClass]="{'text-secondaryMain subheading-2':IsSelected(itemView)}"
                                        class="flex items-center px-[7px] max-sm:w-full"
                                    >
                                        <mat-icon
                                            class="h-4 max-sm:h-6 max-sm:text-2xl max-sm:leading-6 subheading-1 leading-4 mr-2"
                                            >{{itemView.icon}}</mat-icon
                                        >
                                        <span class="mr-7 max-sm:mr-4 leading-4">
                                            {{itemView.name.value}}
                                        </span>
                                        <app-tb-icon
                                            *ngIf="IsSelected(itemView)"
                                            icon="check"
                                            class="mr-[25px]"
                                        >
                                        </app-tb-icon>
                                        <app-tb-button
                                            class="sm:absolute right-[35px] flex max-sm:visible max-sm:ml-auto"
                                            [class.visible]="trigger.menuOpen"
                                            [appearance]="'icon'"
                                            icon="more_vert"
                                            (click)="$event.stopPropagation()"
                                            #trigger="matMenuTrigger"
                                            [matMenuTriggerFor]="itemViewMenu"
                                        >
                                        </app-tb-button>
                                    </div>
                                </div>
                            </div>

                            <mat-menu #itemViewMenu="matMenu">
                                <ng-template matMenuContent>
                                    <div class="flex-start-center content-start">
                                        <div class="dropdown-holder pt-1 px-3.5">
                                            <app-views-list-menu
                                                [view]="itemView"
                                            ></app-views-list-menu>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-menu>
                        </div>

                        <div class="max-sm:hidden block">
                            <app-tb-button
                                [appearance]="'text'"
                                [icon]="'add_circle_outline'"
                                (click)="openDialog(); $event.stopPropagation()"
                                class="flex items-center pl-5"
                            >
                                <span>{{tViewList('view_add')}}</span>
                            </app-tb-button>
                            <div
                                *transloco="let tButtons; read 'buttons'"
                                class="flex items-center gap-4 w-full px-4 mt-4 body-2"
                            >
                                <app-tb-button
                                    class="flex justify-center w-full"
                                    [appearance]="'outlined'"
                                >
                                    {{tButtons('cancel')}}
                                </app-tb-button>
                                <app-tb-button class="flex justify-center w-full">
                                    {{tButtons('save')}}
                                </app-tb-button>
                            </div>
                        </div>

                        <app-tb-button
                            [appearance]="'outlined'"
                            [icon]="'add_circle_outline'"
                            (click)="openBottomSheet(); $event.stopPropagation()"
                            class="sm:hidden flex items-center justify-center w-full"
                        >
                            <span>{{tViewList('view_add')}}</span>
                        </app-tb-button>
                    </ng-template>
                </div>
            </mat-menu>
            <app-tb-button
                [appearance]="'icon'"
                [icon]="'add'"
                [color]="'secondaryMainLight'"
                (click)="openDialog()"
                class="ml-3"
            >
            </app-tb-button>
        </div>

        <!--        small-->
        <div class="sm:hidden flex w-inherit h-10 cursor-pointer mx-4">
            <div
                (click)="openBottomSheetSm()"
                class="flex items-center justify-between w-100 h-10 px-2 mr-4 bg-white rounded-lg text-secondaryMain"
            >
                <div class="flex items-center subheading-2">
                    <mat-icon class="mr-2">{{selectedView.icon}}</mat-icon>
                    <div>{{selectedView.name.value}}</div>
                </div>
                <mat-icon class="w-8 h-8 text-3xl leading-8">expand_more</mat-icon>
            </div>

            <!--            needs to be removed from here when bottom sheet logic will be done-->
            <app-tb-button
                [appearance]="'icon'"
                [icon]="'add'"
                [color]="'secondaryMainLight'"
                (click)="openBottomSheet()"
                class="sm:hidden flex items-center ml-3"
            >
            </app-tb-button>
            <div
                (click)="showFilterBar()"
                class="flex items-center justify-center h-10 w-10 min-w-[40px] cursor-pointer rounded-lg bg-white text-primaryDark"
            >
                <mat-icon svgIcon="custom_slider" class="w-8 h-8 text-3xl leading-8"></mat-icon>
            </div>
        </div>

        <div
            [class.hidden]="toolbarIsInvisible"
            class="relative sm:flex w-100 sm:h-fit h-14 overflow-hidden"
        >
            <mat-toolbar-row
                class="table-toolbar h-fit w-inherit max-w-inherit overflow-y-hidden overflow-x-auto sm:mr-6 mr-4 sm:mt-2 mt-6 ml-4 p-0"
            >
                <app-view-filter class="ml-auto"></app-view-filter>
                <app-view-sort></app-view-sort>
                <app-view-group></app-view-group>
                <app-show [selectedFolder]="folder"></app-show>
            </mat-toolbar-row>
        </div>
    </ng-container>
</ng-container>
