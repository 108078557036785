<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px]"
    *transloco="let tFieldConfiguration; read: 'fields_configuration'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-5 max-sm:pt-4 title text-primaryDark">
        <div class="flex">
            {{ tFieldConfiguration('fields_configuration') }}
            <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
                <mat-icon
                    mat-dialog-close
                    class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
                >
                    close
                </mat-icon>
            </div>
        </div>
        <div class="leading-4 caption-1 text-primarySecondary">
            {{ tFieldConfiguration('fields_configuration_description') }}
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-395px)] py-0 px-5 max-sm:px-0"
    >
        <div class="flex mb-[-2px] w-full overflow-auto">
            <!-- text which starts with $ should be dynamically inserted-->
            <!-- these should be dynamic-->
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="false"
                [icon]="'close'"
            ></app-field-configuration-selector>
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="true"
                [customIcon]="'custom_table'"
            ></app-field-configuration-selector>
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="false"
                [icon]="'close'"
            ></app-field-configuration-selector>
        </div>
        <div class="w-full h-[2px] mb-8 rounded-sm bg-primaryLight"></div>

        <!-- text which starts with $ should be dynamically inserted-->
        <div class="mb-10">
            <div
                class="flex flex-wrap max-xsm:flex-col max-xsm:gap-2 gap-4 positionChildElementsByHalves"
            >
                <!-- these should be added dynamically-->
                <app-field-configuration-type
                    [isField]="false"
                    [name]="'Content'"
                    [description]="'Money'"
                    [customIcon]="'custom_table'"
                ></app-field-configuration-type>
            </div>

            <div class="w-full h-[2px] my-8 rounded-sm bg-primaryLight"></div>

            <div
                class="flex flex-wrap max-xsm:flex-col max-xsm:gap-2 gap-4 positionChildElementsByHalves"
            >
                <!--                this button is always here-->
                <div
                    (click)="addField()"
                    class="flex justify-between items-center xsm:p-4 p-2 rounded-2xl shadow-light text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                >
                    <div class="flex items-center">
                        <mat-icon class="h-10 w-10 text-4xl leading-10 mr-2"
                            >add_circle_outline
                        </mat-icon>
                        <div>{{ tFieldConfiguration('add_field') }}</div>
                    </div>
                </div>

                <!--                these should be added dynamically-->
                <app-field-configuration-type
                    [isField]="true"
                    [name]="'Content'"
                    [description]="'Money'"
                    [customIcon]="'custom_table'"
                ></app-field-configuration-type>
            </div>
        </div>
    </div>

    <div
        class="flex justify-end mt-4 py-0 px-5 max-sm:px-0"
        *transloco="let tButtons; read: 'buttons'"
    >
        <app-tb-button (click)="close()" [appearance]="'outlined'" class="mr-4">
            {{ tButtons('cancel') }}
        </app-tb-button>
        <app-tb-button (click)="close()"> {{ tButtons('apply') }}</app-tb-button>
    </div>
</div>
