<!-- TODO: Implement component library for rating -->
<div class="flex-start-center">
    <mat-icon
        (click)="setRating(reit)"
        (mouseenter)="changeHoverStyles($event, reit)"
        (mouseleave)="backHoverStyles()"
        *ngFor="let reit of countStarsInArray"
        [class.hoverStar]="(reit <= selectValue)"
        class="star-item"
    >
        star
    </mat-icon>
</div>
