import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import { MoneyConfig } from '@core/models'

@Component({
    selector: 'app-money-filter',
    templateUrl: './money-filter.component.html',
    styleUrls: ['./money-filter.component.sass'],
})
export class MoneyFilterComponent extends FilterTypesComponent implements OnInit {
    numberFormControl!: FormControl<number | null>
    currencySymbol!: string

    ngOnInit(): void {
        if (this.field.configure_json) {
            const MoneyConfig: MoneyConfig = JSON.parse(this.field.configure_json)
            if (MoneyConfig) {
                this.currencySymbol = MoneyConfig.currencySymbol
            }
        }

        this.numberFormControl = this.form.controls['value'] as FormControl
    }
}
