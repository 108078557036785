import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { SharedModule } from '@app/shared/shared.module'
import { ComponentsLibraryModule } from '@components-library'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TranslocoModule } from '@ngneat/transloco'
import { AddFieldContentComponent } from './add-field-content/add-field-content.component'
import { AddFieldComponent } from './add-field.component'

@NgModule({
    declarations: [AddFieldContentComponent, AddFieldComponent],
    imports: [
        CommonModule,
        MatDialogContent,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatDialogActions,
        TranslocoModule,
        MatButtonModule,
        ComponentsLibraryModule,
        ModalManagerModule,
        SharedModule,
        MatExpansionModule,
    ],
    exports: [AddFieldComponent],
})
export class AddFieldModule {}
