import { AbstractControl } from '@angular/forms'
import { ActivatedRouteSnapshot, Params } from '@angular/router'
import { LogService } from '@services/log.service'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

export function getBoolean(val: any): boolean {
    if (!val) return false

    const num = +val
    return !isNaN(num)
        ? !!num
        : !!String(val)
              .toLowerCase()
              .replace(!!0 + '', '')
}

export function collectParams(root: ActivatedRouteSnapshot): Params {
    let params: Params = {}
    ;(function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
        Object.assign(params, snapshot.params)
        snapshot.children.forEach(mergeParamsFromSnapshot)
    })(root)
    return params
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined
}

export function generateUuid() {
    return crypto.randomUUID()
}

export const emailValidationPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const numericValidationPattern = /[+-]?([0-9]*[.])?[0-9]+/

export function urlValidator(urlString: string) {
    if (!urlString) return true

    try {
        const url = new URL(urlString)

        if (!checkProtocol(url.protocol)) return false
    } catch (_) {
        return false
    }

    return true
}

/**
 * This function returns control's touched value as observable
 * @param control
 */
export function formListenToTouch(control: AbstractControl): Observable<boolean> {
    const result$ = new BehaviorSubject(false)
    const markFn = control.markAsTouched
    const unmarkFn = control.markAsUntouched

    control.markAsTouched = (opts) => {
        markFn.call(control, opts)
        result$.next(true)
    }

    control.markAsUntouched = (opts) => {
        unmarkFn.call(control, opts)
        result$.next(false)
    }

    return result$.asObservable().pipe(distinctUntilChanged())
}

/**
 * This util type declares that T may be null or undefined. Opposite of typescript's NonNullable<T>
 */
export type Nullable<T> = T | null | undefined

function checkProtocol(protocol: string) {
    return protocol === 'http:' || protocol === 'https:'
}

export function throwAndLogError(logService: LogService, message: string, publicMessage = 'error') {
    const error = new Error(message)
    logService.error(error)
    return throwError(() => publicMessage)
}
