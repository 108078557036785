<div *ngIf="records && records.length; else noResult" class="py-2">
    <div *ngFor="let record of records" class="flex-start-center w-100">
        <app-search-results-item [record]="record"></app-search-results-item>
    </div>
</div>
<ng-template #noResult>
    <div
        class="flex-start-center h-100 w-100"
        *transloco="let tSearchResults; read: 'search.results'"
    >
        <mat-icon class="text-primary fs-lg-21"> search </mat-icon>
        <div class="text-primary fs-lg-21">{{tSearchResults('no_result')}}</div>
    </div>
</ng-template>
