<div class="flex-start-center">
    <ng-container *transloco="let tCellTypesWatch; read: 'cell_types.watch'">
        <ng-container *ngIf="watchCount; else noMenuName">
            <div
                #trigger="matMenuTrigger"
                (mouseenter)="trigger.openMenu()"
                [matMenuTriggerFor]="assignMenu"
            >
                {{tCellTypesWatch('watch')}}
            </div>
            <mat-menu #assignMenu="matMenu">
                <ng-template matMenuContent>
                    <div (click)="$event.stopPropagation()" (mouseleave)="trigger.closeMenu()">
                        <app-people-menu
                            [allowEdit]="false"
                            [users]="watchedUsers"
                        ></app-people-menu>
                    </div>
                </ng-template>
            </mat-menu>
        </ng-container>
        <ng-template #noMenuName>
            <div>{{tCellTypesWatch('watch')}}</div>
        </ng-template>
    </ng-container>
    <div>
        <div (click)="changeWatch()" *ngIf="isUserWatcher; else iconAdd" class="users-icons">
            <span class="active" [matBadge]="watchCount" matBadgeOverlap="false">
                <mat-icon>visibility_off</mat-icon>
            </span>
        </div>
        <ng-template #iconAdd>
            <mat-icon
                (click)="changeWatch()"
                [matBadge]="watchCount"
                [matBadgeHidden]="watchedUsers.length === 0"
                matBadgeOverlap="false"
            >
                remove_red_eye
            </mat-icon>
        </ng-template>
    </div>
</div>
