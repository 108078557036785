export enum UserStatus {
    NoActive = 'NoActive',
    Offline = 'Offline',
    Online = 'Online',
    Remove = 'Remove',
}

export interface ResponseUserModelEntities {
    [guid: string]: ResponseUserModel
}

export interface ResponseUserModel {
    first_name: string
    last_name: string
    avatar_url?: string
    email: string
    role_guid?: string
    group_guid?: string
    status: UserStatus | string
}

export enum AclScopeTypes {
    FOLDER_RECORD = 'folder.record',
    TABLE = 'table',
}

export interface CurrentUser {
    guid: string
    role: CurrentUserRole
    is_admin?: number
}

export interface CurrentUserRole {
    guid: string
    configuration: {
        c?: string
        is_overwritten_c?: number
        r?: string
        is_overwritten_r?: number
        u?: string
        is_overwritten_u?: number
        d?: string
        is_overwritten_d?: number
        scope_guid: string
        scope: string
        scope_type: string
        acl?: string
        is_overwritten_acl?: number
    }[]
    is_global_folder?: number
    is_global_field?: number
    is_new_folder?: number
}
