import { Injectable } from '@angular/core'
import { LogService } from '@services/log.service'
import {
    CommonFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@services/store-facade'
import { EMPTY } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { AppRecord, CreateRecordModel, Folder, RecordUpdate, UpdateLinkModel } from '../models'
import { UpdateService } from './update.service'

@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    constructor(
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private commonFacadeService: CommonFacadeService,
        private updateService: UpdateService,
        private logService: LogService,
    ) {}

    updateLink(data: RecordUpdate) {
        this.schemaFacadeService.selectSelectedTableSchema$
            .pipe(
                take(1),
                switchMap((schema) => {
                    if (!schema) {
                        this.logService.error(new Error('no schema in updateLink'))
                        return EMPTY
                    }
                    const linkData: UpdateLinkModel = {
                        record_guid: data.record.guid!,
                        record_revision: data.record.revision!,
                        object_field_guid: data.cell!.fieldGuid,
                        revision: data.cell!.revision === 0 ? 1 : data.cell!.revision,
                        add_guids: data.value?.add_guids ?? [],
                        delete_guids: data.value?.delete_guids ?? [],
                    }
                    return this.updateService.updateLink([linkData], schema)
                }),
            )
            .subscribe()
    }

    updateRecord(data: RecordUpdate) {
        this.updateRecords([data])
    }

    updateRecords(data: RecordUpdate[]) {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(
                take(1),
                switchMap((folder: Folder) => {
                    return this.updateService.updateRecords(data, folder)
                }),
            )
            .subscribe()
    }

    createRecord(record: CreateRecordModel) {
        this.commonFacadeService.setLoading(true)
        this.updateService.createRecord(record).subscribe()
    }

    deleteRecord(data: AppRecord | Array<AppRecord>) {
        this.commonFacadeService.setLoading(true)
        this.updateService.deleteRecord(data).subscribe()
    }
}
