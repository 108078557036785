<app-tb-menu-list *transloco="let tButtons; read: 'buttons'">
    <app-tb-menu-list-item icon="custom_edit">
        {{ tButtons('edit') }} (not_implemented)
    </app-tb-menu-list-item>
    <app-tb-menu-list-item icon="custom_edit" (click)="fakeUpdate(view)">
        add "1" to the name
    </app-tb-menu-list-item>
    <app-tb-menu-list-item icon="custom_copy">
        {{ tButtons('duplicate') }} (not_implemented)
    </app-tb-menu-list-item>
    <app-tb-menu-list-item icon="custom_share">
        {{ tButtons('share_not_implemented') }}
    </app-tb-menu-list-item>

    <app-tb-divider></app-tb-divider>

    <app-tb-menu-list-item (click)="deleteView(view)">
        <app-tb-icon color="red-200" icon="custom_trash" item-icon></app-tb-icon>
        <span class="text-red-600 body-1" item-title>{{ tButtons('delete') }}</span>
    </app-tb-menu-list-item>
</app-tb-menu-list>
