import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { LinkSettingsComponent } from '@app/shared/cell-types/link/link-settings/link-settings.component'
import { MoneySettingsComponent } from '@app/shared/cell-types/money/money-settings/money-settings.component'
import { RatingSettingsComponent } from '@app/shared/cell-types/rating/rating-settings/rating-settings.component'
import { DropdownSettingsComponent } from '@app/shared/cell-types/select/dropdown'
import { StatusSettingsComponent } from '@app/shared/cell-types/select/status/status-settings/status-settings.component'
import { TextOneLineSettingsComponent } from '@app/shared/cell-types/text-one-line/text-one-line-settings/text-one-line-settings.component'
import { Field, FieldTypes } from '@core/models'
import { cloneDeep } from 'lodash-es'

export const fieldTypeColumnSettingsComponents: { [key: string]: Type<any> } = {
    [FieldTypes.STATUS]: StatusSettingsComponent,
    [FieldTypes.DROPDOWN]: DropdownSettingsComponent,
    [FieldTypes.RATING]: RatingSettingsComponent,
    [FieldTypes.TEXT]: TextOneLineSettingsComponent,
    [FieldTypes.MONEY]: MoneySettingsComponent,
    [FieldTypes.LINK]: LinkSettingsComponent,
}

@Component({
    selector: 'app-add-field-content',
    templateUrl: './add-field-content.component.html',
    styleUrls: ['./add-field-content.component.sass'],
})
export class AddFieldContentComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() field!: Field
    @Input() fieldType!: string

    @Output() fieldChanged: EventEmitter<Field> = new EventEmitter()
    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>()

    @ViewChild('addColumnArea', { read: ViewContainerRef, static: true })
    addColumnArea!: ViewContainerRef

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fieldType) {
            if (!changes.fieldType.currentValue) {
                changes.fieldType.currentValue = changes.fieldType.previousValue
            }
            this.addFieldColumn(changes.fieldType.currentValue)
        }
    }

    ngOnInit() {
        this.addFieldColumn(this.fieldType)
    }

    ngAfterViewInit() {
        this.cdr.detectChanges()
    }

    addFieldColumn(fieldType: string) {
        this.addColumnArea.clear()
        const componentName = fieldTypeColumnSettingsComponents[fieldType]
        if (componentName) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(componentName)
            const component = this.addColumnArea.createComponent(componentFactory)

            component.instance.field = this.field
            component.instance.fieldChanged = this.fieldChanged
            component.instance.valid = this.valid

            if (fieldType === 'field_type_status') {
                component.instance.statusOptions = cloneDeep(this.field.select_object_field)
            }

            if (fieldType === 'field_type_rating') {
                component.instance.field.configure_json =
                    this.field.configure_json || JSON.stringify({ count: 5 })
            }
        } else {
            console.log(this.field.field_type_code)
        }
        this.cdr.detectChanges()
    }
}
