import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-access-selector',
    templateUrl: './access-selector.component.html',
    styleUrls: ['./access-selector.component.sass'],
})
export class AccessSelectorComponent {
    @Input()
    isActive!: boolean
}
