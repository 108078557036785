import { CurrentUser, User } from '../../models'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface UserState extends EntityState<User> {
    readonly loading: boolean
    readonly loaded: boolean
    readonly error: Error | string | null
    readonly selectedGuid: string | null
    readonly currentUser: CurrentUser | null
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId: (user: User) => user.guid,
})

export const initialUserState: UserState = userAdapter.getInitialState({
    selectedGuid: null,
    loading: false,
    loaded: false,
    error: null,
    currentUser: null,
})
