export type FieldJsonConfigurationType =
    | string
    | LinkConfig
    // | StatusConfig
    // | DropdownConfig
    | NumbersConfig
    | AssigneeConfig
    | TextConfig
    | DateConfig
    | RatingConfig

// export interface StatusConfig extends DropdownConfig {
//     options: StatusOption[]
//     done_guids: string[]
//     closed_guid: string
// }
//
// export interface DropdownConfig extends TextConfig {
//     options: DropdownOption[]
//     default_guid: string
// }

export interface LinkConfig {
    object_guid: string
    object_field_guids: string[]
}

export interface NumbersConfig extends TextConfig {
    units: string
    units_position: string
    agg_function: string
}

export interface AssigneeConfig {
    max: number
}

export interface RatingConfig {
    count: number
}

export interface TextOneLineConfig {
    minLength: number
    maxLength: number
}

export interface MoneyConfig {
    currency: string
    currencySymbol: string
}

export interface DateConfig extends TextConfig {
    format_type: string
}

export interface TextConfig {
    is_required: boolean
}

export interface DropdownOption {
    guid: string
    label: string
    color: string
}

export interface StatusOption extends DropdownOption {
    icon: string
    tags: string
}
