import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import { getBoolean } from '@core/global-util'

@Component({
    selector: 'app-boolean-filter',
    templateUrl: './boolean-filter.component.html',
    styleUrls: ['./boolean-filter.component.sass'],
})
export class BooleanFilterComponent extends FilterTypesComponent implements OnInit {
    checkboxFormControl!: FormControl<boolean | null>
    formControlsValue!: AbstractControl<any, any>
    ngOnInit(): void {
        this.formControlsValue = this.form.controls['value']
        this.setFormControl(this.formControlsValue.value)

        this.checkboxFormControl.valueChanges.subscribe((value) => {
            if (this.formControlsValue.untouched) {
                this.formControlsValue.markAsTouched()
            }
            this.formControlsValue.setValue(Number(value))
        })
    }

    private setFormControl(value: number | null) {
        this.checkboxFormControl = new FormControl(value ? getBoolean(value) : false)
        if (this.formControlsValue.value === null) {
            this.formControlsValue.setValue(Number(value))
        }
    }
}
