<div class="flex-start-center">
    <div class="card-field-title">
        <app-field-name
            [field]="field"
            [selectedFolder]="selectedFolder!"
            [showIcon]="false"
        ></app-field-name>
    </div>
    <app-cell-container
        (cellValueChanged)="updateRecord(record, cells[field.guid], $event)"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [guid]="field.guid"
        [record]="record"
        [value]="cells[field.guid].value"
        [isCard]="true"
        class="flex-start-center text-center"
    ></app-cell-container>
</div>
