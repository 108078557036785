<app-tb-button
    [appearance]="'icon'"
    [icon]="'notifications_none'"
    [matMenuTriggerFor]="notificationsMenu"
>
</app-tb-button>
<mat-menu #notificationsMenu="matMenu">
    <ng-template matMenuContent>
        <app-notifications-menu-content></app-notifications-menu-content>
    </ng-template>
</mat-menu>
