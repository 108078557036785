<div class="group header-container" [class]="stateClass">
    <div class="h-full w-full">
        <ng-content></ng-content>
    </div>

    <span class="flex justify-center w-5 h-5 body-2 bg-newNeutral3 rounded-lg">
        {{ items.length }}
    </span>

    <app-tb-button
        appearance="icon"
        icon="keyboard_double_arrow_left"
        class="collapse-btn visible md:invisible group-hover:visible"
        (click)="toggleCollapse()"
    ></app-tb-button>

    <app-tb-menu
        *ngIf="moreMenu"
        class="more-menu visible md:invisible group-hover:visible"
        [template]="moreMenu"
        [replaceable]="true"
        [data]="{ columnItem: columnItem, collapsed, toggleCollapse: toggleCollapseBound }"
    >
        <app-tb-button appearance="icon" icon="more_vert" trigger> </app-tb-button>
    </app-tb-menu>

    <app-tb-checkbox
        class="pb-1 select-checkbox visible group-hover:visible"
        [class]="[selectedItems.length > 0 ? 'visible' : 'md:invisible', collapsed ? 'pr-1' : 'pb-1']"
        (valueChange)="selectColumn.emit($event)"
        [value]="isSomeItemsSelected"
        [disabled]="items.length === 0"
        [indeterminate]="isIndeterminate"
    ></app-tb-checkbox>
</div>
