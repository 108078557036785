import { Injectable } from '@angular/core'
import {
    combineLatest,
    filter,
    forkJoin,
    map,
    Observable,
    OperatorFunction,
    switchMap,
    take,
} from 'rxjs'
import { Folder, Schema, View } from '../models'
import { distinctUntilGuidChanged } from '../rxjs'
import {
    CommonFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
    ViewFacadeService,
} from './store-facade'

export type SystemRecords = {
    folder: Folder | null
    tableSchema: Schema | null
    view: View | null
}

@Injectable({
    providedIn: 'root',
})
export class SystemRecordsFacadeService {
    readonly selectAllFolders$ = this.commonFacadeService.selectDataInitialized$.pipe(
        filter(Boolean),
        switchMap(() => this.folderFacadeService.selectAllFolders$),
    )

    readonly selectAllTableSchemas$ = this.commonFacadeService.selectDataInitialized$.pipe(
        filter(Boolean),
        switchMap(() => this.schemaFacadeService.selectTableSchemas$),
    )

    readonly selectAllViews$ = this.commonFacadeService.selectDataInitialized$.pipe(
        filter(Boolean),
        switchMap(() => this.viewFacadeService.allViews$),
    )

    constructor(
        private folderFacadeService: FolderFacadeService,
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private commonFacadeService: CommonFacadeService,
        private userFacadeService: UserFacadeService,
    ) {}

    getAllSystemRecords() {
        return forkJoin([
            this.selectAllFolders$.pipe(take(1)),
            this.selectAllTableSchemas$.pipe(take(1)),
            this.selectAllViews$.pipe(take(1)),
        ])
    }

    getCurrentUserAndUsersList() {
        return forkJoin([
            this.userFacadeService.currentUser$.pipe(take(1)),
            this.userFacadeService.users$.pipe(take(1)),
        ])
    }

    getSelectedSystemRecordsFromView(viewGuid: string) {
        return this.getAllSelectedSystemRecordsFromView(viewGuid).pipe(
            this.toNullIfSomeValueIsEmpty(),
        )
    }

    getAllSelectedSystemRecordsFromView(viewGuid: string) {
        return this.getAllSystemRecords().pipe(
            map(([folders, tableSchemas, views]) => {
                const view = views.find((view) => view.guid === viewGuid)
                if (!view) return this.prepareSelectedSystemRecords(null, null, null)

                const tableSchema = tableSchemas.find(
                    (tableSchema) => tableSchema.guid === view.parent_sot_guid,
                )
                if (!tableSchema) return this.prepareSelectedSystemRecords(null, null, view)

                const folder =
                    folders.find((folder) => view.folder_guids?.includes(folder.guid)) || null

                return {
                    folder,
                    tableSchema,
                    view,
                }
            }),
        )
    }

    getSystemRecordsFromTableSchema(tableSchemaGuid: string, folderGuid: string) {
        return this.getAllSystemRecordsFromSystemObject(tableSchemaGuid, folderGuid).pipe(
            this.toNullIfSomeValueIsEmpty(),
        )
    }

    getAllSystemRecordsFromSystemObject(
        tableSchemaGuid: string,
        folderGuid: string,
    ): Observable<SystemRecords> {
        return this.getAllSystemRecords().pipe(
            map(([folders, tableSchemas, views]) => {
                const tableSchema = tableSchemas.find(
                    (tableSchema) => tableSchema.guid === tableSchemaGuid,
                )
                if (!tableSchema) return this.prepareSelectedSystemRecords(null, null, null)

                const folder = folders.find((folder) => folderGuid.includes(folder.guid))
                if (!folder) return this.prepareSelectedSystemRecords(null, tableSchema, null)

                const view =
                    views.find(
                        (view) =>
                            view.parent_sot_guid === tableSchema.guid &&
                            view.folder_guids?.includes(folder.guid),
                    ) || null
                if (!view) return this.prepareSelectedSystemRecords(folder, tableSchema, null)

                return this.prepareSelectedSystemRecords(folder, tableSchema, view)
            }),
        )
    }

    getSystemRecordsFromFolder(folderGuid: string) {
        return this.getAllSystemRecordsFromFolder(folderGuid).pipe(this.toNullIfSomeValueIsEmpty())
    }

    getAllSystemRecordsFromFolder(folderGuid: string): Observable<SystemRecords> {
        return this.getAllSystemRecords().pipe(
            map(([folders, tableSchemas, views]) => {
                const folder = folders.find((folder) => folder.guid === folderGuid)
                if (!folder) return this.prepareSelectedSystemRecords(null, null, null)

                const view = views.find((view) => view.folder_guids?.includes(folder.guid))
                if (!view) return this.prepareSelectedSystemRecords(folder, null, null)

                const tableSchema = tableSchemas.find(
                    (tableSchema) => tableSchema.guid === view.parent_sot_guid,
                )
                if (!tableSchema) return this.prepareSelectedSystemRecords(folder, null, view)

                return this.prepareSelectedSystemRecords(folder, tableSchema, view)
            }),
        )
    }

    getSelectedSystemRecords(): Observable<SystemRecords> {
        return combineLatest([
            this.folderFacadeService.selectSelectedFolder$.pipe(distinctUntilGuidChanged()),
            this.schemaFacadeService.selectSelectedTableSchema$.pipe(distinctUntilGuidChanged()),
            this.viewFacadeService.selectedView$.pipe(distinctUntilGuidChanged()),
        ]).pipe(
            map(([selectedFolder, selectedTableSchema, selectedView]) => {
                return this.prepareSelectedSystemRecords(
                    selectedFolder,
                    selectedTableSchema || null,
                    selectedView || null,
                )
            }),
        )
    }

    private prepareSelectedSystemRecords(
        folder: Folder | null,
        tableSchema: Schema | null,
        view: View | null,
    ): SystemRecords {
        return {
            folder,
            tableSchema,
            view,
        }
    }

    private toNullIfSomeValueIsEmpty(): OperatorFunction<
        SystemRecords,
        null | { folder: Folder; tableSchema: Schema; view: View }
    > {
        return map((systemRecords: SystemRecords) => {
            const { folder, tableSchema, view } = systemRecords
            if (!folder || !tableSchema || !view) return null

            return systemRecords as { folder: Folder; tableSchema: Schema; view: View }
        })
    }
}
