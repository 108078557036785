import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms'
import { TextOneLineConfig } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslocoModule } from '@ngneat/transloco'
import { AutoFocusDirective } from '../focus.directive'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { NgIf } from '@angular/common'

@UntilDestroy()
@Component({
    selector: 'app-text-one-line',
    templateUrl: './text-one-line.component.html',
    styleUrls: ['./text-one-line.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TbIconComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        AutoFocusDirective,
        TranslocoModule,
    ],
})
export class TextOneLineComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    minLength!: number
    maxLength!: number

    ngOnInit() {
        if (!this.field.configure_json) {
            this.minLength = 0
            this.maxLength = 1000
            this.setFormControl()
            return
        }

        const textConfig: TextOneLineConfig = JSON.parse(this.field.configure_json)

        if (textConfig) {
            this.minLength = !!textConfig.minLength ? textConfig.minLength : 0
            this.maxLength = !!textConfig.maxLength ? textConfig.maxLength : 1000
            this.setFormControl()
        }
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.textFormControl.invalid || this.value === this.textFormControl.value) return

        this.cellValueChanged.emit(this.textFormControl.value)
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }

    private setFormControl() {
        this.validatorOrOpts = []
        if (this.field.is_required) {
            this.validatorOrOpts.push(Validators.required)
        }

        this.validatorOrOpts.push(Validators.maxLength(this.maxLength))
        this.validatorOrOpts.push(Validators.minLength(this.minLength))

        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: this.disabled,
            },
            {
                validators: this.validatorOrOpts,
                updateOn: 'blur',
            },
        )

        this.textFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.saveValue())
    }
}
