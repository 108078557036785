import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalContainer } from '../modal-manager.service'

/**
 * ModalLayoutComponent is a base layout for all Modal Views. It applies needed styles when we do Modal View Transformation.
 *
 * It has four available slots:
 * - default - all content passed
 * - buttons - reserved place for Modal actions
 * - title - Modal title
 * - subtitle - Modal subtitle
 **/
@Component({
    selector: 'app-modal-layout',
    templateUrl: './modal-layout.component.html',
    styleUrls: ['./modal-layout.component.sass'],
})
export class ModalLayoutComponent {
    /**
     * Required property to indicate for what layout we should prepare styles
     **/
    @Input() containerLayout!: ModalContainer

    /**
     * Optional property to show local semi transparent loader inside modal window
     **/
    @Input() showLoader?: boolean
    /**
     * Optional property to show errors
     **/
    @Input() errors?: string[]

    /**
     * Calling when we click on Cross button
     **/
    @Output() closeModal = new EventEmitter()

    ModalContainer = ModalContainer

    get containerClass() {
        const classes = {
            [ModalContainer.Menu]: 'menu-layout',
            [ModalContainer.BottomSheet]: 'bottom-sheet-layout',
            [ModalContainer.Dialog]: 'dialog-layout',
        }

        return classes[this.containerLayout]
    }
}
