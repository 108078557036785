import { createAction, props } from '@ngrx/store'
import {
    Automation,
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '../../models/ui/automation.model'
import { Update } from '@ngrx/entity'
import { ActionTypeModel } from '@core/models/response/action-type.model'
import { SourceTypeModel } from '@core/models/response/source-type.model'

export const setAutomation = createAction(
    '[Init Automation] SET_AUTOMATION',
    props<{ automations: Automation[] }>(),
)

export const setActionTypes = createAction(
    '[Init ActionTypes] SET_ACTION_TYPES',
    props<{ actionTypes: ActionTypeModel }>(),
)

export const setSourceTypes = createAction(
    '[Init SourceTypes] SET_SOURCE_TYPE',
    props<{ sourceTypes: SourceTypeModel }>(),
)

export const createAutomation = createAction(
    '[Create Automation] CREATE_AUTOMATION',
    props<{ automation: AutomationCreateModel }>(),
)
export const updateAutomation = createAction(
    '[Update Automation] Update automation',
    props<{ automation: AutomationUpdateModel }>(),
)
export const deleteAutomation = createAction(
    '[Delete Automation] Delete automation',
    props<{ automation: AutomationDeleteModel }>(),
)

export const executeAutomation = createAction(
    '[Execute Automation] Execute automation',
    props<{ automations: AutomationExecuteModel }>(),
)

export const addAutomationsFromResponse = createAction(
    '[Add Automation] Add automation from response',
    props<{ automation: Automation }>(),
)

export const updateAutomationFromResponse = createAction(
    '[Response Update Automation] Update automation from response',
    props<{ automation: Update<Automation> }>(),
)

export const deleteAutomationFromResponse = createAction(
    '[Delete Update Automations] Delete automations from response',
    props<{ automations: string[] }>(),
)
