import { ComponentRef, EventEmitter, inject, Injectable } from '@angular/core'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { ModalContainersStore } from '@components-library/tb-modal-manager/modal-containers.store'
import { ModalRenderComponent } from '../modal-render-container/modal-render.component'
import { Subject } from 'rxjs'
import { BaseContainer } from '../modal-containers/base-container'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { take } from 'rxjs/operators'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'

/**
 * BottomSheetInstanceService manages opening MatBottomSheet. It assures that at some point there is opened only one bottom sheet instance, and when we call BottomSheetInstanceService.open() again, it just replaces the Modal Content, instead of opening new MatBottomSheet.
 **/
export class BottomSheetInstanceService {
    /**
     * This Symbol indicates when we want close popup programmatically.
     * There are two options how Dialogs and Bottoms sheets can be closed:
     * - programmatically with ForcedData
     * - by User intention, when click outside the Dialog or Bottom sheet, or click some *close* buttons etc.
     *
     * Programmatically we close Modal Container when we convert it to its Alternative. In this case we shouldn’t call “this.closed.emit()”, so we should pass some flag to notify that this "close" action is programmatically made and do not call the “closed”. That is why ForcedData flag is here.
     *
     * Automatically we close Modal Container when User click outside the Dialog or BottomSheet, or click some *close* buttons etc. In this case we should emit “closed” to call callbacks that were set for this event.
     **/
    private readonly ForcedData = Symbol('ForcedData')

    /**
     * MatBottomSheetRef instance
     **/
    private bottomSheetInstance: MatBottomSheetRef | null = null

    /**
     * Stream that is passed into the ModalRenderComponent to render Modal Container during Navigation. We can't call MatBottomSheetRef.open() on an instantiated MatBottomSheetRef, so we pass stream that receive ModalContainers that should be rendered and replace the previous Modal Container.
     **/
    private renderComponent$ = new Subject<ComponentRef<unknown>>()

    /**
     * Event that signals that MatDialog is closed by User intention.
     **/
    public closed = new EventEmitter()

    constructor(
        private containersStore: ModalContainersStore,
        private bottomSheet: MatBottomSheet,
    ) {}

    /**
     * This method opens MatBottomSheet. It is called by ModalManagerService.
     * @param {BaseContainer<ModalContainerComponent>} container The Modal Container instance
     **/
    open(container: BaseContainer<ModalContainerComponent>) {
        this.reopen(container)

        return container.closed.asObservable().pipe(take(1))
    }

    /**
     * This method opens MatBottomSheet. It is called by ModalManagerService when the Modal Transformation performs.
     * @param {BaseContainer<ModalContainerComponent>} container The Modal Container instance
     **/
    reopen(container: BaseContainer<ModalContainerComponent>) {
        /**
         * This condition assures that the MatBottomSheet is singleton, and it doesn't overlap.
         **/
        if (!this.bottomSheetInstance) {
            this.bottomSheetInstance = this.bottomSheet.open(ModalRenderComponent, {
                data: { renderComponent$: this.renderComponent$ },
                panelClass: 'bottomSheetCustom',
                disableClose: true,
            })

            this.bottomSheetInstance.afterDismissed().subscribe((data) => {
                /**
                 * data === this.ForcedData means that it is programmatically closed, and not by User intention. It indicates Modal Transformation.
                 **/
                if (data === this.ForcedData) return

                this.closed.emit()
                this.clear()
            })

            /**
             * When user click on backdrop we should open Confirmation Dialog.
             * There are two condition:
             * - this.modalContainersService.containers.length > 1 - means that currently we are in the Child Flow component, it happens when we make Navigation
             * - this.modalContainersService.hasMarkedContainer() - when some of the containers in the Stack has marked state
             **/
            this.bottomSheetInstance.backdropClick().subscribe(() => {
                if (
                    this.containersStore.containers.length > 1 ||
                    this.containersStore.hasMarkedContainer()
                ) {
                    this.containersStore.firstContainer?.componentRef.instance.openConfirmationDialog()
                } else {
                    this.closed.emit()
                    this.clear()
                }
            })
        }

        /**
         * If the MatBottomSheet is created, we should pass the Container we want to open, and set Dialog in the ModalContainerComponent.containerLayout property.
         **/
        container.componentRef.setInput('containerLayout', ModalContainer.BottomSheet)
        this.renderComponent$.next(container.componentRef)
    }

    /**
     * Calls when we do Modal Transformation. This method is calling from ModalManagerService.
     **/
    hide() {
        this.bottomSheetInstance?.dismiss(this.ForcedData)
        this.bottomSheetInstance = null
    }

    /**
     * Closes and clears the MatDialogRef after Dialog was closed.
     **/
    private clear() {
        this.bottomSheetInstance?.dismiss()
        this.bottomSheetInstance = null
    }
}
