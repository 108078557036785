<div class="container">
    <div>
        <mat-form-field appearance="fill" *transloco="let tTable; read: 'table'">
            <mat-label>{{tTable('share_field_label')}}</mat-label>
            <mat-select [formControl]="sharedFolders" multiple>
                <mat-option
                    (click)="onItemClick()"
                    *ngFor="let folder of dropdownList"
                    [value]="folder.item_id"
                    >{{folder.item_text}}</mat-option
                >
                <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="bottom" *transloco="let tButtons; read: 'buttons'">
        <button (click)="applyShare()" color="primary" mat-button>{{tButtons('apply')}}</button>
        <button (click)="cancelShare()" mat-button>{{tButtons('cancel')}}</button>
    </div>
</div>
