import { Injectable } from '@angular/core'
import {
    AppState,
    initApp,
    initAppSuccess,
    onMessage,
    reInitApp,
    resetSolutionToTemplate,
    selectDataInitialized,
    setError,
    setLoading,
} from '@core/@ngrx'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { UpdateService } from '@services/update.service'
import { WsProcessingService } from '@services/ws-processing.service'
import { filter, of } from 'rxjs'
import { catchError, mergeMap, switchMap, take, tap } from 'rxjs/operators'

@Injectable()
export class CommonEffects {
    initApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initApp),
            tap(() => this.store.dispatch(setLoading({ loading: true }))),
            switchMap(() => {
                this.wsProcessingService.init()
                return this.store.select(selectDataInitialized).pipe(filter(Boolean))
            }),
            mergeMap(() => {
                return [initAppSuccess(), setLoading({ loading: false })]
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    reInitApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(reInitApp),
            tap(() => this.store.dispatch(setLoading({ loading: true }))),
            switchMap(() => {
                this.wsProcessingService.reInit()
                return this.store.select(selectDataInitialized).pipe(filter(Boolean), take(1))
            }),
            mergeMap(() => {
                return [initAppSuccess(), setLoading({ loading: false })]
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    resetSolution$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetSolutionToTemplate),
            tap(() => this.store.dispatch(setLoading({ loading: true }))),
            mergeMap(() => {
                return this.updateService
                    .resetSolutionToTemplate()
                    .pipe(switchMap(() => [setLoading({ loading: false })]))
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    onMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onMessage),
            tap((res) => {
                this.store.dispatch(setLoading({ loading: true }))
                this.wsProcessingService.onMessage(res.response)
            }),
            mergeMap(() => {
                return [setLoading({ loading: false })]
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private wsProcessingService: WsProcessingService,
        private updateService: UpdateService,
    ) {}
}
