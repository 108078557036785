import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { User } from '@core/models'
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component'
import { MatMenuModule } from '@angular/material/menu'
import { NgIf, NgFor } from '@angular/common'

@Component({
    selector: 'app-people-menu',
    templateUrl: './people-menu.component.html',
    styleUrls: ['./people-menu.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, MatMenuModule, UserAvatarComponent],
})
export class PeopleMenuComponent {
    @Input()
    users!: User[]

    @Input()
    activeUserGuids?: string[]

    @Input()
    allowEdit!: boolean

    @Output()
    changeUser = new EventEmitter<any>()

    toggleUser(guid: string) {
        this.changeUser.emit(guid)
    }
}
