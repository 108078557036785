import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatMenuModule } from '@angular/material/menu'
import { AddRecordModule } from '@app/feature/add-record/add-record.module'
import { PinPanelComponent } from '@app/feature/pin-panel/pin-panel.component'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'
import { PinRecordComponent } from './pin-record/pin-record.component'

@NgModule({
    declarations: [PinPanelComponent, PinRecordComponent],
    imports: [
        CommonModule,
        ComponentsLibraryModule,
        MatMenuModule,
        AddRecordModule,
        TranslocoModule,
    ],
    exports: [PinPanelComponent],
})
export class PinPanelModule {}
