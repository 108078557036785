import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatRadioModule } from '@angular/material/radio'
import { ChangeFieldFolderComponent } from '@app/feature/change-field-folder/change-field-folder.component'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [ChangeFieldFolderComponent],
    imports: [
        CommonModule,
        MatRadioModule,
        TranslocoModule,
        ComponentsLibraryModule,
        MatButtonModule,
    ],
    exports: [ChangeFieldFolderComponent],
})
export class ChangeFieldFolderModule {}
