import { createFeatureSelector, createSelector } from '@ngrx/store'
import { subtaskAdapter, subtaskFeatureKey, SubtaskState } from './subtask.reducer'

const selectSubtaskState = createFeatureSelector<SubtaskState>(subtaskFeatureKey)

const { selectIds, selectEntities, selectAll, selectTotal } =
    subtaskAdapter.getSelectors(selectSubtaskState)

export const selectSubtaskEntities = selectEntities

export const selectAllSubtasks = selectAll

export const selectSubtasksBySchemaGuid = (schemaGuid: string) =>
    createSelector(selectAll, (entities) => {
        return entities.filter((entity) => entity.schemaGuid === schemaGuid)
    })
