import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    AppState,
    dataInitialized,
    dataReset,
    initApp,
    initSystemFields,
    onMessage,
    reInitApp,
    resetSolutionToTemplate,
    selectAllAvailableSolutionObjectTypes,
    selectDataInitialized,
    selectLoaded,
    selectLoading,
    selectSolution,
    selectSystemFields,
    setError,
    setLoading,
    setSolution,
} from '../../@ngrx'
import { ObjectResponseModel, ResponseFieldEntities, SolutionModel } from '../../models'

@Injectable({
    providedIn: 'root',
})
export class CommonFacadeService {
    constructor(private store: Store<AppState>) {}

    selectLoaded$ = this.store.select(selectLoaded)

    selectLoading$ = this.store.select(selectLoading)

    selectDataInitialized$ = this.store.select(selectDataInitialized)

    selectSolution$ = this.store.select(selectSolution)

    selectAllAvailableSolutionObjectTypes$ = this.store.select(
        selectAllAvailableSolutionObjectTypes,
    )

    selectSystemFields$ = this.store.select(selectSystemFields)

    initApp() {
        this.store.dispatch(initApp())
    }

    reInitApp() {
        this.store.dispatch(reInitApp())
    }

    dataInitialized() {
        this.store.dispatch(dataInitialized())
    }

    dataReset() {
        this.store.dispatch(dataReset())
    }

    resetSolutionToTemplate() {
        this.store.dispatch(resetSolutionToTemplate())
    }

    initSystemFields(systemFields: ResponseFieldEntities) {
        this.store.dispatch(initSystemFields({ systemFields }))
    }

    setError(error: string | null) {
        this.store.dispatch(setError({ error }))
    }

    setLoading(loading: boolean) {
        this.store.dispatch(setLoading({ loading }))
    }

    onMessage(response: ObjectResponseModel) {
        this.store.dispatch(onMessage({ response }))
    }

    setSolution(solution: SolutionModel) {
        this.store.dispatch(setSolution({ solution }))
    }
}
