import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TemplateContainerComponent } from './template-container/template-container.component'
import { ModalRenderComponent } from './modal-render-container/modal-render.component'
import { ModalLayoutComponent } from './modal-layout/modal-layout.component'
import { ComponentsLibraryModule } from '../components-library.module'

const componentsForExport = [TemplateContainerComponent, ModalRenderComponent, ModalLayoutComponent]

@NgModule({
    declarations: [...componentsForExport],
    imports: [CommonModule, ComponentsLibraryModule, MatProgressSpinnerModule],
    exports: [...componentsForExport],
})
export class ModalManagerModule {}
