<ng-container *transloco="let tSort; read: 'sort'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (keydown.tab)="$event.stopPropagation();"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <span class="text-primaryDark body-2">{{tSort('sort_by')}}</span>
        </ng-container>

        <div
            (click)="$event.stopPropagation()"
            [cdkDropListData]="sortObjectsArray"
            (cdkDropListDropped)="drop($event)"
            cdkDropList
            class="sort-container max-sm:mt-4 filter-area body-1 leading-4"
        >
            <div class="flex justify-end items-center mb-4">
                <div
                    *ngIf="isSortDefault()"
                    (click)="onClear()"
                    class="flex items-center cursor-pointer text-secondaryMainLight hover:text-secondaryMain body-2"
                >
                    <span>{{tSort('clear_all').toUpperCase()}}</span>
                </div>
            </div>

            <form [formGroup]="sortMenuForm" *ngIf="sortObjectsArray.length">
                <ng-container *ngIf="(selectedFolder$ | async) as selectedFolder; else error">
                    <app-sort-item
                        *ngFor="let object of sortObjectsArray; let index = index"
                        [fields]="fieldsForSortItem(object)"
                        [selectedFolder]="selectedFolder"
                        [sortObject]="object"
                        [sortGroup]="getSortItemGroup(object.fieldGuid)"
                        (changed)="updateSortObjects($event, index)"
                        (removed)="removeSortObject($event, index)"
                    ></app-sort-item>
                </ng-container>
            </form>

            <app-tb-button
                (click)="addSort()"
                [appearance]="'text'"
                [icon]="'add_circle_outline'"
                class="p-1 mb-4"
            >
                <span>{{tSort('add_sort')}}</span>
            </app-tb-button>
        </div>

        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex items-center">
                <app-tb-button
                    [class.invisible]="!isSessionValueSet"
                    [appearance]="'text'"
                    [color]="'secondary'"
                    [icon]="'close'"
                    (click)="resetToDefault()"
                    class="mr-10 whitespace-nowrap"
                >
                    <span class="body-2">{{tSort('reset')}}</span>
                </app-tb-button>
                <div class="flex items-center justify-end">
                    <app-tb-button
                        (click)="onApplyForSession()"
                        [disabled]="!isSessionSaveAvailable"
                        [appearance]="'outlined'"
                        class="mr-4"
                    >
                        {{tButtons('preview')}}
                    </app-tb-button>
                    <app-tb-button (click)="onSaveSort()" [disabled]="!isSaveAvailable">
                        {{tSort('save')}}
                    </app-tb-button>
                </div>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>

<!-- TODO: default error message, correct will be added later -->
<ng-template #error>
    <span>some error message</span>
</ng-template>
