<div *ngIf="field" class="flex items-center">
    <app-tb-icon
        *ngIf="showIcon"
        [icon]="getFieldIcon()"
        color="newTextLight"
        [class]="bgIconColor"
    >
    </app-tb-icon>
    <span
        *ngIf="!showFolderName && field.folder_name?.name"
        class="w-full"
        [class]="[trim ? '' : 'overflow-hidden text-ellipsis whitespace-nowrap']"
        >{{field.folder_name!.name + "/" + field.name}}
    </span>
    <span
        *ngIf="showFolderName || !field.folder_name?.name "
        class="w-full"
        [class]="[trim ? '' : 'overflow-hidden text-ellipsis whitespace-nowrap']"
        >{{ field.name }}
    </span>
</div>
