import { createFeature, createReducer, on } from '@ngrx/store'
import { initialUserState, userAdapter, UserState } from './user.state'
import * as UsersActions from './user.actions'
import { CurrentUser, User } from '../../models'
import { StoreFeatureNames } from '../store-feature-names'
import { dataReset } from '../common/common.actions'

const userReducer = createReducer(
    initialUserState,
    on(dataReset, () => initialUserState),
    on(UsersActions.setUsers, (state: UserState, { users }: { users: User[] }) => {
        return userAdapter.setMany(users, {
            ...state,
        })
    }),
    on(
        UsersActions.setCurrentUser,
        (state: UserState, { currentUser }: { currentUser: CurrentUser | null }) => {
            return {
                ...state,
                currentUser,
            }
        },
    ),
)

export const userFeature = createFeature({
    name: StoreFeatureNames.Users,
    reducer: userReducer,
})
