<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title> all fields</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let key of subFields">
            <app-card-field
                *ngIf="fields[key]"
                [fieldTypes]="fieldTypes"
                [field]="fields[key]"
                [selectedFolder]="selectedFolder"
                [record]="record"
                [cells]="cells"
            ></app-card-field>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>
