import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class ResizeColumnService {
    private columns = new Map<string, Subject<number>>()

    registerColumn(key: string) {
        if (this.columns.has(key)) return

        this.columns.set(key, new Subject())
    }

    getResizedWidth$(key: string) {
        return this.columns.get(key)?.asObservable()
    }

    setResizedWidth(key: string, width: number) {
        this.columns.get(key)?.next(width)
    }
}
