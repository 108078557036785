@if(label) {
<label class="text-size-xs leading-5" [innerText]="label"></label>
}

<mat-form-field appearance="outline">
    @if(selectedTbOptionComponent?.icon) {
    <app-tb-icon
        [icon]="selectedTbOptionComponent?.icon || ''"
        color="newTextLight"
        matIconPrefix
    ></app-tb-icon>
    } @else if(icon) {
    <app-tb-icon [icon]="icon" color="newTextLight" matIconPrefix></app-tb-icon>
    }

    <mat-select
        class="body-1"
        #matSelectComponent
        [formControl]="formControl"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [compareWith]="compareFn"
        [sortComparator]="multipleSortComparatorByLastChecked"
        (focusin)="onFocusIn()"
        (focusout)="onFocusOut()"
        (opened)="onOpened()"
        (closed)="onClosed()"
        (selectionChange)="onSelectionChange($event)"
    >
        <mat-select-trigger>
            <div *ngIf="!multiple">
                <span [innerText]="selectedTbOptionComponent?.label"></span>
            </div>
            <div *ngIf="multiple" class="flex flex-row flex-wrap gap-2">
                <ng-container *ngFor="let val of multipleValue">
                    <app-tb-tag [removable]="true" (remove)="onRemoveValue(val)"
                        >{{ val }}</app-tb-tag
                    >
                </ng-container>
            </div>
        </mat-select-trigger>

        <mat-option
            *ngFor="let tbOption of tbOptionComponents"
            [disabled]="tbOption.disabled"
            [value]="tbOption.value"
            (onSelectionChange)="onOptionSelectionChange($event)"
            ><div class="flex flex-row items-center w-full">
                <div class="w-full">
                    <span class="" *ngTemplateOutlet="tbOption.optionTemplate"></span>
                </div>
                <app-tb-checkbox
                    *ngIf="multiple"
                    [value]="multipleHasValue(tbOption.value)"
                ></app-tb-checkbox></div
        ></mat-option>
    </mat-select>

    <app-tb-icon
        color="newTextLight"
        [icon]="isDropdownExpanded ? 'expand_less' : 'expand_more'"
        matIconSuffix
    ></app-tb-icon>

    @if (errorMessage) {
    <mat-error> {{ errorMessage }} </mat-error>
    } @if (hint) {
    <mat-hint class="caption-1">{{ hint }}</mat-hint>
    } @if (formControl.touched && formControl.invalid) {
    <app-tb-icon icon="error_outline" color="red-200" matIconSuffix></app-tb-icon>
    }
</mat-form-field>
