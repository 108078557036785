import { CanActivateFn } from '@angular/router'
import { filter, Observable } from 'rxjs'
import { inject } from '@angular/core'
import { CommonFacadeService } from '../services/store-facade'
import { map } from 'rxjs/operators'

export const WaitForDataInitGuard: CanActivateFn = (): Observable<boolean> => {
    const commonFacade = inject(CommonFacadeService)

    return commonFacade.selectDataInitialized$.pipe(
        filter(Boolean),
        map(() => true),
    )
}
