import { Component, Input, OnInit } from '@angular/core'
import { BusinessRecords, Field } from '@core/models'
import { LinkReferenceStoreService } from '@core/services/link-reference-store.service'
import { RecordFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-card-link-references',
    templateUrl: './card-link-references.component.html',
    styleUrls: ['./card-link-references.component.sass'],
})
export class CardLinkReferencesComponent implements OnInit {
    @Input()
    record!: BusinessRecords

    @Input()
    field!: Field

    sourceRecords: BusinessRecords[] = []

    constructor(
        private linkReferenceStoreService: LinkReferenceStoreService,
        private recordFacadeService: RecordFacadeService,
    ) {}

    ngOnInit() {
        this.setRecordNames()
    }

    setRecordNames() {
        if (!this.record?.guid || !this.field.virtual_link) return

        const sources = this.linkReferenceStoreService.getSources(
            this.record?.guid,
            this.field.virtual_link,
        )

        if (!sources.length) return

        this.recordFacadeService.selectAllRecords$
            .pipe(take(1), untilDestroyed(this))
            .subscribe((records) => {
                this.sourceRecords = records.filter((record) => sources.includes(record.guid))
            })
    }

    get recordNames() {
        return this.sourceRecords.length !== 0
            ? this.sourceRecords.map((record) => record.name.value).join()
            : '-'
    }
}
