import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { CommonFacadeService } from '@core/services/store-facade'

@Component({
    selector: 'app-global-loading',
    templateUrl: './global-loading.component.html',
    styleUrls: ['./global-loading.component.sass'],
})
export class GlobalLoadingComponent {
    loading$: Observable<boolean> = this.commonFacadeService.selectLoading$

    constructor(private commonFacadeService: CommonFacadeService) {}
}
