import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { ActionsPanelComponent } from '@app/feature/actions-panel/actions-panel.component'
import { GroupCellEditComponent } from '@app/feature/actions-panel/group-cell-edit/group-cell-edit.component'
import { SharedModule } from '@app/shared/shared.module'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'

const forExport = [ActionsPanelComponent]

@NgModule({
    declarations: [...forExport, GroupCellEditComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        ComponentsLibraryModule,
        MatMenuModule,
        SharedModule,
        MatIconModule,
    ],
    exports: forExport,
})
export class ActionsPanelModule {}
