import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { configFeature } from './config.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ConfigEffects } from './config.effects'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(configFeature),
        EffectsModule.forFeature([ConfigEffects]),
    ],
})
export class ConfigStoreModule {}
