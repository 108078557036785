import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { GlobalLoadingComponent } from '@app/feature/global-loading/global-loading.component'

@NgModule({
    declarations: [GlobalLoadingComponent],
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [GlobalLoadingComponent],
})
export class GlobalLoadingModule {}
