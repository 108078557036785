import { Injectable } from '@angular/core'
import { SystemResponseHandler } from '@core/models'
import {
    generateUpdatedSystemRecords,
    ResponseRecordEntities,
    ResponseSchema,
    Schema,
    TableModel,
    View,
    generateRecords,
} from '../models'
import { ViewFacadeService } from '@services/store-facade'

/**
 * This service only handles "init data" or "save data" messages coming from WS.
 */
@Injectable({
    providedIn: 'root',
})
export class ViewResponseHandlerService implements SystemResponseHandler<View> {
    constructor(private viewFacadeService: ViewFacadeService) {}

    initSystemRecord(
        schema: ResponseSchema,
        record: ResponseRecordEntities,
        selectedSystemRecordGuid?: string,
    ): void {
        const views: View[] = generateRecords(schema, record) as View[]
        this.viewFacadeService.initViews(views, selectedSystemRecordGuid)
        this.viewFacadeService.viewsAreLoaded()
    }

    updateRecord(table: TableModel, schema: Schema): void {
        if (table.deleted) {
            this.viewFacadeService.deleteViewsEntities(table.deleted!)
        }

        if (table.updated) {
            const views = generateUpdatedSystemRecords<View>(table, schema)
            this.viewFacadeService.updateViewsEntities(views)
        }

        if (table.added) {
            this.viewFacadeService.addViewsEntities(generateRecords(schema, table.added) as View[])
        }
    }
}
