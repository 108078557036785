import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { getBoolean } from '@core/global-util'
import { Field, Folder } from '@models/ui'

@Component({
    selector: 'app-manage-field-field-options',
    templateUrl: './field-options-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldOptionsMenuComponent {
    @Input() field!: Field
    @Input() selectedFolder!: Folder

    @Output() deleteField = new EventEmitter()

    get isGlobalFolder(): boolean {
        return getBoolean(this.selectedFolder.is_global.value)
    }

    constructor(private manageFieldsService: ManageFieldsService) {}

    editField() {
        this.manageFieldsService.editField(this.field)
    }

    duplicateField() {
        this.manageFieldsService.duplicateField(this.field)
    }
}
