<div
    class="group shadow-sm p-2 rounded-lg flex items-center h-[64px] bg-newNeutral1"
    [class.collapsed]="collapsed"
>
    <div class="header-indicator w-full">
        <ng-content></ng-content>
    </div>

    <span class="mx-2 body-2 bg-newNeutral3 rounded-lg px-[5px]">{{ items.length }}</span>

    <app-tb-button
        appearance="icon"
        icon="keyboard_double_arrow_left"
        class="mr-2 collapse-btn visible md:invisible group-hover:visible"
        (click)="toggleCollapse()"
    ></app-tb-button>

    <app-tb-menu
        *ngIf="moreMenu"
        class="mr-2 more-menu visible md:invisible group-hover:visible"
        [template]="moreMenu"
        [replaceable]="true"
        [data]="{ columnItem: columnItem, collapsed, toggleCollapse: toggleCollapseBound }"
    >
        <app-tb-button appearance="icon" icon="more_vert" trigger> </app-tb-button>
    </app-tb-menu>

    <app-tb-checkbox
        class="select-checkbox visible group-hover:visible"
        [class]="selectedItems.length > 0 ? 'visible' : 'md:invisible'"
        (valueChange)="toggleSelection($event)"
        [value]="isSomeItemsSelected"
        [disabled]="items.length === 0"
        [indeterminate]="isIndeterminate"
    ></app-tb-checkbox>
</div>
