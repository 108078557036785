import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AppRecord, BusinessRecords, Cell, CellEntities, Field, FieldType } from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { ActionsPanelComponent } from '../actions-panel.component'

export interface DialogGroupCellEditInput {
    records: BusinessRecords[]
    field: Field
    fieldTypes: Dictionary<FieldType>
    cells: { [recordGuid: string]: CellEntities }
}

export interface DialogGroupCellEditOutput {
    value: string | undefined
    field: Field
}

@UntilDestroy()
@Component({
    selector: 'app-edit-field',
    templateUrl: './group-cell-edit.component.html',
    styleUrls: ['./group-cell-edit.component.sass'],
})
export class GroupCellEditComponent implements OnInit {
    cells!: { [recordGuid: string]: CellEntities }
    record!: BusinessRecords
    field!: Field
    fieldTypes!: Dictionary<FieldType>
    records!: BusinessRecords[]
    newValue!: string

    constructor(
        private dialogRef: MatDialogRef<
            ActionsPanelComponent,
            DialogGroupCellEditOutput | undefined
        >,
        @Inject(MAT_DIALOG_DATA) public data: DialogGroupCellEditInput,
    ) {}

    ngOnInit() {
        this.record = this.data.records[0]
        this.records = this.data.records
        this.field = this.data.field
        this.fieldTypes = this.data.fieldTypes
        this.cells = this.data.cells

        this.newValue =
            this.records.length === 1 || this.equalCellValueCheck()
                ? this.cells[this.record.guid][this.field.guid].value
                : ''
    }

    updateRecord(record: AppRecord, cellRecord: Cell, value: string) {
        this.newValue = value
    }

    applyEditField() {
        const dialogResult: DialogGroupCellEditOutput = { value: this.newValue, field: this.field }
        this.dialogRef.close(dialogResult)
    }

    closeEditField() {
        this.dialogRef.close()
    }

    private equalCellValueCheck() {
        if (this.records.length > 1) {
            const cellValues = this.records.map(
                (record) => this.cells[record.guid][this.field.guid].value,
            )
            return new Set(cellValues).size === 1
        }
        return true
    }
}
