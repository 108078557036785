import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { MatMenuModule } from '@angular/material/menu'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    standalone: true,
    imports: [TranslocoModule, TbButtonComponent, MatMenuModule],
})
export class VersionComponent implements OnInit {
    version!: { branch?: string; commit?: string; date?: string }
    prod = environment.mode

    constructor() {}

    ngOnInit(): void {
        this.version = {
            branch: environment.version.branch,
            commit: environment.version.commit,
            date: environment.version.date,
        }
    }
}
