import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ErrorPageData } from '@app/pages/errors/error/error.page'
import { filter, take } from 'rxjs'
import { View } from '../models'
import { FolderFacadeService, SchemaFacadeService, ViewFacadeService } from './store-facade'
import { SystemRecords, SystemRecordsFacadeService } from './system-records-facade.service'

@Injectable({
    providedIn: 'root',
})
export class NavigatorService {
    constructor(
        private router: Router,
        private systemRecordsFacadeService: SystemRecordsFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private viewFacadeService: ViewFacadeService,
    ) {}

    goToGlobalFolder() {
        this.schemaFacadeService.setSelectedTableSchemaGuid(null)
        this.viewFacadeService.setSelectedViewGuid(null)

        this.folderFacadeService.selectGlobalFolder$
            .pipe(filter(Boolean), take(1))
            .subscribe((folder) => {
                this.goToFolder(folder.guid)
            })
    }

    goToFolder(folderGuid: string): void {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromFolder(folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewNotFoundPage()
            })
    }

    goToSystemObject(systemObjectGuid: string, folderGuid: string) {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromSystemObject(systemObjectGuid, folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewNotFoundPage()
            })
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl(this.router.url, {
            onSameUrlNavigation: 'reload',
            replaceUrl: true,
        })
    }

    goToView(view: Pick<View, 'guid'>) {
        void this.router.navigate(['view', view.guid])
    }

    goToViewRootPage() {
        void this.router.navigate(['view'])
    }

    goToViewNotFoundPage() {
        this.router.navigateByUrl('/view-not-found')
    }

    goToHome() {
        void this.router.navigate(['home'])
    }

    // todo: looks deprecated
    goToNotificationPage() {
        void this.router.navigate(['notificationPage'])
    }
}
