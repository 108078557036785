export interface AclAdd extends AclCommon {
    //TODO: compare with other ACLs and extract?
    acl_type_id: number
    c?: string
    r?: string
    u?: string
    d?: string
    acl?: string
    scope_guid_2: string
}

export interface AclUpdate extends AclDelete {
    acl_type_id: number
    c?: string
    r?: string
    u?: string
    d?: string
    acl?: string
}

export interface AclDelete extends AclCommon {
    guid: string
    revision: number
}

interface AclCommon {
    role_guid?: string
    user_guid?: string
}

export interface AccessModel {
    add: AclAdd[]
    update: AclUpdate[]
    delete: AclDelete[]
}
