<div class="flex-start-center" *transloco="let t">
    <span>{{t('card_content.status_row_label')}}</span>
    <div class="status-bl">
        <app-status
            *ngIf="statusField"
            (cellValueChanged)="updateRecord(record, record.status[folder.guid], $event)"
            [fieldType]="fieldTypes[statusField.field_type_code]"
            [field]="statusField"
            [record]="record"
            [value]="record.status[folder.guid].value"
            class="flex-start-center text-center"
        ></app-status>
    </div>
</div>
