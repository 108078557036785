import { NgModule } from '@angular/core'
import { GetContrastColorPipe } from './get-contrast-color.pipe'

const pipes = [GetContrastColorPipe]

@NgModule({
    declarations: pipes,
    providers: pipes,
    exports: pipes,
})
export class PipesModule {}
