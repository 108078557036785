import { Injectable } from '@angular/core'
import { DevKeys, SolutionType } from '@test/dev-utils'

@Injectable({
    providedIn: 'root',
})
export class DevStorageService {
    constructor() {}

    getSolutionType() {
        return localStorage.getItem(DevKeys.SolutionType)
    }

    setSolutionType(val: SolutionType) {
        this.setValue(DevKeys.SolutionType, val)
    }

    setValue(key: string, value: SolutionType) {
        localStorage.setItem(key, value)
    }
}
