import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { RatingConfig } from '@core/models'
import { MatIconModule } from '@angular/material/icon'
import { NgIf, NgFor } from '@angular/common'

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, MatIconModule],
})
export class RatingComponent extends CellComponent implements OnInit {
    selectValue!: number | undefined
    countStarsInArray!: number[]
    sliderValue!: number
    hoverValue!: number | undefined

    ngOnInit(): void {
        if (this.field.configure_json) {
            const ratingConfig: RatingConfig = JSON.parse(this.field.configure_json)
            if (ratingConfig) {
                this.sliderValue = ratingConfig.count
            }
        } else {
            this.sliderValue = 5
        }
        this.selectValue = this.value == '' ? undefined : Number(this.value)
        this.hoverValue = undefined
        this.getArrayFromValue(this.sliderValue)
    }

    getArrayFromValue(val: number) {
        this.countStarsInArray = [...Array(Number(val)).keys()]
    }

    setRating(val: number) {
        const changedValue = String(val)
        if (this.value != String(val)) {
            this.cellValueChanged.emit(changedValue)
        }
    }

    changeHoverStyles(event: any, val: number) {
        this.selectValue = undefined
        this.hoverValue = val
    }

    backHoverStyles() {
        this.hoverValue = undefined
        this.selectValue = this.value == '' ? undefined : Number(this.value)
    }
}
