<div class="flex h-9">
    <div class="flex" [class.flex-wrap]="hideOverflowingTabs">
        <ng-container *ngFor="let tab of tabs">
            <app-tb-tab
                (selectTab)="tabOpened.emit(tab)"
                [tab]="tab"
                [moreMenu]="moreMenu ? tabMoreMenu : null"
                [isSelected]="activeTab === tab"
                [icon]="icon"
            ></app-tb-tab>

            <ng-template #tabMoreMenu>
                <ng-container
                    [ngTemplateOutlet]="moreMenu"
                    [ngTemplateOutletContext]="{ tab }"
                ></ng-container>
            </ng-template>
        </ng-container>
    </div>
</div>
