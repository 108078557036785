import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TbButtonModule } from '@components-library/tb-button/tb-button.module'
import { TbMenuListItemModule } from '@components-library/tb-menu-list-item/tb-menu-list-item.module'
import { TbMenuListModule } from '@components-library/tb-menu-list/tb-menu-list.module'
import { TbMenuModule } from '@components-library/tb-menu/tb-menu.module'
import { TbPickerModule } from '@components-library/tb-picker/tb-picker.module'
import { TbTooltipModule } from '@components-library/tb-tooltip/tb-tooltip.module'
import { TbSideNavigationLayoutComponent } from './tb-side-navigation-layout/tb-side-navigation-layout.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { CdkStep, CdkStepperModule } from '@angular/cdk/stepper'
import { TranslocoModule } from '@ngneat/transloco'
import { TbNavigationMenuItemComponent } from './tb-side-navigation-layout/tb-navigation-menu-item/tb-navigation-menu-item.component'
import { TbTabsComponent } from './tb-tabs/tb-tabs.component'
import { MatTabsModule } from '@angular/material/tabs'
import { TbTabComponent } from './tb-tab/tb-tab.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ReactiveFormsModule } from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { MatDividerModule } from '@angular/material/divider'
import { MatInputModule } from '@angular/material/input'
import { TbDividerComponent } from './tb-divider/tb-divider.component'
import { TbIconModule } from './tb-icon/tb-icon.module'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TbToggleComponent } from './tb-toggle/tb-toggle.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { TbRadioButtonComponent } from './tb-radio-button/tb-radio-button.component'
import { MatRadioModule } from '@angular/material/radio'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbConfirmationDialogComponent } from './tb-confirmation-popup/tb-confirmation-dialog.component'
import { TbSelectModule } from './tb-select/tb-select.module'
import { TbCheckboxModule } from './tb-checkbox'
import { TbIconToggleButtonComponent } from './tb-icon-toggle-button/tb-icon-toggle-button.component'
import { TbScrollingModule } from '@components-library/virtual-scrolling/virtual-scrolling.module'
import { TbAccordionComponent } from '@components-library/tb-accordion/tb-accordion.component'
import { MatExpansionModule } from '@angular/material/expansion'

const componentsForExport = [
    TbSideNavigationLayoutComponent,
    TbTabsComponent,
    TbTabComponent,
    TbDividerComponent,
    TbRadioButtonComponent,
    TbToggleComponent,
    TbInputComponent,
    TbConfirmationDialogComponent,
    TbIconToggleButtonComponent,
    TbAccordionComponent,
]

@NgModule({
    declarations: [...componentsForExport, TbNavigationMenuItemComponent],
    imports: [
        TbScrollingModule,
        CommonModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        CdkStepperModule,
        TranslocoModule,
        MatTabsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDividerModule,
        MatInputModule,
        ReactiveFormsModule,
        TbIconModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSlideToggleModule,
        TbSelectModule,
        TbCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        TbTooltipModule,
        TbPickerModule,
        TbMenuModule,
        TbMenuListModule,
        TbMenuListItemModule,
        TbButtonModule,
    ],
    exports: [
        ...componentsForExport,
        CdkStep,
        TbIconModule,
        TbSelectModule,
        TbCheckboxModule,
        TbScrollingModule,
        TbTooltipModule,
        TbPickerModule,
        TbMenuModule,
        TbMenuListModule,
        TbMenuListItemModule,
        TbButtonModule,
    ],
})
export class ComponentsLibraryModule {}
