import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatRadioChange } from '@angular/material/radio'
import { ControlValueAccessor } from '@angular/forms'

@Component({
    selector: 'app-tb-radio-button',
    templateUrl: './tb-radio-button.component.html',
    styleUrl: './tb-radio-button.component.sass',
})
export class TbRadioButtonComponent implements ControlValueAccessor {
    @Input() label?: string = ''

    @Input() value!: any

    @Input() checked: boolean = false

    @Input() disabled: boolean = false

    @Output() valueChange = new EventEmitter<any>()

    private _onTouched = () => {}
    private _onChange = (value: any) => {}

    onRadioButtonValueChange(event: MatRadioChange) {
        this.valueChange.emit(event.value)
        this._onChange(event.value)
        this._onTouched()
    }
    writeValue(value: any): void {
        this.value = value
    }
    registerOnChange(fn: any): void {
        this._onChange = fn
    }
    registerOnTouched(fn: any): void {
        this._onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}
