import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
    CommonDialogConfig,
    CommonDialogResultStatus,
    TbConfirmationDialogComponent,
} from '../tb-confirmation-popup/tb-confirmation-dialog.component'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService {
    constructor(private dialog: MatDialog) {}

    openCommon({
        translations,
        buttons,
        colors = {},
    }: CommonDialogConfig): Observable<CommonDialogResultStatus> {
        const mergedConfig: CommonDialogConfig = {
            translations: {
                title: translations.title || '',
                message: translations.message || '',
                confirm: translations.confirm || 'buttons.apply',
                cancel: translations.cancel || 'buttons.cancel',
            },
            buttons: {
                confirm: buttons?.confirm !== undefined ? buttons?.confirm : true,
                cancel: buttons?.cancel !== undefined ? buttons?.cancel : true,
            },
            colors,
        }

        return this.dialog
            .open(TbConfirmationDialogComponent, { disableClose: true, data: mergedConfig })
            .afterClosed()
    }
}
