import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { CellComponent } from '@app/shared/cell-types/cell.component'
import { DateService } from '@app/shared/cell-types/date.service'
import {
    DaterangepickerComponent,
    DaterangepickerDirective,
    NgxDaterangepickerMd,
} from 'ngx-daterangepicker-material'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatIconModule } from '@angular/material/icon'
import { NgIf } from '@angular/common'
import { AutoFocusDirective } from '../focus.directive'

@UntilDestroy()
@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.sass'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgxDaterangepickerMd, AutoFocusDirective, NgIf, MatIconModule],
})
export class DateComponent extends CellComponent implements OnInit {
    dateFormat = 'MM/DD/YYYY'

    @ViewChild(DaterangepickerDirective, { static: true })
    pickerDirective!: DaterangepickerDirective
    picker!: DaterangepickerComponent
    valueLocale!: string
    isEdit: boolean = false

    constructor(private translation: TranslocoService, private dateService: DateService) {
        super()
    }

    ngOnInit(): void {
        this.translation
            .selectTranslate('cell_types.date.empty_date_label')
            .pipe(untilDestroyed(this))
            .subscribe((dateLabel) => {
                this.picker = this.pickerDirective.picker

                this.valueLocale = this.value ? this.pickDateFromValue() : dateLabel
            })
    }

    editMode(e: Event) {
        this.isEdit = true
        this.pickerDirective?.open(e)
    }

    clearDate() {
        this.cellValueChanged.emit('')
        this.isEdit = true
    }

    filterFrom() {
        const dateFormatted = this.picker.startDate.format(this.dateFormat)
        if (!this.value || dateFormatted !== this.valueLocale) {
            this.cellValueChanged.emit(dateFormatted)
        }
    }

    private pickDateFromValue() {
        this.picker.startDate = this.picker.endDate = this.dateService.createDate(this.value)

        return this.picker.startDate.format(this.dateFormat)
    }
}
