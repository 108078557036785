import { createFeatureSelector } from '@ngrx/store'
import { StoreFeatureNames } from '../store-feature-names'
import { roleAdapter, RoleState } from './role.reducer'

const selectRoleState = createFeatureSelector<RoleState>(StoreFeatureNames.Role)

const { selectIds, selectEntities, selectAll, selectTotal } =
    roleAdapter.getSelectors(selectRoleState)

export const selectRoleIds = selectIds
export const selectRoleEntities = selectEntities
export const selectAllRoles = selectAll
export const selectRoleTotal = selectTotal
