<ng-container *ngIf="allowEdit, else noChangeUser">
    <!-- change to app-tb-button after new design will be implemented -->
    <button (click)="toggleUser(user.guid)" *ngFor="let user of users" mat-menu-item>
        <app-user-avatar
            [userName]="user.fullName"
            [userUrl]="user.avatar_url"
            [class.active]="activeUserGuids!.includes(user.guid)"
        ></app-user-avatar>
        {{user.fullName}}
    </button>
</ng-container>
<ng-template #noChangeUser>
    <!-- change to app-tb-button after new design will be implemented -->
    <button *ngFor="let user of users" mat-menu-item>
        <app-user-avatar
            [userName]="user.fullName"
            [userUrl]="user.avatar_url"
            [class.active]="activeUserGuids && activeUserGuids.includes(user.guid)"
        ></app-user-avatar>
        {{user.fullName}}
    </button>
</ng-template>
