import { Injectable } from '@angular/core'
import {
    CommonFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '@core/services/store-facade'
import { combineLatest, filter } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map } from 'rxjs/operators'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class DataStorageService {
    private namespaceKey!: string

    constructor(
        userFacadeService: UserFacadeService,
        commonFacadeService: CommonFacadeService,
        schemaFacadeService: SchemaFacadeService,
    ) {
        combineLatest([
            userFacadeService.currentUser$,
            commonFacadeService.selectSolution$,
            schemaFacadeService.selectSelectedTableSchemaGuid$,
        ])
            .pipe(
                untilDestroyed(this),
                map(([user, solution, schemaGuid]) => {
                    if (!user || !solution) return

                    return `${user.guid}-${solution.guid}-${schemaGuid}`
                }),
                filter(Boolean),
            )
            .subscribe((combinedKeys) => (this.namespaceKey = combinedKeys))
    }

    get(key: string) {
        return localStorage.getItem(this.getFullKey(key))
    }

    set(key: string, value: string) {
        localStorage.setItem(this.getFullKey(key), value)
    }

    remove(key: string) {
        localStorage.removeItem(this.getFullKey(key))
    }

    getFullKey(key: string) {
        return `${this.namespaceKey}-${key}`
    }
}
