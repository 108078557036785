import { Component } from '@angular/core'
import { NavigatorService } from '@core/services/navigator.service'

@Component({
    selector: 'app-notifications-menu-content',
    styleUrls: ['./notifications-menu-content.component.sass'],
    templateUrl: './notifications-menu-content.component.html',
})
export class NotificationsMenuContentComponent {
    constructor(private navigatorService: NavigatorService) {}

    navigateNotifications() {
        this.navigatorService.goToNotificationPage()
    }
}
