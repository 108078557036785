import { createFeature, createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Config } from '../../models/ui/config.model'
import {
    addConfigFromResponse,
    deleteConfigFromResponse,
    setConfigs,
    updateConfigFromResponse,
} from './config.actions'
import { StoreFeatureNames } from '../store-feature-names'
import { dataReset } from '../common/common.actions'

const configFeatureKey: string = StoreFeatureNames.Config

export interface ConfigState extends EntityState<Config> {}

function selectConfigId(config: Config): string {
    return config.guid
}

export const configAdapter: EntityAdapter<Config> = createEntityAdapter<Config>({
    selectId: selectConfigId,
})

const initialState: ConfigState = configAdapter.getInitialState({})

const configReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(setConfigs, (state: ConfigState, { configs }) => {
        return configAdapter.setAll(configs, state)
    }),

    on(addConfigFromResponse, (state: ConfigState, { config }) => {
        return configAdapter.addOne(config, state)
    }),

    on(updateConfigFromResponse, (state: ConfigState, { config }) => {
        return configAdapter.updateMany(config, state)
    }),

    on(deleteConfigFromResponse, (state: ConfigState, { configs }) => {
        return configAdapter.removeMany(
            configs.map((deleted) => deleted.guid),
            state,
        )
    }),
)
export const configFeature = createFeature({
    name: configFeatureKey,
    reducer: configReducer,
})
