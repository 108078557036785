import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { filter, take } from 'rxjs'
import { View } from '../models'
import { FolderFacadeService, SchemaFacadeService, ViewFacadeService } from './store-facade'
import { SystemRecords, SystemRecordsFacadeService } from './system-records-facade.service'

@Injectable({
    providedIn: 'root',
})
export class NavigatorService {
    constructor(
        private router: Router,
        private systemRecordsFacadeService: SystemRecordsFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private viewFacadeService: ViewFacadeService,
    ) {}

    goToGlobalFolder() {
        this.schemaFacadeService.setSelectedTableSchemaGuid(null)
        this.viewFacadeService.setSelectedViewGuid(null)

        this.folderFacadeService.selectGlobalFolder$
            .pipe(filter(Boolean), take(1))
            .subscribe((folder) => {
                this.goToFolder(folder.guid)
            })
    }

    goToFolder(folderGuid: string): void {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromFolder(folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewCreatePage(systemRecords)
            })
    }

    goToSystemObject(systemObjectGuid: string, folderGuid: string) {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromSystemObject(systemObjectGuid, folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewCreatePage(systemRecords)
            })
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl(this.router.url, {
            onSameUrlNavigation: 'reload',
            replaceUrl: true,
        })
    }

    goToView(view: View) {
        void this.router.navigate(['view', view.guid])
    }

    goToViewPage() {
        void this.router.navigate(['view'])
    }

    goToMyFolder() {
        void this.router.navigate(['myFolder'])
    }

    goToNotificationPage() {
        void this.router.navigate(['notificationPage'])
    }

    goToViewCreatePage(systemRecords: SystemRecords) {
        const { folder, tableSchema } = systemRecords

        if (folder) this.folderFacadeService.setSelectedFolderGuid(folder.guid)

        if (tableSchema) this.schemaFacadeService.setSelectedTableSchemaGuid(tableSchema.guid)

        void this.router.navigate(['view', 'create'])
    }
}
