import { getBoolean } from '@core/global-util'
import { AppRecord } from './base-record.model'
import { Cell } from './cell.model'

export interface Folder extends AppRecord {
    name: Cell
    is_global: Cell
    icon: Cell
    color: Cell
}

export function isFolderGlobal(folder: Folder) {
    return getBoolean(folder.is_global.value)
}
