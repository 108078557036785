import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'

@Component({
    selector: 'app-number-filter',
    templateUrl: './number-filter.component.html',
    styleUrls: ['./number-filter.component.sass'],
})
export class NumberFilterComponent extends FilterTypesComponent implements OnInit {
    numberFormControl!: FormControl<string | string[] | undefined | null>

    ngOnInit(): void {
        this.numberFormControl = this.form.controls['value'] as FormControl
    }
}
