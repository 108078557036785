import { Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { EMPTY, Observable, of, retry, switchMap } from 'rxjs'
import { AuthHttpService, AuthResponseStatus } from './auth-http.service'

export interface AuthResponse {
    data: AuthResponseData
    status: AuthResponseStatus
}

interface AuthResponseData {
    token?: string
    ws?: string
    url?: string
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private tpAuth!: TPAuth

    constructor(private authHttpService: AuthHttpService) {
        this.tpAuth = new TPAuth()
    }

    logout(): Observable<string> {
        return this.authHttpService.logout().pipe(untilDestroyed(this))
    }

    auth(): Observable<AuthResponse> {
        return this.authHttpService.login().pipe(
            untilDestroyed(this),
            retry(3),
            switchMap((response) => {
                if (response.status === AuthResponseStatus.SUCCESS) {
                    return of(response)
                }

                if (response.status === AuthResponseStatus.ERROR) {
                    return this.configureAuth()
                }

                return EMPTY
            }),
        )
    }

    private configureAuth() {
        return new Observable<AuthResponse>((observer) => {
            this.tpAuth
                .configure('tikibase', '', 'y', 'y', '', '', '')
                .on('success', (code: string, state: string) => {
                    this.authHttpService.login(code).subscribe(observer)
                })
                .on('error', (code: string, state: string) => {
                    throw Error(`${code}, ${state}`)
                })

            this.tpAuth.showLogin()
        })
    }
}
