import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'

@UntilDestroy()
@Component({
    selector: 'app-status-element',
    templateUrl: './status-element.component.html',
})
export class StatusElementComponent {
    @Input() color!: string
    @Input() name!: string
}
