import { Component } from '@angular/core'
import { map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { FolderFacadeService } from '@core/services/store-facade'
import { FoldersListComponent } from './folders-list/folders-list.component'

@Component({
    selector: 'app-folder-controls',
    templateUrl: './folder-controls.component.html',
})
export class FolderControlsComponent {
    constructor(private folderFacadeService: FolderFacadeService) {}

    menuComponent = FoldersListComponent

    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$
}
