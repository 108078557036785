<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4">
    <app-tb-button
        appearance="text"
        [color]="!(isSortEntitiesEmpty$ | async) ? 'secondary' : 'primary'"
        icon="custom_sort"
        (click)="openDialog()"
    >
        <div class="flex-start-center">
            <div *transloco="let tButtons; read: 'buttons'" class="flex items-center">
                <span class="subheading-2 mr-2">{{tButtons('sort_by')}}</span>
                <span
                    *ngIf="!(isSortEntitiesEmpty$ | async)"
                    class="caption-1 leading-3 rounded-full"
                >
                    {{filterCountText$ | async}}
                </span>
            </div>
        </div>
    </app-tb-button>
</div>
