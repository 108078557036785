import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router'
import { FolderFacadeService, SchemaFacadeService, ViewFacadeService } from '@services/store-facade'
import { SystemRecords, SystemRecordsFacadeService } from '@services/system-records-facade.service'
import { WsProcessingService } from '@services/ws-processing.service'
import { Observable, of } from 'rxjs'
import { combineLatestWith, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { collectParams } from '../global-util'

type RedirectDeps = {
    router: Router
    systemRecordsFacadeService: SystemRecordsFacadeService
    schemaFacadeService: SchemaFacadeService
    folderFacadeService: FolderFacadeService
    viewFacadeService: ViewFacadeService
}

export const RedirectToSystemRecordGuard: CanActivateFn = (
    childRoute: ActivatedRouteSnapshot,
): Observable<UrlTree | boolean> => {
    const systemRecordsFacadeService = inject(SystemRecordsFacadeService)
    const router = inject(Router)
    const schemaFacadeService = inject(SchemaFacadeService)
    const folderFacadeService = inject(FolderFacadeService)
    const viewFacadeService = inject(ViewFacadeService)
    const wsProcessingService = inject(WsProcessingService)

    const deps: RedirectDeps = {
        router,
        systemRecordsFacadeService,
        schemaFacadeService,
        folderFacadeService,
        viewFacadeService,
    }

    const { viewGuid } = collectParams(childRoute)

    if (!viewGuid) {
        return gotoFirstFolderView(deps).pipe(
            tap(([systemRecords]) => setSystemRecordsToStore(deps, systemRecords)),
            map(([systemRecordsResult, result]) => {
                //TODO: add global error page. handle such errors for change solution
                if (!systemRecordsResult.folder || !systemRecordsResult.tableSchema) {
                    wsProcessingService.logout()
                    return false
                }

                return result
            }),
        )
    }

    return systemRecordsFacadeService.getSelectedSystemRecordsFromView(viewGuid).pipe(
        switchMap((systemRecords) => {
            return validateSystemRecords(deps, systemRecords).pipe(
                tap(() => setSystemRecordsToStore(deps, systemRecords)),
            )
        }),
    )
}

function validateSystemRecords(deps: RedirectDeps, systemRecords: SystemRecords | null) {
    const { router, schemaFacadeService } = deps
    if (!systemRecords?.tableSchema?.guid) {
        return of(router.createUrlTree(['error-page']))
    }

    return schemaFacadeService.selectSchemaByGuid$(systemRecords.tableSchema.guid).pipe(
        map((schema) => {
            if (!schema && systemRecords.tableSchema) {
                return router.createUrlTree(['error-page'])
            }

            if (schema?.acl === -1) {
                return router.createUrlTree(['error-page'])
            }

            return true
        }),
    )
}

function gotoFirstFolderView(deps: RedirectDeps): Observable<[SystemRecords, boolean | UrlTree]> {
    const { systemRecordsFacadeService, schemaFacadeService, router } = deps
    return systemRecordsFacadeService.selectAllFolders$.pipe(
        combineLatestWith(schemaFacadeService.selectSelectedTableSchemaGuid$),
        switchMap(([folders, schemaGuid]) => {
            if (!folders.length) {
                const defaultSystemRecords: SystemRecords = {
                    folder: null,
                    tableSchema: null,
                    view: null,
                }
                const result: [SystemRecords, UrlTree] = [
                    defaultSystemRecords,
                    router.createUrlTree(['error-page']),
                ]
                return of(result)
            }

            const observable = schemaGuid
                ? systemRecordsFacadeService.getAllSystemRecordsFromSystemObject(
                      schemaGuid,
                      folders[0].guid,
                  )
                : systemRecordsFacadeService.getAllSystemRecordsFromFolder(folders[0].guid)

            return observable.pipe(
                mergeMap((systemRecords) => redirectToViewOrErrorPage(deps, systemRecords)),
            )
        }),
    )
}

function redirectToViewOrErrorPage(
    deps: RedirectDeps,
    systemRecords: SystemRecords,
): Observable<[SystemRecords, boolean | UrlTree]> {
    const { router } = deps

    if (!systemRecords?.folder && !systemRecords?.tableSchema && !systemRecords.view) {
        return of([systemRecords, router.createUrlTree(['error-page'])])
    }

    return validateSystemRecords(deps, systemRecords).pipe(
        map((result: UrlTree | boolean) => {
            if (typeof result !== 'boolean' || !result) {
                return [systemRecords, result]
            }

            if (!systemRecords.view && systemRecords.tableSchema) {
                return [systemRecords, router.createUrlTree(['view', 'create'])]
            }

            if (systemRecords.view) {
                return [systemRecords, router.createUrlTree(['view', systemRecords.view.guid])]
            }

            return [systemRecords, true]
        }),
    )
}

function setSystemRecordsToStore(deps: RedirectDeps, systemRecords: SystemRecords | null) {
    const { folderFacadeService, schemaFacadeService, viewFacadeService } = deps

    folderFacadeService.setSelectedFolderGuid(systemRecords?.folder?.guid || null)
    schemaFacadeService.setSelectedTableSchemaGuid(systemRecords?.tableSchema?.guid || null)
    viewFacadeService.setSelectedViewGuid(systemRecords?.view?.guid || null)
}
