import { Update } from '@ngrx/entity'
import { generateRecord } from '../factories/record.factory'
import { TableModel } from '../response'
import { AppRecord } from './base-record.model'
import { Schema } from './schema.model'

export function generateUpdatedSystemRecords<T extends AppRecord>(
    table: TableModel,
    schema: Schema,
): Update<T>[] {
    return Object.keys(table.updated!).map((recordGuid: string) => {
        const updatedRecord = generateRecord(recordGuid, schema, table.updated![recordGuid]) as T

        return {
            id: recordGuid,
            changes: updatedRecord,
        }
    })
}

export function getSystemRecordsByParentGuid<T extends AppRecord>(
    records: T[],
    parentGuid: string | null,
    selectedFolderGuid: string | null,
): T[] {
    let filteredRecords: T[] = []
    if (parentGuid && selectedFolderGuid) {
        filteredRecords = records.filter((record) => {
            return (
                record.parent_sot_guid === parentGuid &&
                record.folder_guids?.includes(selectedFolderGuid)
            )
        })
    }
    return filteredRecords
}
