<div [formGroup]="linkDefinition">
    <div class="w-100 flex-col" *transloco="let tLink; read: 'cell_types.link_content'">
        <mat-form-field class="w-100">
            <mat-label>{{ tLink('system_object_type') }}</mat-label>
            <mat-select formControlName="schema">
                <mat-option *ngFor="let schema of allSchema" [value]="schema.guid">
                    {{ schema.object_type_code }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>{{ tLink('fields') }}</mat-label>
            <mat-select formControlName="fields" (openedChange)="updateValue($event)" multiple>
                <mat-select-trigger>
                    <span *ngFor="let fieldGuid of fieldsValue; let last = last">
                        <app-field-name
                            class="inline-block"
                            [field]="getField(fieldGuid)"
                            [folder]="folder"
                        ></app-field-name>
                        <ng-container *ngIf="!last && fieldsValue.length > 1">,</ng-container>
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let field of selectedSchemaFields" [value]="field.guid">
                    <app-field-name
                        [field]="field"
                        [folder]="folder"
                        [showIcon]="true"
                    ></app-field-name>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
