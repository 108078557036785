<div class="edit-mode-form">
    <div [class.active]="!disabled" class="flex items-center edit-mode-area">
        <input
            ngxDaterangepickerMd
            class="data-input"
            [placeholder]="valueLocale"
            [singleDatePicker]="true"
            [autoApply]="true"
            [customRangeDirection]="false"
            [showDropdowns]="true"
            (change)="filterFrom()"
            [appAutoFocus]="isEdit"
            autocomplete="off"
        />
        <mat-icon (click)="clearDate()" *ngIf="value" class="text-muted"> close </mat-icon>
    </div>
    <div *ngIf="disabled">
        <label> {{valueLocale}} </label>
    </div>
</div>
