<div
    *ngIf="!disabled, else disabledContent"
    [matMenuTriggerFor]="assignMenu"
    (mouseenter)="isShowAssignees = true"
    (mouseleave)="isShowAssignees = false"
>
    <div *ngIf="assignUsersLength; else iconAdd" class="users-icons">
        <app-user-avatar
            *ngFor="let user of assignedUsers"
            [userName]="user.fullName"
            [userUrl]="user.avatar_url"
        >
        </app-user-avatar>
        <div class="assignee-info" *ngIf="assignUsersLength && isShowAssignees">
            <i *ngFor="let user of assignedUsers"> {{user.fullName}} </i>
        </div>
    </div>
    <ng-template #iconAdd>
        <app-tb-icon class="md-inactive" icon="person_add_alt"></app-tb-icon>
    </ng-template>
</div>
<ng-template #disabledContent>
    <ng-container *ngIf="!isGrouping; else groupingContent">
        <div (mouseenter)="isShowAssignees = true" (mouseleave)="isShowAssignees = false">
            <div *ngIf="assignUsersLength; else iconAdd" class="users-icons">
                <span *ngFor="let user of assignedUsers" class="user-avatar">
                    <img
                        *ngIf="user.avatar_url, else defaultAvatar"
                        [src]="user.avatar_url"
                        alt=""
                        onerror="this.src='../../../../assets/img/avatar.png'"
                    />
                    <ng-template #defaultAvatar>
                        <img
                            alt="{{user.fullName}}"
                            src="../../../../../assets/img/avatar.png"
                            title="{{user.fullName}}"
                        />
                    </ng-template>
                </span>
                <div class="assignee-info" *ngIf="assignUsersLength && isShowAssignees">
                    <p *ngFor="let user of assignedUsers">{{user.fullName}}</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #groupingContent>
        <div *ngIf="assignUsersLength; else iconAdd" class="users-icons">
            <span *ngFor="let user of assignedUsers" class="user-avatar">
                <img
                    *ngIf="user.avatar_url, else defaultAvatar"
                    [src]="user.avatar_url"
                    alt=""
                    onerror="this.src='../../../../assets/img/avatar.png'"
                />
                <ng-template #defaultAvatar>
                    <img
                        alt="{{user.fullName}}"
                        src="../../../../../assets/img/avatar.png"
                        title="{{user.fullName}}"
                    />
                </ng-template>
            </span>
            <div class="assignee-info" *ngIf="assignUsersLength">
                <i *ngFor="let user of assignedUsers"> {{user.fullName}} </i>
            </div>
        </div>
    </ng-template>
    <ng-template #iconAdd>
        <ng-container *transloco="let tCellTypesAssign; read: 'cell_types.assign'">
            <app-tb-icon
                class="md-inactive"
                [title]="tCellTypesAssign('template_title')"
                icon="person_add_alt"
            >
            </app-tb-icon>
        </ng-container>
    </ng-template>
</ng-template>
<mat-menu #assignMenu="matMenu">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <mat-form-field class="search">
                <span matPrefix>
                    <app-tb-icon class="md-inactive search-icon" icon="search"></app-tb-icon>
                </span>
                <input [formControl]="search" class="search-input" matInput type="text" />
            </mat-form-field>
            <ng-container *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
                <mat-error *ngIf="isInvalidUser">
                    <span *ngIf="isInvalidUser"> {{tCellTypesErrors('unknown_user')}} </span>
                </mat-error>
            </ng-container>
            <app-people-menu
                (changeUser)="toggleUser($event)"
                [activeUserGuids]="userGuids"
                [allowEdit]="!disabled"
                [users]="users"
            ></app-people-menu>
        </div>
    </ng-template>
</mat-menu>
