<div class="fixed bottom-4 right-4 sm:right-6 sm:bottom-6 z-[1000] opacity-80">
    <div
        class="flex-start-center justify-content-end w-100"
        *transloco="let tButtons; read: 'buttons'"
    >
        <ng-container *ngIf="pinObjects$| async as allObjects">
            <ng-container *ngIf="pinRecordsMore$ | async as pinRecordsMore">
                <ng-container *ngIf="pinRecordsDraftMore$ | async as pinDraftRecordsMore">
                    <app-pinned-record
                        (openRecord)="openPinItem(item.guid)"
                        (delete)="removePinItem(item.guid)"
                        *ngFor="let item of pinRecords$ | async"
                        [name]="item.name"
                    >
                    </app-pinned-record>
                    <app-pinned-record
                        (openRecord)="openPinDraftItem(item)"
                        (delete)="removePinItem(item.guid)"
                        *ngFor="let item of pinRecordsDraft$ | async"
                        [name]="item.name"
                    >
                    </app-pinned-record>

                    <ng-container *ngIf="showMoreBtn(pinRecordsMore, pinDraftRecordsMore)">
                        <app-tb-button
                            [appearance]="'text'"
                            [icon]="'add'"
                            #triggerMore="matMenuTrigger"
                            [matMenuTriggerFor]="moreRecordMenu"
                        >
                            {{ showRecordCount }}/{{ allObjects.length }}
                        </app-tb-button>
                    </ng-container>

                    <mat-menu #moreRecordMenu="matMenu" class="px-2.5 py-5">
                        <ng-template matMenuContent>
                            <div (click)="$event.stopPropagation();$event.preventDefault()">
                                <app-pinned-record
                                    (openRecord)="openPinDraftItem(item)"
                                    (delete)="removePinItem(item.guid)"
                                    *ngFor="let item of pinDraftRecordsMore"
                                    [name]="item.name"
                                >
                                </app-pinned-record>
                                <app-pinned-record
                                    (openRecord)="openPinItem(item.guid)"
                                    (delete)="removePinItem(item.guid)"
                                    *ngFor="let item of pinRecordsMore"
                                    [name]="item.name"
                                >
                                </app-pinned-record>
                            </div>
                        </ng-template>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="schemas$ | async as schemas; else singleButton">
            @if (schemas.length > 1) {
            <app-tb-menu [template]="menuData">
                <app-tb-button [appearance]="'fab'" [color]="'secondary'" [icon]="'add'" trigger>
                </app-tb-button>
            </app-tb-menu>

            <ng-template #menuData>
                <app-tb-menu-list *transloco="let tNavMenu; read 'nav_menu'">
                    <app-tb-menu-list-item
                        *ngFor="let schema of schemas"
                        [icon]="schemaIconByName[schema.name]"
                        (click)="openDialogWithSelectedSchema(schema.guid)"
                    >
                        <ng-container item-title>{{ tNavMenu(schema.name) }}</ng-container>
                    </app-tb-menu-list-item>
                </app-tb-menu-list>
            </ng-template>
            } @else {
            <ng-container *ngTemplateOutlet="singleButton"></ng-container>
            }
        </ng-container>

        <ng-template #singleButton>
            <app-tb-button
                [appearance]="'fab'"
                [color]="'secondary'"
                [icon]="'add'"
                (click)="openEmptyDialog()"
            >
            </app-tb-button>
        </ng-template>
    </div>
</div>
