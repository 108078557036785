<app-modal-layout [containerLayout]="containerLayout" (close)="forceWithValidation()">
    <!-- TODO: change on new design implementation -->
    <div class="w-[300px] h-[100px]">
        <mat-form-field appearance="fill">
            <mat-label>Select folders</mat-label>
            <mat-select [formControl]="sharedFolders" multiple>
                <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                <mat-option
                    (click)="onItemClick()"
                    *ngFor="let folder of dropdownList"
                    [value]="folder.item_id"
                    >{{folder.item_text}}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>

    <div class="flex gap-2 justify-end" buttons>
        <app-tb-button (click)="applyShare()">Apply</app-tb-button>
        <app-tb-button appearance="outlined" (click)="cancelShare()">Cancel</app-tb-button>
    </div>
</app-modal-layout>
