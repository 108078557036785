<!--all text that start with $ should be dynamic -->
<mat-expansion-panel
    *transloco="let tAdvancedOptions; read: 'advanced_options'"
    class="mt-4 p-4 bg-primaryBackground rounded-lg advancedOptions"
    #advancedOptionsSection
    hideToggle="true"
    [expanded]="true"
>
    <mat-expansion-panel-header class="p-0 bg-primaryBackground">
        <mat-panel-title class="flex flex-col items-start text-primaryDark body-1 mr-0">
            <div class="flex items-center h-5 w-full">
                <mat-icon
                    >{{advancedOptionsSection.expanded ? 'expand_less' : 'expand_more'}}</mat-icon
                >
                <span class="capitalize">{{sectionName}}</span>
                <mat-icon
                    (click)="openConfDialog($event)"
                    svgIcon="custom_settings"
                    class="max-sm:hidden block h-5 w-5 ml-auto text-secondaryMainLight hover:text-secondaryMain"
                ></mat-icon>
                <mat-icon
                    (click)="openConfBottom()"
                    svgIcon="custom_settings"
                    class="sm:hidden h-5 w-5 ml-auto text-secondaryMainLight hover:text-secondaryMain"
                ></mat-icon>
            </div>
            <div class="ml-[26px] caption-1 text-primarySecondary">
                {{tAdvancedOptions('options_description')}}
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <!--    these sections should be dynamically inserted-->
    <div class="flex mt-6 p-4 gap-5 rounded-lg bg-white">
        <div class="flex-1 body-2">
            <div class="flex items-center gap-1">
                <mat-icon class="h-5 w-5 text-lg leading-5">task_alt</mat-icon>
                $Content
            </div>
        </div>
        <div class="flex-1">
            <div class="flex items-center gap-2 mb-2">
                <mat-icon class="h-4 w-4 subheading-1 leading-4 text-primarySecondary"
                    >task_alt</mat-icon
                >
                $Content
            </div>
            <div class="flex items-center gap-2">
                <div class="h-4 w-4 bg-red-200 rounded"></div>
                $Content
            </div>
        </div>
    </div>
</mat-expansion-panel>
