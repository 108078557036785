import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-tb-menu-list-item',
    templateUrl: './tb-menu-list-item.component.html',
    styleUrls: ['./tb-menu-list-item.component.sass'],
})
export class TbMenuListItemComponent {
    @Input()
    icon?: string
}
