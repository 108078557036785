import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { ShareRecordComponent } from '@app/feature/share-record/share-record.component'

@NgModule({
    declarations: [ShareRecordComponent],
    imports: [CommonModule, MatInputModule, ReactiveFormsModule, MatButtonModule, MatSelectModule],
    exports: [ShareRecordComponent],
})
export class ShareRecordModule {}
