export const statusColorSet = [
    '#FFFDE7',
    '#FFF9C4',
    '#FFF59D',
    '#FFF176',
    '#F9FBE7',
    '#F0F4C3',
    '#E6EE9C',
    '#DCE775',
    '#E8F5E9',
    '#C8E6C9',
    '#A5D6A7',
    '#81C784',
    '#E0F2F1',
    '#B2DFDB',
    '#80CBC4',
    '#4DB6AC',
    '#E3F2FD',
    '#BBDEFB',
    '#90CAF9',
    '#64B5F6',
    '#E0F7FA',
    '#B2EBF2',
    '#80DEEA',
    '#4DD0E1',
    '#EDE7F6',
    '#D1C4E9',
    '#B39DDB',
    '#9575CD',
    '#F3E5F5',
    '#E1BEE7',
    '#CE93D8',
    '#BA68C8',
    '#FCE4EC',
    '#F8BBD0',
    '#F48FB1',
    '#F06292',
    '#FFF3E0',
    '#FFE0B2',
    '#FFCC80',
    '#FFB74D',
    '#FBE9E7',
    '#FFCCBC',
    '#FFAB91',
    '#FF8A65',
    '#FFEBEE',
    '#FFCDD2',
    '#EF9A9A',
    '#E57373',
]

export const folderColorSet = [
    '#FF6E40',
    '#FF3D00',
    '#FF5252',
    '#D50000',
    '#FF4081',
    '#C51162',
    '#BA68C8',
    '#8E24AA',
    '#4A148C',
    '#E040FB',
    '#AA00FF',
    '#9575CD',
    '#5E35B1',
    '#7C4DFF',
    '#6200EA',
    '#01579B',
    '#0091EA',
    '#00897B',
    '#004D40',
    '#00BFA5',
    '#1B5E20',
    '#00C853',
    '#6D4C41',
    '#3E2723',
    '#3B4383',
    '#06134A',
    '#546E7A',
    '#263238',
]

export const ratingColorSet = [
    '#FFFF8D',
    '#FFFF00',
    '#FDD835',
    '#F4FF81',
    '#EEFF41',
    '#C0CA33',
    '#B9F6CA',
    '#69F0AE',
    '#43A047',
    '#A7FFEB',
    '#64FFDA',
    '#00897B',
    '#82B1FF',
    '#448AFF',
    '#1E88E5',
    '#84FFFF',
    '#18FFFF',
    '#00ACC1',
    '#B388FF',
    '#7C4DFF',
    '#5E35B1',
    '#EA80FC',
    '#E040FB',
    '#8E24AA',
    '#FF80AB',
    '#FF4081',
    '#D81B60',
    '#FFD180',
    '#FFAB40',
    '#FB8C00',
    '#FF9E80',
    '#FF6E40',
    '#F4511E',
    '#FF8A80',
    '#FF5252',
    '#E53935',
]
