import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NavigationEnd, Router } from '@angular/router'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { ShareRecordComponent } from '@app/feature/share-record/share-record.component'
import { ViewFilterService } from '@app/views/view-controls/view-filter/view-filter.service'
import { ResizeColumnService } from '@app/views/table/table/resize-column.service'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    CellEntities,
    CreateViewConfiguration,
    CurrentUser,
    Field,
    FieldEntities,
    FieldType,
    FilterGroup,
    Folder,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    RecordUpdate,
    View,
    ViewData,
} from '@core/models'
import { RecordsService } from '@core/services/records.service'
import {
    CommonFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { ViewSortService } from '@app/views/view-controls/view-sort/view-sort.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { filter, Observable } from 'rxjs'
import { distinctUntilChanged, take } from 'rxjs/operators'

// todo: [table-ref-2] rename this component to TableView
@UntilDestroy()
@Component({
    selector: 'app-table-container',
    templateUrl: './table-container.component.html',
    styleUrls: ['./table-container.component.sass'],
    providers: [ResizeColumnService],
})
export class TableContainerComponent implements OnInit {
    // todo: [table-ref-2] how does my folder work? do we need to pass this data?
    @Input()
    currentUser?: CurrentUser | null

    // todo: [table-ref-2] how does my folder work? do we need to pass this data?
    @Input()
    tableDataIn?: ViewData

    selectedRecords: BusinessRecords[] = []
    tableData!: ViewData | undefined
    filterGroups!: FilterGroup[]

    // todo: [table-ref-2] remove fields and load it in the action-panel
    fields!: FieldEntities

    // todo: [table-ref-2] is not used
    isNewRecord: boolean = false
    newRecordRowGuid!: string | undefined
    newRecordGroup!: RecordGroup

    userGuid?: string
    cells!: { [recordGuid: string]: CellEntities }

    // todo: [table-ref-2] rename to filterHasNoResults
    isDataShown = true

    selectedFolder$: Observable<Folder> = this.folderFacadeService.selectSelectedFolder$
    fieldTypes$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$

    get isGrouped(): boolean {
        return !!this.tableData && !(this.tableData.data instanceof Array)
    }

    // todo: [table-ref-2] use always as group???
    get groups(): RecordGroup[] | undefined {
        if (this.tableData && this.tableData.data instanceof Map) {
            return [...this.tableData.data.values()]
        }
        return
    }

    get records(): BusinessRecords[] | undefined {
        if (this.tableData && this.tableData.data instanceof Array) {
            return this.tableData.data
        }
        return
    }

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private recordsService: RecordsService,
        public dialog: MatDialog,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private viewFacadeService: ViewFacadeService,
        private viewSortService: ViewSortService,
        private manageFieldsService: ManageFieldsService,
        private commonFacadeService: CommonFacadeService,
        private viewFilterService: ViewFilterService,
        private router: Router,
    ) {}

    ngOnInit() {
        if (this.currentUser) {
            this.userGuid = this.currentUser.guid
        }

        if (!this.tableDataIn) {
            this.recordFacadeService
                .selectViewData$()
                .pipe(untilDestroyed(this))
                .subscribe((data) => {
                    if (data && data.selectedView) {
                        this.tableData = data
                        this.fields = data.fields
                        this.filterGroups = this.viewFilterService.getFilterGroupByView(
                            data.selectedView,
                        )
                        this.isNewRecord = false
                        console.log('this.tableData.data', this.tableData)
                        this.prepareCells()
                    }
                })
        } else {
            this.tableData = this.tableDataIn!
            this.fields = this.tableData.fields
            if (this.tableDataIn?.selectedView) {
                this.filterGroups = this.viewFilterService.getFilterGroupByView(
                    this.tableDataIn.selectedView,
                )
            }
            this.prepareCells()
        }

        this.clearSelectionOnSelectedViewChange()
    }

    isFilter() {
        return this.filterGroups && this.filterGroups.length > 0
    }

    openFiltersMenu() {
        this.viewFacadeService.openViewFilterMenu()
    }

    isNewRecordRow() {
        return this.isNewRecord
    }

    updateRecord(props: { data: BusinessRecords; cell: Cell; value: any }) {
        const dataRecord: RecordUpdate = {
            record: props.data,
            cell: props.cell,
            value: props.value,
        }

        if (dataRecord.cell?.fieldType === 'field_type_link') {
            this.recordsService.updateLink(dataRecord)
            return
        }

        this.recordsService.updateRecord(dataRecord)
    }

    // todo: [table-ref-2]: move it to the action-panel component
    editRecords(dataRecord: { value: string; field: Field }) {
        let data = this.selectedRecords.map((record) => {
            return {
                record: record,
                cell: this.cells[record.guid][dataRecord.field.guid],
                value: dataRecord.value,
            }
        })
        this.recordsService.updateRecords(data)
        this.clearSelected()
    }

    shareField(field: Field) {
        this.schemaFacadeService.updateField(field).pipe(take(1), untilDestroyed(this)).subscribe()
    }

    shareRecord(record: AppRecord) {
        const dialogRef = this.dialog.open<ShareRecordComponent, AppRecord>(ShareRecordComponent, {
            width: '400px',
            height: '200px',
            data: record,
        })
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                record = res ? res : record
                console.log(res)
                this.recordsService.updateRecord({ record })
            }
        })
    }

    selectRecord(selectedRecord: BusinessRecords) {
        if (this.selectedRecords.find((record) => selectedRecord.guid === record.guid)) {
            this.selectedRecords = this.selectedRecords.filter(
                (selectedRow) => selectedRow !== selectedRecord,
            )
            return
        }
        this.selectedRecords = [...this.selectedRecords, selectedRecord]
    }

    // todo: [table-ref] clearSelected, and deleteRecords related to state, move them to the State service
    clearSelected() {
        this.selectedRecords = []
    }

    deleteRecords(record?: BusinessRecords) {
        this.recordsService.deleteRecord(record ?? this.selectedRecords)
        this.clearSelected()
    }

    // todo: [table-ref-2] no usage
    createRecord(rowGuid?: string) {
        this.isNewRecord = true
        this.newRecordRowGuid = rowGuid
    }

    // todo: [table-ref-2] no usage
    createRecordInGroup(item: RecordGroup) {
        this.isNewRecord = true
        this.newRecordRowGuid = item.value
        this.newRecordGroup = item
    }

    saveCreateRecord() {
        this.newRecordRowGuid = undefined
        this.isNewRecord = false
    }

    deleteField(guid: string) {
        this.commonFacadeService.setLoading(true)
        this.manageFieldsService.deleteField(guid).pipe(take(1), untilDestroyed(this)).subscribe()
    }

    pinColumn(data: string) {
        if (!this.tableData || !this.tableData.selectedView) {
            return
        }

        this.viewFacadeService
            .updateViewRequest(this.tableData.selectedView, {
                columns_pinned: {
                    cell: this.tableData.selectedView.columns_pinned,
                    newValue: data,
                },
            })
            .subscribe()
    }

    unPinColumn(data: string[]) {
        if (!this.tableData || !this.tableData.selectedView) {
            return
        }

        this.viewFacadeService
            .updateViewRequest(this.tableData.selectedView, {
                columns_pinned: {
                    cell: this.tableData.selectedView.columns_pinned,
                    newValue: data.join(','),
                },
            })
            .subscribe()
    }

    showColumn(data: string[]) {
        if (!this.tableData || !this.tableData.selectedView) {
            return
        }

        this.viewFacadeService
            .updateViewRequest(this.tableData.selectedView, {
                columns_hide: {
                    cell: this.tableData.selectedView.columns_hide,
                    newValue: data.join(','),
                },
            })
            .subscribe()
    }

    hideColumn(value: string) {
        if (!this.tableData || !this.tableData.selectedView) {
            return
        }

        this.viewFacadeService
            .updateViewRequest(this.tableData.selectedView, {
                columns_hide: {
                    cell: this.tableData.selectedView.columns_hide,
                    newValue: value,
                },
            })
            .subscribe()
    }

    sortColumn(guid: string) {
        this.openSortMenu(guid)
    }

    // todo: [table-ref] should be in the table.component
    dropColumn(data: { columnOrder?: string[]; columnPinned?: string[] }) {
        if (!this.tableData || !this.tableData.selectedView) {
            return
        }

        let createViewConfiguration: Partial<CreateViewConfiguration> = {}

        if (data.columnOrder) {
            createViewConfiguration.columns_order = {
                cell: this.tableData.selectedView.columns_order,
                newValue: data.columnOrder.join(','),
            }
        }

        if (Object.keys(createViewConfiguration).length) {
            this.viewFacadeService
                .updateViewRequest(this.tableData.selectedView, createViewConfiguration)
                .subscribe()
        }
    }

    moveRow(data: RecordUpdate) {
        this.recordsService.updateRecord(data)
    }

    resizeColumn(value: { guid: string; width: number }) {
        const columnsWidthValue = this.tableData?.columns.columnsWidth
            ? {
                  ...this.tableData?.columns.columnsWidth,
                  [value.guid]: value.width,
              }
            : { [value.guid]: value.width }

        this.viewFacadeService
            .updateViewRequest(this.tableData?.selectedView as View, {
                columns_width: {
                    cell: this.tableData?.selectedView?.columns_width as Cell,
                    newValue: JSON.stringify(columnsWidthValue),
                },
            })
            .subscribe()
    }

    resetColumnWidth(guid: string) {
        let columnsWidthValue = { ...this.tableData?.columns.columnsWidth }
        delete columnsWidthValue[guid]

        this.viewFacadeService
            .updateViewRequest(this.tableData?.selectedView as View, {
                columns_width: {
                    cell: this.tableData?.selectedView?.columns_width as Cell,
                    newValue: JSON.stringify(columnsWidthValue),
                },
            })
            .subscribe()
    }

    private prepareCells() {
        this.showOrHideFilteredTable()

        if (!this.isDataShown) {
            return
        }

        if (this.records?.length) {
            this.cells = prepareCellsForRecords(this.records)
            return
        }
        if (this.groups?.length) {
            this.cells = prepareFromGroups(this.groups)
            return
        }
    }

    // todo: [table-ref] just return a condition but set to the isDataShown above
    private showOrHideFilteredTable() {
        if (this.filterGroups.length > 0 && this.isGroupsAndRecordsEmpty()) {
            this.isDataShown = false
            return
        }

        this.isDataShown = true
    }

    private isGroupsAndRecordsEmpty() {
        return (
            (!this.records || this.records.length === 0) &&
            (!this.groups || this.groups.length === 0)
        )
    }

    private openSortMenu(guid: string) {
        this.viewSortService.updateSortMenuFromColumnData(guid)
    }

    private clearSelectionOnSelectedViewChange() {
        this.router.events
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => this.clearSelected())
    }
}
