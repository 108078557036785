import { createFeatureSelector, createSelector } from '@ngrx/store'
import { fieldTypeAdapter, FieldTypeState } from './field-type.reducer'
import { StoreFeatureNames } from '../store-feature-names'

const selectFieldTypeState = createFeatureSelector<FieldTypeState>(StoreFeatureNames.FieldType)

const { selectIds, selectEntities, selectAll, selectTotal } =
    fieldTypeAdapter.getSelectors(selectFieldTypeState)

export const selectFieldTypeIds = selectIds
export const selectFieldTypeEntities = selectEntities
export const selectAllFieldTypes = selectAll
export const selectFieldTypeTotal = selectTotal

// selects field types that are ready for filtering in filter menu. Hides field types filtering by which is not implemented
export const selectFieldTypesFilterable = createSelector(selectAllFieldTypes, (fieldTypes) =>
    fieldTypes.filter((fieldType) => !!fieldType.filterOptions?.length),
)
