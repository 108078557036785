import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'

@Component({
    selector: 'app-tb-toggle',
    templateUrl: './tb-toggle.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TbToggleComponent,
        },
    ],
})
export class TbToggleComponent implements ControlValueAccessor {
    @Input() checked: boolean = false
    @Input() disabled: boolean = false

    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    private _onTouched = () => {}
    private _onChange = (value: boolean) => {}

    onToggleChange(event: MatSlideToggleChange) {
        this.writeValue(event.checked)
        this.valueChange.emit(event.checked)
        this._onChange(event.checked)
        this._onTouched()
    }

    writeValue(value: boolean): void {
        this.checked = value
    }

    registerOnChange(fn: any): void {
        this._onChange = fn
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}
