import { Component, OnInit } from '@angular/core'
import { ViewData } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordFacadeService, UserFacadeService } from '@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-my-folder',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
    tableDataArr!: ViewData[]

    constructor(
        private userFacadeService: UserFacadeService,
        private recordFacadeService: RecordFacadeService,
    ) {}

    currentUser$ = this.userFacadeService.currentUser$

    ngOnInit(): void {
        this.recordFacadeService.selectTableDataBySchemasAssignedCurrenUser$
            .pipe(untilDestroyed(this))
            .subscribe((tableDataArr) => {
                if (tableDataArr) {
                    this.tableDataArr = tableDataArr
                }
            })
    }

    tabLabel(data: ViewData) {
        if (data.schema) {
            const label = data.schema.object_type_code?.split('.')[1]
            return `${label?.slice(0, 1).toUpperCase()}${label?.slice(1).toLowerCase()}`
        }
        return ''
    }
}
