import { CDK_DROP_LIST, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'

export type MovedItem<T> = {
    item: T
    previousItems: unknown[]
    items: unknown[]
    previousIndex: number
    currentIndex: number
}

@Component({
    selector: 'app-tb-board-column',
    templateUrl: './tb-board-column.component.html',
    styleUrls: ['./tb-board-column.component.sass'],
})
export class TbBoardColumnComponent<Item> implements AfterViewInit {
    @Input({ required: true })
    items!: Item[]

    @Input({ required: true })
    card!: TemplateRef<any>

    @Input({ required: true })
    columnItem!: unknown

    @Input({ required: true })
    key!: keyof Item

    @Input()
    headerMenu!: TemplateRef<any>

    @Input()
    disableDragIntoList!: boolean

    @Output()
    columnMoved = new EventEmitter<MovedItem<Item>>()

    @ViewChild(CdkDropList) cdkDropList!: CdkDropList
    @ViewChild('cardContainer', { read: ViewContainerRef }) cardContainer!: ViewContainerRef

    collapsed = false
    injector!: Injector

    isDisabledDragIntoTheListBound = this.isDisabledDragIntoTheList.bind(this)

    constructor(private cd: ChangeDetectorRef, private componentInjector: Injector) {}

    ngAfterViewInit() {
        this.injector = Injector.create({
            providers: [],
            parent: Injector.create({
                providers: [
                    {
                        provide: CDK_DROP_LIST,
                        useValue: this.cdkDropList,
                    },
                ],
                parent: this.componentInjector,
            }),
        })
        this.cd.detectChanges()
    }

    drop(event: CdkDragDrop<Item[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
            return
        }

        this.columnMoved.emit({
            item: event.item.data,
            previousItems: event.previousContainer.data,
            items: event.container.data,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex,
        })
    }

    private isDisabledDragIntoTheList() {
        return !this.disableDragIntoList
    }
}
