<div class="container">
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Select folders</mat-label>
            <mat-select [formControl]="sharedFolders" multiple>
                <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                <mat-option
                    (click)="onItemClick()"
                    *ngFor="let folder of dropdownList"
                    [value]="folder.item_id"
                    >{{folder.item_text}}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>
    <div class="bottom">
        <button (click)="applyShare()" color="primary" mat-button>Apply</button>
        <button (click)="cancelShare()" mat-button>Cancel</button>
    </div>
</div>
