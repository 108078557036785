import { Injectable } from '@angular/core'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@core/services/local-storage/view-settings-storage/view-settings-storage.service'

@Injectable({
    providedIn: 'root',
})
export class GroupStorageService extends ViewSettingsStorageService<string> {
    constructor(localStorageService: DataStorageService) {
        super(localStorageService, 'group')
    }
}
