import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { AutomationEffects } from './automation.effects'
import { automationFeature } from './automation.reducer'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(automationFeature),
        EffectsModule.forFeature([AutomationEffects]),
    ],
})
export class AutomationStoreModule {}
