<ng-container *ngIf="selectedFolder$ | async as selectedFolder">
    <ng-container *ngIf="fieldTypes$ | async as fieldTypes">
        <ng-container *transloco="let tManageFields; read: 'manage_fields'">
            <app-modal-layout
                [containerLayout]="containerLayout"
                (closeModal)="forceWithValidation()"
                [showLoader]="showLoader"
                [errors]="errors"
            >
                <ng-container title>{{ tManageFields('manage_fields') }}</ng-container>
                <ng-container subtitle>{{ tManageFields('manage_fields_subtitle') }}</ng-container>

                <app-fields-collection
                    [accordionContent]="accordionContent"
                    [selectedFolder]="selectedFolder"
                    [excludedFieldTypes]="excludedFieldTypes"
                    [folders]="folders$ | async"
                    [selectedSchema]="selectedSchema$ | async"
                    [fieldTypes]="fieldTypes"
                >
                </app-fields-collection>

                <ng-container buttons>
                    <div class="flex justify-end">
                        <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                            <app-tb-button (click)="addField(selectedFolder)" class="w-full">
                                {{ tManageFields('add_field') }}
                            </app-tb-button>
                        </div>
                    </div>
                </ng-container>
            </app-modal-layout>

            <ng-template
                #accordionContent
                [appTypedTemplate]="contextType"
                let-fieldTypes="fieldTypes"
                let-folder="folder"
                let-fields="fields"
            >
                <div
                    *ngFor="let field of fields"
                    class="flex xsm:flex-row items-center mt-2 xsm:mb-0 mb-4"
                >
                    <div
                        [style]="{background: folder.color.value, width: '6px', height: '10px'}"
                    ></div>
                    <div class="flex items-center my-2 mx-4 gap-x-4">
                        <div class="w-[352px]">
                            {{ field.name }}
                            <app-tb-icon *ngIf="field.is_required" icon="asterisk"></app-tb-icon>
                            <!--TODO: "On top" logic for field https://chat.anveo.com/git/AnveoTikibase_ui/issues/1465-->
                            <app-tb-icon icon="upgrade"></app-tb-icon>
                        </div>
                        <app-tb-tag
                            class="w-[176px] text-ellipsis overflow-hidden whitespace-nowrap"
                        >
                            {{ field.folder_name?.name }}
                        </app-tb-tag>
                        <div class="flex items-center w-[132px] h-fit">
                            <app-tb-icon
                                *ngIf="fieldTypes[field.field_type_code]?.icon as icon"
                                [icon]="icon"
                            >
                            </app-tb-icon>
                            <span
                                class="text-ellipsis overflow-hidden whitespace-nowrap capitalize"
                            >
                                {{ fieldTypes[field.field_type_code]?.name ??
                                tManageFields('unknown_type') }}
                            </span>
                        </div>
                    </div>

                    <app-tb-menu [template]="fieldOptions" xPosition="after">
                        <app-tb-button trigger appearance="icon" icon="more_vert"></app-tb-button>
                    </app-tb-menu>

                    <ng-template class="mb-2" #fieldOptions>
                        <app-manage-field-field-options
                            [field]="field"
                            [selectedFolder]="folder"
                            (deleteField)="deleteField(field)"
                        ></app-manage-field-field-options>
                    </ng-template>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
