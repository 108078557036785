<div class="flex flex-col py-6 px-10 w-[400px]" *transloco="let t">
    <div class="text-center">
        <div class="title">{{ t(data.translations.title) }}</div>
        <div class="body-1 text-newTextLight mt-2">{{ t(data.translations.message) }}</div>
    </div>
    <div class="mt-7 flex justify-center">
        <app-tb-button
            class="mr-4"
            [color]="cancelButtonColor"
            appearance="outlined"
            (click)="close()"
            >{{ t(data.translations.cancel || '') }}</app-tb-button
        >
        <app-tb-button [color]="confirmButtonColor" (click)="confirm()"
            >{{ t(data.translations.confirm || '') }}</app-tb-button
        >
    </div>
</div>
