import { EventEmitter } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { BehaviorSubject, Subject } from 'rxjs'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { BaseContainer } from '../modal-containers/base-container'

/**
 * MenuInstanceService manages opening and closing Menu. Unlike the BottomSheetInstanceService and DialogInstanceService MenuInstanceService isn't singleton,
 * for each menu instance (MatMenuTrigger) we create its own MenuInstanceService. According to the requirements Menus doesn't have Navigation, so we shouldn't
 * care about whether it is singleton or not.
 *
 * Also, the MenuInstanceService differs from the other Instances with defining backdrop inside TbMenu component, unlike having that processing inside the service.
 * It is made because Material doesn't support subscription on backdrop click in Menus, so to implement Confirmation Dialog,
 * we created custom logic of handling backdrop events inside the TbMenu.
 **/
export class MenuInstanceService {
    /**
     * This stream indicates when we want close menu programmatically.
     * There are two options how Modal Flow can be closed:
     * - programmatically with forced$
     * - by User intention, when click outside Menu, or click some *close* buttons etc.
     *
     * Programmatically we close Modal Container when we convert it to its Alternative.
     * In this case we shouldn’t call “this.closed.emit()”, so we should pass some flag to notify that this "close" action is programmatically
     * made and do not call the “closed”. That is why forced$ stream is here.
     *
     * Automatically we close Modal Container when User click outside the Dialog or BottomSheet, or
     * click some *close* buttons etc. In this case we should emit “closed” to call callbacks that were set for this event.
     *
     * Comparing to the DialogInstanceService and BottomSheetInstanceService we can't use Symbol, since
     * MatMenuTrigger doesn't return data on close.
     **/
    private forced$ = new BehaviorSubject(false)

    /**
     * Stream is for unsubscribing from observables
     **/
    private destroyed$ = new Subject<void>()

    /**
     * Event that signals that Menu is closed by User intention.
     **/
    public closed = new EventEmitter()

    constructor(private menuTrigger: MatMenuTrigger) {
        this.menuTrigger.menuClosed
            .pipe(withLatestFrom(this.forced$), takeUntil(this.destroyed$))
            .subscribe(([_, forced]) => {
                if (forced) {
                    this.forced$.next(false)
                    return
                }

                this.closed.emit()
            })
    }

    /**
     * This method opens MatMenu. It is called by ModalManagerService.
     * @param {BaseContainer<ModalContainerComponent>} menuContainer The Modal Container instance
     **/
    open(menuContainer: BaseContainer<ModalContainerComponent>) {
        this.reopen()

        return menuContainer.closed.asObservable().pipe(take(1))
    }

    /**
     * This method opens MatMenu. It is called by ModalManagerService when the Modal Transformation performs.
     **/
    reopen() {
        this.menuTrigger.openMenu()
    }

    /**
     * Calls when we do Modal Transformation. This method is called from ModalManagerService.
     **/
    hide() {
        if (this.menuTrigger.menuOpen) {
            this.forced$.next(true)
            this.menuTrigger.closeMenu()
        }
    }

    /**
     * Calls when container is destroying.
     **/
    destroy() {
        this.destroyed$.next()
    }
}
