import { Injectable } from '@angular/core'
import { AppState, setRoles } from '@core/@ngrx'
import { Role } from '@core/models/ui/role.model'
import { Store } from '@ngrx/store'

@Injectable({
    providedIn: 'root',
})
export class RoleFacadeService {
    constructor(private store: Store<AppState>) {}

    setRoles(roles: Role[]) {
        this.store.dispatch(setRoles({ roles }))
    }
}
