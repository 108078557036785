<mat-expansion-panel
    hideToggle="true"
    [expanded]="expanded"
    class="xsm:mt-6 mt-2 mb-2.5 rounded-lg"
    [class]="{'hover:shadow-light': background}"
    (opened)="expanded = true"
    (closed)="expanded = false"
>
    <mat-expansion-panel-header
        class="pr-4 pl-4"
        [class]="[headerBackgroundDisplaying, headerHeightDisplaying]"
    >
        <div class="flex flex-col w-100">
            <div class="w-100">
                <mat-panel-title class="justify-between text-newText body-2 mr-0">
                    <div class="flex">
                        <app-tb-icon
                            [icon]="expanded ? 'expand_less' : 'expand_more'"
                        ></app-tb-icon>
                        {{ title }}
                    </div>
                    @if (settingsShow && background) {
                    <div (click)="$event.stopPropagation()" #advanced>
                        <ng-content select="[advanced]"></ng-content>
                    </div>
                    <app-tb-button
                        *ngIf="!advanced.childElementCount"
                        appearance="icon"
                        icon="custom_settings"
                        color="text"
                        (click)="callAdvanced.emit(); $event.stopPropagation()"
                    ></app-tb-button>
                    }
                </mat-panel-title>
            </div>
            @if (description && background) {
            <div class="flex text-newTextLight caption-1 ml-5">
                <span>{{description}}</span>
            </div>
            }
        </div>
    </mat-expansion-panel-header>
    <div class="flex justify-center" [class]="[bodyBackgroundDisplay]">
        <div
            class="w-100 flex justify-center m-4 pt-4 pb-4 rounded-lg"
            [class]="[contentBackgroundDisplay]"
        >
            <ng-content></ng-content>
        </div>
    </div>
</mat-expansion-panel>
