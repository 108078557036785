import { Subject } from 'rxjs'
import { DisplaySize } from '../../services/breakpoint.service'
import { ComponentType } from '@angular/cdk/overlay'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { ComponentRef } from '@angular/core'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'

/**
 * BaseContainer is a base Modal Container class.
 *
 * We have two containers: Dialog and Menu.
 * This class creates ComponentRef on creation, and during Modal Transformation, it tells what View we should convert into.
 **/
export abstract class BaseContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> {
    closed = new Subject()
    componentRef!: ComponentRef<T>

    constructor(
        protected modalManagerService: ModalManagerService,
        protected modalContainerFactoryService: ModalContainerFactoryService,
        protected component: ComponentType<T>,
        protected data?: ContainerData,
    ) {
        this.componentRef = this.modalContainerFactoryService.createComponentRef(
            modalManagerService,
            component,
            data,
        )
    }

    /**
     * This method define in what Modal View we should open this Modal Container.
     *
     * It is called when we change screen resolution.
     **/
    abstract displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer

    /**
     * Close Modal Container with data.
     **/
    close(data?: Result) {
        this.closed.next(data)
        this.closed.complete()
    }

    /**
     * Calls to destroy inner componentRef.
     **/
    destroy() {
        this.componentRef.destroy()
    }
}
