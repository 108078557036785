<table class="mt-8" mat-table [dataSource]="subtaskRecords">
    <ng-container *ngFor="let column of columns">
        <ng-container [matColumnDef]="column" *ngIf="getFieldByGuid(column) as field">
            <th mat-header-cell *matHeaderCellDef class="text-center">{{field.name}}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="getCells(element) as cells">
                    <app-cell-container
                        *ngIf="field.field_type_code !== 'field_type_persent'; else percent"
                        class="flex-start-center text-center"
                        [fieldTypes]="fieldTypes"
                        [field]="field"
                        [record]="element"
                        [value]="cells[field.guid].value"
                        (cellValueChanged)="updateSubtask(element, cells[field.guid], $event)"
                    ></app-cell-container>
                    <ng-template #percent>
                        <app-text-one-line
                            class="flex-start-center text-center"
                            [field]="field"
                            [record]="element"
                            [value]="cells[field.guid].value"
                            (cellValueChanged)="updateSubtask(element, cells[field.guid], $event)"
                        ></app-text-one-line>
                    </ng-template>
                </ng-container>
            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="text-center">Delete</th>
        <td mat-cell *matCellDef="let element">
            <div class="flex-start-center text-center">
                <app-tb-button
                    icon="delete"
                    appearance="text"
                    (click)="deleteSubtask(element)"
                ></app-tb-button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
</table>

<div class="mt-8">
    <app-card-create-subtask
        [parentRecordGuid]="record.guid"
        [subtaskSchema]="subtaskSchema"
    ></app-card-create-subtask>
</div>
