import { Component, Input } from '@angular/core'
import { TbIconComponent } from '../tb-icon/tb-icon.component'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-tb-menu-list-item',
    templateUrl: './tb-menu-list-item.component.html',
    styleUrls: ['./tb-menu-list-item.component.sass'],
    standalone: true,
    imports: [NgIf, TbIconComponent],
})
export class TbMenuListItemComponent {
    @Input()
    icon?: string

    @Input()
    color?: string

    get textColor() {
        return `text-${this.color ? this.color : 'inherit'}`
    }
}
