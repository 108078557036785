<ng-container *ngFor="let status of folderStatuses">
    <div [class.selected]="status.folderStatus.selected" class="header-status">
        <div class="name-div flex-start-center">{{status.folderStatus.folderName}}</div>
        <mat-icon class="mt-sm">arrow_right_alt</mat-icon>
        <div class="position-relative mb-0">
            <app-status
                *ngIf="status"
                [disabled]="true"
                [fieldType]="fieldTypes[status.field.field_type_code]"
                [field]="status.field"
                [record]="record"
                [value]="record ? record.status[status.folderStatus.folderGuid].value : ''"
            >
            </app-status>
        </div>
    </div>
</ng-container>
