import { AppRecord } from './base-record.model'
import { Cell, TypeRelatedCellEntities } from './cell.model'

export interface View extends AppRecord {
    name: Cell
    type_code: Cell
    columns_order: Cell
    columns_pinned: Cell
    columns_hide: Cell
    columns_width: Cell
    group: Cell
    filter: Cell
    sort: Cell
    icon: string
}

export enum ViewTypeCodes {
    BOARD = 'kanban',
    TABLE = 'table',
    LIST = 'list',
}

//TODO: update images and rewrite usage to svg
export const viewTypeCodesIcons: { [key: string]: string } = {
    [ViewTypeCodes.BOARD]: 'custom_board',
    [ViewTypeCodes.TABLE]: 'custom_table',
    [ViewTypeCodes.LIST]: 'custom_contacts',
}

export function getViewIconByCode(type: string) {
    return viewTypeCodesIcons[type]
}

export function generateViewFields(type: string): TypeRelatedCellEntities {
    return {
        icon: getViewIconByCode(type),
    }
}
