import { Injectable } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import {
    ResponseRecordEntities,
    ResponseSchema,
    Schema,
    SystemTypeCode,
    TableModel,
} from '../models'
import { ConfigResponseHandlerService } from './config-response-handler.service'
import { FolderResponseHandlerService } from './folder-response-handler.service'
import { ViewResponseHandlerService } from './view-response-handler.service'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SystemRecordService {
    constructor(
        private folderManagerService: FolderResponseHandlerService,
        private viewManagerService: ViewResponseHandlerService,
        private configManagerService: ConfigResponseHandlerService,
    ) {}

    initSystemRecordByObjectType(
        schema: ResponseSchema,
        record: ResponseRecordEntities,
        selectedSystemRecordGuid?: string,
    ) {
        switch (schema.system_object_type_code) {
            case SystemTypeCode.FOLDER:
                this.folderManagerService.initSystemRecord(schema, record, selectedSystemRecordGuid)
                break
            case SystemTypeCode.VIEW:
                this.viewManagerService.initSystemRecord(schema, record, selectedSystemRecordGuid)
                break
            case SystemTypeCode.CONFIG:
                this.configManagerService.initSystemRecord(schema, record)
                break
            default:
                console.error('init system record with schema', schema)
                break
        }
    }

    updateSystemRecord(table: TableModel, schema: Schema) {
        switch (schema.system_object_type_code) {
            case SystemTypeCode.VIEW:
                this.viewManagerService.updateRecord(table, schema)
                break
            case SystemTypeCode.FOLDER:
                this.folderManagerService.updateRecord(table, schema)
                break
            case SystemTypeCode.CONFIG:
                this.configManagerService.updateRecord(table, schema)
                break
            default:
                console.error('Update system record with schema', schema)
                break
        }
    }
}
