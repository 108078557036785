import { Component, Input, OnInit } from '@angular/core'
import { AppRecord, FieldEntities, Folder, View, ViewData } from '@core/models'
import { RecordFacadeService, ViewFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

/**
 * Menu item in view controls menu which shows dropdown with visible/hidden columns
 */
@UntilDestroy()
@Component({
    selector: 'app-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.sass'],
})
export class ShowComponent implements OnInit {
    @Input()
    selectedFolder!: Folder

    record!: AppRecord
    fields!: FieldEntities

    visibleFields: string[] = []
    hiddenFields: string[] = []

    viewData!: ViewData

    constructor(
        private viewFacadeService: ViewFacadeService,
        private recordsFacadeService: RecordFacadeService,
    ) {}

    ngOnInit(): void {
        this.recordsFacadeService
            .selectViewData$()
            .pipe(untilDestroyed(this))
            .subscribe((viewData) => {
                this.viewData = viewData
                this.visibleFields = viewData.columns.columns
                this.hiddenFields = viewData.columns.hiddenColumns
                this.fields = viewData.fields
            })
    }

    changeFieldState(guid: string, state: boolean) {
        if (!this.viewData.selectedView) return

        const visibleArr = [...this.visibleFields]
        const hiddenArr = [...this.hiddenFields]

        if (state) {
            hiddenArr.splice(hiddenArr.indexOf(guid), 1)
            visibleArr.push(guid)
        } else {
            visibleArr.splice(visibleArr.indexOf(guid), 1)
            hiddenArr.push(guid)
        }

        this.visibleFields = visibleArr
        this.hiddenFields = hiddenArr

        this.viewFacadeService
            .updateViewRequest(this.viewData.selectedView, {
                columns_hide: {
                    cell: this.viewData.selectedView.columns_hide,
                    newValue: this.hiddenFields.join(','),
                },
            })
            .subscribe()
    }
}
