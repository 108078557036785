import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { environmentMode, EnvironmentMode } from '@test/dev-utils'
import { environment } from 'src/environments/environment'

@Directive({
    selector: '[appEnv]',
    standalone: true,
})
export class EnvDirective implements OnInit {
    @Input('appEnv') env: EnvironmentMode = 'prod'
    @Input({
        required: true,
        alias: 'appEnvVisible',
    })
    visible = false

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        const correctEnv = this.correctEnv()
        if (correctEnv) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }

    private correctEnv() {
        const mode = environmentMode(environment.mode)
        return (this.visible && this.env === mode) || (!this.visible && this.env !== mode)
    }
}
