<div
    class="text-inherit bg-inherit flex flex-row items-center overflow"
    [class]="[rounded ? 'rounded-lg' : 'rounded']"
>
    @if (icon) {
    <app-tb-icon class="pl-2" [icon]="icon" [style.color]="iconColor ?? ''"></app-tb-icon>
    }
    <span class="py-1 px-2 text-sm font-semibold tb-tag-value"><ng-content></ng-content></span>
    <app-tb-icon
        class="pr-1 tb-tag-close icon-sm"
        icon="close"
        *ngIf="removable"
        (click)="$event.stopPropagation(); remove.emit()"
    ></app-tb-icon>
</div>
