<app-modal-layout [containerLayout]="containerLayout">
    <ng-container *ngIf="selectedFolder$ | async as selectedFolder">
        <ng-container *ngIf="folders$ | async as folders">
            <app-folder
                *ngIf="folders.global"
                [folder]="folders.global"
                [canDelete]="false"
                [showSelectedIcon]="containerLayout === 'BottomSheet'"
                [selectedFolderGuid]="selectedFolder.guid"
                (selectFolder)="selectFolder($event)"
                (edit)="openFolderEditDialog(folders.global)"
            ></app-folder>
            <mat-divider
                class="max-sm:mt-4 mb-4 sm:mb-2 h-0.5 bg-primaryLight border-primaryLight"
            ></mat-divider>
            <app-folder
                *ngFor="let folder of folders.nonGlobal"
                [folder]="folder"
                [selectedFolderGuid]="selectedFolder.guid"
                [showSelectedIcon]="containerLayout === 'BottomSheet'"
                (selectFolder)="selectFolder($event)"
                (edit)="openFolderEditDialog(folder)"
            ></app-folder>

            <app-tb-button
                *transloco="let tButtons; read 'buttons'"
                [appearance]="'text'"
                [color]="'primary'"
                [icon]="'add_circle_outline'"
                (click)="openFolderCreateDialog()"
            >
                <span class="font-semibold">{{tButtons('add_workspace')}}</span>
            </app-tb-button>
        </ng-container>
    </ng-container>
</app-modal-layout>
