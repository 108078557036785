import { Injectable } from '@angular/core'
import { difference, get, pull, set } from 'lodash-es'
import { AppRecord, Deleted } from '../models'

type RecordsValuesStore = {
    [linkGuid: string]: {
        [recordGuid: string]: string[]
    }
}
type LinkedRecordSourcesStore = {
    [linkedRecordGuid: string]: {
        [linkGuid: string]: string[]
    }
}

@Injectable({
    providedIn: 'root',
})
export class LinkReferenceStoreService {
    private recordsValuesStore: RecordsValuesStore = {}
    private linkedRecordsRefStore: LinkedRecordSourcesStore = {}

    constructor() {}

    updateRecordValue(linkGuid: string, record: AppRecord) {
        const prevValues = get(this.recordsValuesStore, [linkGuid, record.guid]) || []
        const recordValue = record.noNameCells[linkGuid].value
        const newValues = recordValue ? recordValue.split(',') : []

        const removeRecordsArray = difference(prevValues, newValues)
        const addRecordsArray = difference(newValues, prevValues)

        set(this.recordsValuesStore, `${linkGuid}.${record.guid}`, newValues)

        removeRecordsArray.forEach((linkedRecordGuid) => {
            const linkedRecord = get(this.linkedRecordsRefStore, [linkedRecordGuid, linkGuid])
            if (!linkedRecord) return

            pull(linkedRecord, record.guid)
        })

        addRecordsArray.forEach((linkedRecordGuid) => {
            const linkedRecord = get(this.linkedRecordsRefStore, [linkedRecordGuid, linkGuid])
            if (!linkedRecord) {
                set(this.linkedRecordsRefStore, [linkedRecordGuid, linkGuid], [record.guid])
                return
            }

            linkedRecord.push(record.guid)
        })
    }

    addRecordValues(linkGuid: string, recordGuid: string, values: string[]) {
        set(this.recordsValuesStore, [linkGuid, recordGuid], values)

        values.forEach((value) => {
            const linkedRecord = get(this.linkedRecordsRefStore, [value, linkGuid])

            if (linkedRecord) {
                linkedRecord.push(recordGuid)
                return
            }

            set(this.linkedRecordsRefStore, [value, linkGuid], [recordGuid])
        })
    }

    getSourceCount(recordGuid: string, linkGuid: string) {
        return this.getSources(recordGuid, linkGuid).length
    }

    getSources(recordGuid: string, linkGuid: string) {
        return get(this.linkedRecordsRefStore, [recordGuid, linkGuid]) || []
    }

    removeLinkFromStore(linkGuid: string) {
        delete this.recordsValuesStore[linkGuid]
    }

    removeRecords(linkGuid: string, records: Deleted[]) {
        records.forEach((record) => {
            delete this.recordsValuesStore[linkGuid][record.guid]

            Object.keys(this.linkedRecordsRefStore).forEach((linkedRecordGuid) => {
                pull(this.linkedRecordsRefStore[linkedRecordGuid][linkGuid], record.guid)
            })
        })
    }

    getRecordsValueStore(): RecordsValuesStore {
        return this.recordsValuesStore
    }
}
