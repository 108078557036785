import { Contact, Deal, Issue, Vendor } from './business-record.model'
import { Cell, CellEntities } from './cell.model'
import { Field, FieldEntities } from './field.model'
import { Schema } from './schema.model'
import { View } from './view.model'

export interface AppRecord {
    type: SystemObjectTypes
    guid: string
    noNameCells: CellEntities
    schemaGuid: string
    revision: number
    folder_guids?: string
    parent_sot_guid?: string
    create_at: Cell
    create_by: Cell
    update_at: Cell
    update_by: Cell
}

export enum SystemObjectTypes {
    //system
    Folder = 'folder',
    View = 'view',
    Config = 'config',
    Subtask = 'subtask',
    //CRM
    Vendor = 'vendor',
    Contact = 'contact',
    Deal = 'deal',
    //TM
    Issue = 'issue',
}

// TODO: Remove BusinessRecords
export type BusinessRecords = Vendor | Deal | Contact | Issue

export interface RecordGroup {
    field?: Field
    value?: string
    data: BusinessRecords[]
}

export enum ObjectTypes {
    CRM_COMPANY = 'CRM.COMPANY',
    CRM_CONTACT = 'CRM.CONTACT',
    CRM_DEAL = 'CRM.DEAL',
    CRM_SUBTASK_COMPANY = 'CRM.SUBTASK.COMPANY',
    CRM_SUBTASK_CONTACT = 'CRM.SUBTASK.CONTACT',
    CRM_SUBTASK_DEAL = 'CRM.SUBTASK.DEAL',

    TM_ISSUE = 'TM.ISSUE',
    TM_SUBTASK_ISSUE = 'TM.SUBTASK.ISSUE',
}

export type ColGroup = { colspan: number; subheaders: string[] }

export interface ViewData {
    fields: FieldEntities
    data: BusinessRecords[] | Map<string, RecordGroup>
    selectedView: View | undefined
    selectedFolderGuid?: string
    schema?: Schema
    columns: {
        columns: string[]
        hiddenColumns?: string[]
        pinnedColumns?: string[]
        columnsOrder?: string[]
        columnsWidth?: { [p: string]: number }
        colGroups?: { [colGuid: string]: ColGroup }
    }
}

export interface RecordUpdate {
    record: Partial<AppRecord>
    cell?: Cell
    value?: any
}
