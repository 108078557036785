import { Component, OnInit } from '@angular/core'
import {
    GroupModalComponent,
    GroupDialogData,
} from '@app/views/view-controls/view-group/group-modal/group-modal.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { Field, FieldEntities, Folder, getIconByFieldType, View, ViewTypeCodes } from '@core/models'
import {
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { isProd } from '@test/dev-utils'
import { combineLatest, of, switchMap, take } from 'rxjs'
import { ViewGroupService } from 'src/app/views/view-controls/view-group/view-group.service'

@UntilDestroy()
@Component({
    selector: 'app-view-group',
    templateUrl: './view-group.component.html',
})
export class ViewGroupComponent implements OnInit {
    groupFieldGuid?: string
    fieldEntities?: FieldEntities
    selectedFolder!: Folder
    selectedView!: View

    isGroupDisabled = false

    constructor(
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private groupStorageService: GroupStorageService,
        private groupService: ViewGroupService,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    get icon() {
        const fieldTypeGuid = this.groupField?.field_type_code ?? ''
        return getIconByFieldType(fieldTypeGuid)
    }

    get groupField(): Field | undefined {
        if (this.fieldEntities && this.groupFieldGuid) {
            return this.fieldEntities[this.groupFieldGuid]
        }
        return
    }

    get fields(): Field[] | undefined {
        if (this.fieldEntities) {
            return this.groupService.filterAllowedGroupFieldsByType(this.fieldEntities)
        }
        return
    }

    ngOnInit(): void {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(
                untilDestroyed(this),
                switchMap((folder) => {
                    return combineLatest([
                        of(folder),
                        this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                            folder,
                        ),
                    ])
                }),
            )
            .subscribe(([folder, fields]) => {
                this.selectedFolder = folder
                this.fieldEntities = fields
            })

        combineLatest([this.viewFacadeService.selectedView$, this.groupStorageService.getStore$()])
            .pipe(untilDestroyed(this))
            .subscribe(([view]) => {
                if (!view) return

                this.selectedView = view
                this.groupFieldGuid = this.groupService.getGroupByView(view)
                this.groupStorageService.updateIsSetValue(view.guid)
                this.isGroupDisabled = this.setIsGroupDisabled(this.selectedView.type_code.value)
            })
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<GroupModalComponent, GroupDialogData>({
                component: GroupModalComponent,
                data: {
                    selectedView: this.selectedView,
                    selectedFolder: this.selectedFolder,
                    fields: this.fields,
                    groupValue: this.groupFieldGuid,
                },
            })
            .pipe(take(1))
    }

    private setIsGroupDisabled(viewType: string) {
        return isProd() && viewType === ViewTypeCodes.TABLE
    }
}
