import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'

@Component({
    selector: 'app-url',
    templateUrl: './url-filter.component.html',
    styleUrls: ['./url-filter.component.sass'],
})
export class UrlFilterComponent extends FilterTypesComponent implements OnInit {
    textFormControl!: FormControl

    ngOnInit(): void {
        this.textFormControl = this.form.controls['value'] as FormControl
    }
}
