import { Injectable } from '@angular/core'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, filter } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { CurrentUser, User } from '../models'
import {
    CommonFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from './store-facade'

enum ErrorType {
    user = 'user',
    schema = 'schema',
    folder = 'folder',
}

export interface ErrorEntity {
    type: ErrorType
    message: string
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SystemRecordsErrorService {
    constructor(
        private commonFacadeService: CommonFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private userFacadeService: UserFacadeService,
        private translation: TranslocoService,
    ) {}

    getApplicationErrorMessages() {
        return this.commonFacadeService.selectLoaded$.pipe(
            filter(Boolean),
            switchMap(() => this.getErrorMessagesAndTypes()),
        )
    }

    private getErrorMessagesAndTypes() {
        return combineLatest([
            this.folderFacadeService.selectFoldersAreLoaded$,
            this.folderFacadeService.selectFoldersIsError$,
            this.schemaFacadeService.selectSelectedTableSchema$,
            this.schemaFacadeService.selectSelectedTableSchemaGuid$,
            this.userFacadeService.currentUser$,
            this.userFacadeService.users$,
            this.translation.selectTranslateObject('errors'),
        ]).pipe(
            untilDestroyed(this),
            take(1),
            map(
                ([
                    folderLoaded,
                    folderError,
                    schema,
                    selectedSchemaGuid,
                    currentUser,
                    users,
                    errorsText,
                ]) => {
                    const errorEntities: ErrorEntity[] = []
                    if (!folderLoaded || folderError) {
                        errorEntities.push(
                            this.combineErrorEntity(ErrorType.folder, errorsText['folder_error']),
                        )
                    }

                    if (selectedSchemaGuid && !schema) {
                        errorEntities.push(
                            this.combineErrorEntity(ErrorType.schema, errorsText['schema_error']),
                        )
                    }

                    if (selectedSchemaGuid && schema?.acl === -1) {
                        errorEntities.push(
                            this.combineErrorEntity(
                                ErrorType.schema,
                                errorsText['schema_access_error'],
                            ),
                        )
                    }

                    if (!currentUser) {
                        errorEntities.push(
                            this.combineErrorEntity(ErrorType.user, errorsText['null_user_error']),
                        )
                    }

                    if (currentUser && !this.userInList(currentUser, users)) {
                        errorEntities.push(
                            this.combineErrorEntity(ErrorType.user, errorsText['user_not_in_list']),
                        )
                    }

                    return errorEntities
                },
            ),
        )
    }

    private combineErrorEntity(type: ErrorType, message: string): ErrorEntity {
        return {
            type,
            message,
        }
    }

    private userInList(currentUser: CurrentUser, users: User[]) {
        return !!users.find((user) => user.guid === currentUser.guid)
    }
}
