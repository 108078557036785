import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Field, Folder } from '@core/models'
import { FormControl, FormGroup } from '@angular/forms'
import { SortDirection, SortObject } from '@models/ui/sort.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SortItemFormGroup } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'

@UntilDestroy()
@Component({
    selector: 'app-sort-item',
    templateUrl: './sort-item.component.html',
    styleUrls: ['./sort-item.component.sass'],
})
export class SortItemComponent implements OnInit {
    @Input() selectedFolder!: Folder
    @Input() fields!: Field[]
    @Input() sortObject!: SortObject
    @Input() sortGroup!: FormGroup<SortItemFormGroup>

    @Output() removed: EventEmitter<string> = new EventEmitter<string>()
    @Output() changed: EventEmitter<SortObject> = new EventEmitter<SortObject>()

    sortSelectionControl!: FormControl<string | null>
    sortDirectionControl!: FormControl<SortDirection | null>

    ngOnInit() {
        if (!this.sortObject || !this.sortGroup) {
            this.sortGroup = new FormGroup({
                select: new FormControl(),
                direction: new FormControl(),
            })
        }

        this.setControls()
    }

    removeItem() {
        this.removed.emit(this.sortObject.fieldGuid)
    }

    changeDirection(direction: string) {
        this.sortDirectionControl.setValue(direction as SortDirection)
    }

    private setControls() {
        this.sortSelectionControl = this.sortGroup.controls.select
        this.sortDirectionControl = this.sortGroup.controls.direction

        this.sortSelectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((guid) => {
            if (!this.sortDirectionControl.value) {
                this.sortDirectionControl.setValue(SortDirection.DESC)
                this.sortObject.sortDirection = SortDirection.DESC
            }

            this.changed.emit({
                ...this.sortObject,
                fieldGuid: guid ?? '',
            })
        })

        this.sortDirectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((direction) => {
            this.changed.emit({
                ...this.sortObject,
                sortDirection: direction ?? SortDirection.DESC,
            })
        })
    }
}
