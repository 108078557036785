<!-- show ng-content if content is not empty. Otherwise show label text -->

<ng-template #optionTemplate>
    <div class="flex flex-row items-center">
        @if(icon) {
        <app-tb-icon [icon]="icon" color="newTextLight" matIconPrefix></app-tb-icon>
        }

        <div #optionContent>
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
