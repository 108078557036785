<div
    *ngIf="fieldTypes && formItem"
    [formGroup]="formItem"
    class="relative flex items-center max-sm:pl-4 max-sm:flex-wrap max-sm:mt-4 sm:mb-4"
>
    <div
        class="hidden max-sm:block absolute top-0 left-0 h-[calc(100%-16px)] w-[4px] bg-primaryLight rounded"
    ></div>

    <div class="flex flex-1">
        <app-tb-select
            class="flex-1 max-sm:mb-4 mr-2 noHintError min-w-[120px]"
            formControlName="fieldGuid"
        >
            <app-tb-option *ngFor="let fieldGuid of fieldGuids" [value]="fieldGuid">
                <app-field-name
                    [field]="fields[fieldGuid]"
                    [selectedFolder]="selectedFolder"
                    [showIcon]="false"
                ></app-field-name>
            </app-tb-option>
        </app-tb-select>

        <app-tb-select
            *transloco="let tOption; read: 'filter.filter_option'"
            class="max-sm:mb-4 max-w-fit mr-2 min-w-[110px] noHintError"
            formControlName="option"
        >
            <app-tb-option *ngFor="let item of fieldFilterOptions(fieldGuid)" [value]="item">
                {{tOption(item)}}
            </app-tb-option>
        </app-tb-select>
    </div>
    <div class="flex items-center flex-1">
        <div *ngIf="formItem.contains('value')" class="flex-1 min-w-[120px] max-sm:mb-4 mr-2">
            <app-filter-types-container
                [field]="fields[formItem.controls['fieldGuid'].value]"
                [form]="formItem"
            ></app-filter-types-container>
        </div>
        <mat-icon
            svgIcon="custom_trash"
            (click)="removeFilter()"
            class="w-[32px] min-w-[24px] max-sm:h-8 = max-sm:mb-4 ml-auto text-secondaryMainLight hover:text-secondaryMain c-pointer"
        ></mat-icon>
    </div>
</div>
