import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ActionsPanelModule } from '@app/feature/actions-panel/actions-panel.module'
import { AddFieldModule } from '@app/feature/add-field/add-field.module'
import { ChangeFieldFolderModule } from '@app/feature/change-field-folder/change-field-folder.module'
import { ShareFieldModule } from '@app/feature/share-field/share-field.module'
import { ShareRecordModule } from '@app/feature/share-record/share-record.module'
import { SharedModule } from '@app/shared/shared.module'
import { ViewControlsModule } from '@app/views/view-controls/view-controls.module'
import { ComponentsLibraryModule } from '@components-library'
import { TranslocoModule } from '@ngneat/transloco'
import { TableContainerComponent } from './table-container.component'
import { BusinessRecordTableComponent } from './table/business-record-table.component'
import { ColumnMenuComponent } from './table/column-menu/column-menu.component'
import { ResizeColumnDirective } from './table/resize-column.directive'
import { TableRowMenuComponent } from './table/table-row-menu/table-row-menu.component'
import { TableComponent } from './table/table/table.component'

@NgModule({
    declarations: [
        TableContainerComponent,
        ColumnMenuComponent,
        ResizeColumnDirective,
        BusinessRecordTableComponent,
        BusinessRecordTableComponent,
        TableComponent,
        ResizeColumnDirective,
        TableRowMenuComponent,
    ],
    imports: [
        CommonModule,
        AddFieldModule,
        ShareFieldModule,
        ShareRecordModule,
        ChangeFieldFolderModule,
        MatTableModule,
        DragDropModule,
        MatSortModule,
        MatMenuModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        FormsModule,
        MatRadioModule,
        TranslocoModule,
        SharedModule,
        ViewControlsModule,
        ComponentsLibraryModule,
        ActionsPanelModule,
    ],
    exports: [TableContainerComponent],
})
export class TableContainerModule {}
