<ng-container *ngFor="let fieldGuid of fields">
    <div
        *ngIf="cells[fieldGuid].value && fieldEntities[fieldGuid] as field"
        class="flex-start-center"
    >
        <div>
            <app-field-name
                *ngIf="selectedFolder"
                [field]="field"
                [selectedFolder]="selectedFolder"
                [showIcon]="false"
            ></app-field-name>
        </div>
        <app-cell-container
            [disabled]="true"
            [fieldTypes]="fieldTypes"
            [field]="field"
            [guid]="fieldGuid"
            [value]="cells[fieldGuid].value"
            class="flex-start-center text-center"
        ></app-cell-container>
    </div>
</ng-container>
