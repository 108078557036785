import { Component, Input, OnInit } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    FieldEntities,
    FieldType,
    RecordUpdate,
    User,
} from '@core/models'
import { Dictionary } from '@ngrx/entity'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordsService } from '@core/services/records.service'
import { UserFacadeService } from '@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-card-update',
    templateUrl: './card-update.component.html',
    styleUrls: ['./card-update.component.sass'],
})
export class CardUpdateComponent implements OnInit {
    @Input()
    record!: BusinessRecords

    @Input()
    fields!: FieldEntities

    @Input()
    fieldTypes!: Dictionary<FieldType>

    user!: User | undefined
    users!: User[]
    updateDate!: string

    constructor(
        private recordsService: RecordsService,
        private userFacadeService: UserFacadeService,
    ) {}

    ngOnInit(): void {
        this.userFacadeService.users$.pipe(untilDestroyed(this)).subscribe((users) => {
            if (users) {
                this.users = users
                if (this.record.update_by) {
                    this.user = this.users.find((user) => user.guid === this.record.update_by.value)
                }
            }
        })

        const updateAt = this.record.update_at?.value
        this.updateDate = updateAt
            ? new Date(updateAt).toLocaleString('en-US', {
                  timeZone: 'America/New_York',
              })
            : ''
    }

    updateRecord(data: AppRecord, cell: Cell, newValue: any) {
        const dataRecord: RecordUpdate = {
            record: data,
            cell: cell,
            value: newValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }
}
