import { createFeatureSelector, createSelector } from '@ngrx/store'
import { automationAdapter, AutomationState } from './automation.reducer'
import { AppState } from '../app.state'
import { Automation } from '../../models/ui/automation.model'
import { Schema } from '../../models'
import { selectSelectedTableSchema } from '../schema'
import { StoreFeatureNames } from '../store-feature-names'

const selectAutomationState = createFeatureSelector<AutomationState>(StoreFeatureNames.Automation)

const { selectIds, selectEntities, selectAll, selectTotal } =
    automationAdapter.getSelectors(selectAutomationState)

export const selectAutomationIds = selectIds
export const selectAutomationEntities = selectEntities
export const selectAllAutomations = selectAll
export const selectAutomationTotal = selectTotal

export const selectSelectedTableSchemaAutomations = createSelector<
    AppState,
    [Automation[], Schema | undefined],
    Automation[]
>(selectAllAutomations, selectSelectedTableSchema, (automations, selectedSchema) => {
    if (!selectedSchema) return []

    return automations.filter((automation) => automation.sotGuid === selectedSchema.guid)
})
