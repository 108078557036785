import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatOption } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { getBoolean } from '@core/global-util'
import { AppRecord, Folder } from '@core/models'
import { FolderFacadeService } from '@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { listFolderItem } from '../share-field/share-field.component'

@UntilDestroy()
@Component({
    selector: 'app-share-record',
    templateUrl: './share-record.component.html',
    styleUrls: ['./share-record.component.sass'],
})
export class ShareRecordComponent implements OnInit {
    record!: AppRecord
    folders!: Dictionary<Folder>
    dropdownList: Array<listFolderItem> = []
    selectedItems: Array<listFolderItem> = []
    sharedFolders!: FormControl
    folderGuids!: string
    @ViewChild('allSelected') private allSelected: MatOption | undefined

    constructor(
        private folderFacadeService: FolderFacadeService,
        public dialogRef: MatDialogRef<ShareRecordComponent, AppRecord | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: AppRecord,
    ) {
        this.record = data
        console.log(this.record)
    }

    ngOnInit(): void {
        this.folderFacadeService.selectFolderEntities$
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.sharedFolders = new FormControl<any>('')
                this.folderGuids = this.record.folder_guids || ''
                this.folders = data

                this.setLists()
            })
    }

    onItemClick() {
        if (this.allSelected?.selected) {
            this.allSelected.deselect()
        }

        if (this.sharedFolders.value.length === this.dropdownList.length) {
            this.allSelected?.select()
        }

        this.updateFolderGuids()
    }

    toggleAllSelection() {
        if (!this.allSelected?.selected) {
            this.sharedFolders.patchValue([])
            this.folderGuids = this.record.folder_guids ?? ''
            return
        }

        this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
        this.folderGuids = this.removeValueOfAllOption().toString()
    }

    applyShare() {
        this.record = {
            ...this.record,
            folder_guids: this.folderGuids,
        }

        this.dialogRef.close(this.record)
    }

    cancelShare() {
        this.dialogRef.close()
    }

    private setLists() {
        Object.keys(this.folders).forEach((folderGuid) => {
            if (!getBoolean(this.folders[folderGuid]?.is_global.value)) {
                const folderName = this.folders[folderGuid]?.name.value
                if (!folderName) return

                this.setDropdownList(folderGuid, folderName)

                this.setSelectedList(folderGuid, folderName)
            }
        })

        this.setFormControlSelected()
    }

    private setDropdownList(folderGuid: string, folderName: string) {
        if (!this.folderGuids?.includes(folderName)) {
            this.dropdownList.push({
                item_id: folderGuid,
                item_text: folderName,
            })
        }
    }

    private setSelectedList(folderGuid: string, folderName: string) {
        if (this.folderGuids.length && this.folderGuids.includes(folderGuid)) {
            this.selectedItems.push({
                item_id: folderGuid,
                item_text: folderName,
            })
        }
    }

    private setFormControlSelected() {
        if (this.selectedItems.length === 0) return

        if (this.selectedItems.length !== this.dropdownList.length) {
            this.sharedFolders.patchValue(this.selectedItems.map((item) => item.item_id))
            return
        }

        this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
    }

    private updateFolderGuids() {
        if (!this.folderGuids) {
            this.folderGuids = this.sharedFolders.value[0]
            return
        }

        this.folderGuids = this.sharedFolders.value.includes(0)
            ? this.removeValueOfAllOption().toString()
            : this.sharedFolders.value.toString()
    }

    private removeValueOfAllOption() {
        return this.sharedFolders.value.filter((item: string | number) => item !== 0)
    }
}
