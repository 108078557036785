import { Injectable } from '@angular/core'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@core/services/local-storage/view-settings-storage/view-settings-storage.service'
import { SortObjectEntities } from '@models/ui/sort.model'

@Injectable({
    providedIn: 'root',
})
export class SortStorageService extends ViewSettingsStorageService<SortObjectEntities> {
    constructor(localStorageService: DataStorageService) {
        super(localStorageService, 'sort')
    }

    get(key: string): SortObjectEntities | null {
        const sortValue = super.get(key)

        if (!sortValue) return null

        if (typeof sortValue !== 'string') return sortValue

        return sortValue.length ? JSON.parse(sortValue) : null
    }
}
