import { Component, OnInit } from '@angular/core'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import { SelectObjectOptions } from '@core/models/response/select-object-options'

@Component({
    selector: 'app-dropdown-filter-container',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.sass'],
})
export class DropdownFilterComponent extends FilterTypesComponent implements OnInit {
    dropdownData!: SelectObjectOptions
    activeOptions!: string[]

    constructor() {
        super()
    }

    optionKeys() {
        if (this.dropdownData) {
            return Object.keys(this.dropdownData)
        }
        return []
    }

    ngOnInit() {
        if (this.field) {
            this.dropdownData = this.field.select_object_field!
        }
        this.activeOptions = this.getActiveOptions()
    }

    checkActive(id: string) {
        this.addUniqueId(id)
        this.form.controls['value'].setValue([...this.activeOptions])
        this.form.controls['value'].markAsTouched()
    }

    addUniqueId(id: string) {
        if (this.activeOptions.includes(id)) {
            this.activeOptions.splice(this.activeOptions.indexOf(id), 1)
        } else {
            this.activeOptions = [...this.activeOptions, id]
        }
    }

    getBgColor(id: string) {
        if (this.dropdownData) {
            return this.dropdownData[id].color
        }
        return ''
    }

    private getActiveOptions() {
        const control = this.form.controls['value']
        if (!control.value || !Array.isArray(control.value)) return []

        return control.value.filter((option) => !!this.dropdownData[option])
    }
}
