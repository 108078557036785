import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CellComponent } from '../cell.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'

@UntilDestroy()
@Component({
    selector: 'app-name',
    templateUrl: './name.component.html',
    styleUrls: ['./name.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameComponent extends CellComponent implements OnInit {
    @Input() trim?: boolean

    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    constructor(private modalFlowManagerService: ModalFlowManagerService) {
        super()
    }

    ngOnInit() {
        this.isEdit = !!this.trim
        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            {
                validators: Validators.required,
                updateOn: 'blur',
            },
        )

        this.textFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.saveValue()
        })
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.value === this.textFormControl.value || this.textFormControl.invalid) return

        this.cellValueChanged.emit(this.textFormControl.value)
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }

    showCard() {
        if (!this.record) return

        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: {
                recordGuid: this.record.guid,
                isFolder: true,
            },
        })
    }
}
