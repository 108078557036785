<div
    cdkDropList
    [cdkDropListData]="items"
    [cdkDropListEnterPredicate]="isDisabledDragIntoTheListBound"
    (cdkDropListDropped)="drop($event)"
    class="w-[300px] h-full"
    [class.collapsed]="collapsed"
>
    <div class="mb-2">
        <app-tb-board-column-header
            [items]="items"
            [moreMenu]="headerMenu"
            [columnItem]="columnItem"
            [key]="key"
            (collapseChanged)="collapsed = $event"
        >
            <ng-content select="[header]"></ng-content>
        </app-tb-board-column-header>
    </div>
    @if(injector) {
    <div [class.hidden]="collapsed">
        @for (item of items; track item) {
        <div class="mb-2">
            <ng-container
                *ngTemplateOutlet="card; injector: injector; context: { item }"
            ></ng-container>
        </div>
        }
    </div>
    }
</div>
