import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { StoreModule } from '@ngrx/store'
import { userFeature } from './user.reducer'

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature(userFeature)],
})
export class UserStoreModule {}
