import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { commonFeature } from './common.reducer'
import { EffectsModule } from '@ngrx/effects'
import { CommonEffects } from './common.effects'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(commonFeature),
        EffectsModule.forFeature([CommonEffects]),
    ],
})
export class CommonStoreModule {}
