import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core'
import { TbMenuState } from '@components-library/tb-menu'
import { ModalContainer } from '@components-library/tb-modal-manager/modal-manager.service'
import { TbButtonComponent } from '../tb-button/tb-button.component'
import { NgIf, NgTemplateOutlet, NgFor } from '@angular/common'
import { TbMenuComponent } from '../tb-menu/tb-menu.component'

@Component({
    selector: 'app-tb-picker',
    templateUrl: './tb-picker.component.html',
    styleUrl: './tb-picker.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TbMenuComponent, NgIf, NgTemplateOutlet, TbButtonComponent, NgFor],
})
export class TbPickerComponent<T> {
    @Input() itemTemplate!: TemplateRef<any>

    @Input() items!: T[]

    @Input() showClear = false

    @Input() selectedItem!: T | null

    @Input() name?: string

    @Output() selectItem = new EventEmitter<T | null>()

    opened = false

    isMenuOpened(status: TbMenuState) {
        this.opened = status === TbMenuState.Open
    }

    protected readonly ModalContainer = ModalContainer
}
