import { Injectable } from '@angular/core'
import { ActionTypeModel } from '@core/models/response/action-type.model'
import { SourceTypeModel } from '@core/models/response/source-type.model'
import {
    Automation,
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '@models/ui/automation.model'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import {
    addAutomationsFromResponse,
    AppState,
    createAutomation,
    deleteAutomation,
    deleteAutomationFromResponse,
    executeAutomation,
    selectAllAutomations,
    selectSelectedTableSchemaAutomations,
    setActionTypes,
    setAutomation,
    setSourceTypes,
    updateAutomation,
    updateAutomationFromResponse,
} from '../../@ngrx'

@Injectable({
    providedIn: 'root',
})
export class AutomationFacadeService {
    constructor(private store: Store<AppState>) {}

    selectAutomations$ = this.store.select(selectAllAutomations)

    selectSelectedTableSchemaAutomations$ = this.store.select(selectSelectedTableSchemaAutomations)

    setAutomation(automations: Automation[]) {
        this.store.dispatch(setAutomation({ automations }))
    }

    setActionTypes(actionTypes: ActionTypeModel) {
        this.store.dispatch(setActionTypes({ actionTypes }))
    }

    setSourceTypes(sourceTypes: SourceTypeModel) {
        this.store.dispatch(setSourceTypes({ sourceTypes }))
    }

    createAutomation(automation: AutomationCreateModel) {
        this.store.dispatch(createAutomation({ automation }))
    }

    updateAutomation(automation: AutomationUpdateModel) {
        this.store.dispatch(updateAutomation({ automation }))
    }

    deleteAutomation(automation: AutomationDeleteModel) {
        this.store.dispatch(deleteAutomation({ automation }))
    }

    executeAutomation(automations: AutomationExecuteModel) {
        this.store.dispatch(executeAutomation({ automations }))
    }

    addAutomationsFromResponse(automation: Automation) {
        this.store.dispatch(addAutomationsFromResponse({ automation }))
    }

    updateAutomationFromResponse(automation: Update<Automation>) {
        this.store.dispatch(updateAutomationFromResponse({ automation }))
    }

    deleteAutomationFromResponse(automations: string[]) {
        this.store.dispatch(deleteAutomationFromResponse({ automations }))
    }
}
