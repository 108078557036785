import { DataStorageService } from 'src/app/@core/services/local-storage/view-settings-storage/data-storage.service'
import { BehaviorSubject, Observable } from 'rxjs'

export abstract class ViewSettingsStorageService<T> {
    private readonly isSet$$ = new BehaviorSubject<boolean>(false)
    private readonly updatedStoreCache$$ = new BehaviorSubject<null | { [key: string]: T }>(null)

    private storeCached = new Map<string, T>()
    protected constructor(private dataStorageService: DataStorageService, private key: string) {}

    getStore$(): Observable<null | { [key: string]: T }> {
        return this.updatedStoreCache$$.asObservable()
    }

    get(key: string): string | T | null {
        const fullKey = this.dataStorageService.getFullKey(this.combineKey(key))
        const cacheData = this.storeCached.get(fullKey)
        if (cacheData) return cacheData

        return this.dataStorageService.get(this.combineKey(key))
    }

    set(key: string, setData: T) {
        const combinedKey = this.combineKey(key)

        this.dataStorageService.set(combinedKey, this.stringifyLocalValue(setData))
        this.storeCached.set(this.dataStorageService.getFullKey(combinedKey), setData)
        this.updatedStoreCache$$.next(Object.fromEntries(this.storeCached.entries()))
    }

    remove(key: string) {
        const combinedKey = this.combineKey(key)

        this.dataStorageService.remove(combinedKey)
        this.storeCached.delete(this.dataStorageService.getFullKey(combinedKey))
        this.updatedStoreCache$$.next(Object.fromEntries(this.storeCached.entries()))
    }

    isSet$(): Observable<boolean> {
        return this.isSet$$.asObservable()
    }

    updateIsSetValue(key: string) {
        const localValue = this.dataStorageService.get(this.combineKey(key))
        this.isSet$$.next(!!localValue)
    }

    private combineKey(key: string): string {
        return `${key}-${this.key}`
    }

    private stringifyLocalValue(data: T): string {
        return typeof data !== 'string' ? JSON.stringify(data) : data
    }
}
