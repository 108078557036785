import { createAction, props } from '@ngrx/store'
import { Config, ConfigUpdateModel } from '../../models/ui/config.model'
import { Update } from '@ngrx/entity'
import { Deleted } from '../../models'

export const setConfigs = createAction('[Init Config] SET_CONFIG', props<{ configs: Config[] }>())

export const createConfigs = createAction(
    '[Create Config] CREATE_CONFIG',
    props<{ configs: Map<string, string> }>(),
)
export const updateConfigs = createAction(
    '[Update Config] Update configs',
    props<{ configs: ConfigUpdateModel }>(),
)
export const deleteConfigs = createAction(
    '[Delete Config] Delete configs',
    props<{ configs: ConfigUpdateModel }>(),
)

export const addConfigFromResponse = createAction(
    '[Add Config] Add configs from response',
    props<{ config: Config }>(),
)

export const updateConfigFromResponse = createAction(
    '[Response Update Config] Update configs from response',
    props<{ config: Update<Config>[] }>(),
)

export const deleteConfigFromResponse = createAction(
    '[Delete Update Config] Delete configs from response',
    props<{ configs: Deleted[] }>(),
)
