<mat-checkbox
    [class.small]="size === sizeEnum.small"
    [class.indeterminate]="indeterminate"
    [class.disabled]="disabled"
    [class.tb-color-secondary]="color === colorEnum.secondary"
    [color]="color"
    [checked]="value"
    [disabled]="disabled"
    [indeterminate]="indeterminate"
    (change)="onCheckboxValueChange($event)"
>
    <ng-content></ng-content>
</mat-checkbox>
