import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA)
        public data: { message: string },
    ) {}
}
