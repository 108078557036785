import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbIconModule } from '@components-library/tb-icon/tb-icon.module'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'

@NgModule({
    declarations: [TbMenuListItemComponent],
    imports: [CommonModule, TbIconModule],
    exports: [TbMenuListItemComponent],
})
export class TbMenuListItemModule {}
