import { NgFor, NgTemplateOutlet } from '@angular/common'
import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChildren,
} from '@angular/core'
import { timer } from 'rxjs'
import { TbTab, TbTabComponent } from '../tb-tab/tb-tab.component'

@Component({
    selector: 'app-tb-tabs',
    templateUrl: './tb-tabs.component.html',
    styleUrls: ['./tb-tabs.component.sass'],
    standalone: true,
    imports: [NgFor, TbTabComponent, NgTemplateOutlet],
})
export class TbTabsComponent implements OnChanges {
    @Input()
    tabs!: TbTab[]

    @Input()
    icon?: TemplateRef<any>

    @Input()
    activeTab: TbTab | null = null

    @Input()
    moreMenu: TemplateRef<any> | null = null

    @Input()
    hideOverflowingTabs = false

    @Output()
    tabOpened = new EventEmitter<TbTab>()

    @ViewChildren(TbTabComponent) tabComponents!: QueryList<TbTabComponent>

    constructor(private readonly elRef: ElementRef, private readonly renderer2: Renderer2) {}

    ngOnChanges() {
        // timer is needed to wait until tabs are rendered
        timer(0).subscribe(() => this.setTabsOrder())
    }

    @HostListener('window:resize')
    onResize() {
        this.setTabsOrder()
    }

    setTabsOrder() {
        if (!this.hideOverflowingTabs) {
            return
        }

        const allTabs = this.tabComponents.toArray()
        const activeTab = this.tabComponents.find((tab) => tab.isSelected)

        allTabs.forEach((tab, index) => {
            this.renderer2.setStyle(tab.nativeElement, `order`, index)
        })

        // Decrement active tab's order until it stops overflowing
        for (let _ of allTabs) {
            if (!activeTab || !this.isTabOverflowing(activeTab)) {
                return
            }

            const activeTabOrder = activeTab.nativeElement.style.order

            this.renderer2.setStyle(activeTab.nativeElement, 'order', activeTabOrder - 1)
        }
    }

    isTabOverflowing(tab: TbTabComponent) {
        const tabAbsoluteOffsetTop = tab?.nativeElement.getBoundingClientRect().top
        const hostElementAbsoluteOffsetTop = this.elRef.nativeElement.getBoundingClientRect().top

        return tabAbsoluteOffsetTop > hostElementAbsoluteOffsetTop
    }
}
