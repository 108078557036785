import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { AbstractControl, FormControl, ValidationErrors, ReactiveFormsModule } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { urlValidator } from '@core/global-util'
import { TranslocoModule } from '@ngneat/transloco'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { AutoFocusDirective } from '../focus.directive'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { NgIf } from '@angular/common'

@UntilDestroy()
@Component({
    selector: 'app-url',
    templateUrl: './url.component.html',
    styleUrls: ['./url.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TbInputComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        AutoFocusDirective,
        TbIconComponent,
        TranslocoModule,
    ],
})
export class UrlComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    ngOnInit() {
        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: this.disabled,
            },
            {
                validators: this.urlValidator(),
                updateOn: 'blur',
            },
        )

        this.textFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.saveValue())
    }

    editMode() {
        this.isEdit = true
    }

    urlValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (urlValidator(control.value)) return null
            return { urlValid: false }
        }
    }

    saveValue() {
        this.isEdit = false

        if (this.value !== this.textFormControl.value && !this.textFormControl.invalid) {
            this.cellValueChanged.emit(this.textFormControl.value)
        }
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }
}
