import { ConfigFields } from '@core/models/ui/config.model'

export interface UpdateConfigUpdateModel {
    record_guid: string
    record_revision: number
    configuration: ConfigFields
}

export interface DeleteConfigUpdateModel {
    record_guid: string
    record_revision: number
}
