import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity'
import { BusinessRecords, Deleted } from '../../models'
import { createFeature, createReducer, on } from '@ngrx/store'

import {
    addRecordsFromResponse,
    deleteRecordsFromResponse,
    initRecords,
    resetRecords,
    updateRecordsFromResponse,
} from '../record/record.actions'
import { StoreFeatureNames } from '../store-feature-names'
import { dataReset } from '../common/common.actions'

const recordFeatureKey: string = StoreFeatureNames.Record

export interface RecordState extends EntityState<BusinessRecords> {}

function selectRecordId(record: BusinessRecords): string {
    return record.guid
}

export const recordAdapter: EntityAdapter<BusinessRecords> = createEntityAdapter<BusinessRecords>({
    selectId: selectRecordId,
})

const initialState: RecordState = recordAdapter.getInitialState()

const recordReducer = createReducer(
    initialState,
    on(dataReset, () => initialState),
    on(initRecords, (state: RecordState, { records }: { records: BusinessRecords[] }) => {
        return recordAdapter.addMany(records, state)
    }),
    on(resetRecords, (state: RecordState) => {
        return recordAdapter.removeAll({ ...state })
    }),
    on(
        addRecordsFromResponse,
        (state: RecordState, { records }: { records: BusinessRecords[] }) => {
            return recordAdapter.addMany(records, state)
        },
    ),
    on(
        updateRecordsFromResponse,
        (state: RecordState, { records }: { records: Update<BusinessRecords>[] }) => {
            return recordAdapter.updateMany(records, state)
        },
    ),
    on(deleteRecordsFromResponse, (state: RecordState, { records }: { records: Deleted[] }) => {
        return recordAdapter.removeMany(
            records.map((deleted) => deleted.guid),
            state,
        )
    }),
)

export const recordFeature = createFeature({
    name: recordFeatureKey,
    reducer: recordReducer,
})
