import { Injectable } from '@angular/core'
import { MenuItem } from '@components-library/tb-side-navigation-layout/side-navigation.service'
import { Schema } from '@core/models'
import { NavigatorService } from '@core/services/navigator.service'
import { FolderFacadeService, SchemaFacadeService } from '@core/services/store-facade'
import { LogService } from '@services/log.service'
import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

const sotNameToIconMap: { [key: string]: string } = {
    table_contact: 'custom_contacts',
    table_company: 'custom_companies',
    table_deal: 'custom_deals',
    Issues: 'custom_tasks',
    default: 'folder',
}

const enum constantMenuItemsId {
    HOME = 'home',
    ADMINISTRATION = 'administration',
    ALL_PRODUCTS = 'all_products',
    HELP = 'help',
}

const constantMenuItems: {
    id: constantMenuItemsId
    icon: string
}[] = [
    {
        icon: 'custom_home',
        id: constantMenuItemsId.HOME,
    },
    {
        icon: 'custom_settings',
        id: constantMenuItemsId.ADMINISTRATION,
    },
    {
        icon: 'custom_apps',
        id: constantMenuItemsId.ALL_PRODUCTS,
    },
    {
        icon: 'custom_help_support',
        id: constantMenuItemsId.HELP,
    },
]

@Injectable()
export class NavigationMenuService {
    private selectedSOTGuid$ = this.schemaFacadeService.selectSelectedTableSchemaGuid$
    private selectedFolderGuid$ = this.folderFacadeService.selectSelectedFolderGuid$
    private schemas$ = this.schemaFacadeService.selectTableSchemas$.pipe(
        map((schemas) => {
            return schemas.filter((schema) => schema.acl !== -1)
        }),
    )

    menu$: Observable<MenuItem[]> = combineLatest([
        //TODO: add global menu selected item to common selector
        this.selectedSOTGuid$,
        this.selectedFolderGuid$,
        this.schemas$,
    ]).pipe(
        filter(([, , schemas]) => !!schemas.length),
        map(([selectedSchemaGuid, folderGuid, schemas]) => {
            const result: MenuItem[] = constantMenuItems.map(({ id, icon }) => ({
                id,
                icon,
                action: this.getConstantMenuItemActionById(id),
                selected: false,
                name: id,
            }))

            const schemaMenuItems = schemas.map<MenuItem>((schema) => {
                return {
                    id: schema.guid,
                    action: this.navigateToSOT.bind(this, schema, folderGuid!),
                    icon: sotNameToIconMap[schema.name],
                    selected: selectedSchemaGuid === schema.guid,
                    name: schema.name,
                }
            })

            result.splice(1, 0, ...schemaMenuItems)

            return result
        }),
    )

    constructor(
        private navigatorService: NavigatorService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private logService: LogService,
    ) {}

    getConstantMenuItemActionById(id: constantMenuItemsId): () => void {
        switch (id) {
            case constantMenuItemsId.HOME:
                return this.navigateMyFolder.bind(this)
            case constantMenuItemsId.ADMINISTRATION:
            case constantMenuItemsId.ALL_PRODUCTS:
            case constantMenuItemsId.HELP:
            default:
                return () => {
                    this.logService.error(new Error(`not implemented menu item ${id}`))
                }
        }
    }

    navigateMyFolder() {
        this.navigatorService.goToMyFolder()
    }

    navigateToSOT(systemObject: Schema, folderGuid: string) {
        this.navigatorService.goToSystemObject(systemObject.guid, folderGuid)
    }
}
