<!-- TODO: Implement component library for searchable select -->
<div [matMenuTriggerFor]="assignMenu">
    <div
        *ngIf="getAssignUsersLength() !== 0; else iconAdd"
        (mouseenter)="isShowAssignees = true"
        (mouseleave)="isShowAssignees = false"
        class="users-icons"
    >
        <span *ngFor="let user of assignedUsers" class="user-avatar">
            <app-user-avatar
                [userName]="user.fullName"
                [userUrl]="user.avatar_url"
                [class.active]="this.userGuids.includes(user.guid)"
            >
            </app-user-avatar>
        </span>
        <div class="assignee-info" *ngIf="getAssignUsersLength() && isShowAssignees">
            <i *ngFor="let user of assignedUsers"> {{user.fullName}} </i>
        </div>
    </div>
    <ng-template #iconAdd>
        <app-tb-icon class="md-inactive" icon="person_add_alt"></app-tb-icon>
    </ng-template>
</div>
<mat-menu #assignMenu="matMenu">
    <ng-template matMenuContent>
        <mat-form-field (click)="$event.stopPropagation()" class="noHintError" appearance="outline">
            <span matPrefix>
                <app-tb-icon class="md-inactive search-icon" icon="search"></app-tb-icon>
            </span>
            <input [formControl]="search" class="search-input" matInput type="text" />
        </mat-form-field>
        <app-tb-button
            [appearance]="'text'"
            (click)="addUser(user.guid);$event.stopPropagation()"
            *ngFor="let user of users"
        >
            <span class="user-avatar">
                <app-user-avatar
                    [userName]="user.fullName"
                    [userUrl]="user.avatar_url"
                    [class.active]="this.userGuids.includes(user.guid)"
                >
                </app-user-avatar>
            </span>
            {{user.fullName}}
        </app-tb-button>
    </ng-template>
</mat-menu>
