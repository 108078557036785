import { moveItemInArray } from '@angular/cdk/drag-drop'
import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ChangeFieldFolderComponent } from '@app/feature/change-field-folder/change-field-folder.component'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { ShareFieldComponent } from '@app/feature/share-field/share-field.component'
import {
    BusinessRecords,
    Cell,
    CellEntities,
    Field,
    FieldType,
    Folder,
    RecordGroup,
    ViewData,
} from '@core/models'
import { Dictionary } from '@ngrx/entity'
import { isNumber } from 'lodash-es'
import { DropColumns, MovedRow, ResizeColumn, TableColumn, TableRow } from './table/table.component'

@Component({
    selector: 'app-business-record-table',
    templateUrl: './business-record-table.component.html',
    styleUrls: ['./business-record-table.component.sass'],
})
export class BusinessRecordTableComponent implements AfterContentInit, OnChanges, OnInit {
    // todo: [table-ref-2] get this param from TableViewService
    @Input() tableData!: ViewData | undefined

    // todo: [table-ref-2] is not used
    @Input() dropRowData!: BusinessRecords[] | undefined

    // todo: [table-ref] we pass the same data to the "records" and to the "group"
    @Input() records!: BusinessRecords[] | undefined

    @Input() isGrouped!: boolean

    // todo: [table-ref-2] is not used
    @Input() sortActive!: string | undefined

    // todo: [table-ref-2] is not used
    @Input() isShowCheckbox!: boolean

    // todo: [table-ref] we pass the same data to the "records" and to the "group"
    @Input() group!: RecordGroup | undefined

    // todo: [table-ref-2] is not used
    @Input() isNewRecordRow!: boolean

    @Input() selectedRecords: BusinessRecords[] = []

    // todo: [table-ref-2] is not used
    @Input() newRecordGroup?: RecordGroup

    @Input() currentUser?: string
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() selectedFolder!: Folder
    @Input() cells!: { [recordGuid: string]: CellEntities }

    // todo: [table-ref-2] is not used
    @Input() isFilter = false

    @Output() dropColumn = new EventEmitter<{
        columnOrder?: string[]
    }>()

    @Output() showColumn = new EventEmitter<string[]>()
    @Output() deleteRecord = new EventEmitter<BusinessRecords>()
    @Output() updateRecord = new EventEmitter<{ data: BusinessRecords; cell: Cell; value: any }>()
    @Output() deleteColumn = new EventEmitter<string>()
    @Output() pinColumn = new EventEmitter<string>()
    @Output() unPinColumn = new EventEmitter<string[]>()
    @Output() hideColumn = new EventEmitter<string>()
    @Output() selectRecord = new EventEmitter<BusinessRecords>()
    @Output() sortColumn = new EventEmitter<string>()

    // todo: [table-ref-2] is not used
    @Output() saveCreateRecord = new EventEmitter<BusinessRecords>()
    @Output() abandonCreateRecord = new EventEmitter<any>()

    @Output() sharedField = new EventEmitter<Field>()
    @Output() sharedRecord = new EventEmitter<BusinessRecords>()
    @Output() moveFieldToFolder = new EventEmitter<any>()
    @Output() resizeColumn = new EventEmitter<any>()
    @Output() resetColumnWidth = new EventEmitter<any>()
    @Output() moveRow = new EventEmitter<any>()

    isResizable: boolean = false
    isPinAvailable!: boolean
    doesHaveColGroups = false
    rows: TableRow[] = []
    columns: TableColumn[] = []
    selectedRowItems: TableRow[] = []

    constructor(public dialog: MatDialog, public manageFieldsService: ManageFieldsService) {}

    // todo: [table-ref-2] not sure if it is needed, remove
    ngAfterContentInit() {
        this.isResizable = true
    }

    ngOnInit() {
        this.checkIfTableHasColGroups()

        if (!this.records) return
        this.prepareRowsAndColumns(this.records)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sortActive) {
            this.sortActive = changes.sortActive.currentValue
        }

        if (changes.isNewRecordRow) {
            this.isNewRecordRow = changes.isNewRecordRow.currentValue
        }

        if (changes.tableData) {
            console.log('changes.tableData.currentValue', changes.tableData.currentValue)
            this.tableData = changes.tableData.currentValue
            this.checkIfTableHasColGroups()
            if (!this.records) return
            this.prepareRowsAndColumns(this.records)
        }

        if (changes.dropRowData) {
            this.dropRowData = changes.records.currentValue
            console.log('this.records', this.records)
        }

        if (changes.selectedRecords) {
            this.selectedRowItems = this.recordsToRow(changes.selectedRecords.currentValue)
        }
    }

    prepareRowsAndColumns(records: BusinessRecords[]) {
        this.rows = this.recordsToRow(records)

        const tableColumns = this.tableData?.columns
        if (!tableColumns) return

        this.columns = tableColumns.columns.map((fieldGuid) => {
            const colGroup = tableColumns.colGroups && tableColumns.colGroups[fieldGuid]
            const colWidth = tableColumns.columnsWidth && tableColumns.columnsWidth[fieldGuid]

            let column: TableColumn = {
                isPinned: this.isPinned(fieldGuid),
                item: { guid: fieldGuid },
                guid: fieldGuid,
            }

            if (colGroup) {
                column.colGroup = colGroup
            }

            if (isNumber(colWidth)) {
                column.colWidth = colWidth
            }

            return column
        })

        this.isPinAvailable = !!this.tableData?.selectedView?.columns_pinned?.value?.length
    }

    recordsToRow(records: BusinessRecords[]) {
        return records.map((record) => ({ item: record, guid: record.guid }))
    }

    movedFieldToFolder(field: Field) {
        const dialogRef = this.dialog.open<ChangeFieldFolderComponent, Field>(
            ChangeFieldFolderComponent,
            {
                width: '300px',
                height: '200px',
                data: field,
            },
        )
        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            field = res ? res : field
            console.log(res)
            this.moveFieldToFolder.emit(field)
        })
    }

    isPinned(guid: string) {
        return this.tableData?.columns.pinnedColumns
            ? this.tableData!.columns.pinnedColumns.includes(guid)
            : false
    }

    isColumnWidth(guid: string) {
        if (this.tableData?.columns.columnsWidth) {
            return !!this.tableData.columns.columnsWidth[guid]
        }
        return false
    }

    updateRecordFn(data: BusinessRecords, cell: Cell, value: any) {
        this.updateRecord.emit({ data, cell, value })
    }

    checkIfTableHasColGroups() {
        const colGroups = this.tableData?.columns?.colGroups
        const colGroupLength = colGroups && Object.keys(colGroups).length
        this.doesHaveColGroups = !!colGroupLength
    }

    deleteRecordFn(data: BusinessRecords) {
        this.deleteRecord.emit(data)
    }

    copyField(guid: string) {
        const field = this.tableData!.fields[guid]
        this.manageFieldsService.duplicateField(field)
    }

    shareRecord(record: BusinessRecords) {
        this.sharedRecord.emit(record)
    }

    shareField(field: Field) {
        const dialogRef = this.dialog.open<ShareFieldComponent, Field>(ShareFieldComponent, {
            width: '400px',
            height: '200px',
            data: field,
        })

        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            if (res) {
                field = res ? res : field
                console.log(res)
                this.sharedField.emit(field)
            }
        })
    }

    editField(field: Field) {
        this.manageFieldsService.editField(field)
    }

    deleteFieldFn(guid: string) {
        this.deleteColumn.emit(guid)
    }

    // todo: [table-ref-2] is not used
    showColumnFn(item: string) {
        if (this.tableData && this.tableData.columns.hiddenColumns) {
            let hiddenColumns = [...this.tableData.columns.hiddenColumns]
            hiddenColumns.splice(hiddenColumns.indexOf(item), 1)
            this.showColumn.emit(hiddenColumns)
        }
    }

    pinColumnFn(guid: string) {
        if (this.tableData && this.tableData.columns.pinnedColumns) {
            let arr = [...this.tableData.columns.pinnedColumns]
            arr.push(guid)
            const value = arr.join(',')
            this.pinColumn.emit(value)
        }
    }

    unPinColumnFn(guid: string) {
        if (this.tableData && this.tableData.columns.pinnedColumns) {
            let pinnedColumns = [...this.tableData.columns.pinnedColumns]
            pinnedColumns.splice(pinnedColumns.indexOf(guid), 1)
            this.unPinColumn.emit(pinnedColumns)
        }
    }

    hideColumnFn(item: string) {
        if (this.tableData && this.tableData.columns.hiddenColumns) {
            let arr = [...this.tableData.columns.hiddenColumns]
            if (arr.indexOf(item) == -1) {
                arr.push(item)
            }
            const value = arr.join(',')
            this.hideColumn.emit(value)
        }
    }

    sortColumnFn(guid: string) {
        this.sortColumn.emit(guid)
    }

    dropColumnFn({ columns, previousIndex, currentIndex }: DropColumns) {
        this.moveColumnInArray({
            columns: columns,
            previousIndex,
            currentIndex,
        })
    }

    dropRow(event: MovedRow) {
        this.moveRecordBetweenGroups(event)
    }

    moveRecordBetweenGroups(event: MovedRow) {
        const field = this.group?.field!
        const value = this.group?.value!

        this.moveRow.emit({
            record: event.item,
            cell: this.cells[event.item.guid][field.guid],
            value: value,
        })
    }

    resizeColumnFn({ column, width }: ResizeColumn) {
        this.resizeColumn.emit({ guid: column.item.guid, width })
    }

    private moveColumnInArray({ columns, previousIndex, currentIndex }: DropColumns) {
        if (
            !this.tableData ||
            !this.tableData.columns.pinnedColumns ||
            !this.tableData.selectedView
        ) {
            return
        }

        if (!this.tableData) return

        moveItemInArray(columns, previousIndex, currentIndex)

        const lastAffectedIndex = this.getLastIndex(currentIndex, previousIndex)
        const affectedColumns = this.getColumnGuids(columns)

        affectedColumns.splice(lastAffectedIndex, affectedColumns.length)

        const columnsOrder = this.selectColumnsFromSelectedView()

        if (lastAffectedIndex >= columnsOrder.length) {
            this.dropColumn.emit({ columnOrder: affectedColumns })
            return
        }

        columnsOrder.splice(0, lastAffectedIndex)

        this.dropColumn.emit({ columnOrder: affectedColumns.concat(columnsOrder) })
    }

    private getLastIndex(currentIndex: number, previousIndex: number) {
        const indexModifier = this.tableData?.selectedView?.columns_pinned.value.length ? 2 : 1
        return indexModifier + (previousIndex > currentIndex ? previousIndex : currentIndex)
    }

    private getColumnGuids(columns: TableColumn[]): string[] {
        return columns.map((column) => column.item.guid)
    }

    private selectColumnsFromSelectedView() {
        if (!this.tableData) return []

        const columnsOrderString = this.tableData.selectedView?.columns_order.value

        return columnsOrderString?.length === 0 ? [] : columnsOrderString?.split(',') ?? []
    }
}
