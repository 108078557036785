import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TbIconComponent } from '../../../tb-icon/tb-icon.component'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-tb-tag',
    templateUrl: './tb-tag.component.html',
    styleUrl: './tb-tag.component.sass',
    standalone: true,
    imports: [NgIf, TbIconComponent],
})
export class TbTagComponent {
    @Input() rounded = false

    @Input() icon?: string

    @Input() iconColor?: string

    @Input() removable = false

    @Output() remove = new EventEmitter<void>()
}
