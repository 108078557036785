import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ViewGroupService } from '@app/views/view-controls/view-group/view-group.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Field, Folder, ObjectResponseModel, View } from '@core/models'
import { FormControl } from '@angular/forms'
import { ViewFacadeService } from '@services/store-facade'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'

export interface GroupDialogData {
    selectedFolder: Folder
    selectedView: View
    fields?: Field[]
    groupValue?: string
}

@UntilDestroy()
@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
})
export class GroupModalComponent extends ModalContainerComponent implements OnInit, OnChanges {
    selectedFolder!: Folder
    selectedView!: View
    applyForSession: boolean = false
    selectControl!: FormControl

    groupValue?: string
    fields?: Field[]
    isSessionValueSet: boolean = false

    isDirty = false

    constructor(
        private groupStorageService: GroupStorageService,
        private groupService: ViewGroupService,
        private viewFacadeService: ViewFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) public groupMenuData: GroupDialogData,
    ) {
        super(groupMenuData, modalManagerService, confirmationDialogService)
    }

    ngOnInit() {
        this.groupValue = this.groupMenuData.groupValue
        this.fields = this.groupMenuData.fields || []
        this.selectedView = this.groupMenuData.selectedView
        this.selectedFolder = this.groupMenuData.selectedFolder

        if (this.groupStorageService.get(this.selectedView.guid)) {
            this.applyForSession = true
        }
        this.selectControl = new FormControl(this.groupValue)

        dirtyCheck(this.selectControl, of(this.selectControl.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })

        this.groupStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isSessionValueSet = state))
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectControl && changes.groupValue) {
            this.selectControl.setValue(this.groupValue)
        }
    }

    onApplyForSession() {
        this.applyForSession = true
        this.groupStorageService.set(this.selectedView.guid, this.selectControl.value)
        this.onUpdateValue(this.groupStorageService.isSet$())
    }

    onSaveGroup() {
        const saveData = this.groupService.saveGroupData(
            this.selectedView,
            this.selectControl.value,
        )

        this.onUpdateValue(
            'cell' in saveData
                ? this.viewFacadeService.updateViewRequest(this.selectedView, { group: saveData })
                : saveData,
        )
    }

    resetToDefault() {
        this.applyForSession = false
        this.groupStorageService.remove(this.selectedView.guid)
        this.selectControl.setValue(this.groupValue)
        this.onUpdateValue(this.groupStorageService.isSet$())
    }

    isSelectControlChanged() {
        return this.selectControl.dirty && this.selectControl.value !== this.groupValue
    }

    isSaveButtonActive() {
        return this.isSelectControlChanged() || this.isValueSession()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private isValueSession() {
        return this.selectedView.group.value !== this.groupValue && this.isSessionValueSet
    }

    private onUpdateValue(updateObservable: Observable<ObjectResponseModel | boolean>) {
        this.showLoader = true
        updateObservable.pipe(take(1)).subscribe({
            next: (data) => {
                this.processUpdateGroupData(data)
            },
            error: (error) => {
                this.showLoader = false
                this.errors = [error]
            },
            complete: () => {
                this.showLoader = false
            },
        })
    }

    private processUpdateGroupData(data: ObjectResponseModel | boolean) {
        this.showLoader = false
        if (typeof data === 'boolean') {
            this.close()
            return
        }
        if (data.status === 'success') {
            this.applyForSession = false
            this.groupStorageService.remove(this.selectedView.guid)
            this.close()
            return
        }
        this.errors = data.error?.map((error) => error.message)
    }
}
