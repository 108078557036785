<div class="flex items-center">
    <mat-icon
        svgIcon="custom_drag"
        class="h-5 w-5 min-w-[20px] mr-1 text-primarySecondary cursor-pointer"
    ></mat-icon>
    <div
        class="flex items-center w-full h-10 gap-2 mr-2 px-3 py-2 rounded-lg border border-solid border-primaryLight"
    >
        <div class="h-5 w-5 rounded-sm bg-{{color}}"></div>
        {{name}}
    </div>
    <mat-icon
        class="h-8 w-8 min-w-[28px] text-3xl leading-8 sm:h-5 sm:w-5 sm:min-w-[20px] sm:text-xl sm:leading-5 mr-1 text-primarySecondary hover:text-secondaryMain cursor-pointer"
        >water_drop_outline</mat-icon
    >
    <mat-icon
        svgIcon="custom_trash"
        class="h-8 w-8 min-w-[28px] sm:h-5 sm:w-5 sm:min-w-[20px] mr-1 text-primarySecondary hover:text-secondaryMain cursor-pointer"
    ></mat-icon>
</div>
