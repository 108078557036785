import { Component, Inject, OnInit } from '@angular/core'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Field, Folder, ObjectResponseModel, RecordUpdate, View } from '@core/models'
import { FolderFacadeService, SchemaFacadeService, ViewFacadeService } from '@services/store-facade'
import { filter, map, take } from 'rxjs/operators'
import { SortDirection, SortObject, SortObjectEntities } from '@models/ui/sort.model'
import { SortStorageService } from '@services/local-storage/sort-storage.service'
import { cloneDeepWith, isEqual } from 'lodash-es'
import { FormControl, FormGroup } from '@angular/forms'
import { ViewSortService } from '@app/views/view-controls/view-sort/view-sort.service'
import { combineLatest, Observable, of, switchMap } from 'rxjs'
import { generateUuid } from '@core/global-util'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'

export type SortItemFormGroup = {
    select: FormControl<string | null>
    direction: FormControl<SortDirection | null>
}

@UntilDestroy()
@Component({
    selector: 'app-sort-modal',
    templateUrl: './sort-modal.component.html',
})
export class SortModalComponent extends ModalContainerComponent implements OnInit {
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    private readonly combinedSortRelatedData$ = this.selectedFolder$.pipe(
        untilDestroyed(this),
        switchMap((folder) => {
            return this.combineViewAndFields(folder)
        }),
    )

    sortMenuForm!: FormGroup

    fields!: Field[]
    sortEntitiesArray!: SortObject[]
    sortObjectsArray!: SortObject[]
    selectedView!: View

    isSaveAvailable: boolean = false
    isSessionSaveAvailable: boolean = false
    isSessionValueSet: boolean = false

    isDirty = false

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private sortStorageService: SortStorageService,
        private viewFacadeService: ViewFacadeService,
        private folderFacadeService: FolderFacadeService,
        private sortService: ViewSortService,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: unknown,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    ngOnInit() {
        this.sortStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((isSessionSortSet) => (this.isSessionValueSet = isSessionSortSet))

        this.setInitSortData()

        this.refreshSaveAvailability()
    }

    getSortItemGroup(guid: string) {
        return this.sortMenuForm.controls[guid] as FormGroup
    }

    updateSortObjects(object: SortObject, index: number) {
        const currentObject = this.sortObjectsArray[index]
        if (currentObject.fieldGuid !== object.fieldGuid) {
            this.sortMenuForm.controls[object.fieldGuid] =
                this.sortMenuForm.controls[currentObject.fieldGuid]
            delete this.sortMenuForm.controls[currentObject.fieldGuid]
        }
        this.sortObjectsArray[index] = object
    }

    removeSortObject(guid: string, index: number) {
        this.sortObjectsArray = this.sortObjectsArray.filter(
            (guid, guidIndex) => guidIndex !== index,
        )

        this.sortMenuForm.removeControl(guid)

        if (!this.sortObjectsArray.length) {
            this.onClear()
        }
    }

    addSort() {
        const newGuid = generateUuid()
        this.sortObjectsArray.push(this.setEmptySortArray(newGuid)[0])
        this.sortMenuForm.setControl(
            newGuid,
            new FormGroup({
                select: new FormControl(),
                direction: new FormControl(),
            }),
        )
    }

    onApplyForSession() {
        const filteredSortArray = this.filterEmptyOptions()

        this.saveSortToStorage(this.convertArrayToObject(filteredSortArray))
    }

    onSaveSort() {
        const filteredSortArray = this.filterEmptyOptions()

        this.saveSortToView(this.convertArrayToObject(filteredSortArray))
    }

    onClear() {
        this.sortObjectsArray = this.setEmptySortArray(generateUuid())
        this.setSortMenuForm()
        this.refreshSaveAvailability()
    }

    isSortDefault() {
        return this.filterEmptyOptions().length
    }

    fieldsForSortItem(sortObject: SortObject) {
        const objectsArray = this.sortObjectsArray.filter(
            (object) => object.fieldGuid !== sortObject.fieldGuid,
        )

        return this.filterFieldsBySortObjects(objectsArray)
    }

    resetToDefault() {
        this.setSortObjectsArray(null)
        this.reset()

        // closes modal
        this.close()
    }

    drop(event: CdkDragDrop<SortObject[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)

        this.refreshSaveAvailability()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private setInitSortData() {
        this.combinedSortRelatedData$.subscribe(([view, fields, columnGuid]) => {
            this.selectedView = view

            this.sortStorageService.updateIsSetValue(view.guid)
            this.sortEntitiesArray = Object.values(this.sortService.getSortByView(view))

            this.setSortObjectsArray(columnGuid)

            this.setSortMenuForm()

            this.fields = fields

            this.refreshSaveAvailability()
        })
    }

    private combineViewAndFields(folder: Folder) {
        return this.viewFacadeService.selectedView$.pipe(
            filter(Boolean),
            switchMap((view) =>
                this.schemaFacadeService
                    .selectSelectedTableSchemaFieldEntitiesFiltered$(folder)
                    .pipe(
                        untilDestroyed(this),
                        map((fieldEntities) => {
                            if (!fieldEntities) return []
                            return Object.values(fieldEntities)
                        }),
                        switchMap((fields) => {
                            return combineLatest([
                                of(view),
                                of(fields),
                                this.sortService.getSortGuidFromColumn$,
                                this.sortStorageService.getStore$(),
                            ])
                        }),
                    ),
            ),
        )
    }

    private setSortMenuForm() {
        this.sortMenuForm = new FormGroup(
            this.sortObjectsArray.reduce((sortControls, sortObject) => {
                sortControls[sortObject.fieldGuid] = new FormGroup<SortItemFormGroup>({
                    select: new FormControl(sortObject.fieldGuid),
                    direction: new FormControl<SortDirection | null>(sortObject.sortDirection),
                })
                return sortControls
            }, {} as { [sortGuid: string]: FormGroup<SortItemFormGroup> }),
        )

        this.sortMenuForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.refreshSaveAvailability()
        })

        dirtyCheck(this.sortMenuForm, of(this.sortMenuForm.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    private refreshSaveAvailability() {
        this.isSessionSaveAvailable = !isEqual(this.sortObjectsArray, this.sortEntitiesArray)
        this.isSaveAvailable = this.isSessionSaveAvailable || this.isSessionValueSet
    }

    private saveSortToView(sortObjects: SortObjectEntities) {
        const data = this.createRecordUpdateObjectForSortSave(this.selectedView, sortObjects)

        this.onUpdateValue(
            this.viewFacadeService.updateViewRequest(this.selectedView, {
                sort: {
                    cell: this.selectedView.sort,
                    newValue: data.value,
                },
            }),
        )

        this.sortStorageService.remove(this.selectedView.guid)
    }

    private createRecordUpdateObjectForSortSave(view: View, sortEntity: SortObjectEntities) {
        const sortEntityString = JSON.stringify(sortEntity)
        return {
            record: view,
            cell: view.sort,
            value: sortEntityString,
        } as RecordUpdate
    }

    private saveSortToStorage(sortObjects: SortObjectEntities) {
        this.sortService.setSortToStorage(this.selectedView.guid, sortObjects)
        this.sortService.clearSortGuid()
        this.onUpdateValue(this.sortStorageService.isSet$())
    }

    private reset() {
        this.sortStorageService.remove(this.selectedView.guid)
        this.sortService.clearSortGuid()
        this.onUpdateValue(this.sortStorageService.isSet$())
    }

    private setSortObjectsArray(columnGuid: string | null) {
        if (!columnGuid || !this.isColumnGuidAvailable(columnGuid)) {
            this.sortObjectsArray = this.sortEntitiesArray.length
                ? cloneDeepWith(this.sortEntitiesArray)
                : this.setEmptySortArray(generateUuid())
            return
        }

        this.setObjectsArrayWithColumnSort(columnGuid)
    }

    private isColumnGuidAvailable(columnGuid: string) {
        return !this.sortEntitiesArray.find((object) => object.fieldGuid === columnGuid)
    }

    private setObjectsArrayWithColumnSort(columnGuid: string) {
        const sortObjectFromColumn: SortObject = {
            fieldGuid: columnGuid,
            sortDirection: SortDirection.DESC,
        }

        this.sortObjectsArray = this.sortEntitiesArray.length
            ? [...cloneDeepWith(this.sortEntitiesArray), sortObjectFromColumn]
            : [sortObjectFromColumn]
    }

    private convertArrayToObject(sortObjectsArray: SortObject[]) {
        return sortObjectsArray.reduce((sortObjects, object) => {
            sortObjects[object.fieldGuid] = object
            return sortObjects
        }, {} as SortObjectEntities)
    }

    private filterEmptyOptions() {
        return this.sortObjectsArray.filter(
            (sortObject) => sortObject.fieldGuid.length && !!sortObject.sortDirection,
        )
    }

    private filterFieldsBySortObjects(objectsArray: SortObject[]) {
        return this.fields.filter(
            (field) => !objectsArray.find((sortObject) => sortObject.fieldGuid === field.guid),
        )
    }

    private setEmptySortArray(guid: string) {
        return [
            {
                fieldGuid: guid,
            } as SortObject,
        ]
    }

    private onUpdateValue(updateObservable: Observable<ObjectResponseModel | boolean>) {
        this.sortService.clearSortGuid()
        this.showLoader = true
        updateObservable.pipe(take(1)).subscribe({
            next: (data) => {
                this.processUpdateSortData(data)
            },
            error: (error) => {
                this.showLoader = false
                this.errors = [error]
            },
            complete: () => {
                this.showLoader = false
            },
        })
    }

    private processUpdateSortData(data: ObjectResponseModel | boolean) {
        this.showLoader = false
        if (typeof data === 'boolean') {
            this.close()
            return
        }
        if (data.status === 'success') {
            this.isSessionValueSet = false
            this.sortStorageService.remove(this.selectedView.guid)
            this.close()
            return
        }
        this.errors = data.error?.map((error) => error.message)
    }
}
