import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { SelectObjectOptions } from '@core/models/response/select-object-options'
import { TranslocoService } from '@ngneat/transloco'
import { Observable, of } from 'rxjs'
import { CellComponent } from '../../cell.component'

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.sass'],
})
export class StatusComponent extends CellComponent implements OnInit, OnChanges {
    statusData!: SelectObjectOptions
    default!: string

    constructor(private translation: TranslocoService) {
        super()
    }

    optionKeys() {
        if (this.statusData) {
            return Object.keys(this.statusData)
        }
        return []
    }

    ngOnInit(): void {
        if (this.field) {
            this.statusData = this.field.select_object_field!
            if (this.statusData) {
                this.default = Object.keys(this.statusData).find(
                    (guid) => this.statusData[guid].is_default == 1,
                )!
            }
            if (!this.default) {
                throw new Error('error no default')
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.value && !changes.field) return
        const fieldCurrentValue = changes?.field?.currentValue
        const valueCurrentValue = changes?.value?.currentValue

        if (fieldCurrentValue) {
            this.statusData = fieldCurrentValue.select_object_field!
            if (this.statusData) {
                this.default = Object.keys(this.statusData).find(
                    (guid) => this.statusData[guid].is_default === 1,
                )!
            }
            if (!this.default) {
                console.log('error no default')
            }
        } else if (valueCurrentValue) {
            this.value = valueCurrentValue
        }
    }

    checkActive(val: string) {
        if (this.value != val) {
            this.cellValueChanged.emit(val)
        }
    }

    getTitleColor(id: string | undefined) {
        if (this.statusData) {
            if (this.statusData[id!]) {
                return this.statusData[id!].color
            } else {
                return '#aa2255'
            }
        }
        return ''
    }

    getTitle(id: string | undefined): Observable<string> {
        if (this.statusData) {
            if (this.statusData[id!]) {
                return of(this.statusData[id!].label)
            } else {
                return this.translation.selectTranslate('cell_types.dropdown_default_title')
            }
        }
        return of('')
    }

    getIcon(id: string | undefined) {
        if (this.statusData) {
            if (this.statusData[id!]) {
                return this.statusData[id!].icon
            } else {
                return 'beach_access'
            }
        }
        return ''
    }
}
