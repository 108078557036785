<app-tb-menu class="w-full" [template]="pickerTemplate" (stateChanged)="isMenuOpened($event)">
    <div class="rounded-lg inline-flex w-full" trigger>
        <div
            [class]="{'trigger-item': name && !opened, 'opened-menu-trigger-item': name && opened}"
        >
            <ng-container *ngIf="name; else content">
                <div class="name-container">
                    <div>{{ name }}</div>
                    <div class="name-item-container">
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-template #content>
                <ng-content select="[trigger]"></ng-content>
            </ng-template>
        </div>
    </div>
</app-tb-menu>

<ng-template #pickerTemplate let-containerLayout="containerLayout" let-container="container">
    <div
        class="picker-menu-template"
        [class]="containerLayout === ModalContainer.BottomSheet ? '' : 'max-w-[452px] h-[160px]' "
    >
        <app-tb-button
            *ngIf="showClear"
            appearance="icon"
            icon="close"
            (click)="selectItem.emit(null)"
            class="clear-picker-component"
            [class]="selectedItem === null ? 'border-newText hover:border-newText' : 'border-transparent'"
        ></app-tb-button>

        <div *ngFor="let item of items" (click)="selectItem.emit(item)">
            <ng-container
                *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
            ></ng-container>
        </div>
    </div>
</ng-template>
