import { RecordCellModel } from '@core/models/request/record-request.model'

export interface CreateSubtaskModel {
    solution_object_type_guid: string
    parent_guid: string
    folders_guid: string
    cells: RecordCellModel[]
}

export interface UpdateSubtaskModel {
    solution_object_type_guid: string
    records: UpdateSubtaskRecordModel[]
}

export interface UpdateSubtaskRecordModel {
    record_guid: string
    record_revision: string
    parent_guid: string
    folders_guid: string
    cells: RecordCellModel[]
}
