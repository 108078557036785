import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatMenuModule } from '@angular/material/menu'
import { TbMenuComponent } from '@components-library/tb-menu/tb-menu.component'

@NgModule({
    declarations: [TbMenuComponent],
    imports: [CommonModule, MatMenuModule],
    exports: [TbMenuComponent],
})
export class TbMenuModule {}
