<ng-container *ngIf="field.operationCode; else operationCodeError">
    <ng-container *transloco="let tTableColumnMenu; read: 'table.column_menu'">
        <div (click)="sortColumn.emit(field.guid)">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'sort', $implicit: tTableColumnMenu('sort')}"
            >
            </ng-container>
        </div>

        <div (click)="pinColumn.emit(field.guid)" *ngIf="isPinAvailable">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'pin_drop', $implicit: tTableColumnMenu('pin')}"
            >
            </ng-container>
        </div>

        <div *ngIf="isPinned" (click)="unPinColumn.emit(field.guid)">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'pin_drop', $implicit: tTableColumnMenu('unpin')}"
            >
            </ng-container>
        </div>

        <div *ngIf="!isNameColumn" (click)="hideColumn.emit(guid)">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'remove_red_eye', $implicit: tTableColumnMenu('hide')}"
            >
            </ng-container>
        </div>
        <!--TODO: combine share and move dialogs-->
        <div (click)="shareWith.emit(field)" *ngIf="isShareAvailable">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'folder_shared', $implicit: tTableColumnMenu('share')}"
            >
            </ng-container>
        </div>

        <div
            *appOperation="field.operationCode; target: 'freeze'; condition: !isNameColumn"
            (click)="moveFieldToFolder.emit(field)"
        >
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'folder', $implicit: tTableColumnMenu('move')}"
            >
            </ng-container>
        </div>

        <div
            *appOperation="field.operationCode; target: 'update'; condition: !isNameColumn"
            (click)="editField.emit(field)"
        >
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'settings', $implicit: tTableColumnMenu('settings')}"
            >
            </ng-container>
        </div>

        <div (click)="resetWidth.emit(guid)" *ngIf="isCustomWidth">
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'update', $implicit: tTableColumnMenu('reset')}"
            >
            </ng-container>
        </div>

        <div
            *appOperation="field.operationCode; target: 'copy'"
            (click)="copyColumn.emit(field.guid)"
        >
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'file_copy', $implicit: tTableColumnMenu('copy')}"
            >
            </ng-container>
        </div>

        <div
            *appOperation="field.operationCode; target: 'delete'"
            (click)="deleteColumn.emit(field.guid)"
        >
            <ng-container
                *ngTemplateOutlet="option; context: {icon: 'delete', $implicit: tTableColumnMenu('delete')}"
            >
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #option let-icon="icon" let-name>
    <button mat-menu-item>
        <mat-icon>{{ icon }}</mat-icon>
        <span>{{ name }}</span>
    </button>
</ng-template>

<ng-template #operationCodeError>
    <!--    TODO: add UI for operationCodeError-->
    operationCodeError
</ng-template>
