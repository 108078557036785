import { ComponentType } from '@angular/cdk/overlay'
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import {
    MAT_MENU_DEFAULT_OPTIONS,
    MatMenuDefaultOptions,
    MatMenuTrigger,
    MenuPositionX,
    MenuPositionY,
} from '@angular/material/menu'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ModalContainerComponent } from '../tb-modal-manager/modal-container-component/modal-container.component'
import { TemplateContainerComponent } from '../tb-modal-manager/template-container/template-container.component'
import { TbMenuState } from './models'

@UntilDestroy()
@Component({
    selector: 'app-tb-menu',
    templateUrl: './tb-menu.component.html',
    styleUrls: ['./tb-menu.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbMenuComponent implements AfterViewInit {
    @Input()
    container: ComponentType<ModalContainerComponent> | null = null

    @Input()
    template?: TemplateRef<any>

    @Input()
    data: unknown

    @Input()
    replaceable: boolean = false

    @Input()
    xPosition: MenuPositionX = this.matMenuDefaultOptions.xPosition

    @Input()
    yPosition: MenuPositionY = this.matMenuDefaultOptions.yPosition

    @Output()
    closed = new EventEmitter<unknown>()

    @Output()
    stateChanged = new EventEmitter<TbMenuState>()

    @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger
    @ViewChild('content', { read: ViewContainerRef }) menuContent!: ViewContainerRef

    modalManager!: ModalManagerService

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_MENU_DEFAULT_OPTIONS) private matMenuDefaultOptions: MatMenuDefaultOptions,
        private modalFlowManagerService: ModalFlowManagerService,
    ) {}

    ngAfterViewInit(): void {
        if (!this.menuTrigger) {
            throw new Error('trigger is required in app-tb-menu')
        }

        this.menuTrigger.menuOpened.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.menuTrigger.menuOpen) return

            const componentRef = this.modalManager.getContainerStore().getComponentRef()
            if (!componentRef) return

            componentRef.setInput('containerLayout', ModalContainer.Menu)

            this.menuContent.detach()
            this.menuContent.insert(componentRef.hostView)
            this.changeDetectorRef.detectChanges()

            this.stateChanged.emit(TbMenuState.Open)
        })

        this.menuTrigger.menuClosed.pipe(untilDestroyed(this)).subscribe(() => {
            this.stateChanged.emit(TbMenuState.Closed)
        })
    }

    openMenu() {
        const container = this.container || TemplateContainerComponent
        this.modalManager = this.modalFlowManagerService.createModalManager()
        this.modalFlowManagerService.addFlow(this.modalManager)

        this.modalManager
            .openMenu({
                menuTrigger: this.menuTrigger,
                component: container,
                template: this.template,
                data: this.data,
                replaceable: this.replaceable,
            })
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.stateChanged.emit(TbMenuState.Closed)
                this.closed.emit(data)
            })
    }
}
