import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core'
import { MultiselectItemsService } from '@components-library/services/multiselect-items.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map } from 'lodash-es'

@UntilDestroy()
@Component({
    selector: 'app-tb-board-column-header',
    templateUrl: './tb-board-column-header.component.html',
    styleUrls: ['./tb-board-column-header.component.sass'],
})
export class TbBoardColumnHeaderComponent<Item, Column> implements OnInit, OnChanges {
    @Input({ required: true })
    items!: Item[]

    @Input()
    moreMenu!: TemplateRef<any>

    @Input({ required: true })
    columnItem!: Column

    @Input({ required: true })
    key!: keyof Item

    @Output()
    collapseChanged = new EventEmitter<boolean>()

    collapsed = false

    itemsId: string[] = []

    selectedItems!: string[]

    toggleCollapseBound = this.toggleCollapse.bind(this)

    constructor(private multiselectService: MultiselectItemsService) {
        multiselectService
            .getSelectedItems$()
            .pipe(untilDestroyed(this))
            .subscribe((selectedItems) => {
                this.selectedItems = selectedItems
            })
    }

    ngOnChanges(): void {
        this.setItemsId()
        this.checkCollapsedState()
    }

    ngOnInit(): void {
        this.setItemsId()
        this.checkCollapsedState()
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed
        this.collapseChanged.emit(this.collapsed)
    }

    toggleSelection(selected: boolean) {
        if (selected) {
            this.multiselectService.selectItems(this.itemsId)
            return
        }

        this.multiselectService.deselectItems(this.itemsId)
    }

    checkCollapsedState() {
        const collapsed = this.items.length === 0
        if (collapsed === this.collapsed) return

        this.collapsed = collapsed
        this.collapseChanged.emit(this.collapsed)
    }

    get isAllItemsSelected() {
        return this.multiselectService.areSelected(this.itemsId)
    }

    get isSomeItemsSelected() {
        return this.itemsId.some((item) => this.multiselectService.isSelected(item))
    }

    get isIndeterminate() {
        return !this.isAllItemsSelected && this.isSomeItemsSelected
    }

    private setItemsId() {
        const item = this.items[0]
        if (!item) return

        if (typeof item[this.key] !== 'string') {
            throw new Error('item[key] should be a string value')
        }

        this.itemsId = map(this.items, this.key) as string[]
    }
}
