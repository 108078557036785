import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TbSelectValue } from '@components-library/tb-select/models'
import {
    Field,
    FieldEntities,
    FieldType,
    FilterCombinationTypes,
    FilterGroupsCombinationTypes,
    Folder,
} from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { isArray } from 'lodash-es'
import { FormFilterService } from '@app/views/view-controls/view-filter/form-filter.service'

@UntilDestroy()
@Component({
    selector: 'app-filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.sass'],
})
export class FilterGroupComponent implements OnInit {
    @Input()
    fields!: FieldEntities

    @Input()
    fieldTypes!: FieldType[]

    @Input()
    single!: boolean

    @Input()
    selectedFolder!: Folder

    @Input()
    isEmpty!: boolean

    @Input()
    groupIndex!: number

    @Output()
    deleteGroup: EventEmitter<void> = new EventEmitter<void>()

    @Output()
    clearAll: EventEmitter<void> = new EventEmitter<void>()

    filterGroupsCombinationTypes = FilterGroupsCombinationTypes
    filterCombinationTypes = FilterCombinationTypes

    constructor(public formFilterService: FormFilterService) {}

    get isCombinationGroupToggleDisabled() {
        return this.groupIndex !== 1
    }

    get fieldGuids() {
        return Object.keys(this.fields!)
    }

    get first() {
        return !this.groupIndex
    }

    get filterCombinationType() {
        return this.formGroup.get('filterCombinationType')!.value
    }

    get formItems() {
        return this.formFilterService.getFilterGroupValues(this.groupIndex).controls as FormGroup[]
    }

    get formGroup() {
        return this.formFilterService.getFilterFormArray.at(this.groupIndex) as FormGroup
    }

    ngOnInit(): void {
        if (
            !this.first &&
            !this.formFilterService.getFilterGroupValues(this.groupIndex) &&
            this.fieldGuids[0]
        ) {
            this.addFilter(this.fieldGuids[0])
        }
    }

    deleteFilter(itemIndex: number) {
        this.formFilterService.deleteFilterItem(this.groupIndex, itemIndex)
    }

    addFilterClick(guid: TbSelectValue) {
        if (!guid || isArray(guid) || this.formGroup.invalid) {
            return
        }

        this.addFilter(guid as string)
    }

    addFilter(guid: string) {
        this.formFilterService.addFilterItem(this.groupIndex, guid)
    }

    getFieldByGuid(fieldGuid: string): Field {
        return this.fields[fieldGuid]
    }

    isShowFilterCombinationToggle() {
        return (
            !this.isEmpty && this.formFilterService.getFilterGroupValues(this.groupIndex).length > 1
        )
    }

    groupCombinationTypeChange(filterGroupsCombinationType: string) {
        this.formFilterService.setCombinationGroupType(filterGroupsCombinationType)
    }

    combinationTypeChange(filterCombinationType: string) {
        this.formFilterService.setCombinationType(this.groupIndex, filterCombinationType)
    }
}
