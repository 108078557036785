<mat-radio-button
    [value]="value"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onRadioButtonValueChange($event)"
>
    <ng-container *ngIf="!slotText.children.length">
        <span> {{ label }} </span>
    </ng-container>
    <div #slotText>
        <ng-content></ng-content>
    </div>
</mat-radio-button>
