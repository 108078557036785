import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { View } from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ViewFacadeService } from '@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-views-list-menu',
    templateUrl: './views-list-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewsListMenuComponent {
    @Input() view!: View

    constructor(private viewFacadeService: ViewFacadeService) {}

    fakeUpdate(view: View) {
        this.viewFacadeService
            .updateViewRequest(view, {
                name: { cell: view.name, newValue: view.name.value + '1' },
            })
            .subscribe()
    }

    deleteView(view: View) {
        this.viewFacadeService.deleteViewRequest(view).subscribe()
    }
}
