import { Component, Input } from '@angular/core'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { SolutionModel, User } from '@core/models'
import { NavigatorService } from '@core/services/navigator.service'
import { CommonFacadeService } from '@core/services/store-facade'
import { DevStorageService } from '@services/local-storage/dev-storage.service'
import { WsProcessingService } from '@services/ws-processing.service'
import { DevKeys, SolutionType } from '@test/dev-utils'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-user-settings-content',
    templateUrl: './user-settings-content.component.html',
    styleUrls: ['./user-settings-content.component.sass'],
})
export class UserSettingsContentComponent {
    @Input()
    userInfo!: User
    @Input()
    currentUserIsAdmin!: number | undefined

    solution$: Observable<SolutionModel> = this.commonFacadeService.selectSolution$

    devSolutionTypes: SolutionType[] = ['TM', 'CRM']
    devDefaultSolutionValue = this.devStorageService.getSolutionType() ?? 'CRM'

    constructor(
        private commonFacadeService: CommonFacadeService,
        private navigatorService: NavigatorService,
        private wsProcessingService: WsProcessingService,
        private devStorageService: DevStorageService,
        private confirmationDialogService: ConfirmationDialogService,
    ) {}

    navigateNotifications() {
        this.navigatorService.goToNotificationPage()
    }

    logout() {
        this.wsProcessingService.logout()
    }

    resetSolution() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'user_settings.reset_solution_confirmation_title',
                    message: 'user_settings.reset_solution_confirmation_message',
                    cancel: 'user_settings.reset_solution_cancel',
                    confirm: 'user_settings.reset_solution_confirm',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.commonFacadeService.resetSolutionToTemplate()
                }
            })
    }

    devSolutionChanged(val: string) {
        this.devStorageService.setSolutionType(val as SolutionType)
        window.location.reload()
    }
}
