<div class="flex items-center w-initial h-10 m-4 px-4 rounded-lg bg-primaryDark">
    <div class="flex items-center justify-between w-full">
        <div *transloco="let tTable; read: 'table'">
            <app-tb-checkbox
                (valueChange)="clearAllSelected()"
                [value]="true"
                class="mr-2"
            ></app-tb-checkbox>
            <span class="text-white"
                >{{ tTable('actions_panel_count', { count: selectedCount }) }}
            </span>
        </div>
        <div class="flex items-center">
            <div class="max-xsm:hidden">
                <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
            </div>
            <!-- incorrect bg color -->
            <app-tb-button
                [appearance]="'icon'"
                [dark]="true"
                [icon]="'more_vert'"
                (click)="openBottomSheetS()"
                class="xsm:hidden h-6"
            ></app-tb-button>
            <app-tb-button
                [appearance]="'icon'"
                [dark]="true"
                [icon]="'close'"
                (click)="clearAllSelected()"
                aria-label="Close"
                class="h-5 ml-6"
            ></app-tb-button>
        </div>

        <mat-menu class="px-2 mt-2" #fieldsMenu="matMenu">
            <!--            todo: [table-ref-2] will we use  this meu somewhere else? if so, then we should create separate component for  that-->
            <ng-template matMenuContent>
                <app-tb-button
                    [appearance]="'text'"
                    (click)="editField(field)"
                    *ngFor="let field of fieldsList"
                    class="flex items-center justify-start w-full"
                >
                    <span class="w-full d-flex">
                        <app-field-name
                            [field]="field"
                            [selectedFolder]="selectedFolder"
                            [showIcon]="true"
                        ></app-field-name>
                    </span>
                </app-tb-button>
            </ng-template>
        </mat-menu>

        <!-- No design, final solution will be implemented later, for testing purposes -->
        <ng-template #automationMenu>
            <div *transloco="let tButtons; read 'buttons'">
                <p>
                    <button (click)="createAutomation()">
                        {{ tButtons('create_automation') }}
                    </button>
                </p>
                <p>
                    <app-tb-menu [template]="automationUpdate" xPosition="after">
                        <button trigger>{{ tButtons('update_automation') }}</button>
                    </app-tb-menu>
                </p>
                <p>
                    <app-tb-menu [template]="automationDelete" xPosition="after">
                        <button trigger>{{ tButtons('delete_automation') }}</button>
                    </app-tb-menu>
                </p>
                <p>
                    <app-tb-menu [template]="automationExecute" xPosition="after">
                        <button trigger>{{ tButtons('execute_automation') }}</button>
                    </app-tb-menu>
                </p>
            </div>
        </ng-template>

        <ng-template #automationUpdate>
            <div *ngFor="let automation of (automations$ | async)">
                <button (click)="updateAutomation(automation)">{{ automation.name }}</button>
            </div>
        </ng-template>

        <ng-template #automationDelete>
            <div *ngFor="let automation of (automations$ | async)">
                <button (click)="deleteAutomation(automation.guid)">{{ automation.name }}</button>
            </div>
        </ng-template>

        <ng-template #automationExecute>
            <div *ngFor="let automation of (automations$ | async)">
                <button (click)="executeAutomation(automation.guid)">{{ automation.name }}</button>
            </div>
        </ng-template>

        <ng-template #automationButton>
            <button
                *transloco="let tButtons; read 'buttons'"
                class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
            >
                <mat-icon class="h-5 w-5 text-lg leading-5"> build</mat-icon>
                <span class="max-xsm:block max-md:hidden">{{ tButtons('automations') }}</span>
            </button>
        </ng-template>

        <ng-template #actionButtons>
            <div
                *transloco="let tButtons; read 'buttons'"
                class="flex items-center max-xsm:flex-col gap-2 w-full max-xsm:body-1"
            >
                <div
                    (click)="closeBottomSheetS()"
                    class="xsm:hidden flex w-full body-2 my-4 cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5 mr-2">arrow_back</mat-icon>
                    <div>{{ tButtons('back') }}</div>
                </div>

                <app-tb-menu *appEnv="'prod'" [template]="automationMenu" xPosition="before">
                    <ng-container trigger *ngTemplateOutlet="automationButton"></ng-container>
                </app-tb-menu>

                <app-tb-menu
                    *appEnv="'prod'; visible: true"
                    [template]="automationExecute"
                    xPosition="before"
                >
                    <ng-container trigger *ngTemplateOutlet="automationButton"></ng-container>
                </app-tb-menu>

                <!-- will be investigated further by designer, could be changed after investigation -->
                <app-tb-button
                    *appEnv="'prod'"
                    class="mb-1"
                    [dark]="true"
                    [icon]="'people_outline'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden body-2">
                        {{ tButtons('set_assignee') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    *appEnv="'prod'"
                    class="mb-1"
                    [dark]="true"
                    [icon]="'task_alt'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('set_status') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    class="mb-1"
                    [dark]="true"
                    [icon]="'star_outline'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('favorite') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    *appEnv="'prod'"
                    class="mb-1"
                    [dark]="true"
                    [icon]="'share'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('share') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    *appEnv="'prod'"
                    class="mb-1"
                    [dark]="true"
                    [icon]="'custom_copy'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('duplicate') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    *appEnv="'prod'"
                    [class.bg-secondaryMain]="trigger.menuOpen"
                    #trigger="matMenuTrigger"
                    [mat-menu-trigger-for]="fieldsMenu"
                    class="mb-1"
                    [dark]="true"
                    [icon]="'cached'"
                    [appearance]="'text'"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('edit') }}
                    </span>
                </app-tb-button>
                <app-tb-button
                    class="mb-1"
                    [dark]="true"
                    [icon]="'custom_trash'"
                    [appearance]="'text'"
                    (click)="deleteAllRecords()"
                >
                    <span class="inline-flex max-xsm:block max-md:hidden">
                        {{ tButtons('delete') }}
                    </span>
                </app-tb-button>
            </div>
        </ng-template>
    </div>
</div>
