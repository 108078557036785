export interface RecordCellModel {
    value: string
    field_guid: string
    revision?: string
}

export interface UpdateRecordModel {
    solution_object_type_guid: string
    records: UpdateRecordInnerModel[]
}

export interface UpdateRecordInnerModel {
    record_guid: string
    record_revision: string
    parent_sot_guid?: string
    cells: RecordCellModel[]
    folders_guid: string
}

export interface CreateRecordModel {
    solution_object_type_guid: string
    parent_sot_guid: string | undefined
    folders_guid: string
    cells: RecordCellModel[]
}

export interface DeleteRecordModel {
    records: DeleteRecordInfo[]
    solution_object_type_guid: string
}

export interface DeleteRecordInfo {
    record_guid: string
    record_revision: string
}
