import { FieldTypes } from '../response'
import { SelectOption } from '../response/select-object-options'
import { AccessModel } from './access.model'
import { FieldGuid, RequestCreateCell, RequestUpdateCell } from './common.model'

export type FolderCreateSotType = 'CRM.CONTACT' | 'CRM.COMPANY' | 'CRM.DEAL'

export interface FolderCreateViews {
    [type: string]: boolean
}

export interface FolderCreateField {
    field_type_code: FieldTypes | string
    name: string
    template_field_guid?: string
    select_object_field?: SelectOption[]
}

export interface FolderCreateModel {
    configuration: Record<FieldGuid, RequestCreateCell>
    views?: Record<FolderCreateSotType, FolderCreateViews>
    fields?: Record<FolderCreateSotType, FolderCreateField[]>
    access?: AccessModel
}

export interface FolderDeleteModel {
    record_guid: string
    record_revision: number
}

export interface FolderUpdateModel {
    record_guid: string
    record_revision: number
    configuration?: Record<FieldGuid, RequestUpdateCell>
    access?: AccessModel
}
