<div [formGroup]="formGroup" class="mb-lg" *transloco="let tFilter; read: 'filter'">
    <ng-container *ngIf="!single && !first">
        <app-filter-combination-toggle
            class="flex w-fit mx-auto my-4 andOrToggle"
            [disabled]="isCombinationGroupToggleDisabled"
            [value]="formFilterService.getGroupsCombinationType"
            [combinationTypes]="filterGroupsCombinationTypes"
            (valueChange)="groupCombinationTypeChange($event)"
            translateKey="filter.groups_combination_types"
        ></app-filter-combination-toggle>
    </ng-container>
    <div [class.notEmpty]="!isEmpty" [formGroup]="formGroup">
        <div [class.emptyTemplate]="isEmpty">
            <ng-container *ngIf="!isEmpty">
                <ng-container *ngTemplateOutlet="filterItem"></ng-container>
            </ng-container>

            <div *ngIf="fieldGuids" class="p-0 w-full">
                <ng-container
                    *ngIf="isEmpty; then emptySelector; else regularSelector"
                ></ng-container>
            </div>
        </div>
    </div>

    <ng-template #filterItem>
        <div [class.sm:mb-4]="!isEmpty" class="flex items-center p-0 text-primarySecondary">
            <div>{{tFilter('where_label')}}</div>
            <div class="flex items-center" *ngIf="isShowFilterCombinationToggle()">
                <app-filter-combination-toggle
                    class="mx-4"
                    [value]="filterCombinationType"
                    [combinationTypes]="filterCombinationTypes"
                    (valueChange)="combinationTypeChange($event)"
                ></app-filter-combination-toggle>
                <div>{{tFilter('where_conditions_match')}}</div>
            </div>
        </div>
        <ng-container *ngIf="formGroup.value">
            <app-filter-item
                (deleteFilter)="deleteFilter(itemIndex)"
                (deleteGroup)="deleteGroup.emit()"
                [itemIndex]="itemIndex"
                [groupIndex]="groupIndex"
                *ngFor="let formItem of formItems; index as itemIndex"
                [fieldTypes]="fieldTypes"
                [fieldGuids]="fieldGuids"
                [totalItems]="formFilterService.getFilterGroupValues(this.groupIndex).length"
                [fields]="fields"
                [selectedFolder]="selectedFolder"
            ></app-filter-item>
        </ng-container>
    </ng-template>

    <ng-template #emptySelector>
        <span class="flex start space-x-4" *transloco="let tFilter; read: 'filter'">
            <div class="py-3">{{tFilter('where_label')}}</div>
            <app-tb-select
                class="w-full"
                [placeholder]="tFilter('select_filter')"
                [focusInitial]="true"
                (selectionChange)="addFilterClick($event)"
            >
                <app-tb-option *ngFor="let fieldGuid of fieldGuids" [value]="fieldGuid">
                    <app-field-name
                        [field]="getFieldByGuid(fieldGuid)"
                        [selectedFolder]="selectedFolder"
                        [showIcon]="false"
                    ></app-field-name>
                </app-tb-option>
            </app-tb-select>
        </span>
    </ng-template>

    <ng-template #regularSelector>
        <app-tb-menu [template]="menuContent" [class.disabled-menu-trigger]="formGroup.invalid">
            <div trigger class="flex justify-between" *transloco="let tFilter; read: 'filter'">
                <app-tb-button
                    [disabled]="formGroup.invalid"
                    [appearance]="'text'"
                    [icon]="'add_circle_outline'"
                >
                    <span class="body-2">{{tFilter('add_filter')}}</span>
                </app-tb-button>
                <ng-container *ngIf="!single">
                    <app-tb-button
                        [icon]="'close'"
                        [appearance]="'text'"
                        [color]="'secondary'"
                        (click)="deleteGroup.emit()"
                    >
                        <span class="body-2">{{tFilter('delete_condition_group')}}</span>
                    </app-tb-button>
                </ng-container>
            </div>
        </app-tb-menu>

        <ng-template #menuContent let-layout>
            <app-tb-menu-list [containerLayout]="layout">
                <app-tb-menu-list-item
                    (click)="$event.stopPropagation(); addFilterClick(fieldGuid)"
                    *ngFor="let fieldGuid of fieldGuids"
                >
                    <app-field-name
                        [field]="getFieldByGuid(fieldGuid)"
                        [selectedFolder]="selectedFolder"
                        [showIcon]="false"
                    ></app-field-name>
                </app-tb-menu-list-item>
            </app-tb-menu-list>
        </ng-template>
    </ng-template>
</div>
