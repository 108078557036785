<mat-button-toggle-group
    *transloco="let tTypes; read: translateKey"
    class="toggle-container"
    (change)="toggle()"
    [disabled]="disabled"
>
    <mat-button-toggle
        *ngFor="let combinationKey of combinationTypesKeys"
        [value]="combinationTypes[combinationKey]"
        [checked]="combinationTypes[combinationKey] === value"
        >{{tTypes(combinationKey)}}</mat-button-toggle
    >
</mat-button-toggle-group>
