export type AutomationEntities = Record<string, AutomationModel>

export interface AutomationModel {
    record?: Record<string, ResponseAutomation>
    added?: Record<string, ResponseAutomation>
    updated?: Record<string, ResponseAutomation>
    deleted?: string[]
}

export enum AutomationTypes {
    AUTO = 'auto',
    MANUAL = 'manual',
}

export interface ResponseAutomation {
    name: string
    automation_type: AutomationTypes | string
    is_active?: number
    last_user_guid: string
    last_timestamp: string
    conditions: AutomationConditionEntities
    actions: AutomationActionEntities
    user_guids?: string[]
    system_note?: string
    fail_count?: number
    is_global?: number
}

export type AutomationConditionEntities = Record<string, AutomationCondition>

export interface AutomationCondition {
    source_type: string
    source_details: string
    values: string[]
}

export type AutomationActionEntities = Record<string, AutomationAction>

export interface AutomationAction {
    action_type_code: 'action_item'
    action_config: AutomationActionConfig
}

export interface AutomationActionConfig {
    target_object_field_guid: string
    value: string
}
