<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="fieldTypes && record && fields; else error">
        <div class="w-100 board-header">
            <app-card-header
                [folderStatuses]="folderStatuses"
                [fieldTypes]="fieldTypes"
                [record]="record"
            ></app-card-header>
            <!-- change to app-tb-button after new design will be implemented -->
            <button (click)="pinRecord()" mat-icon-button>
                <mat-icon *ngIf="isRecordPinned">check</mat-icon>
                <mat-icon> minimize</mat-icon>
            </button>
        </div>

        <div class="card-form">
            <div class="flex-start-center w-100 board-body align-items-start">
                <div class="board-side">
                    <div class="board-side-top">
                        <app-card-title
                            [schema]="schema"
                            [fieldTypes]="fieldTypes"
                            [record]="record"
                            [fields]="fields"
                            [folder]="selectedFolder"
                        ></app-card-title>

                        <div class="card-tabs">
                            <app-tb-tabs
                                [tabs]="cardTabs"
                                [activeTab]="activeTab"
                                (tabOpened)="activeTab = $event"
                            ></app-tb-tabs>
                        </div>
                    </div>

                    <div class="board-side-content" *ngIf="selectedFolder && selectedFolder.guid">
                        <ng-container *ngIf="activeTab?.id === 'details'">
                            <app-card-content
                                [schema]="schema"
                                [description]="selectedRecordDescription"
                                [richTextDescription]="record.richTextDescription ? fields[record.richTextDescription.fieldGuid] : undefined"
                                [fieldTypes]="fieldTypes"
                                [fields]="fields"
                                [folderStatuses]="folderStatusesArr"
                                [record]="record"
                                [status]="selectedRecordStatus"
                                [selectedFolder]="selectedFolder"
                                [cells]="cells"
                            ></app-card-content>
                        </ng-container>
                        <ng-container *ngIf="activeTab?.id === 'subtasks'">
                            <app-card-subtasks
                                [schema]="schema"
                                [record]="record"
                                [fieldTypes]="fieldTypes"
                            ></app-card-subtasks>
                        </ng-container>
                    </div>
                </div>

                <div class="board-side">
                    <div class="board-side-top">
                        <app-card-update
                            [fieldTypes]="fieldTypes"
                            [record]="record"
                            [fields]="fields"
                        ></app-card-update>
                    </div>
                    <div class="board-side-content"></div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #error>
    <!--    TODO: add UI for error-->
    error
</ng-template>
