import { LinkDefinition } from '@core/models'
import { SelectOption } from '@core/models/response/select-object-options'

export interface UpdateFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_guid: string
    system_name?: string
    revision?: number
    configure_json?: string
    is_required?: number
    acl: any
    name?: string
    frontend_validations: any
    select_object_field?: SelectOption[]
    shared_with_folder?: string
    folder_guid?: string
    link_definition?: LinkDefinition
    is_on_top?: number
    default_value?: string
}

export interface CreateFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_type_code: string
    configure_json?: string
    is_required?: number
    acl: any
    name: string
    frontend_validations: any
    folder_guid?: string
    select_object_field?: SelectOption[]
    link_definition?: LinkDefinition
    is_on_top?: number
    default_value?: string
}

export interface DeleteFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_guid: string
    revision?: number
}
