import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
    imports: [CommonModule, MatTooltipModule],
    declarations: [TbTooltipComponent],
    exports: [TbTooltipComponent],
})
export class TbTooltipModule {}
