import { Injectable } from '@angular/core'
import { ratingColorSet, statusColorSet, folderColorSet } from '@app/shared/color-picker/color-sets'
import { SetType } from '@app/shared/models/picker'

interface ColorSet {
    [key: string]: string[]
}

@Injectable({
    providedIn: 'root',
})
export class ColorPickerService {
    constructor() {}

    private readonly colorSet: ColorSet = {
        status: statusColorSet,
        rating: ratingColorSet,
        folder: folderColorSet,
    }

    getColorSet(setType: SetType) {
        return this.colorSet[setType]
    }

    getDefaultColor(pickerType: SetType) {
        if (pickerType === SetType.RATING) {
            return '#FF6E40'
        }

        return this.colorSet['folder'][0]
    }
}
