import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { switchMap } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthResponse } from './auth-service'
import { ConfigService } from './config.service'

export enum AuthResponseStatus {
    SUCCESS = 'success',
    AUTH = 'auth',
    ERROR = 'error',
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {
    constructor(private httpClient: HttpClient, private configService: ConfigService) {}

    login(code?: string) {
        return this.configService.getServerUrl().pipe(
            map((url) => {
                const authUrl = `${url}/api/v1/auth/login`
                return code ? `${authUrl}?code=${code}` : authUrl
            }),
            switchMap((url) => {
                return this.httpClient.get<AuthResponse>(url, {
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                })
            }),
        )
    }

    logout() {
        return this.configService.getServerUrl().pipe(
            switchMap((url) => {
                return this.httpClient.get(`${url}/api/v1/auth/logout`, {
                    withCredentials: true,
                    responseType: 'text',
                    headers: {
                        'solution.logout': 'logout',
                    },
                })
            }),
        )
    }
}
