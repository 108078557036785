<div class="flex items-center edit-mode-form">
    <div [class.active]="!disabled" class="edit-mode-area">
        <input
            ngxDaterangepickerMd
            class="data-input"
            [locale]="{ format: dateLabelFormat }"
            [singleDatePicker]="true"
            [timePicker]="true"
            [placeholder]="valueLocale"
            [timePicker24Hour]="true"
            [customRangeDirection]="false"
            [showDropdowns]="true"
            [showCancel]="true"
            (change)="filterFrom()"
            autocomplete="off"
        />
        <mat-icon (click)="clearDate()" *ngIf="value" class="text-muted"> close </mat-icon>
    </div>
    <div *ngIf="disabled">
        <label> {{valueLocale}} </label>
    </div>
</div>
