import { Injectable } from '@angular/core'
import { BreakpointService, DisplaySize } from '../services/breakpoint.service'
import { MatDrawerMode } from '@angular/material/sidenav'
import { BehaviorSubject } from 'rxjs'

export interface MenuItem {
    id: string
    name: string
    icon: string
    selected: boolean
    action: () => void
}

enum Mode {
    SIDE = 'side',
    OVER = 'over',
    PUSH = 'push',
}

export enum ExpandMods {
    DISABLED,
    EXPANDED,
    COLLAPSED,
}

export interface SideNavState {
    mode: MatDrawerMode
    opened: boolean
    expand: ExpandMods
    canClose: boolean
}

const initialState: SideNavState = {
    mode: Mode.SIDE,
    opened: true,
    expand: ExpandMods.EXPANDED,
    canClose: false,
}

@Injectable({
    providedIn: 'root',
})
export class SideNavigationService {
    private state$$ = new BehaviorSubject<SideNavState>(initialState)
    state$ = this.state$$.asObservable()

    get isOpened() {
        const state = this.state$$.getValue()
        return state.expand === ExpandMods.EXPANDED || state.opened
    }

    constructor(private breakpointService: BreakpointService) {
        breakpointService.displaySize$.subscribe((size: DisplaySize) => {
            const isBigScreen = [DisplaySize.Large, DisplaySize.XLarge].includes(size)

            this.setOpen(isBigScreen)
        })
    }

    setOpen(isOpened: boolean) {
        const size = this.breakpointService.displaySize

        switch (size) {
            case DisplaySize.XSmall:
            case DisplaySize.Small:
                this.patchState({
                    mode: Mode.OVER,
                    opened: isOpened,
                    expand: ExpandMods.DISABLED,
                    canClose: true,
                })
                break
            case DisplaySize.Medium:
                this.patchState({
                    expand: isOpened ? ExpandMods.EXPANDED : ExpandMods.COLLAPSED,
                    mode: isOpened ? Mode.OVER : Mode.SIDE,
                    opened: true,
                })
                break
            case DisplaySize.Large:
            case DisplaySize.XLarge:
                this.patchState({
                    expand: isOpened ? ExpandMods.EXPANDED : ExpandMods.COLLAPSED,
                    mode: Mode.SIDE,
                    opened: true,
                })
                break
            default:
                console.error(new Error(`unexpected screen size ${size}`))
        }
    }

    private patchState(change: Partial<SideNavState>): void {
        this.state$$.next({
            ...initialState,
            ...this.state$$.getValue(),
            ...change,
        })
    }
}
