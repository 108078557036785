<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px]"
    *transloco="let tStatusConfiguration; read: 'status_configuration'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-5 max-sm:pt-4 title text-primaryDark">
        <div class="flex">
            {{tStatusConfiguration('status_configuration')}}
            <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
                <mat-icon
                    mat-dialog-close
                    class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
                >
                    close
                </mat-icon>
            </div>
        </div>
        <div class="leading-4 caption-1 text-primarySecondary">
            {{tStatusConfiguration('status_configuration_description')}}
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-395px)] py-0 px-5 max-sm:px-0"
    >
        <!--        this should possible be remade into a component-->
        <div class="flex mb-[-2px] w-full overflow-auto">
            <!-- text which starts with $ should be dynamically inserted-->
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="false"
                [icon]="'close'"
            ></app-field-configuration-selector>
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="true"
                [customIcon]="'custom_table'"
            ></app-field-configuration-selector>
            <app-field-configuration-selector
                [name]="'Company'"
                [isSelected]="false"
                [icon]="'close'"
            ></app-field-configuration-selector>
        </div>
        <div class="w-full h-[2px] mb-4 rounded-sm bg-primaryLight"></div>

        <!-- text which starts with $ should be dynamically inserted-->
        <div class="flex max-sm:flex-col mb-10 body-1">
            <div class="sm:mr-4">
                <app-template-selector></app-template-selector>
            </div>

            <!--        all <app-status-element> should be dynamically inserted-->
            <div class="flex flex-col gap-6 w-full">
                <div class="flex flex-col gap-3">
                    <div class="px-5 body-2">{{tStatusConfiguration('active_statuses')}}</div>
                    <app-status-element [name]="'Active'" [color]="'red-400'"></app-status-element>
                    <div
                        class="flex items-center gap-2 px-5 body-2 text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                    >
                        <mat-icon class="h-5 w-5 text-xl leading-5">add_circle_outline</mat-icon>
                        {{tStatusConfiguration('add_status')}}
                    </div>
                </div>

                <div class="flex flex-col gap-3">
                    <div class="px-5 body-2">{{tStatusConfiguration('done_statuses')}}</div>
                    <app-status-element [name]="'Done'" [color]="'red-400'"></app-status-element>
                    <div
                        class="flex items-center gap-2 px-5 body-2 text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                    >
                        <mat-icon class="h-5 w-5 text-xl leading-5">add_circle_outline</mat-icon>
                        {{tStatusConfiguration('add_status')}}
                    </div>
                </div>

                <div class="flex flex-col gap-3">
                    <div class="px-5 body-2">{{tStatusConfiguration('closed_statuses')}}</div>
                    <app-status-element [name]="'Closed'" [color]="'red-400'"></app-status-element>
                    <div
                        class="flex items-center gap-2 px-5 body-2 text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                    >
                        <mat-icon class="h-5 w-5 text-xl leading-5">add_circle_outline</mat-icon>
                        {{tStatusConfiguration('add_status')}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="flex justify-end mt-4 py-0 px-5 max-sm:px-0"
        *transloco="let tButtons; read: 'buttons'"
    >
        <app-tb-button (click)="close()" [appearance]="'outlined'" class="mr-4">
            {{tButtons('cancel')}}
        </app-tb-button>
        <app-tb-button> {{tButtons('apply')}} </app-tb-button>
    </div>
</div>
