import { Injectable } from '@angular/core'
import { forEach } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MultiselectItemsService {
    private selectedItems: Set<string> = new Set()
    private readonly selectItems$ = new BehaviorSubject(Array.from(this.selectedItems))

    constructor() {}

    select(value: string) {
        this.selectedItems.add(value)
        this.notifyUpdatedItems()
    }

    selectItems(items: string[]) {
        items.forEach((value) => {
            this.selectedItems.add(value)
        })
        this.notifyUpdatedItems()
    }

    toggle(value: string) {
        if (this.isSelected(value)) {
            this.deselect(value)
            return
        }

        this.select(value)
    }

    toggleItems(values: string[]) {
        if (this.areSelected(values)) {
            this.deselectItems(values)
            return
        }

        this.selectItems(values)
    }

    isSelected(value: string) {
        return this.selectedItems.has(value)
    }

    areSelected(values: string[]) {
        return values.every((value) => {
            return this.isSelected(value)
        })
    }

    deselect(value: string) {
        this.selectedItems.delete(value)
        this.notifyUpdatedItems()
    }

    deselectItems(values: string[]) {
        forEach(values, (value) => {
            this.selectedItems.delete(value)
        })
        this.notifyUpdatedItems()
    }

    deselectAll() {
        this.selectedItems.clear()
        this.notifyUpdatedItems()
    }

    getSelectedItems$() {
        return this.selectItems$.asObservable()
    }

    private notifyUpdatedItems() {
        this.selectItems$.next(Array.from(this.selectedItems))
    }
}
