import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ErrorPageComponent } from '@app/pages/error-page/error-page.component'
import { HomeComponent } from '@app/pages/main/pages/home/home.component'
import { MoveToRouteWithErrorConditionGuard } from '@core/guards/move-to-route-with-error-condition.guard'
import { RedirectToErrorPageIfEmptyDataGuard } from '@core/guards/redirect-to-error-page-if-empty-data.guard'
import { RedirectToSystemRecordGuard } from '@core/guards/redirect-to-system-record.guard'
import { WaitForDataInitGuard } from '@core/guards/wait-for-data-init.guard'
import { ViewErrorComponent } from '@app/pages/main/pages/error/view-error.component'
import { ViewContainerComponent } from '@app/pages/main/pages/view-container/view-container.component'
import { ViewComponent } from '../pages/main/pages/view/view.component'

const routes: Routes = [
    {
        path: '',
        component: ViewContainerComponent,
        canActivate: [WaitForDataInitGuard],
        children: [
            {
                path: 'error-page',
                canActivate: [MoveToRouteWithErrorConditionGuard],
                component: ErrorPageComponent,
                data: {
                    condition: 'no-errors',
                    path: ['view'],
                },
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'view',
            },
            {
                path: 'view',
                canActivate: [RedirectToErrorPageIfEmptyDataGuard],
                children: [
                    {
                        path: 'create',
                        component: ViewErrorComponent,
                    },
                    {
                        path: '',
                        canActivate: [RedirectToSystemRecordGuard],
                        runGuardsAndResolvers: 'always',
                        children: [
                            {
                                path: ':viewGuid',
                                component: ViewComponent,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'myFolder',
        component: HomeComponent,
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ViewRoutingModule {}
