import { BreakpointState } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { ActionReducer } from '@ngrx/store'

@Injectable({
    providedIn: 'root',
})
export class LogService {
    static logStoreActions(reducer: ActionReducer<any>): ActionReducer<any> {
        return function (state, action) {
            LogService.printDebug('action', action, 'state', state)
            return reducer(state, action)
        }
    }

    private static printError(error: Error) {
        console.error(error)
    }

    private static printDebug(...params: any) {
        console.debug(...params)
    }

    resolutionChanged(breakpointState: BreakpointState) {
        LogService.printDebug(breakpointState)
    }

    request(endpoint: string, body: string) {
        LogService.printDebug(endpoint, body)
    }

    error(error: Error) {
        LogService.printError(error)
    }
}
