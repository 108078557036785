import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import {
    FieldEntities,
    FieldType,
    FilterCombinationTypes,
    FilterGroup,
    Folder,
    getFieldEntitiesByFieldTypes,
    ObjectResponseModel,
    View,
} from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@services/store-facade'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, map, take } from 'rxjs/operators'
import { FormFilterService } from '@app/views/view-controls/view-filter/form-filter.service'

export interface FilterDialogData {
    filterGroups: FilterGroup[]
    selectedView: View
}

@UntilDestroy()
@Component({
    selector: 'app-filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.sass'],
    providers: [FormFilterService],
})
export class FilterModalComponent extends ModalContainerComponent implements OnChanges, OnInit {
    filterGroups!: FilterGroup[]
    selectedView!: View
    fields$!: Observable<{ fields: FieldEntities; fieldTypes: FieldType[] } | undefined>

    selectedFolder!: Folder
    showResetDefault: boolean = false

    canAddFilterGroup = false

    isDirty = false

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private filterStorageService: FilterStorageService,
        public formFilterService: FormFilterService,
        private viewFacadeService: ViewFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: any,
    ) {
        super(data, modalManagerService, confirmationDialogService)
        this.filterGroups = data?.filterGroups || []
        this.selectedView = data?.selectedView || []
    }

    get isFilterFormEmpty() {
        return (
            this.formFilterService.getFilterFormArray?.length === 1 &&
            !this.formFilterService.getFilterGroupValues(0).length
        )
    }

    get formGroups(): FormGroup[] {
        return this.formFilterService.getFilterFormArray.controls as FormGroup[]
    }

    get showApplyButton() {
        const filterValue = this.selectedView.filter.value

        return (
            (filterValue && filterValue !== '[]') ||
            this.showResetDefault ||
            this.formFilterService.getFilterFormArray.touched
        )
    }

    get isDisabledApplyAction() {
        return (
            this.formFilterService.getFilterFormArray.untouched ||
            this.formFilterService.getFilterFormArray.invalid
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        const prevGroupLength = this.data.filterGroups.previousValue?.length
        const currGroupLength = this.data.filterGroups.currentValue?.length
        if (prevGroupLength !== currGroupLength && !this.filterGroups?.length) {
            this.formFilterService.addDefaultFilterGroup()
            this.formFilterService.getFilterFormArray.markAsPristine()
            this.formFilterService.getFilterFormArray.markAsUntouched()
        }
    }

    ngOnInit(): void {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(untilDestroyed(this))
            .subscribe((selectedFolder) => (this.selectedFolder = selectedFolder))

        this.initializeFields()

        this.formFilterService.initForm(this.filterGroups)

        this.showResetDefault = !!this.filterStorageService.get(this.selectedView.guid)

        const filterFormArray = this.formFilterService.getFilterFormArray
        filterFormArray.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.canAddFilterGroup = this.formFilterService.canAddFilterGroup
            })
    }

    addGroupAction(event: any) {
        if (this.formFilterService.getFilterFormArray.invalid) {
            return
        }
        event.stopPropagation()
        this.formFilterService.addFilterGroup(FilterCombinationTypes.ALL)
    }

    saveFilter() {
        let rawFilter = this.formFilterService.prepareToSave()

        if (this.isFilterFormEmpty) {
            rawFilter = []
        }

        const newFilter = JSON.stringify(rawFilter)

        if (this.selectedView.filter.value === newFilter) {
            return
        }

        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()

        this.updateFilterValueInSelectedView(newFilter)
    }

    applyFilterForSession() {
        let rawFilter = this.formFilterService.prepareToSave()
        if (this.isFilterFormEmpty) {
            rawFilter = []
        }
        this.filterStorageService.set(this.selectedView.guid, rawFilter)
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
        this.onUpdateValue(this.filterStorageService.isSet$())
    }

    resetToDefault() {
        this.filterStorageService.remove(this.selectedView.guid)
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
        this.onUpdateValue(this.filterStorageService.isSet$())
    }

    deleteGroup(groupIndex: number) {
        if (groupIndex !== -1) {
            this.formFilterService.deleteFilterGroup(groupIndex)
        }

        if (!this.formFilterService.getFilterFormArray.length) {
            this.formFilterService.addDefaultFilterGroup()
        }
    }

    isClearAll() {
        if (this.formFilterService.getFilterFormArray.length > 1) {
            return true
        }

        return this.formFilterService
            .prepareToSave()
            .some((group) => (group.values ? Object.values(group.values).length > 1 : false))
    }

    clearAll() {
        this.formFilterService.getFilterFormArray.clear()
        this.formFilterService.addDefaultFilterGroup()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private initializeFields(): void {
        this.fields$ = combineLatest([
            this.fieldTypeFacadeService.selectAllFieldTypes$,
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                this.selectedFolder,
            ),
        ]).pipe(
            map(([fieldTypes, fieldEntities]) => {
                if (!fieldEntities || !fieldTypes) {
                    return { fields: {}, fieldTypes: [] }
                }

                const fields = getFieldEntitiesByFieldTypes(fieldEntities, fieldTypes)

                return {
                    fields,
                    fieldTypes,
                }
            }),
        )
    }

    private updateFilterValueInSelectedView(filterValue: string) {
        this.onUpdateValue(
            this.viewFacadeService.updateViewRequest(this.selectedView, {
                filter: {
                    cell: this.selectedView.filter,
                    newValue: filterValue,
                },
            }),
        )
    }

    private onUpdateValue(updateObservable: Observable<ObjectResponseModel | boolean>) {
        this.showLoader = true
        updateObservable.pipe(take(1)).subscribe({
            next: (data) => {
                this.processUpdateFilterData(data)
            },
            error: (error) => {
                this.showLoader = false
                this.errors = [error]
            },
            complete: () => {
                this.showLoader = false
            },
        })
    }

    private processUpdateFilterData(data: ObjectResponseModel | boolean) {
        this.showLoader = false
        if (typeof data === 'boolean') {
            this.close()
            return
        }
        if (data.status === 'success') {
            this.showResetDefault = false
            this.filterStorageService.remove(this.selectedView.guid)
            this.close()
            return
        }
        this.errors = data.error?.map((error) => error.message)
    }
}
