<button
    class="flex w-inherit rounded-lg justify-center items-center transition-colors"
    [class]="getClasses()"
    (click)="toggleButton()"
>
    <app-tb-icon [icon]="icon" [filled]="isActive" [class]="label ? 'mr-2' : '' "></app-tb-icon>
    <span *ngIf="label; else content" class="body-2 mr-2"> {{label}} </span>

    <ng-template #content>
        <span class="body-2">
            <ng-content></ng-content>
        </span>
    </ng-template>
</button>
