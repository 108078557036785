import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl, Validators } from '@angular/forms'
import { emailValidationPattern } from '@core/global-util'

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    textFormControl!: FormControl<string | null>

    ngOnInit() {
        this.validatorOrOpts = []

        if (this.field.is_required) {
            this.validatorOrOpts.push(Validators.required)
        }

        this.validatorOrOpts.push(Validators.pattern(emailValidationPattern))

        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            this.validatorOrOpts,
        )
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.textFormControl.invalid || this.value === this.textFormControl.value) return

        this.cellValueChanged.emit(this.textFormControl.value)
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }
}
